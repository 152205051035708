import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/system';
import {
    DataGrid as DataTableGrid,
    DataGridProps as DataTableGridProps,
    DEFAULT_GRID_AUTOSIZE_OPTIONS,
    GRID_DATE_COL_DEF,
    GRID_DATETIME_COL_DEF,
    GridActionsCellItemProps,
    GridActionsColDef,
    GridRowId,
    GridRowModesModel,
    GridValidRowModel,
    useGridApiRef
} from '@mui/x-data-grid';
import { GridBaseColDef, GridSingleSelectColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridRowIdGetter } from '@mui/x-data-grid/models/gridRows';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import * as React from 'react';
import {
    JSXElementConstructor,
    ReactElement,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
import { IPaging } from '@/data/Paging';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import Weekdays from '@/wrappers/Datatable/CellViews/Weekdays';
import RowWithPanel from '@/wrappers/Datatable/RowWithPanel';
import Toolbar from '@/wrappers/Datatable/Toolbar';

export type IDatatableActions<RowType> = {
    cancelRowUpdate: (id: GridRowId) => void;
    finishRowUpdate: (id: GridRowId) => void;
    removeRow: (id: GridRowId) => void;
    startRowUpdate: (id: GridRowId) => void;
    updateRow: (updatedRow: RowType) => void;
};
type ICustomActionColDef<RowType extends GridValidRowModel = any, V = any, F = V> = Omit<
    GridActionsColDef<RowType, V, F>,
    'getActions'
> & {
    getActions: (
        params: GridRowParams<RowType>,
        rowModes: GridRowModesModel
    ) => ReactElement<GridActionsCellItemProps>[];
};
type IGridBaseColDef<RowType extends GridValidRowModel = any, V = any, F = V> = Omit<
    GridBaseColDef<RowType, V, F>,
    'type'
> & {
    type?: GridBaseColDef<RowType, V, F>['type'] | 'color' | 'days';
};
export type IDatatableProps<RowType extends object, V = any, F = V> = Pick<
    DataTableGridProps<RowType>,
    'autoHeight' | 'density' | 'editMode' | 'getRowId' | 'loading' | 'sortingMode' | 'sx' | 'onRowClick' | 'onCellClick'
> & {
    customActionsInToolbar?: ReactElement;
    customAddActionInToolbar?: JSXElementConstructor<{ onSubmit: (newRow: RowType) => void }>;
    data: DataTableGridProps<RowType>['rows'];
    detailPanel?: (row: RowType) => ReactElement;
    header: (
        | IGridBaseColDef<RowType, V, F>
        | ICustomActionColDef<RowType, V, F>
        | GridSingleSelectColDef<RowType, V, F>
    )[];
    hiddenFields?: string[];
    innerRef?: Ref<IDatatableActions<RowType>>;
    name?: string;
    // // editable?: MaterialTableProps<RowType>['editable'];
    noRecordMessage?: string;
    // // rowStyle?: Options<RowType>['rowStyle'];
    // title?: string;
    formatters?: Partial<{
        dateTime: (value?: Date) => string;
        date: (value?: Date) => string;
    }>;
    hasTitle?: boolean;
    hasSelection?: boolean;
    hasSorting?: boolean;
    hasPaginating?: boolean;
    hasSearch?: boolean;
    // truncateCells?: string[];
    labelAddButton?: ReactNode;
    paging?: Partial<IPaging>;
    searchPlaceHolder?: string;
    showQuickFilter?: boolean;
    simplePaging?: boolean;
    onRowAdded?: (row: RowType) => void;
    onRowUpdated?: (updatedRow: RowType, originalRow: RowType) => void;
    onRowRemoved?: (id: number | string) => void;
    onValidateRow?: (newRow: RowType, oldRow: RowType) => Promise<RowType>;
    onPagingChanged?: (page: number, perPage: number) => void;
    onSortingChanged?: (columnName: string, direction: 'asc' | 'desc') => void;
    onSearchChanged?: (searchText: string) => void;
    // filterEnabled?: boolean;
};

const Datatable = <RowType extends object>({
    autoHeight = true,
    customAddActionInToolbar: CustomAddActionInToolbar,
    customActionsInToolbar,
    data,
    detailPanel,
    formatters,
    getRowId,
    hasTitle = true,
    hasSearch = true,
    hasSelection = true,
    hasSorting = true,
    hasPaginating = true,
    header,
    hiddenFields,
    innerRef,
    labelAddButton,
    name = '',
    noRecordMessage,
    paging,
    // filterEnabled,
    searchPlaceHolder,
    showQuickFilter = true,
    // searchPlaceHolder,
    simplePaging = false,
    sortingMode = 'server',
    // rowWrapper,
    onPagingChanged,
    onRowAdded,
    onRowRemoved,
    onRowUpdated,
    onSearchChanged,
    onSortingChanged,
    onValidateRow,
    ...rest
}: IDatatableProps<RowType>) => {
    const [openedDetailPanelIds, setOpenedDetailPanelIds] = useState<GridRowId[]>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [rows, setRows] = useState(data ?? []);
    const { t } = useAppTranslation();
    const apiRef = useGridApiRef();

    const _getRowId = useCallback(
        () =>
            getRowId
                ? (((row) =>
                      Object.keys(row).includes('_action')
                          ? (row as { id: GridRowId }).id
                          : (row as RowType & { isNew?: boolean }).isNew
                          ? (row as { id: GridRowId }).id
                          : getRowId(row)) as GridRowIdGetter<RowType>)
                : (row: RowType) => (row as { id: GridRowId }).id,

        [getRowId]
    );

    useImperativeHandle(
        innerRef,
        (): IDatatableActions<RowType> => ({
            cancelRowUpdate: (id: number | string) => {
                const filter = _getRowId();
                const row = filter && rows.find((item) => filter(item) === id);

                if (row && (row as { isNew?: boolean }).isNew) {
                    apiRef.current.updateRows([{ id, _action: 'delete' }]);

                    const filterRows = _getRowId();

                    setRows(rows.filter((item) => filterRows && filterRows(item) !== id));
                } else {
                    apiRef.current.stopRowEditMode({ id, ignoreModifications: true });
                }
            },
            finishRowUpdate: (id: number | string) => {
                apiRef.current.stopRowEditMode({ id });
            },
            removeRow: (id: number | string) => {
                apiRef.current.updateRows([{ id, _action: 'delete' }]);

                const filterRows = _getRowId();

                setRows(rows.filter((row) => filterRows && filterRows(row) !== id));
                if (onRowRemoved) {
                    onRowRemoved(id);
                }
            },
            startRowUpdate: (id: number | string) => {
                apiRef.current.startRowEditMode({ id });
            },
            updateRow: (updatedRow: RowType) => {
                apiRef.current.updateRows([updatedRow]);
            }
        })
    );

    const handleDisplayDetailPanel = useCallback(
        (rowId: number | string) => {
            if (openedDetailPanelIds.includes(rowId)) {
                setOpenedDetailPanelIds((ids) => ids.filter((id) => id !== rowId));
            } else {
                setOpenedDetailPanelIds((ids) => [...ids, rowId]);
            }
        },
        [openedDetailPanelIds]
    );
    const handlePagingModelChanged = useCallback(
        (model: { page: number; pageSize: number }) => {
            if (onPagingChanged) {
                onPagingChanged(model.page, model.pageSize);
            }
        },
        [onPagingChanged]
    );
    const handleSortModelChanged = useCallback(
        (
            model: {
                field: string;
                sort: 'asc' | 'desc' | null | undefined;
            }[]
        ) => {
            if (onSortingChanged) {
                if (model.length > 0) {
                    onSortingChanged(model[0].field, model[0].sort === 'asc' ? 'asc' : 'desc');
                } else if (paging && paging.sort) {
                    onSortingChanged(paging.sort, paging.sort !== 'asc' ? 'asc' : 'desc');
                }
            }
        },
        [onSortingChanged]
    );
    const handleSearchModelChanged = useCallback(
        (model: GridFilterModel) => {
            if (onSearchChanged) {
                onSearchChanged(model.quickFilterValues?.join(' ') ?? '');
            }
        },
        [onSearchChanged]
    );
    const handleAddButtonClick = useCallback(() => {
        const newId = -new Date().getTime();

        setRows([
            ...rows,
            {
                id: newId,
                ...(header.reduce((prev, current) => {
                    const value =
                        'valueOptions' in current && Array.isArray(current.valueOptions)
                            ? current.valueOptions.find(() => true) ?? ''
                            : '';

                    return {
                        ...prev,
                        [current.field]: typeof value === 'object' ? value.value : value
                    };
                }, {}) as RowType),
                isNew: true
            }
        ]);
        setTimeout(() => {
            apiRef.current.setCellFocus(newId, header.find(() => true)?.field ?? 'id');
            apiRef.current.startRowEditMode({
                id: newId,
                fieldToFocus: header.find(() => true)?.field ?? 'id'
            });
        }, 100);
    }, [header, rows]);
    const handleAdd = useCallback(
        (newRow: RowType) => {
            setRows(
                rows.map((row) => ({
                    ...row,
                    ...((row as { isNew?: boolean }).isNew &&
                    (row as { id: GridRowId }).id === (newRow as { id: GridRowId }).id
                        ? { ...newRow, isNew: false }
                        : {})
                }))
            );

            if (onRowAdded) {
                onRowAdded({ ...newRow, isNew: false });
            }
        },
        [rows, onRowAdded]
    );
    const handleValidateRow = useCallback(
        async (newRow: RowType, oldRow: RowType) =>
            (onValidateRow ? onValidateRow(newRow, oldRow) : Promise.resolve(newRow)).then((validRow) => {
                if ((newRow as { isNew?: boolean }).isNew) {
                    handleAdd(validRow);
                } else if (onRowUpdated) {
                    onRowUpdated(newRow, oldRow);
                }

                return { ...validRow, isNew: false };
            }),
        [rows, handleAdd, onValidateRow]
    );

    const autoSizeOptions = {
        ...DEFAULT_GRID_AUTOSIZE_OPTIONS,
        includeOutliers: true,
        expand: true
    };

    useEffect(() => {
        // apiRef.current.autosizeColumns(autoSizeOptions);
    }, [header, data]);
    useEffect(() => {
        setRows(data ?? []);
    }, [data]);

    return (
        // <StyledWrapper ref={wrapperRef}>
        <DataTableGrid<RowType>
            {...rest}
            sx={{
                background: (theme: Theme) => theme.palette.background.paper
            }}
            apiRef={apiRef}
            autosizeOptions={autoSizeOptions}
            columns={[
                ...((detailPanel
                    ? [
                          {
                              disableColumnMenu: true,
                              display: 'flex',
                              field: 'detailPanelButton',
                              headerName: '',
                              type: 'custom',
                              sortable: false,
                              width: 40,
                              renderCell: ({ row }) => (
                                  <IconButton
                                      onClick={() =>
                                          handleDisplayDetailPanel(
                                              getRowId ? getRowId(row) : (row as { id: GridRowId }).id
                                          )
                                      }
                                  >
                                      {openedDetailPanelIds.includes(
                                          getRowId ? getRowId(row) : (row as { id: GridRowId }).id
                                      ) ? (
                                          <KeyboardArrowDownIcon />
                                      ) : (
                                          <KeyboardArrowRightIcon />
                                      )}
                                  </IconButton>
                              )
                          }
                      ]
                    : []) as DataTableGridProps<RowType>['columns']),
                ...(header.map(({ type: headerItemType, ...headerItem }) => ({
                    disableColumnMenu: true,
                    display: 'flex',
                    type: ['days', 'color'].includes(headerItemType ?? '') ? undefined : headerItemType,
                    ...(['actions', 'date', 'dateTime', 'number'].includes(headerItem.field) ? {} : { flex: 1 }),
                    ...(headerItemType === 'dateTime'
                        ? {
                              ...GRID_DATETIME_COL_DEF,
                              ...(formatters?.dateTime ? { valueFormatter: formatters.dateTime } : {}),
                              resizable: undefined,
                              valueGetter: (value: string | null) =>
                                  value === null ? null : DateHelper.fromDateTimeString(value).toDate()
                          }
                        : {}),
                    ...(headerItemType === 'date'
                        ? {
                              ...GRID_DATE_COL_DEF,
                              ...(formatters?.date ? { valueFormatter: formatters.date } : {}),
                              resizable: undefined,
                              valueGetter: (value: string | null) =>
                                  value === null ? null : DateHelper.fromDateTimeString(value).toDate()
                          }
                        : {}),
                    ...(headerItemType === 'days'
                        ? {
                              sortable: false,
                              valueGetter: (value: string | null) => value ?? 0,
                              renderCell: ({ value }) => <Weekdays days={value ?? 0} holidays={false} />
                          }
                        : {}),
                    ...(headerItemType === 'color'
                        ? {
                              sortable: false,
                              align: 'center',
                              headerAlign: 'center',
                              valueGetter: (value: string | null) => value ?? '#ffffff',
                              renderCell: ({ value }) => (
                                  <Box sx={{ backgroundColor: value, height: '100%', aspectRatio: '1 / 1' }} />
                              )
                          }
                        : {}),
                    ...(headerItemType === 'boolean'
                        ? { minWidth: headerItem.sortable === false ? 100 : 150, flex: undefined }
                        : {}),
                    ...headerItem,
                    ...(headerItemType === 'actions'
                        ? {
                              getActions: (params: GridRowParams<RowType>) =>
                                  (headerItem as ICustomActionColDef<RowType>).getActions(params, rowModesModel)
                          }
                        : {})
                })) as DataTableGridProps<RowType>['columns'])
            ]}
            disableColumnFilter
            autoHeight={autoHeight}
            resizeThrottleMs={250}
            disableColumnSelector={!hiddenFields || hiddenFields.length === 0}
            disableDensitySelector
            disableRowSelectionOnClick
            filterMode="server"
            getRowId={
                getRowId
                    ? (((row) =>
                          Object.keys(row).includes('_action')
                              ? (row as { id: GridRowId }).id
                              : (row as RowType & { isNew?: boolean }).isNew
                              ? (row as { id: GridRowId }).id
                              : getRowId(row)) as GridRowIdGetter<RowType>)
                    : undefined
            }
            getRowHeight={() => 'auto'}
            ignoreDiacritics
            initialState={
                paging && paging.sort
                    ? {
                          columns: hiddenFields
                              ? {
                                    columnVisibilityModel: hiddenFields.reduce(
                                        (prev, current) => ({ ...prev, [current]: false }),
                                        {} as Record<string, boolean>
                                    )
                                }
                              : undefined,
                          sorting: {
                              sortModel: [
                                  {
                                      field: paging.sort,
                                      sort: paging.direction === 'asc' ? 'asc' : 'desc'
                                  }
                              ]
                          }
                      }
                    : undefined
            }
            localeText={{
                noRowsLabel: noRecordMessage ?? t('label.noRows', 'No Rows')
            }}
            pagination
            pageSizeOptions={simplePaging ? [5] : [5, 10, 25]}
            paginationMode="server"
            paginationModel={{
                page: (paging?.page ?? 1) - 1,
                pageSize: paging?.limit ?? 10
            }}
            rows={rows}
            rowCount={paging?.count ?? 0}
            rowModesModel={rowModesModel}
            processRowUpdate={handleValidateRow}
            slotProps={{
                pagination: {
                    showFirstButton: !simplePaging,
                    showLastButton: !simplePaging
                },
                toolbar: {
                    showQuickFilter,
                    quickFilterProps: {
                        debounceMs: 500,
                        name: 'search',
                        placeholder: searchPlaceHolder ?? t('label.search', 'Search')
                    },
                    csvOptions: {
                        disableToolbarButton: true
                    },
                    printOptions: {
                        disableToolbarButton: true
                    }
                },
                baseSelect: {
                    size: 'small'
                }
            }}
            slots={{
                toolbar: (props) => (
                    <Toolbar
                        {...props}
                        disabledAdd={rows.some((row) => 'isNew' in row && row.isNew)}
                        customAddButton={
                            CustomAddActionInToolbar ? <CustomAddActionInToolbar onSubmit={handleAdd} /> : undefined
                        }
                        hasAddButton={typeof onRowAdded === 'function'}
                        hasSearch={hasSearch}
                        customButtonOnRight={customActionsInToolbar}
                        labelAddButton={labelAddButton}
                        onAddClick={handleAddButtonClick}
                    />
                ),
                row: (rowProps) => (
                    <RowWithPanel detailPanel={detailPanel} openedDetailPanelIds={openedDetailPanelIds} {...rowProps} />
                )
            }}
            sortingMode={sortingMode}
            onProcessRowUpdateError={(error: {
                rowId: GridRowId;
                fieldsStatus: { field: string; error: boolean }[];
            }) => {
                const fieldWithError = error.fieldsStatus.find((fieldStatus) => fieldStatus.error);

                if (fieldWithError) {
                    apiRef.current.setCellFocus(error.rowId, fieldWithError.field);
                }
            }}
            onCellModesModelChange={(e) => console.log('modes', e)}
            onFilterModelChange={handleSearchModelChanged}
            onPaginationModelChange={handlePagingModelChanged}
            onRowEditStop={(newRow) => console.log('onPP', newRow)}
            onRowModesModelChange={setRowModesModel}
            onSortModelChange={handleSortModelChanged}
            // localeText={{
            //     // toolbarDensityLabel: 'asd',
            //     // footerRowSelected: (count) => t('datatable.rows', 'Rows', { count }),
            //     // footerTotalVisibleRows: (visibleCount, totalCount) => `a a`
            //     // // body: {
            //     // //     emptyDataSourceMessage:
            //     // //         noRecordMessage ?? t('datatable.emptyDataSourceMessage', 'No records to display')
            //     // // },
            //     // // pagination: {
            //     // //     labelRowsPerPage: simplePaging ? '' : t('datatable.rowsPerPage', 'Rows per page'),
            //     // //     labelRowsSelect: t('datatable.rows', 'Rows', { count: props?.paging?.limit ?? 10 }),
            //     // //     labelDisplayedRows: simplePaging
            //     // //         ? ''
            //     // //         : t('datatable.labelDisplayedRows', '{from}-{to} of {count}'),
            //     // //     firstTooltip: t('datatable.firstTooltip', 'First Page'),
            //     // //     lastTooltip: t('datatable.lastTooltip', 'Last Page'),
            //     // //     previousTooltip: t('datatable.previousTooltip', 'Previous Page'),
            //     // //     nextTooltip: t('datatable.nextTooltip', 'Next Page')
            //     // // }
            // }}
            // onRowClick={(event, rowData, togglePanel) => {
            //     if (togglePanel && props.detailPanel) {
            //         togglePanel();
            //     }
            // }}
            data-testid={`dataTable${name}`}
        />
        // </StyledWrapper>
    );
};

export default Datatable;
