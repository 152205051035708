import { createAsyncThunk } from '@reduxjs/toolkit';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { getPermissionsList } from '@/data/System/SystemReducer';
import { PermissionsControllerEnum } from '@/utils/enums/PermissionsEnum';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { INotificationEmailCUModel } from './EmailNotificationModels';
import { create, fetchById, fetchList, remove, update } from './NotificationEmailApi';
import { notificationEmailPaging } from './NotificationEmailSlice';

export const fetchNotificationEmails = createAsyncThunk(
    'notificationEmail/list',
    async (args: Partial<IPaging>, thunkAPI) => {
        const currentPaging = notificationEmailPaging(thunkAPI.getState() as IRootState);

        return await fetchList({ ...currentPaging, ...args });
    },
    {
        condition: (_, { getState }) => {
            const state = getState() as IRootState;
            const permissions = getPermissionsList(state);

            return isUserAllowed({ id: PermissionsControllerEnum.NotificationEmails, mode: Mode.READ }, permissions);
        }
    }
);

export const fetchNotificationEmailById = createAsyncThunk('notificationEmail/byId', async (args: number) => {
    return await fetchById(args);
});

export const createNotificationEmail = createAsyncThunk(
    'notificationEmail/create',
    async (args: INotificationEmailCUModel) => {
        return await create(args);
    }
);

export const updateNotificationEmail = createAsyncThunk(
    'notificationEmail/update',
    async (args: { id: number; data: INotificationEmailCUModel }) => {
        return await update(args.id, args.data);
    }
);

export const removeNotificationEmail = createAsyncThunk('notificationEmail/remove', async (id: number) => remove(id));
