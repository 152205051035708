import { useAppSelector } from '@/data/hooks';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { skillEntities } from '@/data/Skills/SkillSlice';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import { IUserCUSkill } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import CrudInlineOperationDatatable from '@/wrappers/CrudInlineDatatable';
import Icon from '@/wrappers/Datatable/CellViews/Icon';

type ISkills = {
    data: IUserCUSkill[];
    handleData: (value: IUserCUSkill[]) => void;
};

const SkillsTab = ({ data, handleData }: ISkills) => {
    const { t } = useAppTranslation();
    const skills = useAppSelector(skillEntities);
    const isSystemUser = useAppSelector(isSignedUserUser);

    return (
        <CrudInlineOperationDatatable<IUserCUSkill>
            getRowId={(row) => row.skill_id}
            labelAddButton={t('label.addSkill', 'Add Skill')}
            header={[
                {
                    field: 'skill_id',
                    headerName: t('header.skillName', 'Skill Name'),
                    editable: true,
                    type: 'singleSelect',
                    valueOptions: ({ row }) =>
                        (Object.values(skills) as ISkillModel[])
                            .filter(
                                (item) =>
                                    row?.skill_id === item.id || !data.some((dataItem) => dataItem.skill_id === item.id)
                            )
                            .map((item) => ({
                                value: item.id,
                                label: item.name
                            })),
                    preProcessEditCellProps: (params) => ({
                        ...params.props,
                        error: !params.props.value || data.some((dataItem) => dataItem.skill_id === params.props.value)
                    }),
                    renderCell: ({ row }) => {
                        const skill = skills[row.skill_id];

                        return <Icon {...skill} />;
                    }
                },
                {
                    field: 'level_of_competence',
                    headerName: t('header.levelOfCompetence', 'Level of Competence'),
                    editable: true,
                    type: 'number',
                    preProcessEditCellProps: (params) => ({
                        ...params.props,
                        error: typeof params.props.value !== 'number'
                    })
                }
            ]}
            nameOfEntity={(row) => skills[row.skill_id]?.name ?? ''}
            data={data}
            onRowAdd={isSystemUser ? undefined : (newData) => handleData([...data, newData])}
            onRowRemove={isSystemUser ? undefined : (id) => handleData(data.filter((row) => row.skill_id !== id))}
            onRowUpdate={
                isSystemUser
                    ? undefined
                    : (newData, oldData) =>
                          handleData(data.map((item) => (item.skill_id === oldData?.skill_id ? newData : item)))
            }
            onValidateRow={(newRow) => {
                const state = [
                    { field: 'skill_id', error: typeof newRow.skill_id !== 'number' },
                    { field: 'level_of_competence', error: typeof newRow.level_of_competence !== 'number' }
                ];

                return state.some(({ error }) => error)
                    ? Promise.reject({ rowId: newRow.skill_id, fieldsStatus: state })
                    : Promise.resolve(newRow);
            }}
        />
    );
};

export default SkillsTab;
