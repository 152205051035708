import { forwardRef } from 'react';
import FormGenerator from '@/base/FormGenerator';
import { IUserCUForecasts } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';

type IProps = {
    data: IUserCUForecasts;
    handleData: (value: IUserCUForecasts) => void;
};

const ForecastsTab = forwardRef<HTMLFormElement, IProps>(function ForecastsInner({ data, handleData }, ref) {
    const { t } = useAppTranslation();

    return (
        <FormGenerator
            ref={ref}
            id="forecastSetting"
            name="usersForecasts"
            openForm={true}
            displayAsSidebar={false}
            displayAsModal={false}
            fields={[
                {
                    type: 'switch',
                    props: {
                        name: 'send_user_to_ai_for_automatic_planning',
                        label: t('label.sendUserToAIForAutomaticPlanning', 'Send User To AI For Automatic Planning'),
                        value: data?.send_user_to_ai_for_automatic_planning,
                        width: 6,
                        onChange: (value) => handleData({ ...data, send_user_to_ai_for_automatic_planning: value })
                    }
                },
                {
                    type: 'newLine',
                    props: {
                        name: 'after-ai',
                        type: 'hidden'
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'allow_work_from_home',
                        label: t('label.allowWorkFromHome', 'Allow work from Home'),
                        value: data?.allow_work_from_home,
                        width: 6,
                        labelPlacement: 'end',
                        onChange: (value) => handleData({ ...data, allow_work_from_home: value }),
                        validation: {
                            deps: 'only_work_from_home'
                        }
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'only_work_from_home',
                        label: t('label.onlyWorkFromHome', 'Only work from Home'),
                        value: data?.only_work_from_home,
                        width: 6,
                        labelPlacement: 'end',
                        disabled: ({ allow_work_from_home }) => allow_work_from_home !== true,
                        onChange: (value) => handleData({ ...data, only_work_from_home: value }),
                        validation: {
                            deps: 'allow_work_from_home'
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'morning',
                        label: t('label.morning', 'Morning'),
                        value: data?.morning,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 1,
                                min: 0,
                                max: 100
                            }
                        },
                        validation: {
                            deps: ['morning'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            },
                            max: {
                                value: 100,
                                message: 'Maximum value is 100.'
                            }
                        },
                        onChange: (event, value) => handleData({ ...data, morning: parseInt(value ?? '') })
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'afternoon',
                        label: t('label.afternoon', 'Afternoon'),
                        value: data?.afternoon,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 1,
                                min: 0,
                                max: 100
                            }
                        },
                        validation: {
                            deps: ['afternoon'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            },
                            max: {
                                value: 100,
                                message: 'Maximum value is 100.'
                            }
                        },
                        onChange: (event, value) => handleData({ ...data, afternoon: parseInt(value ?? '') })
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'night',
                        label: t('label.night', 'Night'),
                        value: data?.night,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 1,
                                min: 0,
                                max: 100
                            }
                        },
                        validation: {
                            deps: ['night'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            },
                            max: {
                                value: 100,
                                message: 'Maximum value is 100.'
                            }
                        },
                        onChange: (event, value) => handleData({ ...data, night: parseInt(value ?? '') })
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'holiday',
                        label: t('label.holiday', 'Holiday'),
                        value: data?.holiday,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 1,
                                min: 0,
                                max: 100
                            }
                        },
                        validation: {
                            deps: ['holiday'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            },
                            max: {
                                value: 100,
                                message: 'Maximum value is 100.'
                            }
                        },
                        onChange: (event, value) => handleData({ ...data, holiday: parseInt(value ?? '') })
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'weekend',
                        label: t('label.weekend', 'Weekend'),
                        value: data?.weekend,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 1,
                                min: 0,
                                max: 100
                            }
                        },
                        validation: {
                            deps: ['weekend'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            },
                            max: {
                                value: 100,
                                message: 'Maximum value is 100.'
                            }
                        },
                        onChange: (event, value) => handleData({ ...data, weekend: parseInt(value ?? '') })
                    }
                }
            ]}
        />
    );
});

export default ForecastsTab;
