import { useMemo } from 'react';
import DateTimeMonthFormat from '@/helpers/date/DateTimeMonthFormat';
import useAppTranslation from '@/hooks/useAppTranslation';
import config from '@/utils/config';

type IProps = {
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
    timeZone?: string | null;
};

const useLocalizeMonthFormatter = ({ month = 'long', timeZone }: IProps = {}) => {
    const { i18n } = useAppTranslation();

    return useMemo(() => {
        const lng = i18n.language ?? config.defaultLocale;

        return new DateTimeMonthFormat(lng, {
            timeZone: timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
            month
        });
    }, [i18n.resolvedLanguage, i18n.language, timeZone]);
};

export default useLocalizeMonthFormatter;
