import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { styled } from '@mui/material/styles';
import { PropsWithChildren, ReactNode } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button';
import PageHeading from '@/wrappers/PageHeading';

export const StyledHeaderButton = styled(Button)`
    width: 122px;
`;
export const StyledWrapper = styled(Box)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding: ${theme.spacing(3)} 0 0 ${theme.spacing(3)};
    overflow-y: visible;
`
);
export const StyledContentWrapper = styled(Box, { shouldForwardProp: (propName) => propName !== 'datatable' })<{
    datatable: boolean;
}>(
    ({ datatable, theme }) => `
  display: ${datatable ? 'flex' : 'block'};
  padding-right: ${theme.spacing(3)};
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    margin: 0 2px;
    background-color: ${theme.palette.primary.main};
    border-radius: ${theme.shape.borderRadius}px;
  }
`
);

type IPredefinedButton = {
    display?: boolean;
    disabled?: boolean;
    size?: ButtonTypeMap['props']['size'];
    onClick: () => void;
};

type ILayoutOfPage = {
    actionBack?: IPredefinedButton;
    actionSave?: IPredefinedButton;
    actionButtonsBeforeSave?: ReactNode[];
    actionButtonsAfterSave?: ReactNode[];
    containsDatatable?: boolean;
    fullHeight?: boolean;
    title: ReactNode;
    subTitle?: string;
    errorTitle?: string;
};

const LayoutOfPage = ({
    subTitle,
    errorTitle = '',
    title,
    actionBack,
    actionSave,
    actionButtonsBeforeSave,
    actionButtonsAfterSave,
    containsDatatable = true,
    fullHeight = true,
    children,
    ...rest
}: PropsWithChildren<ILayoutOfPage>) => {
    const { t } = useAppTranslation();

    return (
        <StyledWrapper sx={containsDatatable ? { height: 1 } : undefined}>
            <Box
                {...rest}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3,
                    mr: 3
                }}
            >
                <PageHeading data-testid="mainPageTitle" subTitle={errorTitle ?? subTitle} error={errorTitle !== ''}>
                    {title}
                </PageHeading>
                <Box sx={{ display: 'flex', gap: 3 }}>
                    {actionBack && actionBack.display && (
                        <StyledHeaderButton
                            name="back"
                            size={actionBack.size ?? 'large'}
                            color="inherit"
                            variant="text"
                            disabled={actionBack.disabled}
                            onClick={actionBack.onClick}
                            startIcon={<ArrowBackIcon />}
                        >
                            {t('label.back', 'Back')}
                        </StyledHeaderButton>
                    )}
                    {actionButtonsBeforeSave}
                    {actionSave && actionSave.display && (
                        <StyledHeaderButton
                            name="save"
                            size={actionSave.size ?? 'large'}
                            color="success"
                            variant="contained"
                            disabled={actionSave.disabled}
                            onClick={actionSave.onClick}
                        >
                            {t('label.save', 'Save')}
                        </StyledHeaderButton>
                    )}
                    {actionButtonsAfterSave}
                </Box>
            </Box>
            <StyledContentWrapper
                datatable={containsDatatable}
                sx={fullHeight ? { position: 'relative', height: '100%', overflow: 'auto' } : { maxHeight: '87%' }}
            >
                {children}
            </StyledContentWrapper>
        </StyledWrapper>
    );
};

export default LayoutOfPage;
