import Box from '@mui/material/Box';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import NullableFieldsOfObject from '@/helpers/NullableFieldsOfObject';
import { ICellDataType } from '@/helpers/schedule';
import SchedulerBodyTooltip from '@/modules/Scheduler/components/SchedulerBodyTooltip';
import ShiftAsText, { IShiftAsTimelineViewProps } from '../../../ShiftAsText';

export type ISchedulerTableCellContentProps = NullableFieldsOfObject<
    ArrayElement<ICellDataType<Date>['vacations']>,
    'abbreviation' | 'abbreviationColor' | 'abbreviationBackgroundColor'
> &
    Pick<IShiftAsTimelineViewProps, 'isDayMode'> & {
        timeZone: string;
    };

const SchedulerCellVacation = ({
    from,
    id,
    timeZone,
    to,
    abbreviation,
    abbreviationColor,
    abbreviationBackgroundColor,
    ...rest
}: ISchedulerTableCellContentProps) => {
    const color = abbreviationColor ?? '#FFFFFF';
    const backgroundColor = abbreviationBackgroundColor ?? '#808080';

    return (
        <SchedulerBodyTooltip columnFrom={from} columnTo={to} isDayMode={false} timeZone={timeZone}>
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor,
                    color,
                    display: 'inline-flex',
                    height: '100%',
                    textAlign: 'center',
                    width: '100%'
                }}
                data-testid={`userToVacations_${id}`}
            >
                <ShiftAsText
                    {...rest}
                    simpleView={true}
                    skills={[]}
                    abbr={{ text: abbreviation ?? 'PTO', color: color }}
                    inStore={false}
                    shiftEnd={null}
                    shiftName={null}
                    shiftStart={null}
                    timeZone={timeZone}
                />
            </Box>
        </SchedulerBodyTooltip>
    );
};

export default SchedulerCellVacation;
