import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBreakModel } from '@/data/Breaks/BreakModels';
import { breakEntities } from '@/data/Breaks/BreakSlice';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { fetchShiftTradeOffersByAssignedShift } from '@/data/ShiftTradeOffers/ShiftTradeOfferActions';
import { createWorkplace, fetchWorkplaceById, updateWorkplace } from '@/data/Workplaces/WorkplaceActions';
import filterIncludeValue from '@/helpers/array/filterIncludeValue';
import sortEntities from '@/helpers/array/sortEntities';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createShift,
    fetchShiftById,
    fetchShifts,
    fetchShiftsForSelect,
    removeShift,
    updateShift
} from './ShiftActions';
import { IShiftModel, IShiftSelectModel } from './ShiftModels';

type IState = {
    selectItems: IShiftSelectModel[];
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: { id: number; state: IRequestState }[];
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IState = {
    selectItems: [],
    paging: defaultPaging('Shifts.name'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: [],
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<IShiftModel>({
    selectId: (entity) => entity.id
});
const shiftSlice = createSlice({
    name: 'shifts',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                const shifts = action.payload.shifts;
                const shiftIds = shifts.map(({ id }) => id);

                state.loadingByIdStatus = [
                    ...state.loadingByIdStatus.filter(({ id }) => !shiftIds.includes(id)),
                    ...shiftIds.map<{ id: number; state: IRequestState }>((id) => ({
                        id,
                        state: 'idle'
                    }))
                ];
                adapter.upsertMany(state, shifts);
            })
            .addCase(fetchShiftTradeOffersByAssignedShift.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    (
                        action.payload.data.filter((item) => item.schedule_plan_day_shift.shift !== null) as {
                            schedule_plan_day_shift: {
                                shift: IShiftModel;
                            };
                        }[]
                    ).map((item) => item.schedule_plan_day_shift.shift)
                );
            })
            .addCase(createWorkplace.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.shift_to_workplaces.map(({ shift }) => shift)
                );
            })
            .addCase(updateWorkplace.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.shift_to_workplaces.map(({ shift }) => shift)
                );
            })
            .addCase(fetchWorkplaceById.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.shift_to_workplaces.map(({ shift }) => shift)
                );
            });
        builder
            .addCase(fetchShifts.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchShifts.fulfilled, (state, action) => {
                state.loadingByIdStatus = [];
                state.loadingListStatus = 'idle';
                adapter.upsertMany(state, action.payload.data);
                if (!action.meta.arg.noPaging) {
                    state.paging = action.payload.collection;
                }
            })
            .addCase(fetchShifts.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchShiftsForSelect.pending, (state) => {
                state.loadingForSelectStatus = 'loading';
            })
            .addCase(fetchShiftsForSelect.fulfilled, (state, action) => {
                state.loadingForSelectStatus = 'idle';
                state.selectItems = action.payload.data;
            })
            .addCase(fetchShiftsForSelect.rejected, (state) => {
                state.loadingForSelectStatus = 'failed';
            })
            .addCase(fetchShiftById.pending, (state, action) => {
                state.loadingByIdStatus = [...state.loadingByIdStatus, { id: action.meta.arg, state: 'loading' }];
            })
            .addCase(fetchShiftById.fulfilled, (state, action) => {
                state.loadingByIdStatus = [
                    ...state.loadingByIdStatus.filter((item) => item.id !== action.meta.arg),
                    { id: action.meta.arg, state: 'idle' }
                ];
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchShiftById.rejected, (state, action) => {
                state.loadingByIdStatus = [
                    ...state.loadingByIdStatus.filter((item) => item.id !== action.meta.arg),
                    { id: action.meta.arg, state: 'failed' }
                ];
            })
            .addCase(createShift.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createShift.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
                if (state.selectItems.length) {
                    state.selectItems.push({
                        id: action.payload.id,
                        name: action.payload.name,
                        days: action.payload.days,
                        valid_start: action.payload.valid_start,
                        valid_end: action.payload.valid_end
                    } as IShiftSelectModel);
                }
            })
            .addCase(createShift.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeShift.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeShift.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg);
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.filter((item) => item.id !== action.meta.arg);
                }
            })
            .addCase(removeShift.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateShift.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateShift.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.map((item) =>
                        item.id === action.payload.id
                            ? {
                                  ...action.payload,
                                  id: action.payload.id,
                                  name: action.payload.name,
                                  days: action.payload.days,
                                  valid_start: action.payload.valid_start,
                                  valid_end: action.payload.valid_end
                              }
                            : item
                    );
                }
            })
            .addCase(updateShift.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[shiftSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default shiftSlice;
export const isShiftsListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const shiftPaging = (state: IRootState) => getState(state).paging;
export const isShiftsByIdLoaded = (state: IRootState, id?: number) =>
    getState(state).loadingByIdStatus.find((item) => item.id === id)?.state === 'idle';
export const selectFilteredShifts = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => getState(state).paging,
    (state: IRootState) => adapterSelectors.selectTotal(state),
    (entities, filter, total) => {
        const page = Math.min(filter.page - 1, Math.floor(total / filter.limit));

        return Object.values(entities)
            .filter((entity) => filterIncludeValue(filter.search, [entity.name]))
            .sort(sortEntities(`${filter.sort}`, `${filter.direction}`))
            .slice(page * filter.limit, (page + 1) * filter.limit);
    }
);
export const shiftEntities = adapterSelectors.selectEntities;
export const shiftById = (state: IRootState, id?: number) => (id ? adapterSelectors.selectById(state, id) : undefined);
export const shiftsList = (state: IRootState) => adapterSelectors.selectAll(state);

const selectShifts = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => breakEntities(state),
    (shifts, breaks) => {
        const result: Record<number, IShiftModel<{ break: IBreakModel }>> = {};

        shifts.forEach((shift) => {
            const mapped = {
                ...shift,
                shift_items: shift.shift_items.map((item) => ({
                    ...item,
                    break: breaks[item.break_id]
                }))
            };

            if (!mapped.shift_items.some((item) => typeof item.break?.id === 'undefined')) {
                result[shift.id] = mapped as IShiftModel<{ break: IBreakModel }>;
            }
        });

        return result;
    }
);

export const selectShiftById = (state: IRootState, shiftId: number | null) =>
    shiftId !== null ? selectShifts(state)[shiftId] ?? null : null;

export const shiftsForSelect = (state: IRootState) => {
    const result = getState(state).selectItems;

    return result.length === 0 ? null : result;
};
export const shiftSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const shiftCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const shiftUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const shiftRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = shiftSlice.actions;
