import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, fetchSelectList, remove, update } from './ShiftApi';
import { IShiftCUModel, IShiftModel } from './ShiftModels';

export const fetchShifts = createAsyncThunk('shifts/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = (thunkAPI.getState() as IRootState).shifts.paging;

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchShiftsForSelect = createAsyncThunk(
    'shifts/selectList',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSelectList(args.search, args.fields || []);
    },
    {
        condition(_, thunkAPI) {
            const state = (thunkAPI.getState() as IRootState).shifts;

            if (state.loadingForSelectStatus !== 'idle' || state.selectItems.length) {
                return false;
            }
        }
    }
);

export const fetchShiftById = createAsyncThunk('shifts/byId', async (args: number) => await fetchById(args), {
    condition(id, thunkAPI) {
        const state = (thunkAPI.getState() as IRootState).shifts;

        if (['loading', 'idle'].includes(state.loadingByIdStatus.find((item) => item.id === id)?.state ?? '')) {
            return false;
        }
    }
});

export const createShift = createAsyncThunk('shifts/create', async (data: IShiftCUModel) => {
    return await create(data);
});

export const updateShift = createAsyncThunk<IShiftModel, { id: number; data: IShiftCUModel }>(
    'shifts/update',
    async ({ id, data }) => {
        return await update(id, data);
    }
);

export const updateShiftActive = createAsyncThunk(
    'shifts/update',
    async ({ id, data }: { id: number; data: { active: boolean } }) => await update(id, data)
);

export const removeShift = createAsyncThunk('shifts/remove', async (id: number) => remove(id));
