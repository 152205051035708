import Box from '@mui/material/Box';
import { useMemo } from 'react';
import theme from '@/assets/theme';
import { useAppSelector } from '@/data/hooks';
import { IUserHours } from '@/data/Summaries/SummariesModels';
import { isUserHoursLoading, userHoursList } from '@/data/Summaries/SummariesSlice';
import { usersForSelect } from '@/data/Users/UserSlice';
import DateHelper from '@/helpers/date/DateHelper';
import { normalizeFloatNumber } from '@/helpers/number';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeMonthFormatter from '@/hooks/useLocalizeMonthFormatter';
import { serializeUser } from '@/utils/UserHelper';
import Datatable from '@/wrappers/Datatable';
import Table from '@/wrappers/Table';

const Hours = () => {
    const { t } = useAppTranslation();
    const usersData = useAppSelector(usersForSelect);
    const hoursUserData = useAppSelector(userHoursList);
    const monthFormatter = useLocalizeMonthFormatter();

    const generateVacations = useMemo(() => {
        const months: { name: string; index: number }[] = [];

        hoursUserData.forEach((hour) =>
            hour.funds.forEach((fund) => {
                const monthIndex = DateHelper.fromDateTimeString(fund.start).month();
                const monthName = monthFormatter.format(monthIndex + 1);

                if (!months.some((month) => month.index === monthIndex)) {
                    months.push({
                        name: monthName,
                        index: monthIndex
                    });
                }
            })
        );

        return months.map((month) => ({
            title: t('header.vacationHoursForMonth', 'Vacation Hours for {{month}}', {
                month: month.name
            }),
            field: `shift_${month.index}`,
            render: (rowData: IUserHours) => {
                const fund = rowData.funds.find(
                    (entity) => DateHelper.fromDateTimeString(entity.start)?.month() === month.index
                );

                return fund?.vacation_hours ?? 0;
            }
        }));
    }, [usersData, hoursUserData, monthFormatter]);

    return (
        <Datatable
            header={[
                {
                    headerName: t('label.user', 'User'),
                    field: 'name',
                    renderCell: ({ row }) => serializeUser(usersData.find((user) => user.id === row.user_id))
                },
                {
                    headerName: t('header.contractHours', 'Contract Hours'),
                    description: t('message.info.contractHours', 'Summarization of users contract hours'),
                    field: 'contract_hours',
                    renderCell: ({ row }) => row.summary.contract_hours
                },
                {
                    headerName: t('header.shiftHours', 'Shift Hours'),
                    description: t(
                        'message.info.shiftHours',
                        'Summarization of shifts with paid and unpaid breaks in hours'
                    ),
                    field: 'shift_hours',
                    renderCell: ({ row }) => <>{normalizeFloatNumber(row.summary.shift_hours)}</>
                },
                ...generateVacations
            ]}
            data={hoursUserData}
            getRowId={(row) => `${row.user_id}_${row.workplace_id}_${row.period_id}`}
            detailPanel={(row) => {
                let hoursSummary = row?.summary?.shift_hours ?? 0;

                row?.funds.forEach((fund) => {
                    hoursSummary += fund?.vacation_hours ?? 0;
                });

                return (
                    <Table
                        name={`summary_detail_of_user_${row.user_id}`}
                        rows={
                            [
                                {
                                    id: t('header.remainingHours', 'Remaining Hours'),
                                    value: row.summary.remaining_hours,
                                    tooltip: t(
                                        'message.info.remainingHours',
                                        'Remaining hours from contract hours minus shifts with paid breaks'
                                    )
                                },
                                {
                                    id: t('header.shiftHoursWithPaidBreaks', 'Shift Hours With Paid Breaks'),
                                    value: row.summary.shift_hours_with_paid_breaks,
                                    tooltip: t(
                                        'message.info.shiftHoursWithPaidBreaks',
                                        'Summarization of shifts with paid breaks in hours'
                                    )
                                },
                                {
                                    id: t('header.paidBreaksHours', 'Paid Breaks Hours'),
                                    value: row.summary.paid_breaks_hours,
                                    tooltip: t('message.info.paidBreaksHours', 'Summarization of paid breaks in hours')
                                },
                                {
                                    id: t('header.unpaidBreaksHours', 'Unpaid Breaks Hours'),
                                    value: row.summary.unpaid_breaks_hours,
                                    tooltip: t(
                                        'message.info.unpaidBreaksHours',
                                        'Summarization of unpaid breaks in hours'
                                    )
                                },
                                {
                                    id: t('header.summary', 'Summary'),
                                    value: hoursSummary,
                                    tooltip: t('message.info.summary', 'Summarization of shifts and vacation in hours')
                                }
                            ] as { id: string; value: number; tooltip: string }[]
                        }
                        columns={[
                            {
                                id: 'id',
                                label: 'id',
                                access: (subRowData) => <Box title={subRowData.tooltip}>{subRowData.id}:</Box>
                            },
                            {
                                id: 'value',
                                label: 'value',
                                access: (subRowData) => (
                                    <Box title={subRowData.tooltip}>{normalizeFloatNumber(subRowData.value)}</Box>
                                )
                            }
                        ]}
                        columnProps={(columnId) => (columnId === 'id' ? { width: '30%' } : {})}
                        rowProps={{
                            sx: {
                                backgroundColor: theme.palette.grey.A200,
                                borderTop: '1px solid ' + theme.palette.grey.A700
                            }
                        }}
                        showHeader={false}
                    />
                );
            }}
            hasTitle={false}
            hasSearch={false}
            hasSelection={false}
            hasSorting={false}
            hasPaginating={false}
            loading={useAppSelector(isUserHoursLoading)}
        />
    );
};

export default Hours;
