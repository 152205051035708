import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import {
    assignShiftIntoDay,
    assignShiftToSignedUser,
    detachShiftFromDay,
    moveShift,
    updateAssignedShift
} from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftActions';
import {
    calculateSchedulePlan,
    clearSchedulePlans,
    fetchSchedulePlanById,
    modifyNeedsOfSchedulePlan,
    recalculateSchedulePlan,
    recalculateSchedulePlanWithShifts,
    removeSchedulePlan,
    removeSchedulePlanFromStore,
    updateRequirementsOnSkill
} from '@/data/SchedulePlans/SchedulePlanActions';
import { agreeWithOfferedShift } from '@/data/ShiftTradeOffers/ShiftTradeOfferActions';
import { takeThisShiftFromTrade } from '@/data/ShiftTrades/ShiftTradeActions';
import { IRootState } from '@/data/store';
import { patchStateToRequest } from '@/data/UserToRequests/UserToRequestActions';
import DateHelper from '@/helpers/date/DateHelper';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { ISchedulePlanDayModel, ISchedulePlanDayOptModel } from './SchedulePlanDayModels';

type IState = {
    updatingStatus: IRequestState;
};

const initialState: IState = {
    updatingStatus: 'idle'
};

const adapter = createEntityAdapter<ISchedulePlanDayOptModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.created.localeCompare(b.created)
});

const schedulePlanDaySlice = createSlice({
    name: 'schedulePlanDays',
    initialState: adapter.getInitialState<IState>(initialState),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.meta.arg
                        ) as ISchedulePlanDayOptModel[]
                    ).map(({ id }) => id)
                );
                adapter.upsertMany(state, action.payload.schedule_plan_days);
            })
            .addCase(removeSchedulePlan.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.meta.arg
                        ) as ISchedulePlanDayModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(clearSchedulePlans.fulfilled, (state) => {
                adapter.removeAll(state);
                state.updatingStatus = initialState.updatingStatus;
            })
            .addCase(calculateSchedulePlan.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.meta.arg
                        ) as ISchedulePlanDayModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(recalculateSchedulePlan.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.meta.arg
                        ) as ISchedulePlanDayModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(recalculateSchedulePlanWithShifts.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.meta.arg
                        ) as ISchedulePlanDayModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(updateAssignedShift.fulfilled, (state, action) => {
                const from = DateHelper.fromDateTimeString(action.payload.new.shift_start),
                    to = DateHelper.fromDateTimeString(action.payload.new.shift_end);

                adapter.updateMany(
                    state,
                    (
                        action.payload.new.schedule_plan_days?.filter(
                            (item) =>
                                item?.schedule_plan_id === action.payload.new.schedule_plan_id &&
                                DateHelper.isBetween(DateHelper.fromDateTimeString(item.start), from, to, '[)')
                        ) ?? ([] as ISchedulePlanDayOptModel[])
                    ).map((item) => ({
                        id: item.id,
                        changes: { schedule_plan_day_skills: item.schedule_plan_day_skills }
                    }))
                );
            })
            .addCase(assignShiftIntoDay.fulfilled, (state, action) => {
                const from = DateHelper.fromDateTimeString(action.payload.shift_start),
                    to = DateHelper.fromDateTimeString(action.payload.shift_end);

                adapter.updateMany(
                    state,
                    (
                        (action.payload.schedule_plan_days?.filter(
                            (item) =>
                                item?.schedule_plan_id === action.payload.schedule_plan_id &&
                                DateHelper.isBetween(DateHelper.fromDateTimeString(item.start), from, to, '[)')
                        ) ?? []) as ISchedulePlanDayOptModel[]
                    ).map((item) => ({
                        id: item.id,
                        changes: { schedule_plan_day_skills: item.schedule_plan_day_skills }
                    }))
                );
            })
            .addCase(assignShiftToSignedUser.fulfilled, (state, action) => {
                const from = DateHelper.fromDateTimeString(action.payload.shift_start),
                    to = DateHelper.fromDateTimeString(action.payload.shift_end);

                adapter.updateMany(
                    state,
                    (
                        (action.payload.schedule_plan_days?.filter(
                            (item) =>
                                item?.schedule_plan_id === action.payload.schedule_plan_id &&
                                DateHelper.isBetween(DateHelper.fromDateTimeString(item.start), from, to, '[)')
                        ) ?? []) as ISchedulePlanDayOptModel[]
                    ).map((item) => ({
                        id: item.id,
                        changes: { schedule_plan_day_skills: item.schedule_plan_day_skills }
                    }))
                );
            })
            .addCase(takeThisShiftFromTrade.fulfilled, (state, action) => {
                adapter.upsertMany(state, action.payload.schedule_plan_days);
            })
            .addCase(patchStateToRequest.fulfilled, (state, action) => {
                if (action.payload.request?.state == UserToRequestsStateEnum.ACCEPTED) {
                    adapter.upsertMany(state, action.payload.schedule_plan_days ?? []);
                }
            })
            .addCase(detachShiftFromDay.fulfilled, (state, action) => {
                if (action.payload.shift_start && action.payload.shift_end) {
                    const from = DateHelper.fromDateTimeString(action.payload.shift_start),
                        to = DateHelper.fromDateTimeString(action.payload.shift_end);

                    adapter.updateMany(
                        state,
                        (
                            (action.payload.schedule_plan_days?.filter(
                                (item) =>
                                    item?.schedule_plan_id === action.meta.arg.schedulePlanId &&
                                    DateHelper.isBetween(DateHelper.fromDateTimeString(item.start), from, to, '[)')
                            ) ?? []) as ISchedulePlanDayOptModel[]
                        ).map((item) => ({
                            id: item.id,
                            changes: { schedule_plan_day_skills: item.schedule_plan_day_skills }
                        }))
                    );
                } else {
                    adapter.updateMany(
                        state,
                        (action.payload.schedule_plan_days ?? []).map((item) => ({
                            id: item.id,
                            changes: { schedule_plan_day_skills: item.schedule_plan_day_skills }
                        }))
                    );
                }
            })
            .addCase(agreeWithOfferedShift.fulfilled, (state, action) => {
                if (action.payload?.plan) {
                    adapter.upsertMany(state, action.payload.plan.schedule_plan_days);
                }
            })
            .addCase(moveShift.fulfilled, (state, action) => {
                adapter.upsertMany(state, action.payload.schedule_plan_days);
            })
            .addCase(removeSchedulePlanFromStore, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter((item) => item?.schedule_plan_id === action.payload) as {
                            id: number;
                        }[]
                    ).map(({ id }) => id)
                );
            });
        builder
            .addCase(updateRequirementsOnSkill.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateRequirementsOnSkill.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.upsertMany(state, action.payload.schedule_plan_days);
            })
            .addCase(updateRequirementsOnSkill.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
        builder
            .addCase(modifyNeedsOfSchedulePlan.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(modifyNeedsOfSchedulePlan.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.upsertMany(state, action.payload.schedule_plan_days);
            })
            .addCase(modifyNeedsOfSchedulePlan.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[schedulePlanDaySlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default schedulePlanDaySlice;

export const schedulePlanDaysBySchedulePlan = createSelector(adapterSelectors.selectAll, (schedulePlanDays) => {
    const result: Record<number, ISchedulePlanDayOptModel[]> = {};

    schedulePlanDays.forEach((schedulePlanDay) => {
        if (schedulePlanDay.schedule_plan_id in result) {
            result[schedulePlanDay.schedule_plan_id].push(schedulePlanDay);
        } else {
            result[schedulePlanDay.schedule_plan_id] = [schedulePlanDay];
        }
    });

    return result;
});
export const schedulePlanDaysBySchedulePlanId = (state: IRootState, schedulePlanId: number | null) =>
    schedulePlanId ? schedulePlanDaysBySchedulePlan(state)[schedulePlanId] ?? null : null;
