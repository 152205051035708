import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { fetchShiftTradeOffersByAssignedShift } from '@/data/ShiftTradeOffers/ShiftTradeOfferActions';
import { fetchShiftTradesByAssignedShift } from '@/data/ShiftTrades/ShiftTradeActions';
import { selectUserToContracts } from '@/data/UserToContracts/UserToContractSlice';
import { requestForSchedulerList } from '@/data/UserToRequests/UserToRequestSlice';
import { IUserToShiftsExtendedModel } from '@/data/UserToShifts/UserToShiftsModels';
import { selectUserToShifts } from '@/data/UserToShifts/UserToShiftsSlice';
import { userToVacationAll } from '@/data/UserToVacations/UserToVacationSlice';
import { IUserToWorkplaceModel } from '@/data/UserToWorkplaces/UserToWorkplaceModels';
import { userToWorkplaceAll } from '@/data/UserToWorkplaces/UserToWorkplaceSlice';
import { createWorkplace, fetchWorkplaceById, updateWorkplace } from '@/data/Workplaces/WorkplaceActions';
import { IWorkplaceModel } from '@/data/Workplaces/WorkplaceModels';
import { workplaceEntities } from '@/data/Workplaces/WorkplaceSlice';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createUser,
    downloadUsers,
    fetchUserById,
    fetchUsers,
    fetchUsersForSelect,
    removeUser,
    updateUser,
    updateUserActive
} from './UserActions';
import { IUserAbsentSelectModel, IUserExtendedModel, IUserModel, IUserSelectModel } from './UserModels';

type IUserReducerState = {
    selectItems: IUserSelectModel[];
    absentUsers: IUserAbsentSelectModel[];
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: { id: number; state: IRequestState }[];
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    downloadingRequestStatus: IRequestState;
    downloadingStatus: IRequestState;
    removingStatus: IRequestState;
    cloningStatus: IRequestState;
};

export const initialState: IUserReducerState = {
    selectItems: [],
    absentUsers: [],
    paging: defaultPaging('created'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: [],
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    downloadingRequestStatus: 'idle',
    downloadingStatus: 'idle',
    removingStatus: 'idle',
    cloningStatus: 'idle'
};

const adapter = createEntityAdapter<IUserModel>({
    selectId: (entity) => entity.id
});
const userSlice = createSlice({
    name: 'users',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        },
        invalidateAbsentUsers(state, action: PayloadAction<IUserAbsentSelectModel[]>) {
            if (!(action.payload.length === 0 && state.absentUsers.length === 0)) {
                state.absentUsers = action.payload;
            }
        },
        patchDownloadedUsersStatus(state, action: PayloadAction<IRequestState>) {
            state.downloadingStatus = action.payload;
        },
        patchDownloadedUsers(state, action: PayloadAction<IUserModel[]>) {
            adapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                const usersFromWorkplace = action.payload.users.map(({ user_to_contracts, ...item }) => item);
                const ids = usersFromWorkplace.map(({ id }) => id);

                adapter.upsertMany(state, usersFromWorkplace as IUserModel[]);
                state.loadingByIdStatus = [
                    ...state.loadingByIdStatus.filter((item) => !ids.includes(item.id)),
                    ...usersFromWorkplace.map(({ id }) => ({ id, state: 'idle' }))
                ] as IUserReducerState['loadingByIdStatus'];
            })
            .addCase(fetchShiftTradeOffersByAssignedShift.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.data.map((item) => ({ ...item.schedule_plan_day_shift.user, subordinate: [] }))
                );
            })
            .addCase(fetchShiftTradesByAssignedShift.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.data.map((item) => ({ ...item.schedule_plan_day_shift.user, subordinate: [] }))
                );
            })
            .addCase(fetchWorkplaceById.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.user_to_workplaces.map(({ user }) => user)
                );
            })
            .addCase(updateWorkplace.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.user_to_workplaces.map(({ user }) => user)
                );
            })
            .addCase(createWorkplace.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.user_to_workplaces.map(({ user }) => user)
                );
            });
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.loadingByIdStatus = [];
                state.loadingListStatus = 'idle';

                adapter.setAll(
                    state,
                    action.payload.data.map(({ user_to_contracts, user_to_workplaces, ...rest }) => ({
                        ...rest,
                        subordinate: []
                    }))
                );
                if (!action.meta.arg.noPaging) {
                    state.paging = action.payload.collection;
                }
            })
            .addCase(fetchUsers.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(updateUserActive.pending, (state, action) => {
                state.loadingByIdStatus = [...state.loadingByIdStatus, { id: action.meta.arg.id, state: 'loading' }];
            })
            .addCase(updateUserActive.fulfilled, (state, action) => {
                state.loadingByIdStatus = [...state.loadingByIdStatus, { id: action.meta.arg.id, state: 'idle' }];
                adapter.updateOne(state, { id: action.meta.arg.id, changes: { active: action.payload.active } });
            })
            .addCase(updateUserActive.rejected, (state, action) => {
                state.loadingByIdStatus = [...state.loadingByIdStatus, { id: action.meta.arg.id, state: 'failed' }];
            })
            .addCase(fetchUsersForSelect.pending, (state) => {
                state.loadingForSelectStatus = 'loading';
            })
            .addCase(fetchUsersForSelect.fulfilled, (state, action) => {
                state.loadingForSelectStatus = 'idle';
                state.selectItems = action.payload.data;
            })
            .addCase(fetchUsersForSelect.rejected, (state) => {
                state.loadingForSelectStatus = 'failed';
            })
            .addCase(fetchUserById.pending, (state, action) => {
                state.loadingByIdStatus = [...state.loadingByIdStatus, { id: action.meta.arg, state: 'loading' }];
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const { user_to_contracts, user_to_workplaces, subordinate, superior, ...toInsert } = action.payload;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                state.loadingByIdStatus = [
                    ...state.loadingByIdStatus.filter((item) => item.id !== action.meta.arg),
                    { id: action.meta.arg, state: 'idle' }
                ];

                adapter.upsertMany(state, [
                    ...subordinate.map((item) => ({ subordinate: [], user_to_requests: [], ...item })),
                    { ...toInsert, subordinate: subordinate.map(({ id }) => id) },
                    ...(superior ? [{ subordinate: [], user_to_requests: [], ...superior }] : [])
                ]);
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.map((item) =>
                        item.id === action.payload.id
                            ? ({
                                  id: action.payload.id,
                                  first_name: action.payload.first_name,
                                  middle_name: action.payload.middle_name,
                                  last_name: action.payload.last_name
                              } as IUserSelectModel)
                            : item
                    );
                }
            })
            .addCase(fetchUserById.rejected, (state, action) => {
                state.loadingByIdStatus = [
                    ...state.loadingByIdStatus.filter((item) => item.id !== action.meta.arg),
                    { id: action.meta.arg, state: 'failed' }
                ];
            })
            .addCase(createUser.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createUser.fulfilled, (state, action) => {
                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const { user_to_contracts, user_to_workplaces, ...toInsert } = action.payload;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                state.creatingStatus = 'idle';
                adapter.addOne(state, { ...toInsert, subordinate: toInsert.subordinate.map(({ id }) => id) });
                if (state.selectItems.length) {
                    state.selectItems.push({
                        id: action.payload.id,
                        first_name: action.payload.first_name,
                        middle_name: action.payload.middle_name,
                        last_name: action.payload.last_name
                    } as IUserSelectModel);
                }
            })
            .addCase(createUser.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeUser.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg);
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.filter((item) => item.id !== action.meta.arg);
                }
            })
            .addCase(removeUser.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateUser.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, {
                    id: action.meta.arg.id,
                    changes: { ...action.payload, subordinate: action.payload.subordinate.map(({ id }) => id) }
                });
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.map((item) =>
                        item.id === action.payload.id
                            ? ({
                                  id: action.payload.id,
                                  first_name: action.payload.first_name,
                                  middle_name: action.payload.middle_name,
                                  last_name: action.payload.last_name
                              } as IUserSelectModel)
                            : item
                    );
                }
            })
            .addCase(updateUser.rejected, (state) => {
                state.updatingStatus = 'failed';
            })
            .addCase(downloadUsers.pending, (state) => {
                state.downloadingRequestStatus = 'loading';
                state.downloadingStatus = 'loading';
            })
            .addCase(downloadUsers.fulfilled, (state) => {
                state.downloadingRequestStatus = 'idle';
            })
            .addCase(downloadUsers.rejected, (state) => {
                state.downloadingStatus = 'failed';
                state.downloadingRequestStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[userSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default userSlice;
export const isUserListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const userPaging = (state: IRootState) => getState(state).paging;
export const isUserByIdInProgress = (state: IRootState, id?: number) =>
    getState(state).loadingByIdStatus.find((item) => item.id === id)?.state === 'loading';
export const isUserLoaded = (state: IRootState, userId?: number) =>
    getState(state).loadingByIdStatus.find((item) => item.id === userId)?.state === 'idle';
export const isUsersDownloading = (state: IRootState) =>
    getState(state).downloadingStatus === 'loading' || getState(state).downloadingRequestStatus === 'loading';
export const userEntities = adapterSelectors.selectEntities;
export const userById = (state: IRootState, id?: number) => (id ? adapterSelectors.selectById(state, id) : undefined);
export const usersForSelect = (state: IRootState) => getState(state).selectItems;
export const userSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const userUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const userRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const userList = adapterSelectors.selectAll;
export const getAbsentUsers = (state: IRootState) => getState(state).absentUsers;
export const { invalidateAbsentUsers, patchDownloadedUsers, patchDownloadedUsersStatus } = userSlice.actions;

export const selectUserEntities = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => userToWorkplaceAll(state),
    (state: IRootState) => workplaceEntities(state),
    (state: IRootState) => selectUserToContracts(state),
    (state: IRootState) => userToVacationAll(state),
    (state: IRootState) => selectUserToShifts(state),
    (state: IRootState) => requestForSchedulerList(state),
    (users, userToWorkplaces, workplaces, userToContracts, vacations, shifts, userToRequest) => {
        const start = Date.now();
        const result: Record<
            number,
            IUserExtendedModel & {
                user_to_shifts: IUserToShiftsExtendedModel[];
            }
        > = {};

        const userToWorkplacesExtended: (IUserToWorkplaceModel & { workplace: IWorkplaceModel })[] = [];

        userToWorkplaces.forEach((item) => {
            const workplace = workplaces[item.workplace_id];

            if (workplace) {
                userToWorkplacesExtended.push({
                    ...item,
                    workplace
                });
            }
        });

        users.forEach((user) => {
            result[user.id] = {
                ...user,
                user_to_contracts: userToContracts.filter((item) => item.user_id === user.id),
                user_to_workplaces: userToWorkplacesExtended.filter((item) => item.user_id === user.id),
                user_to_vacations: vacations.filter((item) => item.user_id === user.id),
                user_to_requests: userToRequest.filter((item) => item.user_id === user.id),
                user_to_shifts: shifts.filter((item) => item.user_id === user.id)
            };
        });

        const time = Date.now() - start;

        if (time > 5) {
            console.warn('selectUsers is slow', time);
        }

        return result;
    }
);

export const selectUsers = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => userToWorkplaceAll(state),
    (state: IRootState) => workplaceEntities(state),
    (users, userToWorkplaces, workplaces) => {
        const userToWorkplacesExtended: (IUserToWorkplaceModel & { workplace: IWorkplaceModel })[] = [];

        userToWorkplaces.forEach((item) => {
            const workplace = workplaces[item.workplace_id];

            if (workplace) {
                userToWorkplacesExtended.push({
                    ...item,
                    workplace
                });
            }
        });

        return users.map((user) => ({
            ...user,
            user_to_workplaces: userToWorkplacesExtended.filter((item) => item.user_id === user.id)
        }));
    }
);

export const selectUserById = (state: IRootState, id: number) => selectUserEntities(state)[id];
