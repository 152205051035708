import { Fragment } from 'react';
import SchedulerCalendarDataRowCell, { ISchedulerCalendarDataRowCellProps } from './SchedulerCalendarDataRowCell';

type DataItem<DateType> = {
    uId: string;
    id: number;
    from: DateType;
    shiftId: number | null;
    shiftName: string | null;
    abbreviation: string | null;
    abbreviationColor: string | null;
    abbreviationBackgroundColor: string | null;
    paddingRight: boolean;
    to: DateType;
    type: 'empty' | 'request' | 'shift' | 'vacation';
    userId: number | null;
    width: number;
};

export type RenderAbleBlock<DateType = Date> = {
    uId: string;
    id: number;
    from: DateType;
    to: DateType;
    toMerge: boolean;
    items: {
        uId: string;
        id: number;
        from: DateType;
        to: DateType;
        shift_id: number | null;
        shift_name: string | null;
        abbreviation: string | null;
        abbreviation_color: string | null;
        abbreviation_background_color: string | null;
        extra_padding_right: boolean;
        type: 'empty' | 'request' | 'shift' | 'vacation';
        width: number;
        user_id: number | null;
    }[];
};
type BaseColumn<DATA, DateType> = {
    uId: string;
    columnStart: number;
    type: 'simple' | 'merged';
    from: DateType;
    to: DateType;
    width: number;
    data: DATA;
};
export type ISimpleColumn<DateType = Date> = BaseColumn<DataItem<DateType>, DateType> & {
    type: 'simple';
};
export type IMergeRow<DateType = Date> = Pick<ISimpleColumn<DateType>, 'uId' | 'from' | 'to'> & {
    data: ISimpleColumn<DateType>['data'][];
};
export type IMergedColumn<DateType = Date> = BaseColumn<IMergeRow<DateType>[], DateType> & {
    type: 'merged';
};
type ISchedulerTableCellProps = Omit<ISchedulerCalendarDataRowCellProps, 'data'> & {
    columns: (ISimpleColumn | IMergedColumn)[];
};

const SchedulerCalendarDataRow = ({ columns, isSchedulePlanClosed = false, ...rest }: ISchedulerTableCellProps) => {
    if (rest.userName === null) {
        return <></>;
    }

    return (
        <>
            {columns.map((column) => (
                <Fragment key={column.uId}>
                    <SchedulerCalendarDataRowCell {...rest} data={column} isSchedulePlanClosed={isSchedulePlanClosed} />
                </Fragment>
            ))}
        </>
    );
};

export default SchedulerCalendarDataRow;
