import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import useAppTranslation from '@/hooks/useAppTranslation';

export default function WelcomePage() {
    const { t } = useAppTranslation();

    return (
        <Box
            className="welcomePage"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            data-testid="welcome-page"
        >
            <Box sx={{ typography: 'h1' }}>{t('title.welcome', 'Welcome')}</Box>
            <CircularProgress />
            <Box sx={{ typography: 'h6' }}>{t('subTitle.systemIsStarting', 'System is starting...')}</Box>
        </Box>
    );
}
