import { useTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Fragment, RefObject, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import DateHelper from '@/helpers/date/DateHelper';
import NonNullableFieldsOfObject from '@/helpers/NonNullableFieldsOfObject';
import useSchedulerColumnTitles from '@/hooks/scheduler/useSchedulerColumnTitles';
import useSchedulerRequirements from '@/hooks/scheduler/useSchedulerRequirements';
import useSchedulerTitles from '@/hooks/scheduler/useSchedulerTitles';
import useAppTranslation from '@/hooks/useAppTranslation';
import HeaderDateContent from '@/modules/Scheduler/components/HeaderDateContent';
import LeftTopCorner from '@/modules/Scheduler/components/LeftTopCorner';
import SkillIcon from '@/modules/Scheduler/components/SkillIcon';
import { IChildSchedulerTypes } from '@/modules/Scheduler/Scheduler';
import { SchedulerContext } from '@/modules/Scheduler/SchedulerContext';
import {
    StyledCoverageDataItem,
    StyledCoverageDataWrapper,
    StyledCoverageSideBar,
    StyledSkillDataWrapper,
    StyledSkillSideBar,
    StyledSkillSideBarItem,
    StyledTopDataItem,
    StyledTopDataWrapper
} from '@/modules/Scheduler/StyledParts';
import Checkbox from '@/wrappers/Checkbox/Checkbox';
import Switch from '@/wrappers/Switch';
import SchedulerTableHeaderCell from './SchedulerCalendarHeaderCell';

type IBaseProps = NonNullableFieldsOfObject<IChildSchedulerTypes, 'from' | 'to'> & {
    isDayMode: boolean;
    schedulePlanId?: number;
    isCollapsedSideBar: boolean;
    onCollapseSideBar: () => void;
    onExpandSideBar: () => void;
    timeZone: string | null;
    isSchedulePlanClosed?: boolean;
    schedulerRef?: RefObject<HTMLDivElement>;
};

export type IShiftsProps = IBaseProps & {
    type: 'shifts';
    isCollapsedSkillsPart: boolean;
    selectedRequirements: number[] | null;
    rows: ReturnType<typeof useSchedulerRequirements>;
    onSelectRequirements: (id: number, value: boolean) => void;
    onCollapseRequirements: () => void;
    onExpandRequirements: () => void;
    onCollapseSideBar: () => void;
    onExpandSideBar: () => void;
};

export type IRequestsProps = IBaseProps & {
    type: 'requests';
    isCollapsedSkillsPart?: undefined;
    selectedRequirements?: null;
    onSelectRequirements?: undefined;
    rows?: undefined;
    onCollapseRequirements?: undefined;
    onExpandRequirements?: undefined;
};

export type ISchedulerCalendarHeaderProps = IRequestsProps | IShiftsProps;

function SchedulerCalendarHeader(props: ISchedulerCalendarHeaderProps) {
    const {
        type,
        isCollapsedSkillsPart,
        isCollapsedSideBar,
        isDayMode,
        selectedRequirements = [],
        schedulePlanId,
        onSelectRequirements,
        onCollapseRequirements,
        onExpandRequirements,
        onCollapseSideBar,
        onExpandSideBar,
        rows = [],
        from,
        to,
        timeZone,
        isSchedulePlanClosed = false,
        schedulerRef = null
    } = props;
    const { compactMode } = useContext(SchedulerContext);
    const theme = useTheme();
    const { t } = useAppTranslation();
    const topRow = useSchedulerTitles(isDayMode, from, to);
    const columnTitles = useSchedulerColumnTitles(isDayMode, from, to);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: 'instant',
                block: 'start',
                inline: 'center'
            });
        } else if (schedulerRef?.current) {
            schedulerRef.current.scrollIntoView({
                behavior: 'instant',
                block: 'start',
                inline: 'center'
            });
        }
    }, [scrollRef.current, schedulerRef?.current, isDayMode]);

    const handleToggleCollapseSkills = useCallback(() => {
        if (isCollapsedSkillsPart && onExpandRequirements) {
            onExpandRequirements();
        } else if (onCollapseRequirements) {
            onCollapseRequirements();
        }
    }, [isCollapsedSkillsPart]);
    const handleToggleCollapseSideBar = useCallback(() => {
        if (isCollapsedSideBar && onExpandSideBar) {
            onExpandSideBar();
        } else {
            onCollapseSideBar();
        }
    }, [isCollapsedSideBar]);

    const flooredNowDate = isDayMode
        ? DateHelper.addMinutesByTimezone(DateHelper.floorMinutes(DateHelper.now()), timeZone)
        : DateHelper.getUTCStartOfTheDay(DateHelper.subtractMinutesByTimezone(DateHelper.now(), timeZone));

    const nowDate = isDayMode
        ? DateHelper.addMinutesByTimezone(DateHelper.now(), timeZone)
        : DateHelper.subtractMinutesByTimezone(DateHelper.now(), timeZone);

    const percentageForGradient = useMemo(
        () => (isDayMode ? (100 / 60) * nowDate.toDate().getMinutes() : (100 / 24) * nowDate.toDate().getHours()),
        [nowDate.toISOString(), isDayMode]
    );
    const countOfColumns = DateHelper.getDifferenceInHours(
        from,
        isDayMode ? DateHelper.getFirstMomentOfDay(to) : DateHelper.addDays(to, 1)
    );

    return (
        <>
            <LeftTopCorner
                withCollapse={typeof isCollapsedSideBar !== 'undefined'}
                onClick={handleToggleCollapseSideBar}
                isCollapsed={isCollapsedSideBar}
            />
            <StyledTopDataWrapper countOfColumns={countOfColumns} isCompact={compactMode}>
                {topRow.map((column, index) => (
                    <StyledTopDataItem
                        key={`date_div_${index}`}
                        columnIndex={column.startColumn}
                        isDayView={isDayMode}
                        width={column.width}
                        ref={DateHelper.isEqual(flooredNowDate, column.from) ? scrollRef : null}
                        sx={{
                            ...(DateHelper.isEqual(flooredNowDate, column.from)
                                ? {
                                      backgroundColor: `linear-gradient(90deg, ${theme.palette.grey.A200} ${percentageForGradient}%, white ${percentageForGradient}%)`,
                                      borderLeft: '1px black solid',
                                      fontWeight: 'bold'
                                  }
                                : DateHelper.isAfter(flooredNowDate, column.from)
                                ? { backgroundColor: theme.palette.grey.A200 }
                                : {})
                        }}
                    >
                        <HeaderDateContent
                            key={column.id}
                            from={column.from}
                            isDayMode={isDayMode}
                            isCompact={compactMode}
                            timeZone={timeZone ?? 'UTC'}
                            data-testid={`date_${index}`}
                        />
                    </StyledTopDataItem>
                ))}
            </StyledTopDataWrapper>
            {type !== 'requests' && (
                <>
                    <StyledCoverageSideBar isCompact={compactMode}>
                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Switch
                                    name="toggle-collapse-sideBar"
                                    value={isCollapsedSkillsPart}
                                    onChange={handleToggleCollapseSkills}
                                />
                            }
                            label={
                                <Collapse in={!isCollapsedSideBar} orientation="horizontal">
                                    {t('label.coverageOrDemand', 'Coverage / Demand')}
                                </Collapse>
                            }
                        />
                    </StyledCoverageSideBar>
                    <StyledCoverageDataWrapper countOfColumns={countOfColumns} isCompact={compactMode}>
                        {topRow.map((column, rowIndex) => {
                            const endIndex = isDayMode ? 4 * (rowIndex + 1) : rowIndex + 1;
                            const requiredCols: number[] = [];
                            const covered: number[] = [];
                            let shouldDisplay = true;

                            for (let i = isDayMode ? 4 * rowIndex : rowIndex; i < endIndex; i++) {
                                const validColumns =
                                    rows
                                        ?.map((row) => ({
                                            content:
                                                row.columns.length > i && row.columns[i].required !== null
                                                    ? Number(row.columns[i].required)
                                                    : 0,
                                            covered: row.columns.length > i ? row.columns[i].covered : 0
                                        }))
                                        .filter((row) => row.covered !== null) ?? [];

                                shouldDisplay = shouldDisplay && validColumns.length > 0;
                                requiredCols.push(validColumns.reduce((prev, current) => prev + current.content, 0));
                                covered.push(validColumns.reduce((prev, current) => prev + (current.covered || 0), 0));
                            }

                            const required = Math.max(...requiredCols);

                            return (
                                <StyledCoverageDataItem
                                    key={`coverage-${rowIndex}`}
                                    columnIndex={column.startColumn}
                                    isDayView={isDayMode}
                                    width={column.width}
                                    data-testid={`coverage_${rowIndex}`}
                                    sx={{
                                        ...(DateHelper.isEqual(flooredNowDate, column.from)
                                            ? {
                                                  backgroundColor: `linear-gradient(90deg, ${theme.palette.grey.A400} ${percentageForGradient}%, ${theme.palette.grey.A200} ${percentageForGradient}%)`,
                                                  borderLeft: '1px black solid'
                                              }
                                            : DateHelper.isAfter(flooredNowDate, column.from)
                                            ? { backgroundColor: theme.palette.grey.A400 }
                                            : {})
                                    }}
                                >
                                    {Number.isNaN(required) ? '-' : `${Math.max(...covered)}/${required}`}
                                </StyledCoverageDataItem>
                            );
                        })}
                    </StyledCoverageDataWrapper>
                </>
            )}
            {rows?.map(({ columns, ...row }, rowIndex) => (
                <Fragment key={`${row.isSkill ? 'skill' : 'role'}_${row.id}`}>
                    <StyledSkillSideBar
                        data-testid="headerSideBarSkill"
                        isCompact={compactMode}
                        isLast={rows?.length === rowIndex + 1}
                        rowIndex={rowIndex}
                    >
                        <StyledSkillSideBarItem
                            key={`requirement-${row.isSkill}_${row.id}`}
                            data-testid={`requirement-${row.isSkill}_${row.id}`}
                            data-testvalue={row.id}
                            collapsed={isCollapsedSideBar}
                            hidden={isCollapsedSkillsPart}
                            selected={selectedRequirements?.includes(row.id) ?? false}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={`skill-check-${row.id}`}
                                        size={compactMode ? 'small' : undefined}
                                        sx={
                                            compactMode
                                                ? {
                                                      pt: 1 / 2,
                                                      pd: 0
                                                  }
                                                : undefined
                                        }
                                        value={selectedRequirements?.includes(row.id) ?? false}
                                        onChange={(value) =>
                                            onSelectRequirements && onSelectRequirements(row.id, value)
                                        }
                                    />
                                }
                                label={
                                    <>
                                        <Collapse in={!isCollapsedSideBar} orientation="horizontal">
                                            {row.name}
                                        </Collapse>
                                    </>
                                }
                            />
                            <SkillIcon {...row} />
                        </StyledSkillSideBarItem>
                    </StyledSkillSideBar>
                    <StyledSkillDataWrapper
                        key={`requirement-${row.isSkill}${row.id}`}
                        countOfColumns={isDayMode ? countOfColumns * 4 : countOfColumns}
                        isLast={rows?.length === rowIndex + 1}
                        isCompact={compactMode}
                        rowIndex={rowIndex}
                        data-testid="headerSideBarSkillData"
                    >
                        {columns.map(({ id, covered, required, date, planDayId }, index) => (
                            <SchedulerTableHeaderCell
                                key={`requirementRow-${row.isSkill ? 's' : 'r'}${row.id}-${id}`}
                                covered={covered}
                                date={date}
                                index={index}
                                itemId={Math.abs(row.id)}
                                isDayMode={isDayMode}
                                isHidden={isCollapsedSkillsPart}
                                isSkill={row.isSkill}
                                isSelected={selectedRequirements?.includes(row.id) ?? false}
                                isSchedulePlanClosed={isSchedulePlanClosed}
                                required={required}
                                schedulePlanId={schedulePlanId ?? undefined}
                                schedulePlanDayId={planDayId}
                                timezone={timeZone}
                                columnTitle={columnTitles.length > index ? columnTitles[index] : null}
                            />
                        ))}
                    </StyledSkillDataWrapper>
                </Fragment>
            ))}
        </>
    );
}

export default SchedulerCalendarHeader;
