import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as CalcImage } from '@/assets/images/scheduler-calc.svg';
import { ReactComponent as FirstStepsImage } from '@/assets/images/scheduler-first-steps.svg';
import { setInfoAlert } from '@/data/Alert/AlertSlice';
import { fetchApplicationsSettingsItems } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { limitationOfAgentsAndEmptyShifts } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { periodById } from '@/data/Periods/PeriodSlice';
import { schedulePlanDaysBySchedulePlanId } from '@/data/SchedulePlanDays/SchedulePlanDaySlice';
import { createSchedulePlan } from '@/data/SchedulePlans/SchedulePlanActions';
import {
    isPlanLoaded,
    newSchedulePlanData,
    schedulePlanAssignedUsersBySchedulePlanId,
    schedulePlanById,
    selectRolesUsedByPlan,
    selectSkillRequirementsByPlanId,
    selectTimeZoneOfPlan,
    selectUserIdsWithShiftByPlan
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { isSignedUserAdmin } from '@/data/System/SystemReducer';
import { userById } from '@/data/Users/UserSlice';
import { workplaceById } from '@/data/Workplaces/WorkplaceSlice';
import { uniqueArrayOfSimpleValues } from '@/helpers/array/unique';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { getEmptyShiftFrom } from '@/helpers/schedule';
import useSchedulerRequirements from '@/hooks/scheduler/useSchedulerRequirements';
import useAppTranslation from '@/hooks/useAppTranslation';
import SchedulerCalendarBody from '@/modules/Scheduler/components/SchedulerCalendarBody';
import SchedulerCalendarHeader from '@/modules/Scheduler/components/SchedulerCalendarHeader';
import SchedulerTradeBody from '@/modules/Scheduler/components/SchedulerTradeBody';
import { AvailableScheduleTypes, IChildSchedulerTypes } from '@/modules/Scheduler/Scheduler';
import { SchedulerContext } from '@/modules/Scheduler/SchedulerContext';
import SchedulerTableBodyCellDayForm from '@/modules/Scheduler/SchedulerTableBodyCellDayForm';
import SchedulerTableSimpleSkills from '@/modules/Scheduler/SchedulerTableSimpleSkills';
import { StyledWrapper } from '@/modules/Scheduler/StyledParts';
import config from '@/utils/config';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import Button from '@/wrappers/Button';
import { VisibilityOnlyRenderContextProvider } from '@/wrappers/VisibleOnlyRenderer';

const StyledCalcImage = styled(CalcImage)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 20px;
    width: 270px;
    height: 280px;
    flex: none;
    order: 0;
    flex-grow: 0;
`;
const StyledFirstStepsImage = styled(FirstStepsImage)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 20px;
    width: 270px;
    height: 280px;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

const StyledFirstStepsBox = styled(Grid)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    order: 1;
    align-self: stretch;
`;

export type IProps = IChildSchedulerTypes & {
    canCalculate: boolean;
    displayMode: SchedulePlanWidthTypeEnum;
    isNew: boolean;
    isSchedulePlanClosed?: boolean;
    periodId: number | null;
    selectedRequirements: number[] | null;
    type: AvailableScheduleTypes;
    workplaceId: number | null;
    onSchedulePlanCreated: (id: string) => void;
    onSelectRequirements: (requirements: number[] | null) => void;
    onLoadingFinished: () => void;
    onLoadingStart: () => void;
};
export type IRefHandlers = {
    setSelectedRequirements: () => void;
};

const SchedulerCalendar = ({
    canCalculate,
    displayMode,
    from,
    isNew,
    isSchedulePlanClosed = false,
    periodId,
    selectedRequirements,
    schedulePlanId,
    to,
    type,
    workplaceId,
    onLoadingFinished,
    onLoadingStart,
    onSelectRequirements,
    onSchedulePlanCreated
}: IProps) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();

    const [isCollapsedRequirementPart, setIsCollapsedRequirementPart] = useState(false);
    const [isCollapsedSideBar, setIsCollapsedSideBar] = useState(false);
    const [, setIsRefInitialized] = useState(false);

    const { compactMode } = useContext(SchedulerContext);
    const mode = useMemo(
        () => (displayMode != SchedulePlanWidthTypeEnum.Custom ? displayMode : SchedulePlanWidthTypeEnum.Week),
        [displayMode]
    );
    const isLoaded = useAppSelector((state) => isPlanLoaded(state, schedulePlanId));
    const newScheduleData = useAppSelector(newSchedulePlanData);
    const timeZone = useAppSelector((state) => selectTimeZoneOfPlan(state, schedulePlanId));
    const schedulePlanDays = useAppSelector((state) => schedulePlanDaysBySchedulePlanId(state, schedulePlanId));
    const workplace = useAppSelector((state) => workplaceById(state, workplaceId));
    const period = useAppSelector((state) => periodById(state, periodId));
    const skills = useAppSelector((state) => selectSkillRequirementsByPlanId(state, schedulePlanId));
    const roles = useAppSelector((state) => selectRolesUsedByPlan(state, schedulePlanId));
    const userIdsWithShift = useAppSelector((state) => selectUserIdsWithShiftByPlan(state, schedulePlanId));
    const assignedUsersToPlan = useAppSelector((state) =>
        schedulePlanAssignedUsersBySchedulePlanId(state, schedulePlanId)
    );
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const schedulePlan = useAppSelector((state) => schedulePlanById(state, schedulePlanId));
    const agentsAndEmptyShift = useAppSelector(limitationOfAgentsAndEmptyShifts);
    const emptyShiftsFrom = getEmptyShiftFrom(
        period?.period_start,
        schedulePlan?.empty_shifts_from ?? undefined,
        timeZone ?? undefined
    );
    const displayEmptyShifts = Boolean(
        isUserAdmin ||
            agentsAndEmptyShift !== 'from_date' ||
            (emptyShiftsFrom && DateHelper.isAfter(DateHelper.now(), emptyShiftsFrom))
    );
    const enableAssignEmptyShifts = Boolean(
        isUserAdmin ||
            agentsAndEmptyShift === 'default' ||
            (emptyShiftsFrom && DateHelper.isAfter(DateHelper.now(), emptyShiftsFrom))
    );
    const schedulerRef = useRef<HTMLDivElement>(null);

    const countOfUsers = uniqueArrayOfSimpleValues([
        ...(userIdsWithShift ?? []),
        ...(assignedUsersToPlan ?? [])
    ]).length;
    const handleSelectRequirement = useCallback(
        (id: number, shouldAdd: boolean) => {
            if (shouldAdd) {
                onSelectRequirements([...(selectedRequirements ?? []), id]);
            } else {
                onSelectRequirements(selectedRequirements?.filter((itemId) => itemId !== id) ?? null);
            }
        },
        [selectedRequirements]
    );
    const handleRemoveSelectedRequirement = useCallback(
        (id: number) => {
            handleSelectRequirement(id, false);
        },
        [selectedRequirements]
    );
    const handleCalculateNeeds = useCallback(() => {
        if (workplaceId && periodId) {
            dispatch(
                createSchedulePlan({
                    name: canCalculate ? newScheduleData.name : `${workplace?.name} - ${period?.name}`,
                    abbreviation: canCalculate ? newScheduleData.abbreviation : `${workplace?.name} - ${period?.name}`,
                    description: newScheduleData.description,
                    period_id: periodId,
                    workplace_id: workplaceId
                })
            )
                .unwrap()
                .then(({ id }) => {
                    dispatch(
                        setInfoAlert({
                            context: 'message.info.creatingOfSchedulePlanWithId1IsInProgress',
                            defaultMessage:
                                'Creating of Schedule Plan with ID {{id}} is in progress. We will inform you when the plan is ready.',
                            values: { id }
                        })
                    );
                    onSchedulePlanCreated(`${id}`);
                });
        }
    }, [newScheduleData, workplaceId, periodId, workplace, period]);
    const handleExpandRequirementsPart = useCallback(() => {
        setIsCollapsedRequirementPart(false);
    }, [isCollapsedRequirementPart]);
    const handleCollapseRequirementsPart = useCallback(() => {
        setIsCollapsedRequirementPart(true);
    }, [isCollapsedRequirementPart]);
    const handleExpandUsersPart = useCallback(() => {
        setIsCollapsedSideBar(false);
    }, [isCollapsedSideBar]);
    const handleCollapseUsersPart = useCallback(() => {
        setIsCollapsedSideBar(true);
    }, [isCollapsedSideBar]);
    const headerRows = useSchedulerRequirements(
        mode === SchedulePlanWidthTypeEnum.Day,
        from,
        to,
        schedulePlanDays ?? [],
        skills,
        roles
    );
    const [dataToForm, setDataToForm] = useState<{
        date: Date | DateTimeType;
        from: Date | null;
        id: number | null;
        to: Date | null;
        userId: number | null;
    } | null>(null);

    useEffect(() => {
        setIsRefInitialized(schedulerRef.current !== null);
    }, [schedulerRef.current]);
    useEffect(() => {
        setIsRefInitialized(schedulerRef.current !== null);

        dispatch(fetchApplicationsSettingsItems());
    }, []);

    const selectedUser = useAppSelector((state) => userById(state, dataToForm?.userId ?? undefined));

    return (
        <>
            <Collapse in={isCollapsedRequirementPart}>
                <SchedulerTableSimpleSkills
                    skills={skills ?? []}
                    roles={roles ?? []}
                    selectedRequirements={selectedRequirements ?? []}
                    onRemove={handleRemoveSelectedRequirement}
                />
            </Collapse>
            {isNew ? (
                <StyledFirstStepsBox className="firstStepsContent">
                    {workplaceId && periodId ? (
                        <>
                            <StyledCalcImage />
                            <Button
                                name="calculate"
                                onClick={handleCalculateNeeds}
                                variant="contained"
                                color="secondary"
                                startIcon={<AutoFixHighIcon />}
                                data-testid="calculateNeeds"
                            >
                                {t('label.calculateNeeds', 'Calculate Needs')}
                            </Button>
                        </>
                    ) : (
                        <>
                            <StyledFirstStepsImage />
                            <Typography variant="h6" data-testid="scheduleFirstStepsDescription">
                                {!workplaceId &&
                                    !periodId &&
                                    t('message.info.selectWorkplaceToStart', 'Select a Workplace to start')}
                                {workplaceId && !periodId && t('message.info.nowSelectPeriod', 'Now select a Period')}
                            </Typography>
                        </>
                    )}
                </StyledFirstStepsBox>
            ) : (
                <StyledWrapper
                    countOfRows={countOfUsers}
                    countOfSkills={isCollapsedRequirementPart ? 0 : headerRows?.length ?? 0}
                    hasUnassignedRow={displayEmptyShifts && type !== 'shiftTrades'}
                    data-testid="scheduler-table"
                    isClosed={isSchedulePlanClosed || type === 'shiftTrades'}
                    isCompact={compactMode}
                    ref={schedulerRef}
                >
                    <VisibilityOnlyRenderContextProvider
                        container={schedulerRef.current}
                        offsetTop={(headerRows?.length ?? 0) + 2}
                    >
                        {!isNew && schedulePlanId && from && to && isLoaded ? (
                            <>
                                <SchedulerCalendarHeader
                                    type="shifts"
                                    isDayMode={mode === SchedulePlanWidthTypeEnum.Day}
                                    from={from}
                                    to={to}
                                    isCollapsedSkillsPart={isCollapsedRequirementPart}
                                    isCollapsedSideBar={isCollapsedSideBar}
                                    rows={isCollapsedRequirementPart ? null : headerRows}
                                    schedulePlanId={schedulePlanId}
                                    selectedRequirements={selectedRequirements}
                                    timeZone={timeZone}
                                    onCollapseRequirements={handleCollapseRequirementsPart}
                                    onExpandRequirements={handleExpandRequirementsPart}
                                    onCollapseSideBar={handleCollapseUsersPart}
                                    onExpandSideBar={handleExpandUsersPart}
                                    onSelectRequirements={handleSelectRequirement}
                                    isSchedulePlanClosed={isSchedulePlanClosed}
                                />
                                {type !== 'shiftTrades' ? (
                                    <SchedulerCalendarBody
                                        assignedUsers={assignedUsersToPlan}
                                        displayEmptyShifts={displayEmptyShifts}
                                        isDayMode={mode === SchedulePlanWidthTypeEnum.Day}
                                        type={type}
                                        isCollapsed={isCollapsedSideBar}
                                        from={from}
                                        to={to}
                                        schedulePlanId={schedulePlanId}
                                        selectedRequirements={selectedRequirements}
                                        skills={skills}
                                        isSchedulePlanClosed={isSchedulePlanClosed}
                                        timeZone={timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone}
                                        onClick={(data) => setDataToForm(data)}
                                        onLoadingFinished={onLoadingFinished}
                                        onLoadingStart={onLoadingStart}
                                    />
                                ) : (
                                    <SchedulerTradeBody
                                        isDayMode={mode === SchedulePlanWidthTypeEnum.Day}
                                        isCollapsed={isCollapsedSideBar}
                                        from={from}
                                        to={to}
                                        schedulePlanId={schedulePlanId}
                                        selectedRequirements={selectedRequirements}
                                        timeZone={timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone}
                                        onClick={(data) => setDataToForm(data)}
                                        onLoadingFinished={onLoadingFinished}
                                        onLoadingStart={onLoadingStart}
                                    />
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </VisibilityOnlyRenderContextProvider>
                    {dataToForm !== null ? (
                        <SchedulerTableBodyCellDayForm
                            id={dataToForm.id}
                            date={DateHelper.getInstanceOf(dataToForm.date)}
                            enableAssign={dataToForm.userId !== null || enableAssignEmptyShifts}
                            isDayMode={mode === SchedulePlanWidthTypeEnum.Day}
                            recommendedSkills={
                                from && to
                                    ? headerRows
                                          ?.filter(
                                              (headerRow) =>
                                                  headerRow.isSkill &&
                                                  dataToForm.userId !== null &&
                                                  selectedUser?.user_to_skills?.some(
                                                      ({ skill_id }) => skill_id === headerRow.id
                                                  )
                                          )
                                          .map((headerRow) => ({
                                              skillId: headerRow.id,
                                              columns: headerRow.columns.filter((column) =>
                                                  DateHelper.isBetween(column.date, from, to, '[)')
                                              )
                                          })) ?? null
                                    : null
                            }
                            schedulePlanId={schedulePlanId}
                            shiftJoin={
                                dataToForm.id && dataToForm.from && dataToForm.to
                                    ? {
                                          id: dataToForm.id,
                                          from: dataToForm.from,
                                          to: dataToForm.to
                                      }
                                    : null
                            }
                            type={type}
                            userId={dataToForm.userId}
                            onClose={() => setDataToForm(null)}
                        />
                    ) : (
                        <></>
                    )}
                </StyledWrapper>
            )}
        </>
    );
};

export default memo(SchedulerCalendar, (prevProps, nextProps) => {
    const {
        schedulePlanId,
        isSchedulePlanClosed,
        isNew,
        canCalculate,
        displayMode,
        periodId,
        from,
        to,
        selectedRequirements,
        type,
        workplaceId,
        onLoadingFinished,
        onLoadingStart,
        onSelectRequirements,
        onSchedulePlanCreated,
        ...rest
    } = prevProps;

    if (config.isDevelop && Object.keys(rest).length) {
        console.warn('Unknown attributes calendar', rest);
    }

    return (
        schedulePlanId === nextProps.schedulePlanId &&
        isSchedulePlanClosed === nextProps.isSchedulePlanClosed &&
        isNew === nextProps.isNew &&
        canCalculate === nextProps.canCalculate &&
        displayMode === nextProps.displayMode &&
        periodId === nextProps.periodId &&
        JSON.stringify(selectedRequirements) === JSON.stringify(nextProps.selectedRequirements) &&
        type === nextProps.type &&
        workplaceId === nextProps.workplaceId &&
        DateHelper.isEqual(to, nextProps.to) &&
        DateHelper.isEqual(from, nextProps.from) &&
        onLoadingFinished.toString() === nextProps.onLoadingFinished.toString() &&
        onLoadingStart.toString() === nextProps.onLoadingStart.toString() &&
        onSelectRequirements.toString() === nextProps.onSelectRequirements.toString() &&
        onSchedulePlanCreated.toString() === nextProps.onSchedulePlanCreated.toString()
    );
});
