import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { PropsWithChildren } from 'react';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { DateTimeType } from '@/helpers/date/DateHelper';
import { IAbbreviationValue } from '@/wrappers/Abbreviation';
import Icon from '@/wrappers/Datatable/CellViews/Icon';
import TimeRange from '@/wrappers/Datatable/CellViews/TimeRange';

const Item: React.FC = (props) => <Box {...props} />;
const StyledText = styled(Item, {
    shouldForwardProp: (propName) => 'displaySimple' !== propName
})<PropsWithChildren<{ displaySimple: boolean }>>(
    ({ displaySimple }) => `
    ${displaySimple ? '' : 'padding-left: 4px;'}
    ${displaySimple ? '' : 'line-height: 1;'}
    text-align: ${displaySimple ? 'center' : 'left'};
    user-select: none;
   ${displaySimple ? 'margin: 0 auto' : ''};
`
);
const StyledIcon = styled(ShoppingCartIcon)`
    position: absolute;
    top: 0;
    right: 0;
`;
const StyledHomeIcon = styled(HomeIcon)`
    position: absolute;
    top: 0;
`;

export type IShiftAsTimelineViewProps = Pick<BoxProps, 'sx'> & {
    abbr?: Pick<IAbbreviationValue, 'text' | 'color'>;
    hoverEffect?: boolean;
    inStore: boolean;
    isDayMode: boolean;
    simpleView: boolean;
    shiftName: string | null;
    shiftStart: DateTimeType | null;
    shiftEnd: DateTimeType | null;
    skills: Pick<ISkillModel, 'id' | 'color' | 'icon'>[];
    timeZone: string;
    workFromHome?: boolean;
};

const ShiftAsTimelineView = ({
    hoverEffect = false,
    isDayMode,
    inStore,
    simpleView,
    shiftName,
    shiftStart,
    shiftEnd,
    abbr,
    skills,
    timeZone,
    sx = {},
    workFromHome = false,
    ...rest
}: IShiftAsTimelineViewProps) => {
    if (!shiftStart && shiftName && !abbr?.text) {
        return <></>;
    }

    const displaySimple = !isDayMode || simpleView;

    return (
        <>
            <StyledText
                {...rest}
                displaySimple={displaySimple}
                sx={{
                    ...sx,
                    'color': abbr?.color,
                    'opacity': hoverEffect ? 0 : 1,
                    '&:hover': { opacity: 1 },
                    ...(inStore || workFromHome ? { paddingRight: `${inStore && workFromHome ? 40 : 20}px` } : {})
                }}
            >
                {!displaySimple ? (
                    <>
                        <Typography variant="body1" sx={{ display: 'inline' }}>
                            {abbr?.text}
                        </Typography>
                        <Box sx={{ display: 'inline', whiteSpace: 'nowrap', position: 'relative' }}>
                            {skills.map((skill) => (
                                <Icon key={skill.id} icon={skill.icon} color={skill.color} display="inline-block" />
                            ))}
                        </Box>
                        <Typography variant="caption" sx={{ display: 'block' }}>
                            {shiftStart && shiftEnd && (
                                <TimeRange start={shiftStart} end={shiftEnd} timeZone={timeZone} />
                            )}
                        </Typography>
                    </>
                ) : (
                    <Box>
                        <Typography variant="body1" sx={{ display: 'block', fontSize: '0.8rem' }}>
                            {abbr?.text}
                        </Typography>
                        {!isDayMode && (
                            <Box sx={{ display: 'block', fontSize: '0.8em' }}>
                                {skills.map((skill) => (
                                    <Icon
                                        key={skill.id}
                                        icon={skill.icon}
                                        color={skill.color}
                                        display="inline"
                                        sx={{ fontSize: '1rem' }}
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                )}
            </StyledText>
            {inStore ? <StyledIcon /> : <></>}
            {workFromHome ? <StyledHomeIcon sx={{ right: inStore ? 20 : 0 }} /> : <></>}
        </>
    );
};

export default ShiftAsTimelineView;
