import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import { createActivity, fetchActivityById, fetchActivities, removeActivity, updateActivity } from './ActivityActions';
import { IActivityModel } from './ActivityModels';

type IActivityReducerState = {
    paging: IPaging;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IActivityReducerState = {
    paging: defaultPaging('name'),
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<IActivityModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});
const activitySlice = createSlice({
    name: 'activities',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchActivities.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.setAll(state, action.payload.data);
                if (!action.meta.arg.noPaging) {
                    state.paging = action.payload.collection;
                }
            })
            .addCase(fetchActivities.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchActivityById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchActivityById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchActivityById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createActivity.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createActivity.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
            })
            .addCase(createActivity.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeActivity.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeActivity.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg);
            })
            .addCase(removeActivity.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateActivity.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateActivity.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
            })
            .addCase(updateActivity.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[activitySlice.name];

const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export const isActivityListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const activityPaging = (state: IRootState) => getState(state).paging;
export const isActivityByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const activityList = adapterSelectors.selectAll;
export const activityCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const activityById = (state: IRootState, id?: number) =>
    id ? adapterSelectors.selectById(state, id) : undefined;
export const activityUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const activityRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = activitySlice.actions;

export default activitySlice;
