import { useEffect } from 'react';
import { fetchBreaks } from '@/data/Breaks/BreakActions';
import { IBreakModel } from '@/data/Breaks/BreakModels';
import { breakEntities } from '@/data/Breaks/BreakSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import { IUserCUBreaks } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import PeriodViewEnum from '@/utils/enums/PeriodViewEnum';
import CrudInlineOperationDatatable from '@/wrappers/CrudInlineDatatable';
import TextField from '@/wrappers/TextField';

type IProps = {
    data: IUserCUBreaks[];
    handleData: (value: IUserCUBreaks[]) => void;
};

const BreaksTab = ({ data, handleData }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const breaks = useAppSelector(breakEntities);
    const isSystemUser = useAppSelector(isSignedUserUser);

    useEffect(() => {
        dispatch(fetchBreaks({ noPaging: true }));
    }, []);

    return (
        <CrudInlineOperationDatatable<IUserCUBreaks>
            data={data}
            getRowId={(row) => row.break_id}
            header={[
                {
                    field: 'break_id',
                    headerName: t('header.breakName', 'Break Name'),
                    editable: true,
                    type: 'singleSelect',
                    valueOptions: ({ row }) =>
                        (Object.values(breaks) as IBreakModel[])
                            .filter(
                                (item) =>
                                    row?.break_id === item.id || !data.some((dataItem) => dataItem.break_id === item.id)
                            )
                            .map((item) => ({
                                value: item.id,
                                label: item.name
                            })),
                    preProcessEditCellProps: (params) => ({
                        ...params.props,
                        error:
                            !params.props.value ||
                            data.filter((dataItem) => dataItem.break_id === params.props.value).length > 1
                    })
                },
                {
                    field: 'period',
                    headerName: t('header.period', 'Period'),
                    editable: true,
                    type: 'singleSelect',
                    valueOptions: [
                        { value: '', label: ' ' },
                        { value: PeriodViewEnum.week, label: t(`label.${PeriodViewEnum.week.toLowerCase()}`, 'Week') },
                        {
                            value: PeriodViewEnum.month,
                            label: t(`label.${PeriodViewEnum.month.toLowerCase()}`, 'Month')
                        },
                        { value: PeriodViewEnum.day, label: t(`label.${PeriodViewEnum.day.toLowerCase()}`, 'Day') }
                    ],
                    preProcessEditCellProps: (params) => ({
                        ...params.props,
                        error: !params.props.value || ' ' === params.props.value
                    })
                },
                {
                    field: 'subsidy',
                    headerName: t('header.subsidy', 'Subsidy /h'),
                    type: 'number',
                    editable: true,
                    renderEditCell: (params) => (
                        <TextField
                            defaultValue={params.value !== ''}
                            error={params.error}
                            fullWidth={true}
                            inputProps={{
                                min: 0.25,
                                step: 0.25
                            }}
                            InputProps={{
                                endAdornment: t('label.hourShort', 'h')
                            }}
                            name="subsidy"
                            type="number"
                            value={null}
                            onChange={(e) =>
                                params.api.setEditCellValue({
                                    id: params.id,
                                    field: params.field,
                                    value: parseFloat(e.target.value) || 0
                                })
                            }
                        />
                    ),
                    renderCell: ({ row }) => `${row.subsidy}h`
                }
            ]}
            labelAddButton={t('label.addBreakSubsidy', 'Add Break Subsidy')}
            onRowAdd={
                isSystemUser
                    ? undefined
                    : (newData) => {
                          handleData([...data, newData]);
                      }
            }
            onRowRemove={
                isSystemUser ? undefined : (id) => handleData(data.filter((row) => `${row.break_id}` !== `${id}`))
            }
            onRowUpdate={
                isSystemUser
                    ? undefined
                    : (newData, oldData) =>
                          handleData(data.map((item) => (item.break_id === oldData?.break_id ? newData : item)))
            }
            onValidateRow={(newRow) => {
                const state = [
                    { field: 'break_id', error: typeof newRow.break_id !== 'number' },
                    { field: 'subsidy', error: typeof newRow.subsidy !== 'number' || newRow.subsidy < 0.25 },
                    { field: 'period', error: !Object.values(PeriodViewEnum).includes(newRow.period) }
                ];

                return state.some(({ error }) => error)
                    ? Promise.reject({ rowId: newRow.break_id, fieldsStatus: state })
                    : Promise.resolve(newRow);
            }}
        />
    );
};

export default BreaksTab;
