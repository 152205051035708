import { styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { PropsWithChildren } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { getBestPlaceForShiftLabel } from '@/helpers/scheduleBestPositionForLabel';
import { minLengthOfColumnOnDayView } from '@/modules/Scheduler/scheduleConstants';
import { IAbbreviationValue } from '@/wrappers/Abbreviation';
import TimeRange from '@/wrappers/Datatable/CellViews/TimeRange';

const Item: React.FC = (props) => <div {...props} />;

const StyledItem = styled(Item, {
    shouldForwardProp: (propName) =>
        !['columnStart', 'width', 'backgroundColor', 'borderColor'].includes(propName as string)
})<{
    backgroundColor?: string;
    borderColor?: string;
    columnStart: number;
    width: number;
}>(({ backgroundColor, borderColor, columnStart, width }) => ({
    backgroundColor,
    display: 'inline-flex',
    gridRow: 1,
    gridColumn: `${columnStart} /span ${width}`,
    height: '100%',
    lineHeight: 1,
    border: `1px solid ${borderColor}`
}));
const StyledContainer = styled(Box, {
    shouldForwardProp: (propName) => !['countOfColumns', 'backgroundColor', 'borderColor'].includes(propName as string)
})<{ backgroundColor: string; countOfColumns: number }>(({ backgroundColor, countOfColumns }) => ({
    backgroundColor,
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: `repeat(${countOfColumns}, 1fr)`,
    height: '42px',
    lineHeight: 1
}));

const StyledText = styled(Item)<PropsWithChildren<{}>>`
    position: absolute;
    padding-left: 3px;
    top: 0;
    left: 0;
    line-height: 1;
    user-select: none;
`;

export type IShiftAsTimelineViewProps = {
    abbr?: IAbbreviationValue;
    breaks: {
        id: number;
        break_id: number;
        breakDuration: number;
        breakBackground: string;
        outOfShift?: boolean;
        required: boolean;
        valueStart: DateTimeType;
        valueUsed: boolean;
    }[];
    shiftEnd?: DateTimeType;
    shiftStart?: DateTimeType;
    timeZone?: string;
};

const ShiftAsTimelineView = ({ abbr, breaks, shiftStart, shiftEnd, timeZone }: IShiftAsTimelineViewProps) => {
    if (!shiftStart || !shiftEnd || !abbr) {
        return <></>;
    }

    const parts = DateHelper.getDifferenceAsMinutes(shiftStart, shiftEnd) / minLengthOfColumnOnDayView;
    const transformedItems = breaks
        .filter((item) => (item.required || item.valueUsed) && item.outOfShift !== true)
        .sort((a, b) => (DateHelper.isBefore(a.valueStart, b.valueStart) ? 1 : -1))
        .map((item) => ({
            id: item.id,
            backgroundColor: item.breakBackground,
            columnStart: DateHelper.getDifferenceAsMinutes(shiftStart, item.valueStart) / minLengthOfColumnOnDayView,
            width: item.breakDuration / minLengthOfColumnOnDayView
        }));
    const stylesForLabel = getBestPlaceForShiftLabel(true, parts, transformedItems);

    return (
        <StyledContainer backgroundColor={abbr.background} countOfColumns={parts}>
            <StyledText
                sx={{
                    gridRow: 1,
                    gridColumnStart: stylesForLabel.start,
                    gridColumnEnd: `span ${stylesForLabel.width}`
                }}
            >
                <Typography variant="body2" color={abbr.color}>
                    {abbr.text}
                </Typography>
                <Typography variant="caption" color={abbr.color}>
                    <TimeRange start={shiftStart} end={shiftEnd} timeZone={timeZone} />
                </Typography>
            </StyledText>
            {transformedItems.map(({ id, ...rest }) => (
                <StyledItem {...rest} key={id} borderColor={abbr.background} />
            ))}
        </StyledContainer>
    );
};

export default ShiftAsTimelineView;
