import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { requestTypeAll } from '@/data/RequestTypes/RequestTypeSlice';
import { fetchUserFund } from '@/data/UserToVacationFunds/UserToVacationFundActions';
import { IUserVacationFund } from '@/data/UserToVacationFunds/UserToVacationFundModel';
import {
    fundsByUserId,
    getUserToVacationFundKey,
    isSomeUserVacationFundLoading
} from '@/data/UserToVacationFunds/UserToVacationFundSlice';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import { PermissionsControllerEnum } from '@/utils/enums/PermissionsEnum';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import Box from '@/wrappers/Box';
import CrudInlineDatatable from '@/wrappers/CrudInlineDatatable';
import Switch from '@/wrappers/Switch';

type IRequestTypesTab = {
    userId: number | null;
    data: IUserVacationFund[];
    handleData: (value: IUserVacationFund[]) => void;
};

const VacationFundTab = ({ userId, data, handleData }: IRequestTypesTab) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const requestsForSelectData = useAppSelector(requestTypeAll) ?? [];
    const usersFundsIsLoading = useAppSelector((state) =>
        userId ? isSomeUserVacationFundLoading(state, userId) : false
    );
    const usersFunds = useAppSelector((state) => fundsByUserId(state, userId));
    const [showHistory, setShowHistory] = useState(false);
    const findRequestType = (id: string) => requestsForSelectData.find((requestType) => `${requestType.id}` === id);
    const isTimeOff = (id: string) => findRequestType(id)?.type === RequestTypeEnum.times_off;
    const requestsForSelectTimesOffData = useMemo(
        () =>
            requestsForSelectData
                .filter((item) => isTimeOff(`${item.id}`))
                .map((item) => ({
                    value: item.id,
                    label: item.name
                })),
        []
    );

    const preparedData: IUserVacationFund[] = data.map((item) => ({
        ...item,
        exhausted:
            usersFunds?.find(
                (entity) =>
                    userId &&
                    getUserToVacationFundKey(entity) ===
                        getUserToVacationFundKey({
                            user_id: userId,
                            year: item.year,
                            request_type_id: item.request_type_id
                        })
            )?.exhausted ??
            item.exhausted ??
            0
    }));

    return (
        <CrudInlineDatatable<IUserVacationFund>
            getRowId={(row) => `${row.year}_${row.request_type_id}`}
            labelAddButton={t('label.addRequestSetting', 'Add Request Setting')}
            customActionsInToolbar={
                <Box sx={{ display: 'flex', gap: 1, marginRight: 2 }}>
                    <Switch
                        name="showHistory"
                        label={t('label.showHistory', 'Show History')}
                        value={showHistory}
                        labelPlacement="start"
                        onChange={(newValue) => {
                            setShowHistory(newValue);
                        }}
                    />
                </Box>
            }
            resource={PermissionsControllerEnum.UserToVacationFund}
            header={[
                {
                    headerName: t('header.nameOfRequestType', 'NameOfRequestType'),
                    field: 'request_type_id',
                    type: 'singleSelect',
                    valueOptions: requestsForSelectTimesOffData,
                    // settings: {
                    //     unfiltered: true
                    // },
                    //TODO validate: (rowData) => {
                    //     return rowData.request_type_id &&
                    //         !data
                    //             .filter((item) => item.id != rowData.id)
                    //             .some(
                    //                 (item) =>
                    //                     item.year == rowData.year && item.request_type_id == rowData.request_type_id
                    //             )
                    //         ? true
                    //         : { isValid: false };
                    // },
                    renderCell: ({ row }) => {
                        const request = requestsForSelectTimesOffData.find(
                            (item) => `${item.value}` === `${row.request_type_id}`
                        );

                        return request?.label ?? '';
                    }
                },
                {
                    field: 'year',
                    headerName: t('header.year', 'Year')
                    //TODO
                    // validate: (rowData) => (rowData.year ? true : { isValid: false, helperText: 'It cannot be empty' })
                },
                {
                    field: 'fund',
                    headerName: t('header.fund', 'Fund'),
                    type: 'number'
                    //TODO
                    // inputProps: {
                    //     inputProps: {
                    //         step: 1,
                    //         min: 0
                    //     },
                    //     endAdornment: t('label.' + 'requestHoursPerYearShort', 'h/y')
                    // },
                    // validate: (rowData) => (rowData.fund ? true : { isValid: false, helperText: 'It cannot be empty' })
                },
                {
                    field: 'exhausted',
                    headerName: t('header.exhausted', 'Exhausted')
                    //TODO cellStyle: (tableData, rowdata) => ({
                    //     color:
                    //         rowdata && rowdata.exhausted == rowdata.fund
                    //             ? theme.palette.warning.dark
                    //             : rowdata && (rowdata.exhausted ?? 0) > (rowdata.fund ?? 0)
                    //             ? theme.palette.error.dark
                    //             : undefined
                    // })
                }
            ]}
            data={showHistory ? preparedData : preparedData.filter((entity) => entity.year >= DateHelper.now().year())}
            // isLoading={usersFundsIsLoading}
            onRowAdd={(newData) => {
                // handleData([
                //     ...data,
                //     {
                //         ...newData,
                //         id: generateUniqueID(),
                //         year: DateHelper.fromOptionalYear(newData.year)?.year() ?? 0
                //     }
                // ]);
                // if (userId) {
                //     dispatch(
                //         fetchUserFund({
                //             userId: userId,
                //             year: newData.year,
                //             requestTypeId: newData.request_type_id,
                //             fund: newData.fund
                //         })
                //     );
                // }
            }}
            onRowRemove={(id) => handleData(data.filter((row) => `${row.year}_${row.request_type_id}` !== id))}
            onRowUpdate={(newData, oldData) => {
                // handleData([
                //     ...data.filter(
                //         (row) =>
                //             !(
                //                 row.request_type_id == oldData?.request_type_id &&
                //                 row.year == (DateHelper.fromOptionalYear(oldData?.year)?.year() ?? false)
                //             )
                //     ),
                //     {
                //         ...newData,
                //         id: oldData?.id,
                //         // year: newData.year
                //         year: DateHelper.fromOptionalYear(newData.year)?.year() ?? 0
                //     }
                // ]);

                if (userId) {
                    dispatch(
                        fetchUserFund({
                            userId: userId,
                            year: newData.year,
                            requestTypeId: newData.request_type_id,
                            fund: newData.fund
                        })
                    );
                }
            }}
        />
    );
};

export default VacationFundTab;
