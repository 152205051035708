import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, fetchSelectList, remove, update } from './PeriodApi';
import { IPeriodCUModel } from './PeriodModels';
import {
    periodPaging,
    periodSelectValuesStatus,
    isPeriodByIdInProgress,
    periodById,
    periodsForSelect
} from './PeriodSlice';

export const fetchPeriods = createAsyncThunk('periods/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = periodPaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchPeriodById = createAsyncThunk(
    'periods/byId',
    async (args: number | null) => {
        return await fetchById(args!);
    },
    {
        condition(id, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (id === null || !!periodById(state, id) || isPeriodByIdInProgress(state)) {
                return false;
            }
        }
    }
);

export const createPeriod = createAsyncThunk('periods/create', async (args: IPeriodCUModel) => {
    return await create(args);
});

export const fetchPeriodsForSelect = createAsyncThunk(
    'periods/selectList',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSelectList(args.search, args.fields || []);
    },
    {
        condition(args: { search: string; fields?: string[] }, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;
            const stateData = periodsForSelect(state);
            const isNeededToBeLoaded =
                stateData.length === 0 ||
                stateData.some(
                    (item) => args.fields?.some((fieldItem) => Object.keys(item).some((key) => key !== fieldItem))
                );

            if (periodSelectValuesStatus(state) !== 'idle' || !isNeededToBeLoaded) {
                return false;
            }
        }
    }
);

export const updatePeriod = createAsyncThunk('periods/update', async (args: { id: number; data: IPeriodCUModel }) => {
    return await update(args.id, args.data);
});

export const removePeriod = createAsyncThunk('periods/remove', async (id: number) => remove(id));
