import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo } from 'react';
import { FieldsGenerator } from '@/base/FormGenerator';
import { ISimpleFieldTypes } from '@/base/FormGenerator/FieldTypes';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { createContract, updateContract } from '@/data/Contracts/ContractActions';
import { IContractCUModel, IContractModel, IContractsDayData } from '@/data/Contracts/ContractModels';
import {
    contractById,
    contractCreatingStatus,
    contractPaging,
    contractUpdatingStatus,
    updatePaging
} from '@/data/Contracts/ContractSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchShiftsForSelect } from '@/data/Shifts/ShiftActions';
import { shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import { dayTest } from '@/helpers/bit';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useTranslatedDays from '@/hooks/useTranslatedDays';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';
import { ITimeRangePickerProps } from '@/wrappers/TimeRangePicker';

type IProps = Omit<ICuProps<IContractModel>, 'resource'>;

const ContractForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const translatedDays = useTranslatedDays();
    const loadedData = useAppSelector((state) => contractById(state, id));
    const shifts = useAppSelector(shiftsForSelect);
    const paging = useAppSelector(contractPaging);
    const handleData = useCallback((values: IOutputValueType): IContractCUModel => {
        const contractsHours: IContractCUModel['contracts_hours'] = [];

        (values.day_data as IContractsDayData[][])
            .map((item) => item.filter((subItem) => !!subItem.time_range?.start && !!subItem.time_range?.duration))
            .forEach((item, dayIndex) =>
                item.forEach((subItem) => {
                    const timeStart = subItem.time_range ? DateHelper.formatTime(subItem.time_range.start) : null;
                    const timeEnd =
                        subItem.time_range && timeStart && subItem.time_range.duration
                            ? DateHelper.formatTime(
                                  DateHelper.fromTimeStringAndDuration(timeStart, subItem.time_range.duration)
                              )
                            : null;

                    contractsHours.push({
                        id: typeof subItem.id === 'string' ? parseInt(subItem.id) : subItem.id,
                        day: dayIndex,
                        start: timeStart ?? '',
                        end: timeEnd ?? ''
                    });
                })
            );

        return {
            name: values.name as string,
            day_hours_limit: values.day_hours_limit as number,
            week_hours_limit: values.week_hours_limit as number,
            number_of_hours_per_day:
                typeof values.month_hours_limit === 'number' ? null : (values.number_of_hours_per_day as number),
            min_continuous_rest_per_week: values.min_continuous_rest_per_week as number,
            min_break_hours_per_day: values.min_break_hours_per_day as number,
            min_break_hours_per_day_splitted: values.min_break_hours_per_day_splitted as number,
            break_at_least_after_hours: values.break_at_least_after_hours as number,
            month_hours_limit:
                typeof values.number_of_hours_per_day === 'number' ? null : (values.month_hours_limit as number),
            holidays_allowed: values.holidays_allowed as boolean,
            night_shifts_allowed: values.night_shifts_allowed as boolean,
            full_fill: values.full_fill as boolean,
            external_connection_id: values.external_connection_id as string,
            days: (values?.days ?? null) as number | null,
            contracts_hours: contractsHours,
            contract_groups: (
                ((values.groups as []) ?? []) as {
                    id: number | null;
                    min: string;
                    max: string | null;
                    shift_ids: string[];
                }[]
            )
                .filter((item) => Array.isArray(item.shift_ids) && item.shift_ids.length > 0)
                .map((item) => ({
                    id: item.id,
                    min: parseInt(`${item.min}`),
                    max: item.max !== null ? parseInt(`${item.max}`) : null,
                    shift_ids: item.shift_ids.map((shift_id: string) => parseInt(`${shift_id}`))
                }))
        };
    }, []);
    const handleCreate = useCallback((values: IOutputValueType) => createContract(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => updateContract({ id: id!, data: handleData(values) }),
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    useEffect(() => {
        dispatch(fetchShiftsForSelect({ search: '' }));
    }, []);

    const dayDataValues = useMemo(() => {
        const dayData: IContractsDayData[][] = [];

        loadedData?.contracts_hours?.forEach((contractHourItem) => {
            const start = DateHelper.fromOptionalTime(contractHourItem.start);

            if (typeof dayData[contractHourItem.day] === 'undefined') {
                dayData[contractHourItem.day] = [];
            }

            dayData[contractHourItem.day].push({
                id: contractHourItem.id,
                time_range: {
                    start: start,
                    duration: DateHelper.getDifferenceInHours(start, DateHelper.fromOptionalTime(contractHourItem.end))
                }
            });
        });

        return { day_data: dayData };
    }, [loadedData]);

    return (
        <CuForm
            {...props}
            id={id}
            name="contract"
            resource={PermissionsEnum.Contracts}
            creatingStatus={useAppSelector(contractCreatingStatus)}
            updatingStatus={useAppSelector(contractUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            maxWidth="lg"
            items={[
                {
                    type: 'weekdays',
                    props: {
                        name: 'days',
                        value: loadedData?.days ?? 0,
                        multiple: true,
                        width: 12,
                        sx: { alignItems: 'center' },
                        validation: {
                            deps: ['days_type', 'days', 'use_default_days']
                        }
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'holidays_allowed',
                        label: t('label.holidaysAllowed', 'Holidays Allowed'),
                        value: loadedData?.holidays_allowed,
                        width: 4
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'night_shifts_allowed',
                        label: t('label.nightShiftsAllowed', 'Night Shifts Allowed'),
                        value: loadedData?.night_shifts_allowed,
                        width: 4
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'full_fill',
                        label: t('label.fullFill', 'Full Fill'),
                        value: loadedData?.full_fill,
                        width: 4
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: t('label.name', 'Name'),
                        value: loadedData?.name,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'day_hours_limit',
                        label: t('label.dayHoursLimit', 'Day Hours Limit'),
                        value: loadedData?.day_hours_limit,
                        width: 6,
                        type: 'number',

                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'week_hours_limit',
                        label: t('label.weekHoursLimit', 'Week Hours Limit'),
                        value: loadedData?.week_hours_limit,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'number_of_hours_per_day',
                        label: t('label.numberOfHoursPerDay', 'Number Of Hours Per Day'),
                        value: loadedData?.number_of_hours_per_day,
                        disabled: (currentValues) =>
                            currentValues.month_hours_limit !== '' &&
                            currentValues.month_hours_limit !== '0' &&
                            currentValues.month_hours_limit != null,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            deps: ['month_hours_limit'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'min_continuous_rest_per_week',
                        label: t('label.minContinuousRestPerWeek', 'Min Continuous Rest Per Week'),
                        value: loadedData?.min_continuous_rest_per_week,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'min_break_hours_per_day',
                        label: t('label.minBreakHoursPerDay', 'Min Break Hours Per Day'),
                        value: loadedData?.min_break_hours_per_day,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'min_break_hours_per_day_splitted',
                        label: t('label.minBreakHoursPerDaySplitted', 'Min Break Hours Per Day Splitted'),
                        value: loadedData?.min_break_hours_per_day_splitted,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'break_at_least_after_hours',
                        label: t('label.breakAtLeastAfterHours', 'Break At Least After Hours'),
                        value: loadedData?.break_at_least_after_hours,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'month_hours_limit',
                        type: 'number',
                        label: t('label.monthHoursLimit', 'Month Hours Limit'),
                        value: loadedData?.month_hours_limit,
                        disabled: (currentValues) =>
                            currentValues.number_of_hours_per_day !== '' &&
                            currentValues.number_of_hours_per_day !== '0' &&
                            currentValues.number_of_hours_per_day != null,
                        width: 6,
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            deps: ['number_of_hours_per_day'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'external_connection_id',
                        value: loadedData?.external_connection_id,
                        label: t('label.externalConnectionId', 'External Connection ID'),
                        type: 'text',
                        width: 4
                    }
                },
                {
                    type: 'html',
                    props: {
                        name: 'groupsTitle',
                        render: () => t('title.groups', 'Groups')
                    }
                },
                {
                    type: 'multiRowInputs',
                    props: {
                        name: 'groups',
                        value:
                            loadedData?.contract_groups.map((value) => ({
                                id: value.id,
                                min: value.min,
                                max: value.max,
                                shift_ids: value.contract_group_shifts?.map((valueShifts) => `${valueShifts.shift_id}`)
                            })) ?? [],
                        inputs: [
                            {
                                type: 'textField',
                                props: {
                                    required: (data, rowIndex) =>
                                        Array.isArray(data.contract_groups) &&
                                        rowIndex !== null &&
                                        data.contract_groups.length > rowIndex &&
                                        data.contract_groups[rowIndex].shift_ids.length !== 0,
                                    name: 'min',
                                    label: t('label.min', 'Min'),
                                    type: 'number',
                                    InputProps: {
                                        inputProps: {
                                            min: 1
                                        }
                                    },
                                    width: 2,
                                    validation: {
                                        deps: ['shift_ids', 'min'],
                                        min: {
                                            value: 1,
                                            message: t('message.info.minimalValueIs1', 'Minimal value is {{value}}.', {
                                                value: 1
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    name: 'max',
                                    label: t('label.max', 'Max'),
                                    type: 'number',
                                    InputProps: {
                                        inputProps: {
                                            min: 1
                                        }
                                    },
                                    width: 2
                                }
                            },
                            {
                                type: 'multiSelect',
                                props: {
                                    name: 'shift_ids',
                                    required: (data, rowIndex) =>
                                        Array.isArray(data.contract_groups) &&
                                        rowIndex !== null &&
                                        data.contract_groups.length > rowIndex &&
                                        data.contract_groups[rowIndex].min !== '' &&
                                        parseInt(`${data.contract_groups[rowIndex].min}`) !== 0,
                                    label: t('label.shifts', 'Shifts'),
                                    options:
                                        shifts?.map((item) => ({
                                            id: `${item.id}`,
                                            label: `${item.name}`
                                        })) ?? [],
                                    width: 6,
                                    validation: {
                                        deps: ['shift_ids', 'min']
                                    }
                                }
                            }
                        ]
                    }
                },
                ...((translatedDays.length > 0
                    ? translatedDays.map((day, index) => ({
                          type: 'custom',
                          display: (values: IOutputValueType) => dayTest((values.days as number | null) || 0, day.id),
                          props: {
                              name: `day_${index}`,
                              values: dayDataValues,
                              render: (rest) => {
                                  return (
                                      <>
                                          <Typography variant="subtitle2">{day.name}</Typography>
                                          <Divider />
                                          <FieldsGenerator
                                              {...rest}
                                              name={`custom_time_${index}`}
                                              fullWidth
                                              fields={[
                                                  {
                                                      type: 'multiRowInputs',
                                                      props: {
                                                          name: `day_data.${index}`,
                                                          inputs: [
                                                              {
                                                                  type: 'timeRange',
                                                                  props: {
                                                                      name: 'time_range',
                                                                      disableEmpty: false,
                                                                      label: {
                                                                          start: t('label.start', 'Start'),
                                                                          end: t('label.end', 'End')
                                                                      },
                                                                      minTime: (values, rowIndex) => {
                                                                          const time = values.day_data as
                                                                              | {
                                                                                    time_range: ITimeRangePickerProps['value'];
                                                                                }[][]
                                                                              | undefined;

                                                                          let previousTime = null;

                                                                          if (rowIndex && rowIndex > 0 && time) {
                                                                              previousTime =
                                                                                  time[index][rowIndex - 1].time_range;
                                                                          }

                                                                          return previousTime
                                                                              ? DateHelper.addMinutes(
                                                                                    DateHelper.addHours(
                                                                                        previousTime.start,
                                                                                        previousTime.duration
                                                                                    ),
                                                                                    10
                                                                                )
                                                                              : null;
                                                                      },
                                                                      minutesStep: 15,
                                                                      width: 11,
                                                                      validation: {
                                                                          deps: [`day_data.${index}.time_range`]
                                                                      }
                                                                  }
                                                              }
                                                          ]
                                                      }
                                                  }
                                              ]}
                                          />
                                      </>
                                  );
                              }
                          }
                      }))
                    : []) as ISimpleFieldTypes[])
            ]}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default ContractForm;
