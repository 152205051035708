import Avatar from '@mui/material/Avatar';
import { useEffect } from 'react';
import CrudDatatable, { ICrudDatatableProps } from '@/components/CrudDatatable';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchQueuesForSelect } from '@/data/Queues/QueueActions';
import { getPermissionsList } from '@/data/System/SystemReducer';
import { fetchWorkplaces, removeWorkplace } from '@/data/Workplaces/WorkplaceActions';
import { selectWorkplaceList, workplacePaging } from '@/data/Workplaces/WorkplaceSlice';
import WorkplaceForm from '@/forms/WorkplaceForm';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import ChipStack from '@/wrappers/ChipStack';

const WorkplacesListPage = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectWorkplaceList);
    const permissionList = useAppSelector(getPermissionsList);

    useEffect(() => {
        if (isUserAllowed({ id: PermissionsEnum.Queues, mode: Mode.READ }, permissionList)) {
            dispatch(fetchQueuesForSelect({ search: '' }));
        }
    }, []);

    return (
        <LayoutOfPage title={t('title.workplacesList', 'Workplaces List')}>
            <CrudDatatable<ArrayElement<typeof data>>
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <WorkplaceForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    { headerName: t('header.workplaceName', 'Workplace Name'), field: 'name' },
                    {
                        headerName: t('header.administrators', 'Administrators'),
                        field: 'administrators',
                        sortable: false,
                        renderCell: ({ row }) => (
                            <ChipStack
                                name="administrators"
                                direction="row"
                                values={
                                    row.administrators
                                        .map(
                                            (joinRow) =>
                                                `${joinRow.first_name},${
                                                    joinRow.middle_name || null ? joinRow.middle_name + ', ' : ''
                                                } ${joinRow.last_name}`
                                        )
                                        .map((user) => ({
                                            id: user,
                                            name: user,
                                            label: user,
                                            color: 'primary',
                                            avatar: <Avatar>{user.substring(0, 1)}</Avatar>
                                        })) ?? []
                                }
                            />
                        )
                    },
                    ...(isUserAllowed({ id: PermissionsEnum.Queues, mode: Mode.READ }, permissionList)
                        ? ([
                              {
                                  headerName: t('header.queues', 'Queues'),
                                  field: 'queue_to_workplaces',
                                  sortable: false,
                                  renderCell: ({ row }) => (
                                      <ChipStack
                                          name="queues"
                                          direction="row"
                                          values={
                                              row?.queue_to_workplaces?.map(({ queue }) => ({
                                                  id: `${queue.id}`,
                                                  name: queue.name,
                                                  label: queue.name,
                                                  color: 'primary'
                                              })) ?? []
                                          }
                                      />
                                  )
                              }
                          ] as ICrudDatatableProps<ArrayElement<typeof data>>['header'])
                        : []),
                    {
                        headerName: t('header.location', 'Location'),
                        field: 'location'
                    },
                    {
                        headerName: t('header.employees', 'Employees'),
                        field: 'employees',
                        sortable: false,
                        renderCell: ({ row }) =>
                            row.user_to_workplaces.filter((joinTable) => joinTable.type === 'user').length ?? 0
                    }
                ]}
                name="workplace"
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(workplacePaging)}
                resource={PermissionsEnum.Workplaces}
                onFetchList={fetchWorkplaces}
                onRemove={(id) =>
                    dispatch(removeWorkplace(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </LayoutOfPage>
    );
};

export default WorkplacesListPage;
