enum ApplicationSettingsSchedulePlanItemEnum {
    onHolidayInsertSetShiftAsEmpty = 'on_holiday_insert_set_shift_as_empty',
    dndShiftBetweenDays = 'dnd_shift_between_days',
    canTakeTradeWithoutCounteroffer = 'can_take_trade_without_counteroffer',
    limitationOfAgentsAndEmptyShifts = 'limitation_of_agents_and_empty_shifts',
    canOpenSchedulePlan = 'can_open_schedule_plan',
    canCloseSchedulePlan = 'can_close_schedule_plan',
    canEditClosedSchedulePlan = 'can_edit_closed_schedule_plan',
    personWhoClosedSchedulePlanCanOpenSchedulePlan = 'person_who_closed_schedule_plan_can_open_schedule_plan'
}

export default ApplicationSettingsSchedulePlanItemEnum;
