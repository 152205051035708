import CrudDatatable from '@/components/CrudDatatable';
import { fetchAgentGroups, removeAgentGroup } from '@/data/AgentGroups/AgentGroupActions';
import { IAgentGroupModel } from '@/data/AgentGroups/AgentGroupModels';
import { agentGroupList, agentGroupPaging } from '@/data/AgentGroups/AgentGroupReducer';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import AgentGroupForm from '@/forms/AgentGroupForm';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import PageHeading from '@/wrappers/PageHeading';

export default function AgentGroupsListPage() {
    const dispatch = useAppDispatch();
    const data = useAppSelector(agentGroupList);

    return (
        <>
            <PageHeading>Agent Groups</PageHeading>
            <CrudDatatable<IAgentGroupModel>
                autoHeight={false}
                data={data}
                header={[
                    {
                        headerName: 'Agent Group Name',
                        field: 'name',
                        flex: 1
                    },
                    {
                        headerName: 'Team Leaders',
                        field: 'leaders',
                        flex: 1
                    },
                    {
                        headerName: 'Workplace',
                        field: 'place',
                        flex: 1
                    },
                    {
                        headerName: 'Employees (min/max)',
                        field: 'employees',
                        flex: 1
                    }
                ]}
                formRender={(id, justIcon, openButtonValue) => (
                    <AgentGroupForm displayAsSidebar justIcon={justIcon} id={id} openButtonValue={openButtonValue} />
                )}
                name="agentGroup"
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(agentGroupPaging)}
                resource={PermissionsEnum.AgentGroups}
                onFetchList={fetchAgentGroups}
                onRemove={(id) =>
                    dispatch(removeAgentGroup(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </>
    );
}
