enum RoleEnum {
    SystemAdmin = 1,
    Admin,
    TeamLeader,
    Agent,
    PlannerForWorkplace,
    OnlyAttendance,
    HR
}

export default RoleEnum;
