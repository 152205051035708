import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import TimePicker, { ITimePickerProps } from '../TimePicker';

export type IValueType<T> = {
    start: T;
    end: T;
};
export type IValue = {
    start: ITimePickerProps['value'] | null;
    duration: number | null;
};

export type ITimeRangePickerProps = Omit<
    ITimePickerProps,
    'label' | 'defaultValue' | 'minTime' | 'maxTime' | 'error' | 'helperText' | 'onError' | 'onChange' | 'value'
> & {
    label: IValueType<string>;
    value?: IValue;
    minTime?: ITimePickerProps['value'];
    maxTime?: ITimePickerProps['value'];
    error?: IValueType<boolean>;
    helperText?: IValueType<string>;
    zeroIsNextDay?: boolean;
    onError?: (message: IValueType<string>) => void;
    override?: {
        startName?: string;
        endName?: string;
    };
    onChange?: (value: IValue) => void;
};

const StyledWrapper = styled(Box)(({ hidden }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    ...(hidden && { display: 'none' })
}));
const StyledSign = styled(Box, { shouldForwardProp: (propName) => 'size' !== propName })<{
    size: ITimeRangePickerProps['size'];
}>(({ theme, hidden, size }) => ({
    margin: `${theme.spacing(1)} ${theme.spacing(1.7)} 0`,
    ...(size === 'small' && { fontSize: '1rem' }),
    ...(hidden && { display: 'none' })
}));

const TimeRangePicker = ({
    name,
    label,
    value,
    hidden,
    error,
    helperText,
    zeroIsNextDay = false,
    onError,
    onChange,
    minTime,
    maxTime,
    override,
    ...rest
}: ITimeRangePickerProps) => {
    const getEndTime = (start: DateTimeType | null = null, duration: number | null = null) =>
        DateHelper.addHours(start, duration);
    const isNextDay = (start: DateTimeType | null, duration: number | null = null) => {
        const midnight = DateHelper.getFirstMomentOfDay(DateHelper.addDays(start, 1));
        const end = getEndTime(start, duration);

        return midnight && end && (DateHelper.isAfter(end, midnight) || DateHelper.isEqual(end, midnight));
    };

    const currentEndTime = getEndTime(value?.start, value?.duration);

    return (
        <StyledWrapper hidden={hidden}>
            <TimePicker
                {...rest}
                name={`${name}.start`}
                label={label?.start ?? ''}
                value={value?.start ?? null}
                minTime={minTime ?? undefined}
                maxTime={currentEndTime ?? maxTime ?? undefined}
                hidden={hidden}
                error={error?.start}
                helperText={helperText?.start}
                onError={(message) => onError && onError({ start: message, end: '' })}
                onChange={(start) => {
                    if (onChange) {
                        if (value?.start) {
                            const diff = DateHelper.getDifferenceInHours(value.start, start);

                            onChange({
                                start,
                                duration:
                                    start === null
                                        ? null
                                        : typeof value?.duration === 'number'
                                        ? Math.round(value.duration - diff)
                                        : value?.duration
                            });
                        } else {
                            onChange({
                                duration: start === null ? null : value?.duration ?? null,
                                start
                            });
                        }
                    }
                }}
            />
            <StyledSign size={rest.size} hidden={hidden}>
                {' '}
                -{' '}
            </StyledSign>
            <TimePicker
                {...rest}
                name={`${name}.end`}
                label={label?.end ?? ''}
                value={currentEndTime}
                minTime={value?.start ?? minTime ?? undefined}
                maxTime={maxTime ?? undefined}
                hidden={hidden}
                error={error?.end}
                helperText={
                    helperText?.end
                        ? helperText.end
                        : isNextDay(value?.start ?? null, value?.duration ?? null)
                        ? 'next day'
                        : helperText?.end
                }
                onError={(message) => onError && onError({ start: '', end: message })}
                onChange={(end) => {
                    if (onChange) {
                        const newEnd =
                            end && zeroIsNextDay && value?.start && end.hour() === 0 && end.minute() === 0
                                ? DateHelper.utc(DateHelper.fromTimeStringAndLocalDate('24:00', value?.start))
                                : end;

                        if (value?.start) {
                            const midnight = DateHelper.getFirstMomentOfDay(DateHelper.addDays(value.start, 1));
                            const newEndWithCorrectDay = newEnd
                                ? DateHelper.isAfter(newEnd, midnight)
                                    ? DateHelper.addDays(newEnd, -1)
                                    : newEnd
                                : null;

                            const diff =
                                newEndWithCorrectDay !== null
                                    ? DateHelper.getDifferenceAsMinutes(value.start, newEndWithCorrectDay)
                                    : null;

                            if (diff && !isNaN(diff)) {
                                onChange({
                                    ...value,
                                    duration: Math.floor(diff / 60) + Math.round(((diff / 15) * 15) % 60) / 60
                                });
                            }
                        } else {
                            onChange({
                                start: end,
                                duration: 0
                            });
                        }
                    }
                }}
            />
        </StyledWrapper>
    );
};

export default TimeRangePicker;
