import { styled } from '@mui/material/styles';
import {
    TimeValidationError,
    TimePicker as MuiTimePicker,
    TimePickerProps as MuiTimePickerProps
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { forwardRef, useCallback } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import config from '@/utils/config';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';
import TimePickerErrorMessages from './TimePickerErrorMessages';

export type ITimePickerProps = Omit<
    MuiTimePickerProps<Dayjs>,
    'label' | 'name' | 'onError' | 'onChange' | 'renderInput' | 'shouldDisableTime'
> &
    Pick<ITextFieldProps, 'name' | 'required' | 'size' | 'hidden'> & {
        label?: string;
        error?: boolean;
        disableEmpty?: boolean;
        helperText?: string;
        onError?: (message: string) => void;
        onChange?: (value: Dayjs | null) => void;
        // shouldDisableTime?: (value: Dayjs | null, timeValue: number, clockType: ClockPickerView) => boolean;
    };

const StyledField = styled(TextField)`
    flex: 1;
`;

const TimePicker = forwardRef<HTMLDivElement, ITimePickerProps>(function TimePickerInner(
    {
        format = config.time.inputFormat,
        name,
        value = null,
        required,
        label,
        disabled,
        readOnly,
        // shouldDisableTime,
        hidden,
        size,
        error,
        helperText,
        onError,
        onChange,
        disableEmpty = true,
        ...rest
    },
    ref
) {
    const handleError = useCallback(
        (reason: TimeValidationError) => {
            if (!value) return;

            if (onError) onError(TimePickerErrorMessages(reason));
        },
        [onError]
    );

    const handleOnChange = useCallback(
        (newValue: DateTimeType | null) => {
            if (onChange && ((newValue === null && !disableEmpty) || DateHelper.isValid(newValue))) {
                onChange(newValue);
            }
        },
        [disableEmpty, onChange]
    );

    return (
        <MuiTimePicker
            {...rest}
            ref={ref}
            disabled={disabled || readOnly}
            format={format}
            name={name?.replaceAll('.', '__')}
            label={label}
            value={value}
            onError={handleError}
            readOnly={readOnly}
            onChange={handleOnChange}
            // shouldDisableTime={
            //     shouldDisableTime ? (timeValue, view) => {
            //         console.log(timeValue, view);
            //
            //         return false;
            //         // return shouldDisableTime(value, timeValue, view)
            //     } : undefined
            // }
            slotProps={{
                textField: {
                    error,
                    helperText,
                    size,
                    required
                }
            }}
            slots={{
                textField: StyledField
            }}
        />
    );
});

export default TimePicker;
