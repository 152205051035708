import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import config from '@/utils/config';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        backend: {
            loadPath: '/resources/locales/{{lng}}/default.json'
        },
        lng: config.defaultLocale,
        fallbackLng: config.defaultLocale,
        debug: false, //process.env.NODE_ENV === 'development',
        interpolation: { escapeValue: false }
    });

export default i18next;
