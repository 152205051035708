import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchQueuesForSelect } from '@/data/Queues/QueueActions';
import { queuesForSelect } from '@/data/Queues/QueueSlice';
import { fetchSkills, removeSkill } from '@/data/Skills/SkillActions';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { selectFilteredSkills, skillPaging } from '@/data/Skills/SkillSlice';
import { IRootState } from '@/data/store';
import SkillForm from '@/forms/SkillForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import ChipStack from '@/wrappers/ChipStack';
import Icon from '@/wrappers/Datatable/CellViews/Icon';
import Switch from '@/wrappers/Switch';

type ISkillPage = ISkillModel & {
    queueSelect: { id: string; name: string; label: string; color: string }[];
};

const listPageSelector = createSelector(
    (state: IRootState) => selectFilteredSkills(state),
    (state: IRootState) => queuesForSelect(state),
    (skills, queuesList) =>
        skills.map(
            (skill) =>
                ({
                    ...skill,
                    queueSelect:
                        skill?.queue_items
                            ?.map((queueItem) => {
                                const queueData = queuesList?.find((item) => item.id == queueItem.queue_id);

                                return {
                                    id: `${queueData?.id ?? queueItem.queue_id}`,
                                    name: `queue_${queueItem.queue_id}`,
                                    label: queueData?.name ?? `${queueItem.queue_id}`,
                                    color: 'primary'
                                };
                            })
                            .sort((prev, next) => prev.label.localeCompare(next.label)) || []
                }) as ISkillPage
        )
);

export default function SkillsListPage() {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(listPageSelector);

    useEffect(() => {
        dispatch(fetchQueuesForSelect({ search: '' }));
    }, []);

    return (
        <LayoutOfPage title={t('title.skillsList', 'Skills List')}>
            <CrudDatatable<ISkillPage>
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <SkillForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    {
                        headerName: t('header.id', 'ID'),
                        field: 'id'
                    },
                    {
                        headerName: t('header.skillName', 'Skill Name'),
                        field: 'name',
                        renderCell: ({ row }) => (row.icon ? <Icon {...row} /> : undefined)
                    },
                    {
                        headerName: t('header.color', 'Color'),
                        field: 'color',
                        type: 'color'
                    },
                    {
                        headerName: t('header.allowToCombine', 'Allow to Combine'),
                        field: 'allow_to_combine',
                        minWidth: 200,
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch name="allow_to_combine" readOnly={true} value={row.allow_to_combine} />
                        )
                    },
                    {
                        headerName: t('header.allowWorkFromHome', 'Allow work from Home'),
                        field: 'allow_work_from_home',
                        minWidth: 200,
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch name="allow_work_from_home" readOnly={true} value={row.allow_work_from_home} />
                        )
                    },
                    {
                        headerName: t('header.onlyWorkFromHome', 'Only work from Home'),
                        field: 'only_work_from_home',
                        minWidth: 200,
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch name="only_work_from_home" readOnly={true} value={row.only_work_from_home} />
                        )
                    },
                    {
                        headerName: t('header.queues', 'Queues'),
                        field: 'queue_ids',
                        renderCell: ({ row }) => (
                            <ChipStack name="queues" direction="row" overflowHeight="5em" values={row.queueSelect} />
                        )
                    },
                    {
                        headerName: t('header.createdAt', 'Created At'),
                        type: 'dateTime',
                        field: 'created'
                    },
                    {
                        headerName: t('header.updatedAt', 'Updated At'),
                        type: 'dateTime',
                        field: 'modified'
                    }
                ]}
                hiddenFields={[
                    'id',
                    'color',
                    'allow_to_combine',
                    'allow_work_from_home',
                    'only_work_from_home',
                    'created',
                    'modified'
                ]}
                name="skill"
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(skillPaging)}
                resource={PermissionsEnum.Skills}
                searchPlaceHolder={t('label.searchSkill', 'Search Skill')}
                onFetchList={fetchSkills}
                onRemove={(id) =>
                    dispatch(removeSkill(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </LayoutOfPage>
    );
}
