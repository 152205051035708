import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CoffeeIcon from '@mui/icons-material/Coffee';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PsychologyIcon from '@mui/icons-material/Psychology';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { WithRequiredProp } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import FormGenerator, { IOutputValueType } from '@/base/FormGenerator';
import { fetchBreaks } from '@/data/Breaks/BreakActions';
import { fetchCallCentersForSelect } from '@/data/CallCenters/CallCenterActions';
import { callCenterForSelect } from '@/data/CallCenters/CallCenterSlice';
import { fetchContracts, fetchContractsForSelect } from '@/data/Contracts/ContractActions';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchRequestTypes } from '@/data/RequestTypes/RequestTypeActions';
import { fetchRolesForSelect } from '@/data/Roles/RoleActions';
import { rolesForSelect } from '@/data/Roles/RoleSlice';
import { fetchShifts } from '@/data/Shifts/ShiftActions';
import { fetchSkills } from '@/data/Skills/SkillActions';
import { IRootState } from '@/data/store';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import { createUser, fetchUserById, updateUser } from '@/data/Users/UserActions';
import {
    IUserCUBreaks,
    IUserCUContract,
    IUserCUForecasts,
    IUserCUShift,
    IUserCUShiftItem,
    IUserCUSkill,
    IUserCUVacations,
    IUserInformation
} from '@/data/Users/UserModels';
import { isUserLoaded, selectUserById } from '@/data/Users/UserSlice';
import { ISchedulerUserToRequestFromBeModel } from '@/data/UserToRequests/UserToRequestModels';
import { IUserToShiftFormDataType } from '@/data/UserToShifts/UserToShiftsModels';
import { fetchUserFund } from '@/data/UserToVacationFunds/UserToVacationFundActions';
import { IUserVacationFund } from '@/data/UserToVacationFunds/UserToVacationFundModel';
import { IUserToContractFormDataType } from '@/forms/UserToContractForm/UserToContractForm';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import formatPascalToCamel from '@/helpers/format/formatPascalToCamel';
import useAppTranslation from '@/hooks/useAppTranslation';
import OrganisationStructureTab from '@/modules/UserDetail/OrganisationStructureTab';
import RequestsTab from '@/modules/UserDetail/RequestsTab';
import ShiftsTab from '@/modules/UserDetail/ShiftsTab';
import VacationFundTab from '@/modules/UserDetail/VacationFundTab';
import UserSourceEnum from '@/utils/enums/UserSourceEnum';
import { message, regex } from '@/utils/validations';
import Tabs from '@/wrappers/Tabs';
import BreaksTab from './BreaksTab';
import ContractsTab from './ContractsTab';
import ForecastsTab from './ForecastsTab';
import InformationTab from './InformationTab';
import SkillsTab from './SkillsTab';
import VacationsTab from './VacationsTab';

type IProps = {
    id?: number;
    isClone?: boolean | false;
    readOnly?: boolean;
    onSaveFinished: () => void;
};

export interface ControlRef {
    submitForm: () => void;
}

const StyledWrapper = styled(Paper)(
    ({ theme }) => `
        padding-inline: ${theme.spacing(2)};
    `
);

const createDate = (source: DateTimeType | string | null) =>
    source
        ? typeof source === 'string'
            ? DateHelper.fromDateString(source, Intl.DateTimeFormat().resolvedOptions().timeZone)
            : DateHelper.clone(source)
        : null;

const UserDetail = forwardRef<ControlRef, IProps>(function UserDetailInner(
    { id, isClone, readOnly, onSaveFinished },
    ref
) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const userDetailData = useAppSelector((state) => (id ? selectUserById(state, id) : undefined));
    const isUserDetailDataLoaded = useAppSelector((state: IRootState) => isUserLoaded(state, id));
    const rolesData = useAppSelector(rolesForSelect);
    const isSystemUser = useAppSelector(isSignedUserUser);
    const callCentersList = useAppSelector(callCenterForSelect);
    const [userShiftsData, setUserShiftsData] = useState<IUserToShiftFormDataType[]>([]);
    const [userContractsData, setUserContractsData] = useState<WithRequiredProp<IUserToContractFormDataType, 'id'>[]>(
        []
    );
    const [superiorId, setSuperiorId] = useState<number | null>(null);
    const [subordinate, setSubordinate] = useState<number[]>([]);
    const [userSkillsData, setUserSkillsData] = useState<IUserCUSkill[]>([]);
    const [userVacationFundsData, setUserVacationFundsData] = useState<IUserVacationFund[]>([]);
    const [userRequestsData, setUserRequestsData] = useState<ISchedulerUserToRequestFromBeModel[]>([]);
    const [userBreaksData, setUserBreaksData] = useState<IUserCUBreaks[]>([]);
    const [userVacationsData, setUserVacationsData] = useState<IUserCUVacations[]>([]);
    const [userForecastData, setUserForecastData] = useState<IUserCUForecasts>({} as IUserCUForecasts);
    const [userInformation, setUserInformation] = useState<IUserInformation>({});
    const userDetailRef = useRef<HTMLFormElement>(null);

    useImperativeHandle(ref, () => {
        return {
            submitForm() {
                (userDetailRef.current?.querySelector('button[type="submit"]') as HTMLButtonElement)?.click();
            }
        };
    });

    const handleSubmit = (values: IOutputValueType) => {
        const data = {
            active: !!values.active,
            access: !!values.access,
            allow_work_from_home: userForecastData.allow_work_from_home,
            reservations: !!values.reservations,
            first_name: values.first_name as string,
            last_name: values.last_name as string,
            username: values.username as string,
            personal_number: values.personal_number as string,
            email: values.email as string,
            only_work_from_home: userForecastData.allow_work_from_home ? userForecastData.only_work_from_home : false,
            phone_number: values.phone_number as string,
            password: values.password as string,
            source: values.source as string,
            skills: userSkillsData.map((skill) => ({
                skill_id: skill.skill_id,
                level_of_competence: skill.level_of_competence
            })),
            contracts: userContractsData
                .map((contract) => ({
                    ...contract,
                    id: contract.isNew || isClone ? undefined : contract.id,
                    start_at: createDate(contract.start_at),
                    end_at: createDate(contract.end_at),
                    day_data: contract.day_data?.map((item) =>
                        item.filter((subItem) => !!subItem.time_range?.start && !!subItem.time_range?.duration)
                    )
                }))
                .filter(
                    (contract) =>
                        (DateHelper.isValid(contract.start_at) ?? true) && (DateHelper.isValid(contract.end_at) ?? true)
                )
                .map((contract) => ({
                    ...contract,
                    start_at: contract.start_at ? DateHelper.formatDate(contract.start_at) : null,
                    end_at: contract.end_at ? DateHelper.formatDate(contract.end_at) : null,
                    day_data: contract.day_data?.map((item) =>
                        item.map((subItem) => ({
                            id: subItem.id,
                            time_range: {
                                start: subItem.time_range ? DateHelper.formatTime(subItem.time_range.start) : null,
                                duration: subItem.time_range?.duration ?? null
                            }
                        }))
                    ) as IUserCUContract['day_data']
                })),
            roles: [
                {
                    role_id: values.roles as number
                }
            ],
            timezone: values.timezone as string,
            breaks: userBreaksData,
            shifts: userShiftsData.map(
                (shift) =>
                    ({
                        id: shift._type === 'new' || isClone ? undefined : shift.id,
                        shift_id: shift.shift_id,
                        shift_items: shift.user_to_shift_items.map(
                            (shiftItem) =>
                                ({
                                    id: shift._type === 'new' || isClone ? undefined : shiftItem.id,
                                    shift_item_id: shiftItem.shift_item_id,
                                    start: shiftItem.start,
                                    ...(typeof shiftItem.used === 'undefined' ? undefined : { used: shiftItem.used })
                                }) as IUserCUShiftItem
                        )
                    }) as IUserCUShift
            ),
            vacation_fund: userVacationFundsData.map(({ exhausted, ...rest }) => rest),
            external_id: values.external_id as string,
            call_center_id:
                typeof values.call_center_id === 'string'
                    ? parseInt(values.call_center_id)
                    : (values.call_center_id as number | undefined),
            user_category_type: values.user_category_type as string | null,
            adult: values.adult as boolean,
            available_shifts: userForecastData.available_shifts as string,
            weekend: userForecastData.weekend as number,
            morning: userForecastData.morning as number,
            afternoon: userForecastData.afternoon as number,
            night: userForecastData.night as number,
            holiday: userForecastData.holiday as number,
            organization_structure_superior_id: superiorId,
            send_user_to_ai_for_automatic_planning: userForecastData.send_user_to_ai_for_automatic_planning as boolean,
            subordinate
        };

        if (id && !isClone) {
            dispatch(updateUser({ id, data })).unwrap().then(onSaveFinished);
        } else {
            dispatch(createUser(data)).unwrap().then(onSaveFinished);
        }
    };

    useEffect(() => {
        dispatch(fetchBreaks({ noPaging: true }));
        dispatch(fetchContractsForSelect({ search: '' }));
        dispatch(fetchContracts({ noPaging: true }));
        dispatch(fetchShifts({ noPaging: true }));
        dispatch(fetchRequestTypes({ noPaging: true }));
        dispatch(fetchSkills({ noPaging: true }));
        dispatch(fetchRolesForSelect({ search: '' }));
        dispatch(fetchCallCentersForSelect({ search: '' }));
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(fetchUserById(id));
        }
    }, [id]);

    useEffect(() => {
        if (userDetailData) {
            setUserRequestsData(userDetailData.user_to_requests);
            setUserVacationFundsData(
                userDetailData.user_to_vacation_fund.map((data, index) => ({ ...data, id: index.toString() }))
            );

            userDetailData.user_to_vacation_fund.forEach((item) => {
                dispatch(
                    fetchUserFund({
                        userId: userDetailData.id,
                        year: item.year,
                        requestTypeId: item.request_type_id,
                        fund: item.fund
                    })
                );
            });

            setUserContractsData(
                userDetailData.user_to_contracts.map((item) => {
                    const dayData: IUserCUContract['day_data'] = [];

                    item.user_to_contracts_hours.forEach((contractHourItem) => {
                        const start = DateHelper.fromOptionalTime(contractHourItem.start);

                        if (typeof dayData[contractHourItem.day] === 'undefined') {
                            dayData[contractHourItem.day] = [];
                        }

                        dayData[contractHourItem.day].push({
                            id: contractHourItem.id,
                            time_range: {
                                start: start,
                                duration: DateHelper.getDifferenceInHours(
                                    start,
                                    DateHelper.fromOptionalTime(contractHourItem.end)
                                )
                            }
                        });
                    });

                    return {
                        id: item.id,
                        contract_id: item.contract_id,
                        start_at: item.start_at,
                        end_at: item.end_at,
                        days: item.days ?? undefined,
                        month_hours_limit: item.month_hours_limit,
                        day_hours_limit: item.day_hours_limit,
                        week_hours_limit: item.week_hours_limit,
                        number_of_hours_per_day: item.number_of_hours_per_day,
                        min_continuous_rest_per_week: item.min_continuous_rest_per_week,
                        min_break_hours_per_day: item.min_break_hours_per_day,
                        min_break_hours_per_day_splitted: item.min_break_hours_per_day_splitted,
                        break_at_least_after_hours: item.break_at_least_after_hours,
                        holidays_allowed: item.holidays_allowed,
                        night_shifts_allowed: item.night_shifts_allowed,
                        day_data_0: dayData[0],
                        day_data_1: dayData[1],
                        day_data_2: dayData[2],
                        day_data_3: dayData[3],
                        day_data_4: dayData[4],
                        day_data_5: dayData[5],
                        day_data_6: dayData[6],
                        contract_groups: [],
                        isNew: false
                    };
                })
            );

            setUserShiftsData(
                userDetailData?.user_to_shifts?.map((item) => ({
                    ...item,
                    user_to_shift_items: item.user_to_shift_items.map((userToShiftItems) => ({
                        ...userToShiftItems,
                        used: userToShiftItems.used === null ? undefined : userToShiftItems.used,
                        start: userToShiftItems.start
                    })),
                    isNew: false
                })) ?? []
            );
            setSuperiorId(userDetailData.organization_structure_superior_id);
            setSubordinate(userDetailData.subordinate);

            setUserForecastData(userDetailData);

            if (userDetailData.user_to_skills) {
                setUserSkillsData(
                    userDetailData.user_to_skills.map(
                        (skill) =>
                            ({
                                skill_id: skill.skill_id,
                                level_of_competence: skill.level_of_competence
                            }) as IUserCUSkill
                    )
                );
            }

            if (userDetailData.user_to_breaks) {
                setUserBreaksData(
                    userDetailData.user_to_breaks.map((breakData) => ({
                        break_id: breakData.break_id,
                        subsidy: breakData.subsidy,
                        period: breakData.period
                    }))
                );
            }

            if (userDetailData.user_to_vacations) {
                setUserVacationsData(
                    userDetailData.user_to_vacations.map((vacation) => ({
                        ...vacation,
                        from_date: DateHelper.formatDateTimeToISO(vacation.from_date),
                        to_date: DateHelper.formatDateTimeToISO(vacation.to_date)
                    }))
                );
            }

            if (userDetailData.token) {
                setUserInformation(userDetailData);
            }
        }
    }, [userDetailData]);

    const usersSourceOptions = Object.keys(UserSourceEnum).map((key) => ({
        id: UserSourceEnum[key as keyof typeof UserSourceEnum],
        label: t(`enums.userSourceEnum.${formatPascalToCamel(key)}`, key)
    }));

    return (
        <Grid container spacing={2}>
            <Grid item md={4}>
                <StyledWrapper>
                    <FormGenerator
                        ref={userDetailRef}
                        id={id ? `${id}` : undefined}
                        name="userDetail"
                        className="userDetail"
                        isLoaded={id ? isUserDetailDataLoaded : true}
                        fields={[
                            {
                                type: 'switch',
                                props: {
                                    name: 'active',
                                    label: t('label.active', 'Active'),
                                    value: userDetailData?.active,
                                    width: 4,
                                    labelPlacement: 'end'
                                }
                            },
                            {
                                type: 'switch',
                                props: {
                                    name: 'access',
                                    label: t('label.access', 'Access'),
                                    value: userDetailData?.access,
                                    width: 4,
                                    labelPlacement: 'end'
                                }
                            },
                            {
                                type: 'switch',
                                props: {
                                    name: 'adult',
                                    label: t('label.adult', 'Adult'),
                                    value: userDetailData?.adult,
                                    width: 4,
                                    labelPlacement: 'end'
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    required: true,
                                    name: 'first_name',
                                    label: t('label.firstName', 'First Name'),
                                    value: userDetailData?.first_name,
                                    width: 6,
                                    validation: {
                                        pattern: {
                                            value: regex.name,
                                            message: message.name
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    required: true,
                                    name: 'last_name',
                                    label: t('label.lastName', 'Last Name'),
                                    value: userDetailData?.last_name,
                                    width: 6,
                                    validation: {
                                        pattern: {
                                            value: regex.name,
                                            message: message.name
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    required: true,
                                    name: 'username',
                                    label: t('label.userName', 'Username'),
                                    value: userDetailData?.username,
                                    width: 6,
                                    validation: {
                                        pattern: {
                                            value: regex.text,
                                            message: message.text
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    name: 'personal_number',
                                    label: t('label.personalNumber', 'Personal Number'),
                                    value: userDetailData?.personal_number,
                                    width: 6,
                                    validation: {
                                        pattern: {
                                            value: regex.number,
                                            message: message.number
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    required: true,
                                    name: 'email',
                                    label: t('label.email', 'E-mail'),
                                    type: 'email',
                                    value: userDetailData?.email,
                                    width: 6,
                                    validation: {
                                        pattern: {
                                            value: regex.email,
                                            message: message.email
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    name: 'phone_number',
                                    label: t('label.phoneNumber', 'Phone Number'),
                                    type: 'tel',
                                    value: userDetailData?.phone_number,
                                    width: 6,
                                    validation: {
                                        pattern: {
                                            value: regex.phone,
                                            message: message.phone
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    name: 'external_id',
                                    disabled: isSystemUser,
                                    label: t('label.externalID', 'External ID'),
                                    value: userDetailData?.external_id,
                                    width: 6
                                }
                            },
                            {
                                type: 'select',
                                props: {
                                    required: true,
                                    disabled: isSystemUser,
                                    name: 'roles',
                                    label: t('label.roles', 'Roles'),
                                    value: userDetailData?.user_to_roles?.find(() => true)?.role_id?.toString(),
                                    options: rolesData.map((item) => ({
                                        id: `${item.id}`,
                                        label: t(`roles.${item.name.toLowerCase()}`, item.name),
                                        custom: {
                                            testId: item.name
                                        }
                                    })),
                                    width: 6
                                }
                            },
                            {
                                type: 'timezone',
                                props: {
                                    label: t('label.timezone', 'Timezone'),
                                    name: 'timezone',
                                    value: userDetailData?.timezone
                                }
                            },
                            {
                                type: 'select',
                                props: {
                                    required: true,
                                    name: 'source',
                                    label: t('label.source', 'Source'),
                                    value: userDetailData?.source ?? UserSourceEnum.WFM,
                                    options: usersSourceOptions,
                                    validation: {
                                        deps: ['source']
                                    }
                                }
                            },
                            {
                                type: 'select',
                                props: {
                                    required: ({ source }) => source === UserSourceEnum.Daktela,
                                    name: 'call_center_id',
                                    label: t('label.callCenter', 'callCenter'),
                                    value: userDetailData?.call_center_id ?? undefined,
                                    options: callCentersList
                                        .slice()
                                        .sort((prev, next) => prev.name.localeCompare(next.name))
                                        .map((item) => ({ id: `${item.id}`, label: item.name }))
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    name: 'user_category_type',
                                    label: t('label.user_category_type', 'User Category Type'),
                                    value: userDetailData?.user_category_type ?? undefined
                                }
                            },
                            {
                                type: 'newLine',
                                display: ({ source }) => source === UserSourceEnum.WFM && (!id || !!isClone),
                                props: {
                                    name: 'after-roles',
                                    type: 'hidden',
                                    validation: {
                                        deps: ['source']
                                    }
                                }
                            },
                            {
                                type: 'password',
                                display: ({ source }) => source === UserSourceEnum.WFM && (!id || !!isClone),
                                props: {
                                    required: ({ source }) => source === UserSourceEnum.WFM && (!id || !!isClone),
                                    disabled: ({ source }) => !(source === UserSourceEnum.WFM && (!id || !!isClone)),
                                    name: 'password',
                                    label: t('label.password', 'Password'),
                                    size: 'small',
                                    width: 6,
                                    validation: {
                                        deps: ['source']
                                    }
                                }
                            },
                            {
                                type: 'password',
                                display: ({ source }) => source === UserSourceEnum.WFM && (!id || !!isClone),
                                props: {
                                    required: ({ source }) => source === UserSourceEnum.WFM && (!id || !!isClone),
                                    disabled: ({ source }) => !(source === UserSourceEnum.WFM && (!id || !!isClone)),
                                    name: 'confirmPassword',
                                    label: t('label.confirmPassword', 'Confirm password'),
                                    size: 'small',
                                    width: 6,
                                    confirmName: 'password',
                                    validation: {
                                        deps: ['source']
                                    }
                                }
                            }
                        ]}
                        actions={[
                            {
                                type: 'button',
                                props: {
                                    name: 'submit',
                                    type: 'submit',
                                    variant: 'contained',
                                    children: 'Save employee',
                                    hidden: true
                                }
                            }
                        ]}
                        displayAsModal={false}
                        readOnly={readOnly}
                        onSubmit={handleSubmit}
                    />
                </StyledWrapper>
            </Grid>
            <Grid item md={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Tabs
                    fullHeightOfTabContent={true}
                    name="userTabs"
                    contentContainer={Paper}
                    tabsContainer={Paper}
                    mode="js"
                    sxTab={{
                        flexGrow: 1
                    }}
                    tabs={[
                        {
                            id: 'skills',
                            label: (
                                <>
                                    <CheckCircleOutlineRoundedIcon /> {t('header.skills', 'Skills')}
                                </>
                            ),
                            content: <SkillsTab data={userSkillsData} handleData={setUserSkillsData} />
                        },
                        {
                            id: 'contracts',
                            label: (
                                <>
                                    <BookmarkBorderRoundedIcon /> {t('header.contracts', 'Contracts')}
                                </>
                            ),
                            content: <ContractsTab data={userContractsData} handleData={setUserContractsData} />
                        },
                        {
                            id: 'shifts',
                            label: (
                                <>
                                    <FitnessCenterOutlinedIcon /> {t('header.shifts', 'Shifts')}
                                </>
                            ),
                            content: <ShiftsTab data={userShiftsData} handleData={setUserShiftsData} />
                        },
                        {
                            id: 'breaks',
                            label: (
                                <>
                                    <CoffeeIcon /> {t('header.breaks', 'Breaks')}
                                </>
                            ),
                            content: <BreaksTab data={userBreaksData} handleData={setUserBreaksData} />
                        },
                        {
                            id: 'vacations',
                            label: (
                                <>
                                    <HouseboatIcon /> {t('header.vacations', 'Vacations')}
                                </>
                            ),
                            content: <VacationsTab data={userVacationsData} />
                        },
                        {
                            id: 'organisation_structure',
                            label: (
                                <>
                                    <AccountTreeIcon /> {t('header.structure', 'Structure')}
                                </>
                            ),
                            content: (
                                <OrganisationStructureTab
                                    userId={id ?? null}
                                    superiorId={superiorId}
                                    subordinateIds={subordinate}
                                    onChangeLeader={setSuperiorId}
                                    onChangeSubordinate={setSubordinate}
                                />
                            )
                        },
                        ...(!isSystemUser
                            ? [
                                  {
                                      id: 'forecast',
                                      label: (
                                          <>
                                              <PsychologyIcon /> {t('header.forecast', 'Forecast')}
                                          </>
                                      ),
                                      content: <ForecastsTab data={userForecastData} handleData={setUserForecastData} />
                                  }
                              ]
                            : []),
                        {
                            id: 'request',
                            label: (
                                <>
                                    <EventOutlinedIcon /> {t('header.request', 'Request')}
                                </>
                            ),
                            content: <RequestsTab requestList={userRequestsData} />
                        },
                        {
                            id: 'vacation_fund',
                            label: (
                                <>
                                    <TodayOutlinedIcon /> {t('header.vacationFund', 'Vacation Fund')}
                                </>
                            ),
                            content: (
                                <VacationFundTab
                                    userId={id ?? null}
                                    data={userVacationFundsData}
                                    handleData={setUserVacationFundsData}
                                />
                            )
                        },
                        ...(id
                            ? [
                                  {
                                      id: 'information',
                                      label: (
                                          <>
                                              <ManageAccountsIcon /> {t('header.information', 'Information')}
                                          </>
                                      ),
                                      content: <InformationTab data={userInformation} />
                                  }
                              ]
                            : [])
                    ]}
                />
            </Grid>
        </Grid>
    );
});

export default UserDetail;
