import { useCallback, useEffect, useRef } from 'react';
import { ICallableRef, IOutputValueType } from '@/base/FormGenerator';
import CuForm from '@/components/CuForm';
import {
    createApplicationSetting,
    fetchApplicationsSettingsItems,
    updateApplicationSetting
} from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { ISchedulePlanApplicationSettingItem } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemModels';
import { getSchedulePlanApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { rolesForSelect } from '@/data/Roles/RoleSlice';
import {
    getSettingsItemBooleanValueByKey,
    getSettingsItemStringValueByKey
} from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import ApplicationSettingsSchedulePlanItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsSchedulePlanItemEnum';
import ApplicationSettingsItemTypesEnum from '@/utils/enums/ApplicationSettingsItemTypesEnum';
import { PermissionsControllerEnum } from '@/utils/enums/PermissionsEnum';

const SchedulePlanApplicationSettingsForm = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(getSchedulePlanApplicationsSettings);
    const formRef = useRef<ICallableRef | null>(null);
    const rolesData = useAppSelector(rolesForSelect);

    const handleData = useCallback(
        (source: IOutputValueType): ISchedulePlanApplicationSettingItem[] =>
            [
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: ApplicationSettingsSchedulePlanItemEnum.onHolidayInsertSetShiftAsEmpty,
                    value: Boolean(source[ApplicationSettingsSchedulePlanItemEnum.onHolidayInsertSetShiftAsEmpty])
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: ApplicationSettingsSchedulePlanItemEnum.dndShiftBetweenDays,
                    value: Boolean(source[ApplicationSettingsSchedulePlanItemEnum.dndShiftBetweenDays])
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: ApplicationSettingsSchedulePlanItemEnum.canTakeTradeWithoutCounteroffer,
                    value: Boolean(source[ApplicationSettingsSchedulePlanItemEnum.canTakeTradeWithoutCounteroffer])
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: ApplicationSettingsSchedulePlanItemEnum.personWhoClosedSchedulePlanCanOpenSchedulePlan,
                    value: Boolean(
                        source[ApplicationSettingsSchedulePlanItemEnum.personWhoClosedSchedulePlanCanOpenSchedulePlan]
                    )
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: ApplicationSettingsSchedulePlanItemEnum.limitationOfAgentsAndEmptyShifts,
                    value: `${source[ApplicationSettingsSchedulePlanItemEnum.limitationOfAgentsAndEmptyShifts]}`
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: ApplicationSettingsSchedulePlanItemEnum.canOpenSchedulePlan,
                    value: JSON.stringify(source[ApplicationSettingsSchedulePlanItemEnum.canOpenSchedulePlan] ?? '[]')
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: ApplicationSettingsSchedulePlanItemEnum.canCloseSchedulePlan,
                    value: JSON.stringify(source[ApplicationSettingsSchedulePlanItemEnum.canCloseSchedulePlan] ?? '[]')
                },
                {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    key: ApplicationSettingsSchedulePlanItemEnum.canEditClosedSchedulePlan,
                    value: JSON.stringify(
                        source[ApplicationSettingsSchedulePlanItemEnum.canEditClosedSchedulePlan] ?? '[]'
                    )
                }
            ] as ISchedulePlanApplicationSettingItem[],
        []
    );

    const handleSubmit = useCallback(
        (values: IOutputValueType) =>
            updateApplicationSetting({
                id: ApplicationSettingsItemTypesEnum.schedulePlan,
                data: {
                    application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                    value: handleData(values)
                }
            }),
        []
    );

    const handleCreate = useCallback(
        (values: IOutputValueType) =>
            createApplicationSetting({
                application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
                value: handleData(values)
            }),
        []
    );

    const handleOpen = useCallback(() => dispatch(fetchApplicationsSettingsItems()), []);

    //!!!!Důležité tu mít toto protože pokud paralerne s tímto jede jiny test který ve storu ma uložene staré hodnoty, tak je vymaže pod rukama souběžně běžícímu testu !!!!!
    useEffect(() => {
        formRef.current?.setFieldValue(
            ApplicationSettingsSchedulePlanItemEnum.onHolidayInsertSetShiftAsEmpty,
            getSettingsItemBooleanValueByKey(
                data,
                ApplicationSettingsSchedulePlanItemEnum.onHolidayInsertSetShiftAsEmpty
            )
        );
        formRef.current?.setFieldValue(
            ApplicationSettingsSchedulePlanItemEnum.dndShiftBetweenDays,
            getSettingsItemBooleanValueByKey(data, ApplicationSettingsSchedulePlanItemEnum.dndShiftBetweenDays)
        );
        formRef.current?.setFieldValue(
            ApplicationSettingsSchedulePlanItemEnum.canTakeTradeWithoutCounteroffer,
            getSettingsItemBooleanValueByKey(
                data,
                ApplicationSettingsSchedulePlanItemEnum.canTakeTradeWithoutCounteroffer
            )
        );
        formRef.current?.setFieldValue(
            ApplicationSettingsSchedulePlanItemEnum.limitationOfAgentsAndEmptyShifts,
            getSettingsItemStringValueByKey(
                data,
                ApplicationSettingsSchedulePlanItemEnum.limitationOfAgentsAndEmptyShifts
            ) ?? 'default'
        );
        formRef.current?.setFieldValue(
            ApplicationSettingsSchedulePlanItemEnum.personWhoClosedSchedulePlanCanOpenSchedulePlan,
            getSettingsItemStringValueByKey(
                data,
                ApplicationSettingsSchedulePlanItemEnum.personWhoClosedSchedulePlanCanOpenSchedulePlan
            ) ?? true
        );
        formRef.current?.setFieldValue(
            ApplicationSettingsSchedulePlanItemEnum.canOpenSchedulePlan,
            JSON.parse(
                getSettingsItemStringValueByKey(data, ApplicationSettingsSchedulePlanItemEnum.canOpenSchedulePlan) ??
                    '[]'
            )
        );
        formRef.current?.setFieldValue(
            ApplicationSettingsSchedulePlanItemEnum.canCloseSchedulePlan,
            JSON.parse(
                getSettingsItemStringValueByKey(data, ApplicationSettingsSchedulePlanItemEnum.canCloseSchedulePlan) ??
                    '[]'
            )
        );
        formRef.current?.setFieldValue(
            ApplicationSettingsSchedulePlanItemEnum.canEditClosedSchedulePlan,
            JSON.parse(
                getSettingsItemStringValueByKey(
                    data,
                    ApplicationSettingsSchedulePlanItemEnum.canEditClosedSchedulePlan
                ) ?? '[]'
            )
        );
    }, [formRef.current, data]);

    return (
        <CuForm
            innerRef={formRef}
            resource={PermissionsControllerEnum.SettingsItems}
            name="schedulePlanApplicationSettingsForm"
            id={data.length > 0 ? ApplicationSettingsItemTypesEnum.schedulePlan : undefined}
            displayAsSidebar
            displayAsModal={false}
            onOpen={handleOpen}
            maxWidth="xl"
            title={t('label.schedulePlanSetting', 'Schedule Plan Setting')}
            openButtonValue={t('label.update', 'Update')}
            items={[
                {
                    type: 'switch',
                    props: {
                        name: ApplicationSettingsSchedulePlanItemEnum.onHolidayInsertSetShiftAsEmpty,
                        label: t('label.onHolidayInsertSetShiftAsEmpty', 'On Holiday Insert Set Shift As Empty')
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: ApplicationSettingsSchedulePlanItemEnum.dndShiftBetweenDays,
                        label: t(
                            'label.dndShiftBetweenDays',
                            'When Drag & Drop Shift between dates move Original Shift into Empty Shift'
                        )
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: ApplicationSettingsSchedulePlanItemEnum.canTakeTradeWithoutCounteroffer,
                        label: t('label.canTakeTradeWithoutCounteroffer', 'Can take trade without counteroffer')
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: ApplicationSettingsSchedulePlanItemEnum.personWhoClosedSchedulePlanCanOpenSchedulePlan,
                        label: t(
                            'label.personWhoClosedSchedulePlanCanOpenSchedulePlan',
                            'Person Who Closed Schedule Plan Can Open Schedule Plan'
                        )
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: ApplicationSettingsSchedulePlanItemEnum.limitationOfAgentsAndEmptyShifts,
                        label: t('label.limitationOfAgentsAndEmptyShift', 'Limitation of Agents & Empty Shifts'),
                        disableClearable: true,
                        options: [
                            {
                                id: 'default',
                                label: t('label.noLimitation', 'No limitation (default)')
                            },
                            {
                                id: 'before_date',
                                label: t(
                                    'label.agentIsAbleToTakeEmptyShiftsOnlyAfterDate',
                                    'Agent is able to take Empty Shifts only after specific Date'
                                )
                            },
                            {
                                id: 'from_date',
                                label: t(
                                    'label.agentIsAbleToSeeAndTakeEmptyShiftsOnlyAfterSpecificDate',
                                    'Agent is able to see and take Empty Shifts only after specific Date'
                                )
                            }
                        ]
                    }
                },
                {
                    type: 'multiSelect',
                    props: {
                        name: ApplicationSettingsSchedulePlanItemEnum.canOpenSchedulePlan,
                        label: t('label.canOpen', 'Can Open'),
                        options: rolesData.map((item) => ({
                            id: `${item.id}`,
                            label: item.name,
                            color: 'primary'
                        }))
                    }
                },
                {
                    type: 'multiSelect',
                    props: {
                        name: ApplicationSettingsSchedulePlanItemEnum.canCloseSchedulePlan,
                        label: t('label.canClose', 'Can Close'),
                        options: rolesData.map((item) => ({
                            id: `${item.id}`,
                            label: item.name,
                            color: 'primary'
                        }))
                    }
                },
                {
                    type: 'multiSelect',
                    props: {
                        name: ApplicationSettingsSchedulePlanItemEnum.canEditClosedSchedulePlan,
                        label: t('label.canEditClosedPlan', 'Can Edit Closed Plan'),
                        options: rolesData.map((item) => ({
                            id: `${item.id}`,
                            label: item.name,
                            color: 'primary'
                        }))
                    }
                }
            ]}
            onSubmitUpdate={handleSubmit}
            onSubmitCreate={handleCreate}
        />
    );
};

export default SchedulePlanApplicationSettingsForm;
