import { useMemo } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import config from '@/utils/config';

type Props = {
    dateStyle?: 'full' | 'long' | 'medium' | 'short';
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
    day?: 'numeric' | '2-digit';
    formatMatcher?: 'best fit' | 'basic';
    timeZone?: string | null;
};

const useLocalizeDateFormatter = ({ dateStyle, year, month, day, timeZone, formatMatcher }: Props = {}) => {
    const { i18n } = useAppTranslation();

    return useMemo(() => {
        const lng = i18n.language ?? config.defaultLocale;

        return new Intl.DateTimeFormat(lng, {
            timeZone: timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
            dateStyle,
            year,
            month,
            day,
            formatMatcher
        });
    }, [i18n.resolvedLanguage, i18n.language, timeZone, month]);
};

export default useLocalizeDateFormatter;
