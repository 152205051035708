import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, fetchRecordsList, fetchSelectList, remove, update } from './WorkplaceApi';
import { IWorkplaceCUModel } from './WorkplaceModels';
import {
    isWorkplaceByIdInProgress,
    workplaceById,
    workplacePaging,
    workplaceSelectValuesStatus,
    workplacesForSelect
} from './WorkplaceSlice';

export const fetchWorkplaces = createAsyncThunk('workplaces/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = workplacePaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchWorkplaceById = createAsyncThunk(
    'workplaces/byId',
    async (args: number | null) => {
        return await fetchById(args!);
    },
    {
        condition(id, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (id === null || !!workplaceById(state, id) || isWorkplaceByIdInProgress(state)) {
                return false;
            }
        }
    }
);

export const fetchWorkplacesRecordList = createAsyncThunk('workplaces/recordList', async () => {
    return await fetchRecordsList();
});

export const createWorkplace = createAsyncThunk('workplaces/create', async (args: IWorkplaceCUModel) => {
    return await create(args);
});

export const fetchWorkplacesForSelect = createAsyncThunk(
    'workplaces/selectList',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSelectList(args.search, args.fields || []);
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (workplaceSelectValuesStatus(state) !== 'idle' || workplacesForSelect(state).length) {
                return false;
            }
        }
    }
);

export const updateWorkplace = createAsyncThunk(
    'workplaces/update',
    async (args: { id: number; data: IWorkplaceCUModel }) => await update(args.id, args.data)
);

export const removeWorkplace = createAsyncThunk('workplaces/remove', async (id: number) => remove(id));
