import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { HexAlphaColorPicker } from 'react-colorful';
import useDebounce from '@/hooks/useDebounce';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';

export type IColorPickerProps = Omit<ITextFieldProps, 'error' | 'onChange'> & {
    label?: string;
    value?: string;
    disableAlpha?: boolean;
    error?: { message: string };
    onChange?: (value: string | null) => void;
};

const StyledAdornment = styled('div', {
    shouldForwardProp: (props) => props !== 'background'
})<{ background?: string }>(
    ({ theme, background }) => `
        width: ${theme.spacing(3)};
        min-width: ${theme.spacing(3)};
        height: ${theme.spacing(3)};
        min-height: ${theme.spacing(3)};
        margin: ${theme.spacing(1)};
        padding: 0;
        background-color: ${background};
        border-radius: ${theme.shape.borderRadius}px;
    `
);
const StyledButton = styled(Button, {
    shouldForwardProp: (props) => props !== 'background'
})<{ background?: string }>(
    ({ theme, background }) => `
        width: ${theme.spacing(3)};
        min-width: ${theme.spacing(3)};
        height: ${theme.spacing(3)};
        min-height: ${theme.spacing(3)};
        margin: ${theme.spacing(1)} 0;
        padding: 0;
        background-color: ${background};
        background-image: linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(135deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(135deg, transparent 75%, #ccc 75%);
        background-size: 8px 8px;
        background-position: 0 0, 4px 0, 4px -4px, 0px 4px;
        background-clip: content-box;
        border-radius: ${theme.shape.borderRadius}px;
        border: 1px solid rgba(0,0,0,0.15);
    `
);
const StyledButtonContent = styled('div', {
    shouldForwardProp: (props) => props !== 'background'
})<{ background?: string }>(
    ({ theme, background }) => `
        width: 100%;
        height: 100%;
        background-color: ${background};
        border-radius: ${theme.shape.borderRadius}px;
    `
);

const ColorPicker = ({
    error,
    name,
    label,
    disableAlpha,
    readOnly,
    value = '#000000',
    onChange,
    ...rest
}: IColorPickerProps) => {
    const [innerValue, setInnerValue] = useState<string | null>(null);
    const [element, setElement] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setElement(event.currentTarget);
    const handleClose = () => setElement(null);

    const handleChange = useCallback((newValue: string) => {
        setInnerValue(newValue);
    }, []);
    const debounced = useDebounce(innerValue, 200);

    useEffect(() => {
        if (innerValue && onChange) {
            onChange(innerValue);
        }
    }, [debounced]);

    return (
        <>
            <TextField
                {...rest}
                label={label}
                data-testid={`color_picker_${name}`}
                error={!!error}
                helperText={error ? error.message : rest.helperText}
                InputProps={{
                    endAdornment: readOnly ? (
                        <StyledAdornment background={value} />
                    ) : (
                        <StyledButton disableElevation background={value} variant="contained" onClick={handleClick}>
                            <StyledButtonContent background={value} />
                        </StyledButton>
                    )
                }}
                value={value}
                onChange={(_e, newValue) => handleChange(newValue)}
            />
            <Popover
                open={!!element}
                anchorEl={element}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{ top: '5px' }}
                onClose={handleClose}
            >
                <Box data-testid={`${label}-colorPickerPopoverBox`} sx={{ padding: 1.5 }}>
                    <HexAlphaColorPicker color={innerValue ?? undefined} onChange={setInnerValue} />
                </Box>
            </Popover>
        </>
    );
};

export default ColorPicker;
