import configStatic from '@/utils/configStatic';
import loadConfig from '@/utils/envConfig';

const {
    appLocation = '',
    defaultLocale = 'en-gb',
    defaultScheduleCompactMode = false,
    features = {},
    nodeEnv = 'production'
} = loadConfig() as {
    appLocation?: string;
    defaultLocale?: string;
    defaultScheduleCompactMode?: boolean;
    features?: Record<string, boolean>;
    nodeEnv?: 'production' | 'development';
};

const config = {
    ...configStatic,
    isDevelop: nodeEnv === 'development',
    env: nodeEnv,
    subDir: appLocation + '/',
    defaultLocale,
    defaultScheduleCompactMode: Boolean(features.scheduleCompactMode && defaultScheduleCompactMode),
    features: {
        scheduleCompactMode: features.scheduleCompactMode ?? false
    }
};

export default config;
