import { createAsyncThunk } from '@reduxjs/toolkit';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { create, fetchList, remove, update } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemApi';
import {
    IApplicationsSettingsFromBeModel,
    IApplicationsSettingsModel
} from '@/data/ApplicationSettingsItems/ApplicationSettingsItemModels';
import { getSchedulePlanApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { IRootState } from '@/data/store';
import { getPermissionsList } from '@/data/System/SystemReducer';
import {
    getReverseTransformSettingsItemValuesList,
    getTransformSettingItemsValue
} from '@/forms/IntegratedApplicationForm/utils';
import ApplicationSettingsItemTypesEnum from '@/utils/enums/ApplicationSettingsItemTypesEnum';
import { PermissionsControllerEnum } from '@/utils/enums/PermissionsEnum';

const transformSettingItems = (
    values: IApplicationsSettingsFromBeModel['value']
): IApplicationsSettingsModel['value'] =>
    values.map(({ type, value_number, value_text, value_bool, ...rest }) => ({
        ...rest,
        value: getTransformSettingItemsValue(type, value_number, value_text, value_bool)
    })) as IApplicationsSettingsModel['value'];

const reverseTransformSettingsItem = (
    values: IApplicationsSettingsModel['value']
): IApplicationsSettingsFromBeModel['value'] =>
    values.map(({ value, ...rest }) => ({
        ...rest,
        ...getReverseTransformSettingsItemValuesList(value)
    })) as IApplicationsSettingsFromBeModel['value'];

export const fetchApplicationsSettingsItems = createAsyncThunk('applicationsSettingsItems/list', async () => {
    const response = await fetchList();

    return {
        ...response,
        data: response.data.map(({ value, ...rest }) => {
            return {
                ...rest,
                value: transformSettingItems(value ?? [])
            };
        }) as IApplicationsSettingsModel[]
    };
});

export const removeApplicationSetting = createAsyncThunk(
    'applicationsSettingsItems/remove',
    async (args: { id: string; onSuccess?: () => void }) => {
        await remove(args.id as ApplicationSettingsItemTypesEnum).then(() => args.onSuccess && args.onSuccess());

        return args.id;
    }
);

export const createApplicationSetting = createAsyncThunk('setting/create', async (args: IApplicationsSettingsModel) => {
    const response = await create({
        application_key: args.application_key as ApplicationSettingsItemTypesEnum,
        value: reverseTransformSettingsItem(args.value)
    } as IApplicationsSettingsFromBeModel);

    return {
        ...response,
        value: transformSettingItems(response.value)
    } as IApplicationsSettingsModel;
});

export const updateApplicationSetting = createAsyncThunk(
    'setting/update',
    async (
        args: { id: IApplicationsSettingsModel['application_key']; data: IApplicationsSettingsModel },
        { getState }
    ) => {
        const state = getState() as IRootState;
        const currentData = getSchedulePlanApplicationsSettings(state).filter(
            ({ application_key }) => application_key === args.id
        );

        const onlyChanged = args.data.value.filter((item) => {
            const byKey = currentData.find(({ key }) => key === item.key);

            return !byKey || `${byKey.value}` !== `${item.value}`;
        }) as IApplicationsSettingsModel['value'];

        const response = await update(ApplicationSettingsItemTypesEnum.schedulePlan, {
            application_key: ApplicationSettingsItemTypesEnum.schedulePlan,
            value: reverseTransformSettingsItem(onlyChanged)
        } as IApplicationsSettingsFromBeModel);

        return {
            ...response,
            value: transformSettingItems(response.value)
        } as IApplicationsSettingsModel;
    },
    {
        condition: (_, { getState }) => {
            const state = getState() as IRootState;
            const permissions = getPermissionsList(state);

            return isUserAllowed({ id: PermissionsControllerEnum.SettingsItems, mode: Mode.UPDATE }, permissions);
        }
    }
);
