import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { IRootState } from '@/data/store';
import { createUser, fetchUserById, removeUser, updateUser } from '@/data/Users/UserActions';
import { selectUserToShiftItems } from '@/data/UserToShiftItems/UserToShiftItemSlice';
import { IUserToShiftsExtendedModel, IUserToShiftsModel } from '@/data/UserToShifts/UserToShiftsModels';

const adapter = createEntityAdapter<IUserToShiftsModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => (a.id < b.id ? 1 : -1)
});

const userToShiftsSlice = createSlice({
    name: 'userToShifts',
    initialState: adapter.getInitialState({}),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                const usersFromWorkplace = action.payload.users;

                const userToShifts = usersFromWorkplace
                    .flatMap(
                        ({ user_to_shifts }) =>
                            user_to_shifts?.map(({ user_to_shift_items, ...item }) => item as IUserToShiftsModel)
                    )
                    .filter((item) => !!item) as IUserToShiftsModel[];

                adapter.upsertMany(state, userToShifts);
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                adapter.upsertMany(state, action.payload.user_to_shifts ?? []);
            })
            .addCase(createUser.fulfilled, (state, action) => {
                adapter.addMany(state, action.payload.user_to_shifts ?? []);
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (Object.values(state.entities) as IUserToShiftsModel[])
                        .filter((userToShift) => userToShift.user_id === action.payload.id)
                        .map(({ id }) => id)
                );
                adapter.upsertMany(state, action.payload.user_to_shifts ?? []);
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.user_id === action.meta.arg
                        ) as IUserToShiftsModel[]
                    ).map(({ id }) => id)
                );
            });
    }
});

const getState = (state: IRootState) => state[userToShiftsSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default userToShiftsSlice;

export const selectUserToShifts = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => selectUserToShiftItems(state),
    (userToShifts, userToShiftItems): IUserToShiftsExtendedModel[] =>
        userToShifts.map((userToShift) => ({
            ...userToShift,
            user_to_shift_items: userToShiftItems.filter((item) => item.user_to_shift_id === userToShift.id)
        }))
);
