import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { GridActionsCellItem, GridRowId, GridRowModes } from '@mui/x-data-grid';
import { JSXElementConstructor, ReactElement, ReactNode, useCallback, useRef } from 'react';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { useAppSelector } from '@/data/hooks';
import { getPermissionsList } from '@/data/System/SystemReducer';
import useAppTranslation from '@/hooks/useAppTranslation';
import { PermissionsEnumType } from '@/utils/enums/PermissionsEnum';
import Datatable, { IDatatableProps } from '@/wrappers/Datatable';
import { IDatatableActions } from '@/wrappers/Datatable/Datatable';
import RemoveDialog from '@/wrappers/RemoveDialog';

type IProps<RowType extends object> = Pick<
    IDatatableProps<RowType>,
    | 'data'
    | 'customActionsInToolbar'
    | 'customAddActionInToolbar'
    | 'getRowId'
    | 'header'
    | 'labelAddButton'
    | 'paging'
    | 'sortingMode'
    | 'onValidateRow'
> & {
    customEditAction?: JSXElementConstructor<{
        id: GridRowId;
        openButton: (onClick: () => void) => ReactElement;
        row: RowType;
        rows: RowType[];
        onSubmit: (updatedRow: RowType) => void;
    }>;
    labelAddButton: ReactNode;
    nameOfEntity?: (row: RowType) => string;
    resource?: PermissionsEnumType;
    title?: string;
    onRowAdd?: (newData: RowType) => void;
    onRowUpdate?: (newData: RowType, oldData: RowType) => void;
    onRowRemove?: (id: string | number) => void;
};

export type ICrudInlineData<T extends object> = T & { id?: string };
export type ICrudInlineDataList<T extends object> = ICrudInlineData<T>[];

const CrudInlineDatatable = <RowType extends object>({
    customEditAction: CustomEditAction,
    header,
    nameOfEntity,
    resource,
    onRowAdd,
    onRowRemove,
    onRowUpdate,
    ...rest
}: IProps<RowType>) => {
    const { t } = useAppTranslation();
    const dataTableRef = useRef<IDatatableActions<RowType>>(null);
    const permissionsList = useAppSelector(getPermissionsList);

    const handleEdit = useCallback((id: GridRowId) => () => dataTableRef.current?.startRowUpdate(id), []);
    const handleFinishEdit = useCallback((id: GridRowId) => () => dataTableRef.current?.finishRowUpdate(id), []);
    const handleCancelEdit = useCallback((id: GridRowId) => () => dataTableRef.current?.cancelRowUpdate(id), []);
    const handleDelete = useCallback((id: GridRowId) => () => dataTableRef.current?.removeRow(id), []);
    const handleUpdateRow = useCallback((updatedRow: RowType) => dataTableRef.current?.updateRow(updatedRow), []);

    return (
        <>
            <Datatable
                {...rest}
                innerRef={dataTableRef}
                density="standard"
                editMode="row"
                hasSearch={false}
                hasSelection={false}
                hasTitle={!!rest.title}
                header={[
                    ...header,
                    {
                        align: 'center',
                        cellClassName: 'dataGrid-cell-actions',
                        disableExport: true,
                        field: 'actions',
                        headerAlign: 'center',
                        headerName: t('header.actions', 'Actions'),
                        sortable: false,
                        type: 'actions',
                        getActions: (params, rowModes) => {
                            if (rowModes[params.id]?.mode === GridRowModes.Edit) {
                                return [
                                    <GridActionsCellItem
                                        key="finishUpdate"
                                        color="primary"
                                        icon={<CheckIcon />}
                                        label={t('label.save', 'Save')}
                                        onClick={handleFinishEdit(params.id)}
                                    />,
                                    <GridActionsCellItem
                                        key="cancel"
                                        icon={<ClearIcon />}
                                        label={t('label.cancel', 'Cancel')}
                                        onClick={handleCancelEdit(params.id)}
                                    />
                                ];
                            }

                            return [
                                ...(isUserAllowed({ id: resource ?? null, mode: Mode.UPDATE }, permissionsList) &&
                                onRowUpdate
                                    ? [
                                          CustomEditAction ? (
                                              <CustomEditAction
                                                  id={params.id}
                                                  openButton={(onClick) => (
                                                      <GridActionsCellItem
                                                          key="edit"
                                                          color="info"
                                                          icon={<EditIcon />}
                                                          label={t('label.edit', 'Edit')}
                                                          onClick={onClick}
                                                      />
                                                  )}
                                                  row={params.row}
                                                  rows={Array.isArray(rest.data) ? rest.data : []}
                                                  onSubmit={handleUpdateRow}
                                              />
                                          ) : (
                                              <GridActionsCellItem
                                                  key="edit"
                                                  color="info"
                                                  icon={<EditIcon />}
                                                  label={t('label.edit', 'Edit')}
                                                  onClick={handleEdit(params.id)}
                                              />
                                          )
                                      ]
                                    : []),
                                ...(isUserAllowed({ id: resource ?? null, mode: Mode.DELETE }, permissionsList) &&
                                onRowRemove
                                    ? [
                                          <RemoveDialog
                                              key="remove"
                                              onAgree={handleDelete(params.id)}
                                              nameOfItem={nameOfEntity ? nameOfEntity(params.row) : undefined}
                                              openButton={(onClick) => (
                                                  <GridActionsCellItem
                                                      color="error"
                                                      icon={<DeleteIcon />}
                                                      label={t('label.delete', 'Delete')}
                                                      onClick={onClick}
                                                  />
                                              )}
                                          />
                                      ]
                                    : [])
                                //         <UserPermission
                                //             id={rowResource ? rowResource(row) ?? resource ?? null : resource ?? null}
                                //             mode={editResourceMode?.mode}
                                //             operator={editResourceMode?.operator}
                                //         >
                                //             {actions ? actions(row.id, row) : ''}
                                //             {!editByRowClick && formRender(row.id, true, undefined, row)}
                                //         </UserPermission>
                                //         <UserPermission
                                //             id={rowResource ? rowResource(row) ?? resource ?? null : resource ?? null}
                                //             mode={Mode.DELETE}
                                //         >
                                //             {!disableRemove && onRemove ? (
                                //                 <RemoveDialog<IDType>
                                //                     justIcon
                                //                     onAgree={(onSuccess) => handleRemove(row.id, onSuccess)}
                                //                     nameOfItem={nameOfEntity(row)}
                                //                     idOfItem={row.id}
                                //                 />
                                //             ) : (
                                //                 <></>
                                //             )}
                                //         </UserPermission>
                                //     </StyledButtonGroup>
                                //     {dialogs ? dialogs(row.id, row) : ''}
                                // </>
                            ];
                        }
                    }
                ]}
                onRowAdded={
                    onRowAdd && isUserAllowed({ id: resource ?? null, mode: Mode.CREATE }, permissionsList)
                        ? (row) => onRowAdd(row)
                        : undefined
                }
                onRowUpdated={
                    onRowUpdate && isUserAllowed({ id: resource ?? null, mode: Mode.UPDATE }, permissionsList)
                        ? (newRow, originalRow) => onRowUpdate(newRow, originalRow)
                        : undefined
                }
                onRowRemoved={
                    onRowRemove && isUserAllowed({ id: resource ?? null, mode: Mode.DELETE }, permissionsList)
                        ? (id) => onRowRemove(id)
                        : undefined
                }
            />
        </>
    );
};

export default CrudInlineDatatable;
