import Typography from '@mui/material/Typography';
import CrudDatatable from '@/components/CrudDatatable';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchRequestTypes, removeTypeRequest, updateTypeRequest } from '@/data/RequestTypes/RequestTypeActions';
import { IRequestTypeModel } from '@/data/RequestTypes/RequestTypeModels';
import { requestPaging, selectFilteredRequestTypes } from '@/data/RequestTypes/RequestTypeSlice';
import { getPermissionsList } from '@/data/System/SystemReducer';
import RequestTypeForm from '@/forms/RequestTypeForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import Switch from '@/wrappers/Switch';

export default function RequestTypesListPage() {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const data = useAppSelector(selectFilteredRequestTypes);
    const permissionsList = useAppSelector(getPermissionsList);

    return (
        <LayoutOfPage title={t('title.requestTypesList', 'Request Types List')}>
            <CrudDatatable<IRequestTypeModel>
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue, _rowData, renderButton) => (
                    <RequestTypeForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    {
                        headerName: t('header.id', 'ID'),
                        field: 'id'
                    },
                    { headerName: t('header.requestName', 'Request Name'), field: 'name' },
                    {
                        headerName: t('header.requestType', 'Request Type'),
                        field: 'type',
                        renderCell: ({ row }) => {
                            const indexOfEnum = Object.values(RequestTypeEnum).indexOf(
                                row.type as unknown as RequestTypeEnum
                            );

                            const key = Object.keys(RequestTypeEnum)[indexOfEnum];

                            return (
                                <Typography variant="subtitle2">
                                    {t(`enums.requestTypeEnum.${key}`, row.type)}
                                </Typography>
                            );
                        }
                    },
                    { headerName: t('header.description', 'Description'), field: 'description' },
                    {
                        headerName: t('header.abbreviation', 'Abbreviation'),
                        field: 'abbreviation'
                    },
                    {
                        headerName: t('header.color', 'Color'),
                        field: 'color',
                        type: 'color'
                    },
                    {
                        headerName: t('header.background', 'Background'),
                        field: 'background',
                        type: 'color'
                    },
                    {
                        headerName: t('header.active', 'Active'),
                        field: 'active',
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch
                                name={row.name}
                                value={row.active}
                                onChange={(value) =>
                                    isUserAllowed(
                                        {
                                            id: PermissionsEnum.RequestTypes,
                                            mode: [Mode.UPDATE, Mode.CREATE],
                                            operator: 'OR'
                                        },
                                        permissionsList
                                    ) &&
                                    dispatch(
                                        updateTypeRequest({
                                            id: row.id,
                                            data: {
                                                active: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    },
                    {
                        headerName: t('header.approve', 'Approve'),
                        field: 'approve',
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch
                                name="approve"
                                value={row.approve}
                                onChange={(value) =>
                                    isUserAllowed(
                                        {
                                            id: PermissionsEnum.RequestTypes,
                                            mode: [Mode.UPDATE, Mode.CREATE],
                                            operator: 'OR'
                                        },
                                        permissionsList
                                    ) &&
                                    dispatch(
                                        updateTypeRequest({
                                            id: row.id,
                                            data: {
                                                approve: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    },
                    {
                        headerName: t('header.createdAt', 'Created At'),
                        type: 'dateTime',
                        field: 'created'
                    },
                    {
                        headerName: t('header.updatedAt', 'Updated At'),
                        type: 'dateTime',
                        field: 'modified'
                    }
                ]}
                hiddenFields={['id', 'abbreviation', 'color', 'background', 'approve', 'created', 'modified']}
                name="requestType"
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(requestPaging)}
                resource={PermissionsEnum.RequestTypes}
                onFetchList={fetchRequestTypes}
                onRemove={(id) =>
                    dispatch(removeTypeRequest(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </LayoutOfPage>
    );
}
