import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { IRootState } from '@/data/store';
import { createUser, fetchUserById, fetchUsers, removeUser } from '@/data/Users/UserActions';
import { IUserToWorkplaceModel } from '@/data/UserToWorkplaces/UserToWorkplaceModels';
import {
    createWorkplace,
    fetchWorkplaceById,
    fetchWorkplaces,
    removeWorkplace,
    updateWorkplace
} from '@/data/Workplaces/WorkplaceActions';

type IState = {
    updatingStatus: IRequestState;
};

const initialState: IState = {
    updatingStatus: 'idle'
};
const getKey = (entity: IUserToWorkplaceModel) => `${entity.user_id}_${entity.workplace_id}_${entity.type}`;

const adapter = createEntityAdapter<IUserToWorkplaceModel>({
    selectId: getKey,
    sortComparer: (a, b) => getKey(a).localeCompare(getKey(b))
});

const userToWorkplaceSlice = createSlice({
    name: 'userToWorkplaces',
    initialState: adapter.getInitialState<IState>(initialState),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.workplace_id === action.payload.workplace_id
                        ) as IUserToWorkplaceModel[]
                    ).map(({ user_id, workplace_id }) => `${user_id}_${workplace_id}`)
                );
                adapter.addMany(state, action.payload.workplace.user_to_workplaces);
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.user_to_workplaces.map(({ workplace, ...item }) => item)
                );
            })
            .addCase(fetchWorkplaces.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.data.flatMap(({ user_to_workplaces }) => user_to_workplaces)
                );
            })
            .addCase(createUser.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.user_to_workplaces.map(({ workplace, ...item }) => item)
                );
            })
            .addCase(removeWorkplace.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.workplace_id === action.meta.arg
                        ) as IUserToWorkplaceModel[]
                    ).map(({ user_id, workplace_id }) => `${user_id}_${workplace_id}`)
                );
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.user_id === action.meta.arg
                        ) as IUserToWorkplaceModel[]
                    ).map(({ user_id, workplace_id }) => `${user_id}_${workplace_id}`)
                );
            })
            .addCase(fetchWorkplaceById.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.user_to_workplaces.map(({ user, ...entity }) => entity)
                );
            })
            .addCase(updateWorkplace.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.workplace_id === action.meta.arg.id
                        ) as IUserToWorkplaceModel[]
                    ).map(getKey)
                );
                adapter.addMany(
                    state,
                    action.payload.user_to_workplaces.map(({ user, ...entity }) => entity)
                );
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.data
                        .map(
                            ({ user_to_workplaces }) => user_to_workplaces?.map(({ workplace, ...item }) => item) ?? []
                        )
                        ?.flat() ?? []
                );
            })
            .addCase(createWorkplace.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.user_to_workplaces.map(({ user, ...entity }) => entity)
                );
            });
    }
});

const getState = (state: IRootState) => state[userToWorkplaceSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default userToWorkplaceSlice;
export const userToWorkplaceAll = adapterSelectors.selectAll;
