import {
    DateValidationError,
    DesktopDatePicker as MuiDatePicker,
    DesktopDatePickerProps as MuiDatePickerProps
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { forwardRef, memo, useCallback } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import config from '@/utils/config';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';
import DatePickerErrorMessages from './DatePickerErrorMessages';

export type IDatePickerProps = Omit<
    MuiDatePickerProps<Dayjs>,
    'label' | 'name' | 'onError' | 'onChange' | 'renderInput'
> &
    Pick<ITextFieldProps, 'name' | 'required' | 'size'> & {
        label?: string;
        error?: boolean;
        helperText?: string;
        onError?: (message: string) => void;
        onChange?: (value: Dayjs | null) => void;
    };

const DatePicker = forwardRef<HTMLDivElement, IDatePickerProps>(function Inner(
    {
        format = config.date.inputFormat,
        name,
        value = null,
        required,
        label,
        disabled,
        readOnly,
        size,
        error,
        helperText,
        onError,
        onChange,
        ...rest
    },
    ref
) {
    const handleError = (reason: DateValidationError) => {
        if (!value) return;

        if (onError) onError(DatePickerErrorMessages(reason));
    };

    const handleOnChange = useCallback(
        (newValue: DateTimeType | null) => {
            if (onChange && (newValue === null || DateHelper.isValid(newValue))) {
                onChange(newValue);
            }
        },
        [onChange]
    );

    return (
        <MuiDatePicker
            {...rest}
            ref={ref}
            disabled={disabled || readOnly}
            label={label}
            value={value}
            slotProps={{
                textField: {
                    name,
                    required
                }
            }}
            slots={{
                textField: TextField
            }}
            onError={handleError}
            onChange={handleOnChange}
        />
    );
});

export default memo(DatePicker);
