import EditIcon from '@mui/icons-material/EditOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OpenButton } from '@/base/FormGenerator/FormGenerator';
import CrudDatatable from '@/components/CrudDatatable';
import { Mode } from '@/components/UserPermision';
import { fetchApplicationsSettingsItems } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchSchedulePlans, removeSchedulePlan } from '@/data/SchedulePlans/SchedulePlanActions';
import { SchedulePlanStateEnum } from '@/data/SchedulePlans/SchedulePlanEnums';
import { schedulePlanPaging, selectSchedulePlanList } from '@/data/SchedulePlans/SchedulePlanSlice';
import { isSignedUserAdmin } from '@/data/System/SystemReducer';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import formatPascalToCamel from '@/helpers/format/formatPascalToCamel';
import { scheduleStateToColor } from '@/helpers/schedule';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { mainSchedulePlansDetail } from '@/utils/routes';
import Button from '@/wrappers/Button';
import Chip from '@/wrappers/Chip';
import SchedulePlanDownloadDialog from '@/wrappers/SchedulePlanDownloadDialog';
import SchedulePlanUploadDialog from '@/wrappers/SchedulePlanUploadDialog';
import Switch from '@/wrappers/Switch';

const StyledIconButton = styled(MuiIconButton)(
    ({ theme }) => `
        color: ${theme.palette.info.main};
    `
);

export default function SchedulesListPage() {
    const { t } = useAppTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectSchedulePlanList);
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);

    useEffect(() => {
        dispatch(fetchApplicationsSettingsItems());
    }, []);

    return (
        <LayoutOfPage title={t('title.schedulePlansList', 'Schedule Plans List')} data-testid="scheduler-list-page">
            <CrudDatatable<ArrayElement<typeof data>>
                autoHeight={false}
                customActionsInToolbar={
                    isUserAdmin ? (
                        <>
                            <Button
                                name="download"
                                justIcon={true}
                                color="primary"
                                tooltip={t('label.download', 'Download')}
                                onClick={() => setOpenDownloadDialog(true)}
                            >
                                <FileDownloadOutlinedIcon />
                            </Button>
                            <Button
                                name="upload"
                                color="primary"
                                tooltip={t('label.upload', 'Upload')}
                                onClick={() => setOpenUploadDialog(true)}
                            >
                                <FileUploadOutlinedIcon />
                            </Button>
                        </>
                    ) : undefined
                }
                data={data}
                editResourceMode={{
                    mode: [Mode.UPDATE, Mode.READ],
                    operator: 'OR'
                }}
                formRender={(id, _, __, rowData, renderButton) =>
                    id ? (
                        renderButton ? (
                            renderButton(() =>
                                navigate(
                                    mainSchedulePlansDetail({
                                        isNew: 0,
                                        id: id,
                                        workplaceId: rowData?.workplace_id ?? '',
                                        periodId: rowData?.period_id ?? ''
                                    })
                                )
                            )
                        ) : (
                            <>
                                <StyledIconButton
                                    title={t('label.edit', 'Edit')}
                                    data-testid={`openDialog-schedulePlan-${id}_openButton`}
                                    onClick={() =>
                                        navigate(
                                            mainSchedulePlansDetail({
                                                isNew: 0,
                                                id: id,
                                                workplaceId: rowData?.workplace_id ?? '',
                                                periodId: rowData?.period_id ?? ''
                                            })
                                        )
                                    }
                                >
                                    <EditIcon data-testid="edit" />
                                </StyledIconButton>
                            </>
                        )
                    ) : (
                        <OpenButton
                            nameWithId="schedulePlan_openButton"
                            openButtonValue={t('label.addSchedulePlan', 'Add schedule plan')}
                            isEdit={false}
                            justIcon={false}
                            onClick={() =>
                                navigate(
                                    mainSchedulePlansDetail({
                                        isNew: 1
                                    })
                                )
                            }
                        />
                    )
                }
                header={[
                    {
                        headerName: t('header.scheduleName', 'Schedule Name'),
                        field: 'name'
                    },
                    {
                        headerName: t('header.periodName', 'Period Name'),
                        field: 'Periods.name',
                        renderCell: ({ row }) => row.period.name
                    },
                    {
                        headerName: t('header.workplace', 'Workplace'),
                        field: 'Workplaces.name',
                        renderCell: ({ row }) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                name={`workplace-${row.workplace_id}`}
                                label={row.workplace.name}
                            />
                        )
                    },
                    {
                        headerName: t('header.state', 'State'),
                        field: 'state',
                        type: 'boolean',
                        renderCell: ({ row }) => {
                            const indexOfEnum = Object.values(SchedulePlanStateEnum).indexOf(
                                row.state as unknown as SchedulePlanStateEnum
                            );

                            const key = Object.keys(SchedulePlanStateEnum)[indexOfEnum];

                            return (
                                <Chip
                                    variant="outlined"
                                    color={scheduleStateToColor(row.state, row.optimal)}
                                    name={`state-${row.id}`}
                                    label={t(`enums.schedulePlanStateEnum.${formatPascalToCamel(key)}`, key)}
                                />
                            );
                        }
                    },
                    {
                        headerName: t('header.published', 'Published'),
                        field: 'published',
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch name={`published-${row.id}`} value={row.published} readOnly={true} />
                        )
                    },
                    {
                        headerName: t('header.createdAt', 'Created At'),
                        type: 'date',
                        field: 'created'
                    },
                    {
                        headerName: t('header.modifiedAt', 'Modified At'),
                        type: 'date',
                        field: 'modified'
                    }
                ]}
                name="schedule"
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(schedulePlanPaging)}
                resource={PermissionsEnum.SchedulePlans}
                onFetchList={fetchSchedulePlans}
                onRemove={(id) =>
                    dispatch(removeSchedulePlan(id))
                        .unwrap()
                        .then(() => id)
                }
            />
            <SchedulePlanDownloadDialog open={openDownloadDialog} onDialogClose={() => setOpenDownloadDialog(false)} />
            <SchedulePlanUploadDialog open={openUploadDialog} onDialogClose={() => setOpenUploadDialog(false)} />
        </LayoutOfPage>
    );
}
