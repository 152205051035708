import CrudDatatable from '@/components/CrudDatatable';
import { fetchActivities, removeActivity } from '@/data/Activities/ActivityActions';
import { IActivityModel } from '@/data/Activities/ActivityModels';
import { activityList, activityPaging } from '@/data/Activities/ActivitySlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import ActivityForm from '@/forms/ActivityForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';

export default function ActivitiesListPage() {
    const data = useAppSelector(activityList);
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();

    return (
        <LayoutOfPage title={t('title.activitiesList', 'Activities List')}>
            <CrudDatatable<IActivityModel>
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue) => (
                    <ActivityForm displayAsSidebar justIcon={justIcon} id={id} openButtonValue={openButtonValue} />
                )}
                header={[
                    { headerName: t('header.activityName', 'Activity Name'), field: 'name', flex: 1 },
                    {
                        headerName: t('header.createdAt', 'Created At'),
                        field: 'created',
                        flex: 1,
                        type: 'dateTime'
                    }
                ]}
                name="activity"
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(activityPaging)}
                resource={PermissionsEnum.Activities}
                onFetchList={fetchActivities}
                onRemove={(id) =>
                    dispatch(removeActivity(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </LayoutOfPage>
    );
}
