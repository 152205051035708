import { useTheme } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import FormGenerator, { IOutputValueType } from '@/base/FormGenerator';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { updateRequirementsOnSkill } from '@/data/SchedulePlans/SchedulePlanActions';
import { schedulePlanUpdatingStatus } from '@/data/SchedulePlans/SchedulePlanSlice';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { ICell } from '@/helpers/schedule';
import useAppTranslation from '@/hooks/useAppTranslation';
import { StyledSkillDataItem } from '@/modules/Scheduler/StyledParts';
import { IValueType } from '@/wrappers/DateRangePicker';
import { IValue } from '@/wrappers/TimeRangePicker';
import Tooltip from '@/wrappers/Tooltip';
import { ISchedulerCalendarHeaderProps } from '../SchedulerCalendarHeader';

type IProps = Pick<ISchedulerCalendarHeaderProps, 'isDayMode'> & {
    columnTitle: Pick<ICell<DateTimeType>, 'startColumn' | 'width'> | null;
    covered: number | null;
    date: DateTimeType;
    index: number;
    isHidden?: boolean;
    isSelected?: boolean;
    isSkill: boolean;
    itemId: number;
    required: number | null;
    schedulePlanDayId: number | undefined;
    schedulePlanId: number | undefined;
    timezone: string | null;
    isSchedulePlanClosed?: boolean;
};

export default function SchedulerTableHeaderCell(props: IProps) {
    const {
        columnTitle,
        date,
        isHidden,
        isDayMode,
        isSelected,
        isSkill,
        index,
        itemId,
        schedulePlanDayId,
        schedulePlanId,
        covered,
        required,
        timezone,
        isSchedulePlanClosed = false,
        ...rest
    } = props;

    const { t } = useAppTranslation();
    const theme = useTheme();
    const [isOpen, setOpen] = useState(false);

    const dispatch = useAppDispatch();

    const schedulePlanUpdatingStatusData = useAppSelector(schedulePlanUpdatingStatus);

    const handleSubmit = useCallback(
        (values: IOutputValueType) => {
            let start, end;

            if (isDayMode) {
                const duration = (values.fromTo as IValue).duration;

                start = (values.fromTo as IValue).start;
                end = DateHelper.addHours(start, duration);
            } else {
                start = (values.fromTo as IValueType<DateTimeType>).start;
                end = (values.fromTo as IValueType<DateTimeType>).end;

                if (DateHelper.isEqual(start, end)) {
                    end = DateHelper.addDays(start, 1);
                }
            }

            if (!start || !end) {
                throw Error('Unexpected state');
            }

            dispatch(
                updateRequirementsOnSkill({
                    ...(isSkill ? { skill_id: itemId } : { role_id: itemId }),
                    schedule_plan_id: schedulePlanId!,
                    required_min: values.required_min as number,
                    from: start,
                    to: end
                })
            )
                .unwrap()
                .then(() => setOpen(false));
        },
        [itemId, isDayMode, schedulePlanId]
    );

    const isFilled = schedulePlanDayId !== -1 && typeof covered === 'number';
    const hasSchedulePlan = typeof schedulePlanId === 'number';

    const flooredNowDate = isDayMode ? DateHelper.floorMinutes(DateHelper.now()) : DateHelper.nowStartOfTheDay(true);

    const nowDate = isDayMode
        ? DateHelper.addMinutesByTimezone(DateHelper.now(), timezone)
        : DateHelper.subtractMinutesByTimezone(DateHelper.now(), timezone);

    const percentageForGradient = useMemo(
        () => (isDayMode ? (100 / 60) * nowDate.toDate().getMinutes() : (100 / 24) * nowDate.toDate().getHours()),
        [nowDate.toISOString(), isDayMode]
    );

    return (
        <FormGenerator
            {...rest}
            name="schedulerTableCellUpdateForm"
            title="Update requirements"
            justIcon
            displayAsModal
            openForm={isOpen}
            onOpen={() => setOpen(true)}
            openButtonRender={(onClick: (event: MouseEvent) => void) => (
                <StyledSkillDataItem
                    columnIndex={isDayMode ? index : columnTitle?.startColumn ?? index}
                    covered={Boolean(isFilled && typeof covered === 'number' && (covered || 0) >= (required || 0))}
                    hidden={isHidden}
                    noRequirements={!isFilled}
                    outOfPeriod={!hasSchedulePlan}
                    selected={isSelected}
                    width={isDayMode ? 1 : columnTitle?.width ?? 1}
                    onClick={onClick}
                    data-testid={`requirements_${itemId}_${index}`}
                    sx={{
                        ...(DateHelper.isEqual(flooredNowDate, DateHelper.getInstanceOf(date))
                            ? {
                                  borderLeft: '1px black solid',
                                  fontWeight: 'bold'
                              }
                            : {}),
                        ...(!isFilled
                            ? DateHelper.isAfter(flooredNowDate, DateHelper.getInstanceOf(date))
                                ? { backgroundColor: theme.palette.grey.A400 }
                                : DateHelper.isEqual(flooredNowDate, DateHelper.getInstanceOf(date))
                                ? {
                                      backgroundColor: `linear-gradient(90deg, #${theme.palette.grey.A400} ${percentageForGradient}%, white ${percentageForGradient}%)`
                                  }
                                : {}
                            : {})
                    }}
                >
                    {!hasSchedulePlan || schedulePlanDayId === -1 ? (
                        '-'
                    ) : typeof covered === 'number' ? (
                        <Tooltip
                            color="primary"
                            title={
                                <>
                                    {covered}/{required}
                                    <br />
                                    <b>{t('label.required', 'Required')}</b>: {required}
                                    <br />
                                    <b>{t('label.covered', 'Covered')}</b>: {covered}
                                </>
                            }
                        >
                            {isDayMode ? (
                                <>{required}</>
                            ) : (
                                <>
                                    {covered}/{required}
                                </>
                            )}
                        </Tooltip>
                    ) : (
                        '-'
                    )}
                </StyledSkillDataItem>
            )}
            fields={[
                {
                    type: 'textField',
                    props: {
                        name: 'required_min',
                        readOnly: isSchedulePlanClosed,
                        label: 'Min Required',
                        type: 'number',
                        required: true,
                        value: required?.toString()
                    }
                },
                isDayMode && {
                    type: 'timeRange',
                    props: {
                        name: 'fromTo',
                        readOnly: isSchedulePlanClosed,
                        label: {
                            start: t('label.from', 'From'),
                            end: t('label.to', 'To')
                        },
                        required: true,
                        minutesStep: 15,
                        zeroIsNextDay: true,
                        value: {
                            start: DateHelper.fromDateTimeString(DateHelper.formatISO(date)),
                            duration: 0.25
                        }
                    }
                },
                !isDayMode && {
                    type: 'dateRange',
                    props: {
                        readOnly: isSchedulePlanClosed,
                        name: 'fromTo',
                        label: {
                            start: t('label.from', 'From'),
                            end: t('label.to', 'To')
                        },
                        required: true,
                        value: {
                            start: DateHelper.getInstanceOf(date),
                            end: DateHelper.getInstanceOf(date)
                        }
                    }
                }
            ]}
            actions={[
                {
                    type: 'button',
                    props: {
                        type: 'button',
                        name: 'cancel',
                        children: 'Cancel',
                        variant: 'text',
                        onClick: () => setOpen(false)
                    }
                },
                {
                    type: 'loadingButton',
                    display: () => !isSchedulePlanClosed,
                    props: {
                        type: 'submit',
                        name: 'submit',
                        children: 'Update',
                        loading: schedulePlanUpdatingStatusData === 'loading',
                        variant: 'contained'
                    }
                }
            ]}
            onSubmit={handleSubmit}
        />
    );
}
