import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createQueue,
    trainDaktelaQueue,
    fetchQueueById,
    fetchQueuesForSelect,
    fetchQueues,
    removeQueue,
    updateQueue,
    fetchUploadCsvFilesTempleData,
    fetchUploadCsvFilesTempleFile
} from './QueueActions';
import { IQueueCsvFileTempleData, IQueueModel, IQueueSelectModel } from './QueueModels';

type IQueuesReducerState = {
    selectItems: IQueueSelectModel[] | null;
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: IRequestState;
    csvFileTempleDataStatus: IRequestState;
    csvFileTempleFileStatus: IRequestState;
    csvFileTempleData?: IQueueCsvFileTempleData;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    trainStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IQueuesReducerState = {
    selectItems: null,
    paging: defaultPaging('name'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    trainStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle',
    csvFileTempleDataStatus: 'idle',
    csvFileTempleFileStatus: 'idle',
    csvFileTempleData: undefined
};
const adapter = createEntityAdapter<IQueueModel>({
    selectId: (entity) => entity.id
});
const queueSlice = createSlice({
    name: 'queues',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQueues.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchQueues.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.setAll(state, action.payload.data);
                if (!action.meta.arg.noPaging) {
                    state.paging = action.payload.collection;
                }
            })
            .addCase(fetchQueues.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchQueuesForSelect.pending, (state) => {
                state.loadingForSelectStatus = 'loading';
            })
            .addCase(fetchQueuesForSelect.fulfilled, (state, action) => {
                state.loadingForSelectStatus = 'idle';
                state.selectItems = action.payload.data;
            })
            .addCase(fetchQueuesForSelect.rejected, (state) => {
                state.loadingForSelectStatus = 'failed';
            })
            .addCase(fetchQueueById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchQueueById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchQueueById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createQueue.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createQueue.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
                if (state.selectItems?.length) {
                    state.selectItems.push({
                        id: action.payload.id,
                        name: action.payload.name
                    } as IQueueSelectModel);
                }
            })
            .addCase(createQueue.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(trainDaktelaQueue.pending, (state) => {
                state.trainStatus = 'loading';
            })
            .addCase(trainDaktelaQueue.fulfilled, (state) => {
                state.trainStatus = 'idle';
            })
            .addCase(trainDaktelaQueue.rejected, (state) => {
                state.trainStatus = 'failed';
            })
            .addCase(fetchUploadCsvFilesTempleData.pending, (state) => {
                state.csvFileTempleDataStatus = 'loading';
            })
            .addCase(fetchUploadCsvFilesTempleData.fulfilled, (state, action) => {
                state.csvFileTempleDataStatus = 'idle';
                state.csvFileTempleData = action.payload;
            })
            .addCase(fetchUploadCsvFilesTempleData.rejected, (state) => {
                state.csvFileTempleDataStatus = 'failed';
            })
            .addCase(fetchUploadCsvFilesTempleFile.pending, (state) => {
                state.csvFileTempleFileStatus = 'loading';
            })
            .addCase(fetchUploadCsvFilesTempleFile.fulfilled, (state) => {
                state.csvFileTempleFileStatus = 'idle';
            })
            .addCase(fetchUploadCsvFilesTempleFile.rejected, (state) => {
                state.csvFileTempleFileStatus = 'failed';
            })
            .addCase(removeQueue.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeQueue.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg);
                if (state.selectItems?.length) {
                    state.selectItems = state.selectItems.filter((item) => item.id !== action.meta.arg);
                }
            })
            .addCase(removeQueue.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateQueue.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateQueue.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
                if (state.selectItems?.length) {
                    state.selectItems = state.selectItems.map((item) =>
                        item.id === action.payload.id
                            ? {
                                  id: action.payload.id,
                                  name: action.payload.name,
                                  queue_to_types: action.payload.queue_to_types
                              }
                            : item
                    );
                }
            })
            .addCase(updateQueue.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[queueSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default queueSlice;
export const queuesListStatus = (state: IRootState) => getState(state).loadingListStatus;
export const isQueuesListInProgress = (state: IRootState) => queuesListStatus(state) === 'loading';
export const queuePaging = (state: IRootState) => getState(state).paging;
export const isQueueByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const queueList = adapterSelectors.selectAll;
export const queueById = (state: IRootState, id?: number) => (id ? adapterSelectors.selectById(state, id) : undefined);
export const queuesForSelect = (state: IRootState, original = false) => {
    const items = getState(state).selectItems;

    return original || items?.length ? items : null;
};
export const queueSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const queueCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const queueTrainStatus = (state: IRootState) => getState(state).trainStatus;
export const queueUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const isQueueUpdatingProcessComplete = (state: IRootState) => getState(state).updatingStatus !== 'loading';
export const queueCsvFileTempleData = (state: IRootState) => getState(state).csvFileTempleData;
export const queueCsvFileTempleDataStatus = (state: IRootState) => getState(state).csvFileTempleDataStatus;
export const queueCsvFileTempleFileStatus = (state: IRootState) => getState(state).csvFileTempleFileStatus;
export const isQueueCsvFileTempleDataInProgress = (state: IRootState) =>
    queueCsvFileTempleDataStatus(state) === 'loading';
export const isQueueCsvFileTempleFileInProgress = (state: IRootState) =>
    queueCsvFileTempleFileStatus(state) === 'loading';
export const queueRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = queueSlice.actions;
