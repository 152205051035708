import { createAsyncThunk } from '@reduxjs/toolkit';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { create, fetchById, fetchList, fetchSelectList, remove, update } from '@/data/CallCenters/CallCenterApi';
import { ICallCenterCUModel } from '@/data/CallCenters/CallCenterModels';
import callCenterSlice from '@/data/CallCenters/CallCenterSlice';
import { IPaging } from '@/data/Paging';
import { IRootState } from '@/data/store';
import { getPermissionsList } from '@/data/System/SystemReducer';
import { PermissionsControllerEnum } from '@/utils/enums/PermissionsEnum';

export const fetchCallCenters = createAsyncThunk('callCenters/list', async (args: Partial<IPaging>, thunkApi) => {
    const currentPaging = (thunkApi.getState() as IRootState)[callCenterSlice.name].paging;

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchCallCentersForSelect = createAsyncThunk(
    'callCenters/select',
    async (args: { search: string; fields?: string[] }) => fetchSelectList(args.search, args.fields || []),
    {
        condition: (_, { getState }) => {
            const state = getState() as IRootState;
            const permissions = getPermissionsList(state);

            return isUserAllowed({ id: PermissionsControllerEnum.CallCenters, mode: Mode.READ }, permissions);
        }
    }
);

export const fetchCallCenterById = createAsyncThunk('callCenters/byId', async (args: number) => await fetchById(args));

export const createCallCenter = createAsyncThunk(
    'callCenters/create',
    async (args: ICallCenterCUModel) => await create(args)
);

export const updateCallCenter = createAsyncThunk(
    'callCenters/update',
    async (args: { id: number; data: Partial<ICallCenterCUModel> }) => await update(args.id, args.data)
);

export const removeCallCenter = createAsyncThunk('callCenters/remove', async (id: number) => remove(id));
