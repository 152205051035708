import Box from '@mui/material/Box';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarProps as DatatableGridToolbarProps,
    GridToolbarQuickFilter,
    useGridRootProps
} from '@mui/x-data-grid';
import { forwardRef, ReactElement } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button';

type IProps = {
    customButtonOnRight?: ReactElement;
    customAddButton?: ReactElement;
    disabledAdd: boolean;
    hasAddButton: boolean;
    hasSearch: boolean;
    labelAddButton?: string;
    onAddClick: () => void;
} & DatatableGridToolbarProps;

const CrudDatatableToolbar = forwardRef<HTMLDivElement, IProps>(function Inner(
    {
        csvOptions,
        customAddButton,
        customButtonOnRight,
        disabledAdd,
        hasAddButton,
        hasSearch,
        labelAddButton,
        printOptions,
        quickFilterProps,
        showQuickFilter,
        onAddClick,
        ...rest
    },
    ref
) {
    const { t } = useAppTranslation();
    const rootProps = useGridRootProps();

    if (
        rootProps.disableColumnFilter &&
        rootProps.disableColumnSelector &&
        rootProps.disableDensitySelector &&
        !showQuickFilter
    ) {
        return null;
    }

    return (
        <GridToolbarContainer
            ref={ref}
            sx={{
                p: 1,
                ...rest.sx
            }}
            {...rest}
        >
            {hasSearch && showQuickFilter && (
                <GridToolbarQuickFilter variant="outlined" size="small" {...quickFilterProps} />
            )}
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport csvOptions={csvOptions} printOptions={printOptions} />
            <Box sx={{ flex: 1 }} />
            {customButtonOnRight || <></>}
            {hasAddButton &&
                (customAddButton ? (
                    customAddButton
                ) : (
                    <Button name="add" variant="contained" disabled={disabledAdd} onClick={onAddClick}>
                        {labelAddButton ?? t('label.add', 'Add')}
                    </Button>
                ))}
        </GridToolbarContainer>
    );
});

export default CrudDatatableToolbar;
