import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchShifts, removeShift, updateShiftActive } from '@/data/Shifts/ShiftActions';
import { IShiftModel } from '@/data/Shifts/ShiftModels';
import { selectFilteredShifts, shiftPaging } from '@/data/Shifts/ShiftSlice';
import ShiftForm from '@/forms/ShiftForm';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import DateRange from '@/wrappers/Datatable/CellViews/DateRange';
import TimeRange from '@/wrappers/Datatable/CellViews/TimeRange';
import Weekdays from '@/wrappers/Datatable/CellViews/Weekdays';
import Switch from '@/wrappers/Switch';

const ShiftsListPage = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectFilteredShifts);

    return (
        <LayoutOfPage title={t('title.shiftsList', 'Shifts List')}>
            <CrudDatatable<IShiftModel>
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <ShiftForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    {
                        headerName: t('header.id', 'ID'),
                        field: 'id'
                    },
                    { headerName: t('header.shiftName', 'Shift Name'), field: 'name' },
                    {
                        headerName: t('header.days', 'Days'),
                        field: 'days',
                        sortable: false,
                        renderCell: ({ row }) => <Weekdays days={row.days} holidays={row.holiday} />
                    },
                    {
                        headerName: t('header.valid', 'Valid'),
                        field: 'valid',
                        type: 'date',
                        sortable: false,
                        renderCell: ({ row }) => <DateRange start={row.valid_start} end={row.valid_end} />
                    },
                    {
                        headerName: t('header.time', 'Time'),
                        field: 'time',
                        type: 'dateTime',
                        sortable: false,
                        renderCell: ({ row }) => (
                            <TimeRange
                                start={row.time_start}
                                end={DateHelper.fromTimeStringAndDuration(row.time_start, row.duration)}
                            />
                        )
                    },
                    {
                        headerName: t('header.description', 'Description'),
                        field: 'description'
                    },
                    {
                        headerName: t('header.abbreviation', 'Abbreviation'),
                        field: 'abbreviation'
                    },
                    {
                        headerName: t('header.color', 'Color'),
                        field: 'color',
                        type: 'color'
                    },
                    {
                        headerName: t('header.background', 'Background'),
                        field: 'background',
                        type: 'color'
                    },
                    {
                        headerName: t('header.active', 'Active'),
                        type: 'boolean',
                        field: 'active',
                        renderCell: ({ row }) => (
                            <Switch
                                name="active"
                                value={row.active}
                                title={
                                    row.active ? t('label.deactivate', 'Deactivate') : t('label.activate', 'Activate')
                                }
                                onChange={(value) => {
                                    dispatch(
                                        updateShiftActive({
                                            id: row.id,
                                            data: {
                                                active: value
                                            }
                                        })
                                    );
                                }}
                            />
                        )
                    },
                    {
                        headerName: t('header.allowShiftToOverlap', 'Allow Shifts to overlap'),
                        field: 'allow_overlapping_shifts',
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch
                                name="allow_overlapping_shifts"
                                value={row.allow_overlapping_shifts}
                                readOnly={true}
                            />
                        )
                    },
                    {
                        headerName: t('header.holiday', 'Holiday'),
                        field: 'holiday',
                        type: 'boolean',
                        renderCell: ({ row }) => <Switch name="holiday" value={row.holiday} readOnly={true} />
                    },
                    {
                        headerName: t('header.onCall', 'On Call'),
                        field: 'on_call',
                        type: 'boolean',
                        renderCell: ({ row }) => <Switch name="on_call" value={row.on_call} readOnly={true} />
                    },
                    {
                        headerName: t('header.createdAt', 'Created At'),
                        type: 'dateTime',
                        field: 'created'
                    },
                    {
                        headerName: t('header.updatedAt', 'Updated At'),
                        type: 'dateTime',
                        field: 'modified'
                    }
                ]}
                hiddenFields={[
                    'id',
                    'description',
                    'abbreviation',
                    'color',
                    'background',
                    'allow_overlapping_shifts',
                    'holiday',
                    'on_call',
                    'created',
                    'modified'
                ]}
                name="shift"
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(shiftPaging)}
                resource={PermissionsEnum.Shifts}
                onFetchList={fetchShifts}
                onRemove={(id) =>
                    dispatch(removeShift(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </LayoutOfPage>
    );
};

export default ShiftsListPage;
