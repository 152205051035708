import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from '@/data/store';
import config from '@/utils/config';

type ILanguageReducer = {
    language: string;
};

const initialState: ILanguageReducer = {
    language: config.defaultLocale
};

const languageReducer = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<ILanguageReducer>) => {
            state.language = action.payload.language;
        },
        unsetLanguage: (state) => {
            state.language = initialState.language;
        }
    }
});

export const languageInitialState = languageReducer.getInitialState();
export default languageReducer.reducer;
export const getLanguage = (state: IRootState) => state.language.language;
export const { setLanguage, unsetLanguage } = languageReducer.actions;
