import { useEffect, useMemo } from 'react';
import { getSchedulePlanApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchRolesForSelect } from '@/data/Roles/RoleActions';
import { rolesForSelect } from '@/data/Roles/RoleSlice';
import SchedulePlanApplicationSettingsForm from '@/forms/ApplicationsSettingsForms/SchedulePlanApplicationSettingsForm/SchedulePlanApplicationSettingsForm';
import { StyledApplicationSettingsActionPanel } from '@/forms/ApplicationsSettingsForms/utils';
import {
    getSettingsItemBooleanValueByKey,
    getSettingsItemJsonArrayByKey,
    getSettingsItemStringValueByKey
} from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import ApplicationSettingsSchedulePlanItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsSchedulePlanItemEnum';
import ChipStack from '@/wrappers/ChipStack';
import Select from '@/wrappers/Select';
import StackInformation from '@/wrappers/StackInformations';
import Switch from '@/wrappers/Switch';

const SchedulePlanApplicationSettingsFormsViewPage = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(getSchedulePlanApplicationsSettings);
    const rolesData = useAppSelector(rolesForSelect);

    useEffect(() => {
        dispatch(fetchRolesForSelect({ search: '' }));
    }, []);

    const canOpenValues = useMemo(() => {
        const values = getSettingsItemJsonArrayByKey(data, ApplicationSettingsSchedulePlanItemEnum.canOpenSchedulePlan);

        return rolesData
            .filter((item) => values.some((value) => value == `${item.id}`))
            .map((item) => ({
                id: `${item.id}`,
                label: item.name,
                color: 'primary'
            }));
    }, [data, rolesData]);

    const canCloseValues = useMemo(() => {
        const values = JSON.parse(
            getSettingsItemStringValueByKey(data, ApplicationSettingsSchedulePlanItemEnum.canCloseSchedulePlan) ?? '[]'
        ) as string[];

        return rolesData
            .filter((item) => values.some((value) => value == `${item.id}`))
            .map((item) => ({
                id: `${item.id}`,
                label: item.name,
                color: 'primary'
            }));
    }, [data, rolesData]);

    const canEditClosedPlan = useMemo(() => {
        const values = JSON.parse(
            getSettingsItemStringValueByKey(data, ApplicationSettingsSchedulePlanItemEnum.canEditClosedSchedulePlan) ??
                '[]'
        ) as string[];

        return rolesData
            .filter((item) => values.some((value) => value == `${item.id}`))
            .map((item) => ({
                id: `${item.id}`,
                label: item.name,
                color: 'primary'
            }));
    }, [data, rolesData]);

    return (
        <>
            <StyledApplicationSettingsActionPanel>
                <SchedulePlanApplicationSettingsForm />
            </StyledApplicationSettingsActionPanel>
            <StackInformation
                items={[
                    {
                        label: t('label.onHolidayInsertSetShiftAsEmpty', 'On Holiday Insert Set Shift As Empty'),
                        value: (
                            <Switch
                                name="onHolidayInsertSetShiftAsEmpty"
                                readOnly
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsSchedulePlanItemEnum.onHolidayInsertSetShiftAsEmpty
                                )}
                            />
                        )
                    },
                    {
                        label: t(
                            'label.dndShiftBetweenDays',
                            'When Drag & Drop Shift between dates move Original Shift into Empty Shift'
                        ),
                        value: (
                            <Switch
                                name="dndShiftBetweenDays"
                                readOnly
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsSchedulePlanItemEnum.dndShiftBetweenDays
                                )}
                            />
                        )
                    },
                    {
                        label: t('label.canTakeTradeWithoutCounteroffer', 'Can take trade without counteroffer'),
                        value: (
                            <Switch
                                name="canTakeTradeWithoutCounteroffer"
                                readOnly={true}
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsSchedulePlanItemEnum.canTakeTradeWithoutCounteroffer
                                )}
                            />
                        )
                    },
                    {
                        label: t('label.limitationOfAgentsAndEmptyShift', 'Limitation of Agents & Empty Shifts'),
                        value: (
                            <Select
                                name="limitationOfAgentsAndEmptyShift"
                                readOnly={true}
                                disabled={true}
                                options={[
                                    {
                                        id: 'default',
                                        label: t('label.noLimitation', 'No limitation (default)')
                                    },
                                    {
                                        id: 'before_date',
                                        label: t(
                                            'label.agentIsAbleToTakeEmptyShiftsOnlyAfterDate',
                                            'Agent is able to take Empty Shifts only after specific Date'
                                        )
                                    },
                                    {
                                        id: 'from_date',
                                        label: t(
                                            'label.agentIsAbleToSeeAndTakeEmptyShiftsOnlyAfterSpecificDate',
                                            'Agent is able to see and take Empty Shifts only after specific Date'
                                        )
                                    }
                                ]}
                                value={
                                    getSettingsItemStringValueByKey(
                                        data,
                                        ApplicationSettingsSchedulePlanItemEnum.limitationOfAgentsAndEmptyShifts
                                    ) ?? 'default'
                                }
                            />
                        ),
                        valueSx: {
                            flexGrow: 1
                        }
                    },
                    {
                        label: t(
                            'label.personWhoClosedSchedulePlanCanOpenSchedulePlan',
                            'Person Who Closed Schedule Plan Can Open Schedule Plan'
                        ),
                        value: (
                            <Switch
                                name="personWhoClosedSchedulePlanCanOpenSchedulePlan"
                                readOnly={true}
                                value={
                                    getSettingsItemBooleanValueByKey(
                                        data,
                                        ApplicationSettingsSchedulePlanItemEnum.personWhoClosedSchedulePlanCanOpenSchedulePlan
                                    ) ?? true
                                }
                            />
                        )
                    },
                    {
                        label: t('label.canOpen', 'Can Open'),
                        value: <ChipStack name="canClose" direction="row" readOnly values={canOpenValues} />
                    },
                    {
                        label: t('label.canClose', 'Can Close'),
                        value: <ChipStack name="canOpen" direction="row" readOnly values={canCloseValues} />
                    },
                    {
                        label: t('label.canEditClosedPlan', 'Can Edit Closed Plan'),
                        value: (
                            <ChipStack name="canEditClosedPlan" direction="row" readOnly values={canEditClosedPlan} />
                        )
                    }
                ]}
            />
        </>
    );
};

export default SchedulePlanApplicationSettingsFormsViewPage;
