import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { INotificationEmailModel } from '@/data/NotificationEmail/EmailNotificationModels';
import { fetchNotificationEmails, removeNotificationEmail } from '@/data/NotificationEmail/NotificationEmailActions';
import { notificationEmailList, notificationEmailPaging } from '@/data/NotificationEmail/NotificationEmailSlice';
import NotificationEmailForm from '@/forms/NotificationEmailsForm/NotificationEmailForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import ChipStack, { IChipStackValue } from '@/wrappers/ChipStack';

const NotificationSettingsFormViewPage = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(notificationEmailList);

    return (
        <>
            <CrudDatatable<INotificationEmailModel>
                name="notificationEmail"
                resource={PermissionsEnum.NotificationEmails}
                searchPlaceHolder={t('label.searchNotificationEmailType', 'Search Type of Notification Email')}
                nameOfEntity={(item) => item.type}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <NotificationEmailForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    { headerName: t('header.typeOfNotificationEmail', 'Type of Notification Email'), field: 'type' },
                    {
                        headerName: t('header.configuredTranslations', 'Configured Translations'),
                        field: 'configured_translations',
                        renderCell: ({ row }) => (
                            <ChipStack
                                direction="row"
                                name="configuredLanguages"
                                readOnly
                                values={Object.keys(row._translations).map<IChipStackValue>((translationKey) => ({
                                    id: translationKey,
                                    label: translationKey
                                }))}
                            />
                        )
                    }
                ]}
                hasSelection={false}
                data={data}
                paging={useAppSelector(notificationEmailPaging)}
                onFetchList={fetchNotificationEmails}
                onRemove={(id) => dispatch(removeNotificationEmail(id)).unwrap()}
            />
        </>
    );
};

export default NotificationSettingsFormViewPage;
