import { TimeValidationError } from '@mui/x-date-pickers';
import { message } from '@/utils/validations';

const TimePickerErrorMessages = (reason: TimeValidationError) => {
    switch (reason) {
        case 'invalidDate':
            return message.time.invalid;
        case 'minTime':
            return message.time.minTime;
        case 'maxTime':
            return message.time.maxTime;
        default:
            return '';
    }
};

export default TimePickerErrorMessages;
