import Box from '@mui/material/Box';
import { WithRequiredProp } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { useCallback } from 'react';
import theme from '@/assets/theme';
import CrudDatatable from '@/components/CrudDatatable';
import { contractsForSelect } from '@/data/Contracts/ContractSlice';
import { useAppSelector } from '@/data/hooks';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import UserToContractForm from '@/forms/UserToContractForm';
import { IUserToContractFormDataType } from '@/forms/UserToContractForm/UserToContractForm';
import { normalizeFloatNumber } from '@/helpers/number';
import useAppTranslation from '@/hooks/useAppTranslation';
import Table from '@/wrappers/Table';

type IContracts = {
    data: WithRequiredProp<IUserToContractFormDataType, 'id'>[];
    handleData: (value: WithRequiredProp<IUserToContractFormDataType, 'id'>[]) => void;
};

const ContractsTab = ({ data, handleData }: IContracts) => {
    const { t } = useAppTranslation();
    const contractsForSelectData = useAppSelector(contractsForSelect) ?? [];
    const isSystemUser = useAppSelector(isSignedUserUser);

    const handleUpdateData = useCallback(
        (id: number | string, values: WithRequiredProp<IUserToContractFormDataType, 'id'>) => {
            handleData([...data.filter((item) => item.id !== id), { ...values, id: id }]);
        },
        [data]
    );

    const handleCreateData = useCallback(
        (values: WithRequiredProp<IUserToContractFormDataType, 'id'>) => {
            handleData([...data, { ...values }]);
        },
        [data]
    );

    const handleDeleteData = useCallback(
        (id: number | string) => {
            handleData(data.filter((item) => item.id !== id));

            return Promise.resolve();
        },
        [data]
    );

    return (
        <CrudDatatable<WithRequiredProp<IUserToContractFormDataType, 'id'>, number | string>
            autoHeight={false}
            data={data}
            detailPanel={(rowData) => {
                const index = data.findIndex((item) => item.id === rowData.id);

                return (
                    <Table
                        data-testid={`detailPanelTable_${index}`}
                        name={`detail_of_user_to_contract_${rowData.id}`}
                        rows={[
                            {
                                id: t('label.dayHoursLimit', 'Day Hours Limit'),
                                value: rowData.day_hours_limit,
                                id2: t('label.weekHoursLimit', 'Week Hours Limit'),
                                value2: rowData.week_hours_limit,
                                id3: t('label.numberOfHoursPerDay', 'Number Of Hours Per Day'),
                                value3: rowData.number_of_hours_per_day
                            },
                            {
                                id: t('label.minContinuousRestPerWeek', 'Min Continuous Rest Per Week'),
                                value: rowData.min_continuous_rest_per_week,
                                id2: t('label.minBreakHoursPerDay', 'Min Break Hours Per Day'),
                                value2: rowData.min_break_hours_per_day,
                                id3: t('label.minBreakHoursPerDaySplitted', 'Min Break Hours Per Day Splitted'),
                                value3: rowData.min_break_hours_per_day_splitted
                            },
                            {
                                id: t('label.breakAtLeastAfterHours', 'Break At Least After Hours'),
                                value: rowData.break_at_least_after_hours,
                                id2: t('label.monthHoursLimit', 'Month Hours Limit'),
                                value2: rowData.month_hours_limit,
                                id3: '',
                                value3: ''
                            }
                        ]}
                        columns={[
                            {
                                id: 'id',
                                label: 'id',
                                access: (subRowData) => (subRowData.id ? <Box>{subRowData.id}:</Box> : <></>)
                            },
                            {
                                id: 'value',
                                label: 'value',
                                access: (subRowData) =>
                                    (typeof subRowData.value === 'number' && subRowData.value === 0) ||
                                    subRowData.value ? (
                                        <Box>{normalizeFloatNumber(subRowData.value)}</Box>
                                    ) : (
                                        <></>
                                    )
                            },
                            {
                                id: 'id2',
                                label: 'id2',
                                access: (subRowData) => (subRowData.id2 ? <Box>{subRowData.id2}:</Box> : <></>)
                            },
                            {
                                id: 'value2',
                                label: 'value2',
                                access: (subRowData) =>
                                    (typeof subRowData.value2 === 'number' && subRowData.value2 === 0) ||
                                    subRowData.value2 ? (
                                        <Box>{normalizeFloatNumber(subRowData.value2)}</Box>
                                    ) : (
                                        <></>
                                    )
                            },
                            {
                                id: 'id3',
                                label: 'id3',
                                access: (subRowData) => (subRowData.id3 ? <Box>{subRowData.id3}:</Box> : <></>)
                            },
                            {
                                id: 'value3',
                                label: 'value3',
                                access: (subRowData) =>
                                    (typeof subRowData.value3 === 'number' && subRowData.value3 === 0) ||
                                    subRowData.value3 ? (
                                        <Box>{normalizeFloatNumber(subRowData.value3)}</Box>
                                    ) : (
                                        <></>
                                    )
                            }
                        ]}
                        columnProps={(columnId) =>
                            columnId === 'id' || columnId === 'id2' || columnId === 'id3' ? { width: '20%' } : {}
                        }
                        rowProps={{
                            sx: {
                                backgroundColor: theme.palette.grey.A200,
                                borderTop: '1px solid ' + theme.palette.grey.A700
                            }
                        }}
                        showHeader={false}
                    />
                );
            }}
            disabled={isSystemUser}
            formRender={(id, justIcon, openButtonValue, rowData, renderButton) => (
                <UserToContractForm
                    onDataChanged={(values) => (id ? handleUpdateData(id, values) : handleCreateData(values))}
                    onRemove={handleDeleteData}
                    data={rowData}
                    props={{
                        displayAsSidebar: true,
                        openButtonRender: renderButton,
                        justIcon,
                        id,
                        openButtonValue
                    }}
                />
            )}
            header={[
                {
                    field: 'contract_id',
                    headerName: t('header.contractName', 'Contract Name'),
                    renderCell: ({ row }) =>
                        contractsForSelectData.find((item) => item.id === row.contract_id)?.name ?? row.contract_id
                },
                {
                    field: 'start_at',
                    headerName: t('header.contractStart', 'Contract Start'),
                    type: 'date'
                },
                {
                    field: 'end_at',
                    headerName: t('header.contractEnd', 'Contract End'),
                    type: 'date'
                },
                {
                    field: 'days',
                    headerName: t('header.days', 'Days'),
                    type: 'days'
                }
            ]}
            hasSelection={false}
            hasSearch={false}
            name="userToContracts"
            nameOfEntity={(item) => `${item.contract_id}_${item.id}`}
            sortingMode="client"
            onRemove={handleDeleteData}
        />
    );
};

export default ContractsTab;
