import CrudDatatable from '@/components/CrudDatatable';
import { fetchCallCenters, removeCallCenter } from '@/data/CallCenters/CallCenterActions';
import { callCenterPaging, selectFilteredCallCenters } from '@/data/CallCenters/CallCenterSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import CallCenterForm from '@/forms/CallCentersForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';

export default function CallCentersListPage() {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const entities = useAppSelector(selectFilteredCallCenters);
    const data = Object.values(entities);

    return (
        <LayoutOfPage title={t('title.callCentersList', 'Call Centers List')}>
            <CrudDatatable
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <CallCenterForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    {
                        headerName: t('header.name', 'Name'),
                        field: 'name',
                        flex: 1
                    },
                    {
                        headerName: t('header.url', 'URL'),
                        field: 'url',
                        flex: 1
                    }
                ]}
                name="callCenter"
                nameOfEntity={(item) => `${item.id}`}
                paging={useAppSelector(callCenterPaging)}
                resource={PermissionsEnum.CallCenters}
                onFetchList={fetchCallCenters}
                onRemove={(id) =>
                    dispatch(removeCallCenter(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </LayoutOfPage>
    );
}
