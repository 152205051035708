import { Fragment, Ref, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '@/data/hooks';
import { requestTypeAll } from '@/data/RequestTypes/RequestTypeSlice';
import { selectWorkplaceOfPlan } from '@/data/SchedulePlans/SchedulePlanSlice';
import { ISchedulerUserToRequestModel } from '@/data/UserToRequests/UserToRequestModels';
import DateHelper from '@/helpers/date/DateHelper';
import { fillPrefixDate } from '@/helpers/scheduleWorker';
import {
    IMergedColumn,
    IMergeRow,
    ISimpleColumn,
    RenderAbleBlock
} from '@/modules/Scheduler/components/SchedulerCalendarDataRow/SchedulerCalendarDataRow';
import {
    ISchedulerRequestBodyCellProps,
    ISchedulerRequestBodyDataRowsCellCallableRef
} from '@/modules/Scheduler/components/SchedulerRequestBody/DataCellRow/Cell/SchedulerRequestBodyDataRowsCell';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import Cell from './Cell';

type IProps = Pick<ISchedulerRequestBodyCellProps, 'from' | 'schedulePlanId'> & {
    userId: number;
    usersRequestsList: ISchedulerUserToRequestModel[];
    to: ISchedulerRequestBodyCellProps['from'];
    isDayMode: boolean;
    timeZone: string;
    innerRef?: Ref<ICallableRef>;
    onLoadingStart?: () => void;
    onLoadingFinished?: () => void;
};

export type ICallableRef = {
    nextRequest: () => void;
};

const SchedulerRequestBodyDataRow = ({
    from,
    to,
    usersRequestsList,
    userId,
    schedulePlanId,
    isDayMode,
    timeZone,
    innerRef,
    onLoadingStart,
    onLoadingFinished
}: IProps) => {
    const requestData = usersRequestsList.filter((request) => request.user_id === userId);
    const workplace = useAppSelector((state) => selectWorkplaceOfPlan(state, schedulePlanId));
    const requestTypeList = useAppSelector(requestTypeAll) ?? [];
    const findRequestType = (requestTypeId: string) =>
        requestTypeList.find((requestType) => `${requestType.id}` === requestTypeId);

    const isShift = (requestTypeId: string) => findRequestType(requestTypeId)?.type === RequestTypeEnum.shifts;
    const [scrollRefIndex, setScrollRefIndex] = useState(0);
    const scrollRef = useRef<ISchedulerRequestBodyDataRowsCellCallableRef>([]);

    useImperativeHandle(innerRef, () => ({
        nextRequest: () => {
            const focusIndexKeys = Object.keys(scrollRef.current ?? []);
            const currentLength = focusIndexKeys.length ?? 0;
            let element: HTMLDivElement | null = null;
            let index = scrollRefIndex;
            let whileBreak = 0;

            do {
                const scrollRefFocusIndex = parseInt(focusIndexKeys[index]);

                element = scrollRef.current[scrollRefFocusIndex];
                index = index < currentLength - 1 ? index + 1 : 0;
                whileBreak = whileBreak + 1;
            } while (!element && whileBreak <= currentLength);

            element?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
            setScrollRefIndex(index);
        }
    }));

    const columns = useMemo(() => {
        const data = requestData
            .filter((request) => {
                const isValid =
                    !(isShift(`${request.request_type_id}`) && workplace) || workplace.id == request.workplace_id;

                return (
                    isValid &&
                    request.user_id == userId &&
                    (DateHelper.isBetween(request.datetime_from, from, to) ||
                        DateHelper.isBetween(request.datetime_to, from, to))
                );
            })
            .map((request, requestIndex) => ({
                ...request,
                hasOverride: requestData.some(
                    (subRequest, subRequestIndex) =>
                        subRequestIndex !== requestIndex &&
                        (DateHelper.isBetween(
                            subRequest.datetime_from,
                            request.datetime_from,
                            request.datetime_to,
                            '[)'
                        ) ||
                            DateHelper.isBetween(
                                request.datetime_from,
                                subRequest.datetime_from,
                                subRequest.datetime_to,
                                '[)'
                            ))
                )
            }))
            .sort((a, b) => (DateHelper.isBefore(a.datetime_from, b.datetime_from) ? -1 : 1))
            .map(
                ({ hasOverride, ...restItem }) =>
                    ({
                        uId: `request_${restItem.id}`,
                        id: restItem.id,
                        from: DateHelper.isBefore(restItem.datetime_from, from)
                            ? from.toDate()
                            : restItem.datetime_from.toDate(),
                        to: DateHelper.isEqual(restItem.datetime_from, restItem.datetime_to)
                            ? DateHelper.addMinutes(restItem.datetime_to, 15).toDate()
                            : DateHelper.isAfter(restItem.datetime_to, to)
                            ? to.toDate()
                            : restItem.datetime_to.toDate(),
                        toMerge: hasOverride,
                        items: [
                            {
                                uId: `request_${restItem.id}`,
                                type: 'request',
                                id: restItem.id,
                                from: DateHelper.isBefore(restItem.datetime_from, from)
                                    ? from.toDate()
                                    : restItem.datetime_from.toDate(),
                                to: DateHelper.isEqual(restItem.datetime_from, restItem.datetime_to)
                                    ? DateHelper.addMinutes(restItem.datetime_to, 15).toDate()
                                    : DateHelper.isAfter(restItem.datetime_to, to)
                                    ? to.toDate()
                                    : restItem.datetime_to.toDate()
                            }
                        ]
                    }) as RenderAbleBlock
            )
            .reduce<RenderAbleBlock[]>((previousValue, currentValue) => {
                if (previousValue.length === 0) {
                    return [currentValue];
                } else if (!currentValue.toMerge) {
                    return [...previousValue, currentValue];
                } else {
                    let override = false;
                    const result = previousValue.map((item) => {
                        const overridingThisItem =
                            DateHelper.isBetween(currentValue.from, item.from, item.to, '[)') ||
                            DateHelper.isBetween(item.from, currentValue.from, currentValue.to, '[)');

                        if (overridingThisItem) {
                            override = true;

                            return {
                                ...item,
                                to: DateHelper.isAfter(currentValue.to, item.to) ? currentValue.to : item.to,
                                items: [...item.items, ...currentValue.items]
                            };
                        } else {
                            return item;
                        }
                    });

                    return override ? result : [...previousValue, currentValue];
                }
            }, []);

        let start = from.toDate();

        const result: (ISimpleColumn | IMergedColumn)[] = [];
        let startColumn = 0;

        if (data.length !== 0) {
            data.forEach((dataItem) => {
                result.push(
                    ...fillPrefixDate(
                        startColumn,
                        DateHelper.getInstanceOf(start),
                        DateHelper.getInstanceOf(dataItem.from),
                        isDayMode,
                        timeZone
                    ).map((item) => ({
                        ...item,
                        from: DateHelper.toDate(item.from),
                        to: DateHelper.toDate(item.to),
                        data: {
                            ...item.data,
                            from: DateHelper.toDate(item.data.from),
                            to: DateHelper.toDate(item.data.to)
                        }
                    }))
                );
                start = dataItem.from;

                if (DateHelper.isBefore(start, from.toDate())) {
                    start = from.toDate();
                } else if (result.length) {
                    const last = result[result.length - 1];

                    startColumn = last.columnStart + last.width;
                }

                const width = Math.ceil(DateHelper.getDifferenceAsMinutes(start, dataItem.to) / 15);

                if (dataItem.items.length === 1) {
                    result.push({
                        uId: dataItem.uId,
                        type: 'simple',
                        columnStart: startColumn,
                        from: start,
                        to: dataItem.to,
                        width,
                        data: {
                            uId: dataItem.uId,
                            id: dataItem.id,
                            from: start,
                            type: 'request',
                            to: dataItem.to,
                            paddingRight: dataItem.items[0].extra_padding_right,
                            shiftId: dataItem.items[0].shift_id,
                            shiftName: dataItem.items[0].shift_name,
                            userId: dataItem.items[0].user_id,
                            abbreviation: null,
                            abbreviationColor: null,
                            abbreviationBackgroundColor: null,
                            width
                        }
                    });

                    start = dataItem.to;
                    startColumn += width;
                } else {
                    const mappedRows: IMergeRow[] = [];

                    dataItem.items
                        .reduce<IMergeRow[]>((previousValue, currentValue) => {
                            if (previousValue.length === 0) {
                                return [
                                    {
                                        uId: 'row-0',
                                        from: currentValue.from,
                                        to: currentValue.to,
                                        data: [
                                            {
                                                from: currentValue.from,
                                                id: currentValue.id,
                                                to: currentValue.to,
                                                uId: `${currentValue.id}`,
                                                type: currentValue.type,
                                                shiftId: currentValue.shift_id,
                                                userId: currentValue.user_id,
                                                width: 0
                                            }
                                        ]
                                    } as IMergeRow
                                ];
                            } else {
                                let isMerged = false;

                                const newValue: IMergeRow[] = previousValue.map((row) => {
                                    const overridingThisItem =
                                        DateHelper.isBetween(currentValue.from, row.from, row.to, '[]') ||
                                        DateHelper.isBetween(row.from, currentValue.from, currentValue.to, '[]');

                                    if (!overridingThisItem) {
                                        isMerged = true;

                                        return {
                                            ...row,
                                            to: DateHelper.isBefore(row.to, currentValue.to) ? currentValue.to : row.to,
                                            data: [
                                                ...row.data,
                                                {
                                                    ...currentValue,
                                                    uId: `${currentValue.id}`,
                                                    type: 'request',
                                                    width: 0
                                                }
                                            ]
                                        } as IMergeRow;
                                    } else {
                                        return row;
                                    }
                                });

                                if (!isMerged) {
                                    return [
                                        ...previousValue,
                                        {
                                            uId: `row-${previousValue.length}`,
                                            from: currentValue.from,
                                            to: currentValue.to,
                                            data: [
                                                {
                                                    from: currentValue.from,
                                                    id: currentValue.id,
                                                    to: currentValue.to,
                                                    uId: `${currentValue.id}`,
                                                    type: 'request',
                                                    shiftId: currentValue.shift_id,
                                                    userId: currentValue.user_id,
                                                    width: 0
                                                }
                                            ]
                                        } as IMergeRow
                                    ];
                                } else return newValue;
                            }
                        }, [] as IMergeRow[])
                        .forEach((row) => {
                            let rowStart = start;
                            const mapped: IMergeRow['data'] = [];

                            row.data.forEach((rowItem) => {
                                mapped.push(
                                    ...fillPrefixDate(
                                        startColumn,
                                        DateHelper.getInstanceOf(rowStart),
                                        DateHelper.getInstanceOf(rowItem.from),
                                        isDayMode,
                                        timeZone
                                    ).map((item) => ({
                                        ...item.data,
                                        from: DateHelper.toDate(item.data.from),
                                        to: DateHelper.toDate(item.data.to)
                                    }))
                                );

                                const overMidnight = DateHelper.isBefore(dataItem.from, start);

                                if (!overMidnight) {
                                    mapped.push({
                                        ...rowItem,
                                        width: Math.ceil(
                                            DateHelper.getDifferenceAsMinutes(rowItem.from, rowItem.to) / 15
                                        )
                                    });
                                }

                                rowStart = rowItem.to;
                            });
                            mapped.push(
                                ...fillPrefixDate(
                                    startColumn,
                                    DateHelper.getInstanceOf(row.to),
                                    DateHelper.getInstanceOf(dataItem.to),
                                    isDayMode,
                                    timeZone
                                ).map((item) => ({
                                    ...item.data,
                                    from: DateHelper.toDate(item.data.from),
                                    to: DateHelper.toDate(item.data.to)
                                }))
                            );

                            if (mapped.length) {
                                mappedRows.push({
                                    uId: row.uId,
                                    from: row.from,
                                    to: row.to,
                                    data: mapped
                                });
                            }
                        });

                    start = dataItem.to;

                    if (mappedRows.length) {
                        result.push({
                            uId: dataItem.uId,
                            type: 'merged',
                            columnStart: startColumn,
                            from: dataItem.from,
                            to: dataItem.to,
                            width,
                            data: mappedRows
                        });
                    }

                    startColumn += width;
                }
            });
            result.push(
                ...fillPrefixDate(startColumn, DateHelper.getInstanceOf(start), to, isDayMode, timeZone).map(
                    (item) => ({
                        ...item,
                        from: DateHelper.toDate(item.from),
                        to: DateHelper.toDate(item.to),
                        data: {
                            ...item.data,
                            from: DateHelper.toDate(item.data.from),
                            to: DateHelper.toDate(item.data.to)
                        }
                    })
                )
            );
        } else {
            result.push(
                ...fillPrefixDate(startColumn, from, to, isDayMode, timeZone).map((item) => ({
                    ...item,
                    from: DateHelper.toDate(item.from),
                    to: DateHelper.toDate(item.to),
                    data: {
                        ...item.data,
                        from: DateHelper.toDate(item.data.from),
                        to: DateHelper.toDate(item.data.to)
                    }
                }))
            );
        }

        return result;
    }, [from, to, isDayMode, timeZone, workplace]);

    return (
        <>
            {columns.map((wrappedItem, itemIndex) => (
                <Fragment key={`${wrappedItem.uId}_${itemIndex}`}>
                    <Cell
                        innerRef={scrollRef}
                        columnData={wrappedItem}
                        schedulePlanId={schedulePlanId}
                        from={from}
                        timeZone={timeZone}
                        onLoadingStart={onLoadingStart}
                        onLoadingFinished={onLoadingFinished}
                    />
                </Fragment>
            ))}
        </>
    );
};

export default SchedulerRequestBodyDataRow;
