import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchPeriodsForSelect } from '@/data/Periods/PeriodActions';
import { periodsForSelect } from '@/data/Periods/PeriodSlice';
import { fetchUsersAttendanceReport, getUsersAttendanceReportFile } from '@/data/Reports/ReportActions';
import { IUserAttendanceReportResponse } from '@/data/Reports/ReportModels';
import {
    isUsersAttendancesFileReportStatusLoading,
    isUsersAttendancesReportStatusLoading,
    usersAttendancesReport
} from '@/data/Reports/ReportSlice';
import { fetchUsersForSelect } from '@/data/Users/UserActions';
import { usersForSelect } from '@/data/Users/UserSlice';
import { fetchWorkplacesForSelect } from '@/data/Workplaces/WorkplaceActions';
import { workplacesForSelect } from '@/data/Workplaces/WorkplaceSlice';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import UserAttendanceReportTypesEnum from '@/utils/enums/UserAttendanceReportTypesEnum';
import { serializeUser } from '@/utils/UserHelper';
import Button from '@/wrappers/Button/Button';
import Datatable, { IDatatableProps } from '@/wrappers/Datatable';
import DateRangePicker from '@/wrappers/DateRangePicker';
import Dialog from '@/wrappers/Dialog';
import LoadingButton from '@/wrappers/LoadingButton';
import MultiSelect from '@/wrappers/MultiSelect';
import Switch from '@/wrappers/Switch';

const StyledTitle = styled(Box)`
    text-align: center;
`;

const StyledSwitchBox = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -4em;
`;

const StyledDialogContent = styled(Box)`
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 1em;
`;

const StyledBoxForFilters = styled(Box)`
    padding-top: 1em;
    display: grid;
    gap: 10px;
`;
const StyledBoxForFiltersRow = styled(Box)`
    padding-top: 1em;
    display: flex;
    gap: 10px;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
    margin-top: 1em;
    margin-bottom: 1em;
`;

type IProps = {
    open: boolean;
    onDialogClose: () => void;
};

const UserAttendanceReportDialog = ({ open, onDialogClose }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(usersAttendancesReport);
    const periodsData = useAppSelector(periodsForSelect);
    const workplacesData = useAppSelector(workplacesForSelect);
    const usersData = useAppSelector(usersForSelect);
    const isDownloadCsvLoading = useAppSelector(isUsersAttendancesFileReportStatusLoading);
    const [workplaceIdsList, setWorkplaceIdsList] = useState<number[]>([]);
    const [periodIdsList, setPeriodIdsList] = useState<number[]>([]);
    const [userIdsList, setUserIdsList] = useState<number[]>([]);
    const [validFromDate, setValidFromDate] = useState<Dayjs | undefined>(DateHelper.firstDayOfCurrentMonth());
    const [validToDate, setValidToDate] = useState<Dayjs | undefined>(DateHelper.lastDayOfCurrentMonth());
    const [showFilter, setShowFilter] = useState(true);

    const [userAttendanceReportType, setUserAttendanceReportType] = useState<UserAttendanceReportTypesEnum>(
        UserAttendanceReportTypesEnum.Hours
    );
    const handleChangeAttendanceReportType = useCallback((value: string | null) => {
        setUserAttendanceReportType(
            value ? (value as UserAttendanceReportTypesEnum) : UserAttendanceReportTypesEnum.Hours
        );
    }, []);
    const handleDownloadCsvFileOfUsersReport = useCallback(() => {
        if (open) {
            dispatch(
                getUsersAttendanceReportFile({
                    type: userAttendanceReportType,
                    fromDate: validFromDate?.toISOString(),
                    toDate: validToDate?.toISOString(),
                    workplaceIdsList: workplaceIdsList,
                    periodIdsList: periodIdsList,
                    userIdsList: userIdsList
                })
            );
        }
    }, [open, userAttendanceReportType, workplaceIdsList, periodIdsList, userIdsList, validFromDate, validToDate]);
    const handleOpen = useCallback(() => {
        dispatch(fetchWorkplacesForSelect({ search: '' }));
        dispatch(fetchPeriodsForSelect({ search: '' }));
        dispatch(fetchUsersForSelect({ search: '' }));
    }, []);
    const handleClose = useCallback(() => {
        onDialogClose();

        setValidFromDate(DateHelper.firstDayOfCurrentMonth());
        setValidToDate(DateHelper.lastDayOfCurrentMonth());
        setWorkplaceIdsList([]);
        setPeriodIdsList([]);
        setUserIdsList([]);
        setShowFilter(true);
    }, []);

    const headerMap = [
        { field: 'planed_shifts', titleKey: 'planedShifts', titleDefault: 'Planed Shifts' },
        { field: 'on_call_shifts', titleKey: 'onCallShifts', titleDefault: 'On Call Shifts' },
        {
            field: 'without_definition_shifts',
            titleKey: 'withoutDefinitionShifts',
            titleDefault: 'Without Definition Shifts'
        },
        { field: 'vacation', titleKey: 'vacation', titleDefault: 'Vacation' }
    ];
    const getDatatableHeaders = useCallback((): IDatatableProps<IUserAttendanceReportResponse>['header'] => {
        const headerMapResult: ArrayElement<IDatatableProps<IUserAttendanceReportResponse>['header']>[] = [];

        headerMap.forEach((headerItem) => {
            if (
                [UserAttendanceReportTypesEnum.Hours, UserAttendanceReportTypesEnum.DaysAndHours].some(
                    (item) => item === userAttendanceReportType
                )
            ) {
                headerMapResult.push({
                    headerName: t(
                        `header.${headerItem.titleKey}` +
                            (userAttendanceReportType === UserAttendanceReportTypesEnum.DaysAndHours ? 'Hours' : ''),
                        headerItem.titleDefault +
                            (userAttendanceReportType === UserAttendanceReportTypesEnum.DaysAndHours ? ' Hours' : '')
                    ),
                    field: `${headerItem.field}_hours`
                });
            }

            if (
                [UserAttendanceReportTypesEnum.Days, UserAttendanceReportTypesEnum.DaysAndHours].some(
                    (item) => item === userAttendanceReportType
                )
            ) {
                headerMapResult.push({
                    headerName: t(
                        `header.${headerItem.titleKey}` +
                            (userAttendanceReportType === UserAttendanceReportTypesEnum.DaysAndHours ? 'Days' : ''),
                        headerItem.titleDefault +
                            (userAttendanceReportType === UserAttendanceReportTypesEnum.DaysAndHours ? ' Days' : '')
                    ),
                    field: `${headerItem.field}_days`
                });
            }
        });

        return headerMapResult;
    }, [userAttendanceReportType]);

    useEffect(() => {
        if (open) {
            dispatch(
                fetchUsersAttendanceReport({
                    fromDate: validFromDate?.toISOString(),
                    toDate: validToDate?.toISOString(),
                    workplaceIdsList: workplaceIdsList,
                    periodIdsList: periodIdsList,
                    userIdsList: userIdsList
                })
            );
        }
    }, [open, workplaceIdsList, periodIdsList, userIdsList, validFromDate, validToDate]);

    return (
        <Dialog
            name="userAttendanceReportDialog"
            openButtonValue={undefined}
            openButton={undefined}
            title={<StyledTitle>{t('label.attendanceReport', 'attendance Report')}</StyledTitle>}
            maxWidth="xl"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            fullWidth
            actions={(onClose) => (
                <>
                    <Button
                        name="no"
                        variant="outlined"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t('label.close', 'Close')}
                    </Button>
                    <LoadingButton
                        name="downloadCsv"
                        variant="contained"
                        color="primary"
                        loading={isDownloadCsvLoading}
                        onClick={handleDownloadCsvFileOfUsersReport}
                    >
                        {t('label.downloadReportCsvFile', 'Download Report Csv File')}
                    </LoadingButton>
                </>
            )}
        >
            <StyledDialogContent>
                <Box>
                    <StyledBottomNavigation
                        showLabels
                        value={userAttendanceReportType}
                        onChange={(event, value) => {
                            handleChangeAttendanceReportType(value);
                        }}
                        data-testid="summarizationDialogTypeNavigation"
                    >
                        <BottomNavigationAction
                            label={t('label.hours', 'Hours')}
                            value={UserAttendanceReportTypesEnum.Hours}
                            icon={<QueryBuilderOutlinedIcon />}
                        />
                        <BottomNavigationAction
                            label={t('label.days', 'Days')}
                            value={UserAttendanceReportTypesEnum.Days}
                            icon={<DateRangeOutlinedIcon />}
                        />
                        <BottomNavigationAction
                            label={t('label.daysAndHours', 'Days & Hours')}
                            value={UserAttendanceReportTypesEnum.DaysAndHours}
                            icon={<CalendarMonthIcon />}
                        />
                    </StyledBottomNavigation>
                    <StyledSwitchBox>
                        <Switch
                            name="showFilter"
                            label={t('label.showFilters', 'Show Filters')}
                            value={showFilter}
                            onChange={() => setShowFilter(!showFilter)}
                        />
                    </StyledSwitchBox>
                </Box>
                <Collapse in={showFilter}>
                    <StyledBoxForFilters>
                        <DateRangePicker
                            name="date_range"
                            size="small"
                            value={{
                                start: validFromDate ?? null,
                                end: validToDate ?? null
                            }}
                            onChange={(value) => {
                                setValidFromDate(value.start ?? undefined);
                                setValidToDate(value.end ?? undefined);
                            }}
                        />
                        <StyledBoxForFiltersRow>
                            <MultiSelect
                                name="workplace"
                                label={t('label.workplace', 'Workplace')}
                                options={workplacesData.map((workplace) => ({
                                    id: workplace.id.toString(),
                                    label: workplace.name
                                }))}
                                value={workplacesData
                                    .filter((workplace) =>
                                        workplaceIdsList.some((workplaceId) => workplaceId === workplace.id)
                                    )
                                    .map((workplace) => workplace.id.toString())}
                                onChange={(value: string[] | null) =>
                                    setWorkplaceIdsList(value ? value.map((item) => parseInt(item)) : [])
                                }
                            />
                            <MultiSelect
                                name="period"
                                label={t('label.period', 'Period')}
                                options={periodsData.map((period) => ({
                                    id: period.id.toString(),
                                    label: period.name
                                }))}
                                value={periodsData
                                    .filter((period) => periodIdsList.some((periodId) => periodId === period.id))
                                    .map((period) => period.id.toString())}
                                onChange={(value: string[] | null) =>
                                    setPeriodIdsList(value ? value.map((item) => parseInt(item)) : [])
                                }
                            />
                        </StyledBoxForFiltersRow>
                        <StyledBoxForFiltersRow>
                            <MultiSelect
                                name="user"
                                label={t('label.user', 'User')}
                                options={usersData.map((user) => ({
                                    id: user.id.toString(),
                                    label: serializeUser(user)
                                }))}
                                onChange={(value: string[] | null) =>
                                    setUserIdsList(value ? value.map((item) => parseInt(item)) : [])
                                }
                                value={usersData
                                    .filter((user) => userIdsList.some((userId) => userId === user.id))
                                    .map((user) => user.id.toString())}
                            />
                        </StyledBoxForFiltersRow>
                    </StyledBoxForFilters>
                </Collapse>
                <Divider orientation="horizontal" />
                <Collapse in={!useAppSelector(isUsersAttendancesReportStatusLoading)}>
                    <Datatable
                        header={[
                            {
                                headerName: t('header.user', 'User'),
                                field: 'user_name'
                            },
                            ...getDatatableHeaders()
                        ]}
                        data={data}
                        hasTitle={false}
                        hasSearch={false}
                        hasSelection={false}
                        hasSorting={false}
                        hasPaginating={false}
                        loading={useAppSelector(isUsersAttendancesReportStatusLoading)}
                    />
                </Collapse>
                <Collapse in={useAppSelector(isUsersAttendancesReportStatusLoading)}>
                    <Skeleton animation="wave" variant="rectangular" width="100%" height="2em" />
                </Collapse>
            </StyledDialogContent>
        </Dialog>
    );
};

export default UserAttendanceReportDialog;
