import { useAppSelector } from '@/data/hooks';
import { shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import { isUserShiftsSummariesLoading, userShiftsSummariesList } from '@/data/Summaries/SummariesSlice';
import { usersForSelect } from '@/data/Users/UserSlice';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import useAppTranslation from '@/hooks/useAppTranslation';
import { serializeUser } from '@/utils/UserHelper';
import Datatable, { IDatatableProps } from '@/wrappers/Datatable';

const Shifts = () => {
    const { t } = useAppTranslation();
    const usersData = useAppSelector(usersForSelect);
    const summarizationUserData = useAppSelector(userShiftsSummariesList);
    const shiftsData = useAppSelector(shiftsForSelect) ?? [];

    return (
        <Datatable
            getRowId={(row) => row.user_id}
            header={[
                {
                    headerName: t('label.user', 'User'),
                    field: 'name',
                    renderCell: ({ row }) => serializeUser(usersData.find((user) => user.id === row.user_id))
                },
                ...(shiftsData
                    .filter((shift) =>
                        summarizationUserData.some((summaryUser) => {
                            return summaryUser.summary.some((summary) => summary.shift_id === shift.id);
                        })
                    )
                    .map((shift) => ({
                        headerName: shift.name,
                        field: `shift_${shift.id}`,
                        renderCell: ({ row }) =>
                            row.summary.find((entity) => entity.shift_id === shift.id)?.number_of_shift ?? 0
                    })) as IDatatableProps<ArrayElement<typeof summarizationUserData>>['header'])
            ]}
            data={summarizationUserData}
            hasTitle={false}
            hasSearch={false}
            hasSelection={false}
            hasSorting={false}
            hasPaginating={false}
            loading={useAppSelector(isUserShiftsSummariesLoading)}
        />
    );
};

export default Shifts;
