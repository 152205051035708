import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { AreaChart, Brush, ResponsiveContainer, XAxis } from 'recharts';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchSchedulePlansForecastAccuracyReportPredictedTime } from '@/data/Reports/ReportActions';
import {
    getReportSchedulePlanId,
    schedulePlansPredictedTimeModifiedForecastAccuracyReport
} from '@/data/Reports/ReportSlice';
import { schedulePlanList } from '@/data/SchedulePlans/SchedulePlanSlice';
import { fetchWorkplaceById } from '@/data/Workplaces/WorkplaceActions';
import { workplaceById } from '@/data/Workplaces/WorkplaceSlice';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import LayoutOfPage from '@/pages/LayoutOfPage';
import SchedulePlanReportPredictedDurationToRealDurationChart from '@/wrappers/Reports/Charts/PredictedTime/SchedulePlanReportPredictedDurationToRealDurationChart';
import SchedulePlanReportPredictedDurationToRealDurationDiffChart from '@/wrappers/Reports/Charts/PredictedTime/SchedulePlanReportPredictedDurationToRealDurationDiffChart';
import ReportSchedulePlanSelect, { StyledNoScheduleBoxMessage } from '@/wrappers/Reports/ReportSchedulePlanSelect';
import ReportSchedulePlanSlider from '@/wrappers/Reports/ReportSchedulePlanSlider';

const PredictedTimeToRealTimePage = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const schedulePlanDataList = useAppSelector(schedulePlanList);
    const schedulePlanId = useAppSelector(getReportSchedulePlanId);
    const selectedSchedulePlanData = schedulePlanDataList.find((item) => item.id === schedulePlanId);
    const workplaceData = useAppSelector((state) =>
        workplaceById(state, selectedSchedulePlanData?.workplace_id ?? null)
    );
    const predictedTimeDataModified = useAppSelector(schedulePlansPredictedTimeModifiedForecastAccuracyReport);

    useEffect(() => {
        if (selectedSchedulePlanData?.workplace_id) {
            dispatch(fetchWorkplaceById(selectedSchedulePlanData.workplace_id));
        }
    }, [selectedSchedulePlanData]);

    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone: workplaceData?.time_zone });
    const dateTimeTickFormatter = (value: Date) => dateTimeFormatter.format(value);

    const fetchData = () => {
        if (schedulePlanId) {
            dispatch(fetchSchedulePlansForecastAccuracyReportPredictedTime(schedulePlanId));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [schedulePlanId]);

    return (
        <LayoutOfPage
            title={t('label.predictedTimeToRealTime', 'Predicted Time / Real Time')}
            containsDatatable={false}
            actionButtonsBeforeSave={[
                <ReportSchedulePlanSelect
                    key="coverageToRequireScheduleSelect"
                    sx={{ width: '100%', minWidth: '350px' }}
                />
            ]}
        >
            {schedulePlanId && (
                <>
                    <ResponsiveContainer height={20}>
                        <AreaChart
                            syncId="areaChartDuration"
                            data={predictedTimeDataModified.map((item) => ({
                                name: DateHelper.fromDateTimeString(item.start).toDate()
                            }))}
                            margin={{
                                left: 150,
                                right: 150
                            }}
                        >
                            <XAxis dataKey="name" hide={true} />
                            <Brush dataKey="name" height={20} tickFormatter={dateTimeTickFormatter} />
                        </AreaChart>
                    </ResponsiveContainer>
                    <Box sx={{ paddingTop: '1em', height: '90%' }}>
                        <SchedulePlanReportPredictedDurationToRealDurationChart
                            data={predictedTimeDataModified}
                            timeZone={workplaceData?.time_zone}
                        />
                        <SchedulePlanReportPredictedDurationToRealDurationDiffChart
                            data={predictedTimeDataModified}
                            timeZone={workplaceData?.time_zone}
                        />
                    </Box>
                    <ReportSchedulePlanSlider />
                </>
            )}
            {!schedulePlanId && <StyledNoScheduleBoxMessage />}
        </LayoutOfPage>
    );
};

export default PredictedTimeToRealTimePage;
