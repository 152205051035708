import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FormGenerator, {
    FieldWrapper,
    ICallableRef,
    ISupportedFieldType,
    ISupportedValueType
} from '@/base/FormGenerator';
import {
    IMultiRowInputsItem,
    ISimpleFieldTypes,
    ITabType,
    ITimeRangeOrTimeAndLengthItem
} from '@/base/FormGenerator/FieldTypes';
import { IInnerValuesType } from '@/base/FormGenerator/utils';
import { ICuProps, IOutputValueType } from '@/components/CuForm';
import ErrorBoundary from '@/components/ErrorBoundary';
import UserPermission, { isUserAllowed, Mode } from '@/components/UserPermision';
import { canTakeTradeWithoutOffer } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { requestTypeAll } from '@/data/RequestTypes/RequestTypeSlice';
import {
    assignShiftIntoDay,
    assignShiftToSignedUser,
    detachShiftFromDay,
    updateAssignedShift
} from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftActions';
import { ISchedulePlanDayShiftModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import {
    removeAssignedShiftStateStatus,
    selectSchedulePlanDayShiftById
} from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { createOrUpdateSchedulePlanDayShiftRequest } from '@/data/SchedulePlans/SchedulePlanActions';
import {
    availableShiftsInPlan,
    availableUsersInPlan,
    schedulePlanAssignedShiftStatus,
    schedulePlanById,
    selectTimeZoneOfPlan,
    selectWorkplaceOfPlan
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { fetchShiftById, fetchShiftsForSelect } from '@/data/Shifts/ShiftActions';
import { isShiftsByIdLoaded, selectShiftById, shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import { agreeWithOfferedShift, selectOfferedShiftTrade } from '@/data/ShiftTradeOffers/ShiftTradeOfferActions';
import { deleteShiftTrade, takeThisShiftFromTrade } from '@/data/ShiftTrades/ShiftTradeActions';
import { shiftTradeRemovingStatus, shiftTradesBySchedulePlanDayShiftId } from '@/data/ShiftTrades/ShiftTradeSlice';
import { skillAll } from '@/data/Skills/SkillSlice';
import { IRootState } from '@/data/store';
import { getPermissionsList, isSignedUserAdmin, signedUser } from '@/data/System/SystemReducer';
import { selectUserById, userById } from '@/data/Users/UserSlice';
import SkillsOnShift from '@/forms/ShiftPlanDayForm/SkillsOnShift';
import { ISkillsOnShiftProps } from '@/forms/ShiftPlanDayForm/SkillsOnShift/SkillsOnShift';
import TabTrade from '@/forms/ShiftPlanDayForm/TabTrade';
import UserToRequestForm, { handleData as handleUserToRequestData } from '@/forms/UserToRequestForm/UserToRequestForm';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import isEmptyArray from '@/helpers/array/isEmptyArray';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { ICellHeaderData, isDateInValidDateOfShift } from '@/helpers/schedule';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import ShiftAsTimelineView from '@/modules/Scheduler/ShiftAsTimelineView';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { serializeUser } from '@/utils/UserHelper';
import { message, regex } from '@/utils/validations';
import Weekdays from '@/wrappers/Datatable/CellViews/Weekdays';
import { IDateTimeRangePickerProps } from '@/wrappers/DateTimeRangePicker/DateTimeRangePicker';
import LinearProgress from '@/wrappers/LinearProgress';
import Table from '@/wrappers/Table';
import { ITimeRangeOrTimeAndLengthValueType } from '@/wrappers/TimeRangeOrTimeAndLength';
import BreakItem from './BreakItem';
import SubTitle from './SubTitle';
import Title from './Title';

type IBreakItemData = {
    breakBackground: string;
    breakDuration: number;
    breakId: number;
    breakName: string;
    disabled: boolean;
    id: number;
    outOfShift?: boolean;
    required: boolean;
    valueStart: DateTimeType;
    valueUsed: boolean;
    shiftItemDuration: number;
    shiftItemStart: DateTimeType;
};

export type IShiftPlanDayFormProps = Omit<ICuProps<ISchedulePlanDayShiftModel>, 'resource'> & {
    day: DateTimeType;
    enableAssign: boolean;
    isDayMode: boolean;
    open?: boolean;
    recommendedSkills: { skillId: number; columns: ICellHeaderData<DateTimeType>[] }[] | null;
    shiftId?: number;
    schedulePlanId: number | null;
    type: 'shifts' | 'table';
    userId: number | null;
    isSchedulePlanClosed?: boolean;
};
type ISkillRow = {
    id: number | null;
    skill_id: string | number;
    range: ITimeRangeOrTimeAndLengthValueType;
};

const StyledFieldWrapper = styled(Box)`
    padding: 5px;
    border-color: black;
    border-radius: 5px;
    border-style: dashed;
    border-width: 1px;
`;

const removingStatusSelector = createSelector(
    (state: IRootState) => removeAssignedShiftStateStatus(state) === 'loading',
    (state: IRootState) => shiftTradeRemovingStatus(state) === 'loading',
    (...states) => states.some((state) => state)
);

const submitStatusSelector = createSelector(
    (state: IRootState) => schedulePlanAssignedShiftStatus(state) === 'loading',
    (...states) => states.some((state) => state)
);
const cancelDisableStatusSelector = createSelector(removingStatusSelector, submitStatusSelector, (...states) =>
    states.some((state) => state)
);
const handleData = (timeZone: string, planId: number, user: number | null, values: IOutputValueType) => ({
    description: (values.note as IOutputValueType)?.note,
    schedule_plan_id: planId,
    shift_id: values.schedules_shift_id as number,
    user_id: user,
    work_from_home: values.work_from_home as boolean,
    breaks:
        (
            [
                ...(((values.breaks as { mandatoryBreaks: IInnerValuesType[] }).mandatoryBreaks ??
                    []) as IInnerValuesType[]),
                ...(((
                    values.breaks as {
                        optionalBreaks: IInnerValuesType[];
                    }
                ).optionalBreaks ?? []) as IInnerValuesType[])
            ] as IBreakItemData[]
        ).map((item) => ({
            shift_item_id: item.id as number,
            break_id: item.breakId as number,
            start: DateHelper.moveDateTimeToTimeZone(item.valueStart as DateTimeType, timeZone, false),
            used: item.valueUsed as boolean,
            out_of_shift: item.outOfShift as boolean
        })) ?? [],
    skills: (
        ((values.skills as { skillRows: IInnerValuesType[] }).skillRows ?? []) as {
            skill_id: number;
            range: ITimeRangeOrTimeAndLengthValueType;
        }[]
    )
        .filter((skill) => !!skill.skill_id)
        .map((skill) => {
            const start = skill.range.start as DateTimeType;
            const end = DateHelper.addHours(start, skill.range.duration);

            return {
                skill_id: skill.skill_id,
                start,
                end
            };
        })
});

const ShiftPlanDayForm = ({
    enableAssign,
    id,
    day,
    isDayMode,
    open = false,
    openButtonRender,
    schedulePlanId,
    userId,
    recommendedSkills,
    type,
    isSchedulePlanClosed = false,
    onClose,
    ...rest
}: IShiftPlanDayFormProps) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const signedUserSystemData = useAppSelector(signedUser);
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const skillsData = useAppSelector(skillAll);
    const userCanTakeTradeWithoutOffer = useAppSelector(canTakeTradeWithoutOffer);
    const formRef = useRef<ICallableRef | null>(null);
    const schedulePlan = useAppSelector((state) => schedulePlanById(state, schedulePlanId));
    const assignedShift = useAppSelector((state) => (id ? selectSchedulePlanDayShiftById(state, id) : null));
    const workplace = useAppSelector((state) => selectWorkplaceOfPlan(state, schedulePlanId));
    const timeZone =
        useAppSelector((state) => selectTimeZoneOfPlan(state, schedulePlanId)) ??
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone: timeZone });
    const allowedShifts = useAppSelector((state) => availableShiftsInPlan(state, schedulePlanId));
    const usersListData = useAppSelector((state) => availableUsersInPlan(state, schedulePlanId));
    const shiftId = assignedShift?.shift_id ?? null;
    const [isOpen, setIsOpen] = useState(open);
    const [selectedShiftToAssign, setSelectedShiftToAssign] = useState<number | null>(shiftId ?? null);
    const signedUserData = useAppSelector((state) =>
        signedUserSystemData ? userById(state, signedUserSystemData.id) : null
    );
    const [selectedUser, setSelectedUser] = useState<number | null>(userId ?? null);
    const requestTypeList = useAppSelector(requestTypeAll);
    const shiftListData = useAppSelector(shiftsForSelect);
    const userData = useAppSelector((state) =>
        selectedUser || (!isUserAdmin && signedUserData)
            ? selectUserById(state, selectedUser ?? signedUserData!.id)
            : null
    );

    const assignedShiftIsInProgress = useAppSelector(schedulePlanAssignedShiftStatus) === 'loading';
    const permissionsList = useAppSelector(getPermissionsList);
    const data = useAppSelector((state: IRootState) => selectShiftById(state, selectedShiftToAssign));
    const isShiftLoaded = useAppSelector(
        (state) => selectedShiftToAssign && isShiftsByIdLoaded(state, selectedShiftToAssign)
    );
    const trades = useAppSelector((state) => shiftTradesBySchedulePlanDayShiftId(state, id ?? null));
    const date = DateHelper.formatDate(DateHelper.setTimeZone(day, workplace?.time_zone ?? 'UTC'));
    const movedDay = DateHelper.moveDateTimeToTimeZone(day, workplace?.time_zone ?? 'UTC', true);

    const isEdit = typeof id !== 'undefined';
    const cacheKeyBreaks = JSON.stringify([
        assignedShift?.schedule_plan_day_shift_breaks ?? [],
        data?.shift_items ?? []
    ]);

    const isUnassigned = selectedUser === null;

    const timeShiftStart = data
        ? DateHelper.setTimeZone(
              DateHelper.fromDateTimeString(`${DateHelper.formatDate(movedDay)} ${data.time_start}`, timeZone),
              timeZone
          )
        : undefined;
    const timeShiftEnd = data ? DateHelper.addHours(timeShiftStart, data.duration) : undefined;
    const allBreaks = useMemo(() => {
        const breaks: IBreakItemData[] = [];

        const usersShift = userData?.user_to_shifts?.find(
            (userToShift) => userToShift.shift_id === selectedShiftToAssign
        );

        if (assignedShift) {
            data?.shift_items.forEach((shiftItem) => {
                const assignedBreak = Array.from(assignedShift?.schedule_plan_day_shift_breaks ?? []).find(
                        (item) => item.shift_item_id === shiftItem.id
                    ),
                    usersShiftItem = usersShift?.user_to_shift_items.find(
                        (userToShiftItem) => userToShiftItem.shift_item_id === shiftItem.id
                    ),
                    valueStart = assignedBreak
                        ? DateHelper.setTimeZone(DateHelper.fromDateTimeString(assignedBreak.start), timeZone)
                        : DateHelper.setTimeZone(
                              DateHelper.fromDateTimeString(
                                  `${DateHelper.formatDate(movedDay)} ${
                                      usersShiftItem ? usersShiftItem.start : shiftItem.start
                                  }`,
                                  timeZone
                              ),
                              timeZone
                          );

                breaks.push({
                    breakBackground: shiftItem.break.background,
                    breakDuration: shiftItem.break.duration,
                    breakId: shiftItem.break_id,
                    breakName: shiftItem?.break.name,
                    disabled: isUnassigned && !isUserAdmin,
                    id: shiftItem.id,
                    outOfShift:
                        (timeShiftStart && DateHelper.isBefore(valueStart, timeShiftStart)) ||
                        (timeShiftEnd &&
                            !DateHelper.isBefore(
                                DateHelper.addMinutes(valueStart, shiftItem?.break?.duration ?? 0),
                                timeShiftEnd
                            )),
                    required: shiftItem.break.required,
                    shiftItemDuration: shiftItem.duration,
                    shiftItemStart: DateHelper.setTimeZone(
                        DateHelper.fromDateTimeString(
                            `${DateHelper.formatDate(movedDay)} ${shiftItem.start}`,
                            timeZone
                        ),
                        timeZone
                    ),
                    valueUsed: assignedBreak?.used ?? usersShiftItem?.used ?? false,
                    valueStart
                });
            });
        } else {
            data?.shift_items.forEach((shiftItem) => {
                const usersShiftItem = usersShift?.user_to_shift_items.find(
                        (userToShiftItem) => userToShiftItem.shift_item_id === shiftItem.id
                    ),
                    valueStart = DateHelper.setTimeZone(
                        DateHelper.fromDateTimeString(
                            `${DateHelper.formatDate(movedDay)} ${
                                usersShiftItem ? usersShiftItem.start : shiftItem.start
                            }`,
                            timeZone
                        ),
                        timeZone
                    );

                breaks.push({
                    breakBackground: shiftItem.break.background,
                    breakDuration: shiftItem.break.duration,
                    breakId: shiftItem.break_id,
                    breakName: shiftItem.break.name,
                    disabled: isUnassigned && !isUserAdmin,
                    id: shiftItem.id,
                    outOfShift: false,
                    required: shiftItem.break.required,
                    shiftItemDuration: shiftItem.duration,
                    shiftItemStart: DateHelper.setTimeZone(
                        DateHelper.fromDateTimeString(
                            `${DateHelper.formatDate(movedDay)} ${shiftItem.start}`,
                            timeZone
                        ),
                        timeZone
                    ),
                    valueStart,
                    valueUsed: usersShiftItem?.used ?? false
                });
            });
        }

        return breaks.sort((a, b) => (DateHelper.isBefore(a.valueStart, b.valueStart) ? -1 : 1)) ?? [];
    }, [date, cacheKeyBreaks]);
    const setLoadedData = () => {
        if (assignedShift) {
            formRef.current?.setFieldValue('user_id', selectedUser ? `${selectedUser}` : null);
            formRef.current?.setFieldValue('schedules_shift_id', `${shiftId}`);
            formRef.current?.setFieldValue('note', assignedShift.description);
        }
    };
    const handleOpen = useCallback(() => {
        if (formRef.current && shiftId) {
            setLoadedData();
        }

        setSelectedUser(userId ?? null);

        setIsOpen(true);
    }, [formRef.current, shiftId]);
    const handleClose = useCallback(() => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    }, []);
    const handleRemove = useCallback(() => {
        if (id) {
            if (trades?.length) {
                return dispatch(
                    deleteShiftTrade({
                        schedulePlanId: schedulePlanId,
                        id: trades.find(() => true)!.id
                    })
                )
                    .unwrap()
                    .then(handleClose);
            }

            if (schedulePlanId) {
                return dispatch(detachShiftFromDay({ schedulePlanId, id })).unwrap().then(handleClose);
            }
        } else {
            return Promise.reject();
        }
    }, [id, userId, schedulePlanId, trades?.length]);
    const handleTakeShift = useCallback(() => {
        if (id) {
            return dispatch(takeThisShiftFromTrade({ id: id, schedulePlanId })).then(handleClose);
        }
    }, [id, userId, schedulePlanId, trades?.length]);
    const handleCancel = useCallback(() => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    }, []);
    const handleAssignedShiftToUser = useCallback(() => {
        if (signedUserData && id && schedulePlanId) {
            dispatch(
                assignShiftToSignedUser({
                    schedulePlanDayShiftId: id,
                    schedulePlanId: schedulePlanId
                })
            )
                .unwrap()
                .then(handleClose);
        }
    }, [id, signedUserData]);
    const handleSubmit = useCallback(
        (values: ISupportedValueType) => {
            if (schedulePlanId) {
                if (isEdit) {
                    if (!id || !schedulePlanId) {
                        throw new Error('SchedulePlanId or Id is undefined');
                    }

                    if (values.tabs === 'requests' && userData) {
                        return dispatch(
                            createOrUpdateSchedulePlanDayShiftRequest({
                                schedulePlanId,
                                data: {
                                    ...handleUserToRequestData(
                                        values.requests as IOutputValueType,
                                        requestTypeList ?? [],
                                        userData.id,
                                        shiftListData ?? [],
                                        workplace ? [workplace] : []
                                    ),
                                    schedule_plan_day_shift_id: id
                                }
                            })
                        )
                            .unwrap()
                            .then(handleClose);
                    } else if (values.tabs === 'trade') {
                        if (values.selectedTradeOrOffer_isTrade) {
                            return dispatch(
                                selectOfferedShiftTrade({
                                    schedulePlanId: schedulePlanId,
                                    schedulePlanDayShiftId: id,
                                    tradeId: values.selectedTradeOrOffer as number
                                })
                            )
                                .unwrap()
                                .then(handleClose);
                        } else {
                            return dispatch(
                                agreeWithOfferedShift({
                                    schedulePlanId: schedulePlanId,
                                    shiftTradeOfferId: values.selectedTradeOrOffer as number
                                })
                            )
                                .unwrap()
                                .then(handleClose);
                        }
                    }

                    return dispatch(
                        updateAssignedShift({
                            schedulePlanId: schedulePlanId,
                            id: id!,
                            data: {
                                ...handleData(timeZone, schedulePlanId, selectedUser, values),
                                shift_start: (values.shiftStartEnd as { start: DateTimeType }).start,
                                shift_end: (values.shiftStartEnd as { end: DateTimeType }).end
                            }
                        })
                    )
                        .unwrap()
                        .then(handleClose);
                } else {
                    dispatch(
                        assignShiftIntoDay({
                            ...handleData(timeZone, schedulePlanId, selectedUser, values),
                            shift_start: (values.shiftStartEnd as { start: DateTimeType }).start,
                            shift_end: (values.shiftStartEnd as { end: DateTimeType }).end
                        })
                    )
                        .unwrap()
                        .then(handleClose);
                }
            } else {
                dispatch(
                    addNotification({
                        message: t(
                            'message.error.invalidStateMissingSchedulePlan',
                            'Invalid State: Missing Schedule Plan'
                        ),
                        variant: 'error'
                    })
                );
            }
        },
        [isEdit, id, selectedUser, userData?.id, schedulePlanId, requestTypeList, shiftListData, workplace, date]
    );
    const handleNewTradeClick = useCallback(() => {
        handleClose();
    }, []);
    const handleError = useCallback(
        (/*reason: string | null*/) => {
            // handleValid(!reason)
            // handleValid(!reason)
        },
        []
    );

    useEffect(() => {
        dispatch(fetchShiftsForSelect({ search: '' }));
        if (open) {
            formRef.current?.setOpen(true);
            handleOpen();
        }
    }, []);
    useEffect(() => {
        if (selectedShiftToAssign) {
            dispatch(fetchShiftById(selectedShiftToAssign));
        }
    }, [selectedShiftToAssign]);
    useEffect(() => {
        if (selectedShiftToAssign && data && formRef.current && !id) {
            const skillFromUser =
                userData && Array.isArray(userData.user_to_skills) && userData.user_to_skills.length > 0
                    ? userData.user_to_skills[0].skill_id
                    : null;

            const end = isDayMode ? DateHelper.addMinutes(timeShiftStart, 15) : DateHelper.addDays(timeShiftStart, 1);

            const mappedRecommendedSkills =
                recommendedSkills?.map((item) => {
                    const columns = item.columns.filter((column) => DateHelper.isBetween(column.date, movedDay, end));
                    const reducedColumn = columns.reduce(
                        (prev, current) =>
                            prev.required - prev.covered > (current.required ?? 0) - (current.covered ?? 0)
                                ? prev
                                : {
                                      covered: current.covered ?? 0,
                                      required: current.required ?? 0
                                  },
                        { covered: 0, required: 0 }
                    );

                    return {
                        ...reducedColumn,
                        skillId: item.skillId
                    };
                }) ?? null;

            const recommendedSkill =
                mappedRecommendedSkills && mappedRecommendedSkills?.length > 0
                    ? mappedRecommendedSkills.reduce(
                          (prev, current) =>
                              prev.required - prev.covered > current.required - current.covered ? prev : current,
                          mappedRecommendedSkills[0]
                      ).skillId
                    : null;
            let skillId: number | null;

            if (isUnassigned && skillsData && skillsData.length > 0) {
                skillId = skillsData[0].id;
            } else {
                skillId = recommendedSkill ?? skillFromUser;
            }

            formRef.current?.setFieldValue('shiftStartEnd', { start: timeShiftStart, end: timeShiftEnd });

            if (skillId) {
                formRef.current?.setFieldValue('skills.skillRows', [
                    {
                        id: 0,
                        skill_id: skillId ? `${skillId}` : '',
                        range: {
                            isRange: false,
                            start: timeShiftStart,
                            duration: data.duration
                        }
                    }
                ]);
            }
        }

        if (selectedShiftToAssign) {
            const [mandatory, optional] = allBreaks.reduce<[typeof allBreaks, typeof allBreaks]>(
                (prev, current) => [
                    [...prev[0], ...(current.required ? [current] : [])],
                    [...prev[1], ...(!current.required ? [current] : [])]
                ],
                [[], []]
            );

            if (mandatory.length) {
                formRef.current?.setFieldValue('breaks.mandatoryBreaks', mandatory);
            }

            if (optional.length) {
                formRef.current?.setFieldValue('breaks.optionalBreaks', optional);
            }
        }
    }, [
        allBreaks,
        assignedShift,
        workplace?.id ?? '',
        selectedShiftToAssign,
        userData,
        isOpen,
        data,
        formRef.current !== null
    ]);
    useEffect(() => {
        if (formRef.current && selectedUser) {
            formRef.current?.setFieldValue('user_id', `${selectedUser}`);
        }
    }, [formRef.current, selectedUser]);
    useEffect(() => {
        if (formRef.current && open) {
            formRef.current?.setOpen(true);
        }
    }, [formRef.current, open]);

    const name = 'shiftPlanDayForm';

    const removingStatus = useAppSelector(removingStatusSelector) && isOpen;
    const submitStatus = useAppSelector(submitStatusSelector) && isOpen;
    const cancelDisableStatus = useAppSelector(cancelDisableStatusSelector) && isOpen;

    const isUserOwner = signedUserSystemData?.id === userId;
    const filteredShiftData =
        shiftListData?.filter((shift) => {
            return (
                ((userData?.user_to_shifts?.length ?? 0) > 0
                    ? userData?.user_to_shifts.some((userToShift) => userToShift.shift_id == shift.id)
                    : true) &&
                isDateInValidDateOfShift(shift, movedDay) &&
                allowedShifts?.some((item) => item.shift_id === shift.id)
            );
        }) ?? [];

    const isShiftSelected = !!selectedShiftToAssign && `${selectedShiftToAssign}` !== '',
        isLoaded = !isShiftSelected || isShiftLoaded || !assignedShiftIsInProgress,
        isAssignedButtonDisabled = !signedUserData?.user_to_skills?.some(
            (usersSkill) =>
                assignedShift?.schedule_plan_day_shift_skills.some(
                    (planSkill) => planSkill.skill_id == usersSkill.skill_id
                )
        );

    return schedulePlanId === null ? (
        <>{openButtonRender ? openButtonRender(() => {}) : ''}</>
    ) : (
        <ErrorBoundary>
            <UserPermission
                id={PermissionsEnum.Shifts}
                mode={[Mode.READ, ...(isEdit ? [Mode.UPDATE] : [Mode.CREATE])]}
                operator="OR"
            >
                <FormGenerator
                    {...rest}
                    innerRef={formRef}
                    isLoaded={isLoaded}
                    id={id ? `${id}` : undefined}
                    name={name}
                    isEdit={isEdit}
                    justIcon
                    openForm={isOpen}
                    title={<Title isEdit={isEdit} user={userData ?? undefined} />}
                    subTitle={
                        <SubTitle day={DateHelper.toDate(day)} user={userData ?? undefined} timeZone={timeZone} />
                    }
                    maxWidth="lg"
                    displayAsModal={false}
                    displayAsSidebar={true}
                    readOnly={!isUserOwner && !isUserAdmin && !isSchedulePlanClosed}
                    fields={
                        [
                            {
                                type: 'select',
                                changeValueOnlyFromInside: true,
                                display: ({ user_id }) => (type === 'table' ? !user_id : isEdit && isUserAdmin),
                                props: {
                                    name: 'user_id',
                                    label: t('label.user', 'User'),
                                    value: selectedUser?.toString(),
                                    options:
                                        usersListData?.map(({ user }) => ({
                                            id: user.id.toString(),
                                            label: serializeUser(user)
                                        })) ?? [],
                                    onChange: (newUserId) => {
                                        setSelectedUser(newUserId ? parseInt(`${newUserId}`) : null);
                                    },
                                    sx: { marginTop: 1 },
                                    validation: {
                                        deps: [
                                            'user_id',
                                            'tabs',
                                            'breaks.mandatoryBreaks',
                                            'breaks.optionalBreaks',
                                            'skills.skillRows'
                                        ]
                                    }
                                }
                            },
                            {
                                type: 'select',
                                changeValueOnlyFromInside: true,
                                display: ({ user_id }) =>
                                    (type === 'table' ? !!user_id : true) && typeof id !== 'number',
                                props: {
                                    required: true,
                                    name: 'schedules_shift_id',
                                    label: t('label.shift', 'Shift'),
                                    value: shiftId?.toString(),
                                    options: filteredShiftData.map((shift) => ({
                                        id: shift.id.toString(),
                                        label: shift.name
                                    })),
                                    onChange: (newShiftId) => {
                                        setSelectedShiftToAssign(newShiftId ? parseInt(`${newShiftId}`) : null);
                                    },
                                    sx: { marginTop: 1 },
                                    validation: {
                                        deps: [
                                            'user_id',
                                            'schedules_shift_id',
                                            'tabs',
                                            'breaks.mandatoryBreaks',
                                            'breaks.optionalBreaks',
                                            'skills.skillRows'
                                        ]
                                    }
                                }
                            },
                            {
                                type: 'switch',
                                changeValueOnlyFromInside: true,
                                display: ({ user_id }) => !!selectedShiftToAssign && !!user_id,
                                props: {
                                    name: 'work_from_home',
                                    label: t('label.workFromHome', 'Work from Home'),
                                    value: assignedShift?.work_from_home ?? false,
                                    sx: { marginTop: 1 },
                                    validation: {
                                        deps: ['user_id', 'shift_id']
                                    }
                                }
                            },
                            {
                                type: 'html',
                                props: {
                                    name: 'loader',
                                    render: () => (
                                        <LinearProgress
                                            hidden={isLoaded}
                                            sx={{
                                                transitionProperty: 'display',
                                                transitionDelay: '250ms'
                                            }}
                                        />
                                    )
                                }
                            },
                            {
                                type: 'custom',
                                display: ({ shiftStartEnd }) =>
                                    Boolean((shiftStartEnd as IDateTimeRangePickerProps['value'])?.end) &&
                                    Boolean((shiftStartEnd as IDateTimeRangePickerProps['value'])?.start),
                                props: {
                                    name: 'shiftDetail',
                                    hideMode: 'js',
                                    values: {},
                                    render: ({ watch, fullName }) => {
                                        const assignedShiftStartEnd = watch('shiftStartEnd');
                                        const skills = (
                                            (watch('skills.skillRows') ?? [])
                                                .map((row: ISkillRow | Omit<ISkillRow, 'range'>) => ({
                                                    ...row,
                                                    skill: Array.from(userData?.user_to_skills ?? []).find(
                                                        (item) =>
                                                            row.skill_id &&
                                                            'range' in row &&
                                                            row.range &&
                                                            `${item.skill_id}` === `${row.skill_id}`
                                                    )
                                                }))
                                                .filter(
                                                    (
                                                        skill:
                                                            | ArrayElement<ISkillsOnShiftProps['data']>
                                                            | Omit<ArrayElement<ISkillsOnShiftProps['data']>, 'range'>
                                                    ) => 'range' in skill
                                                ) as ISkillsOnShiftProps['data']
                                        ).sort((a, b) =>
                                            a.range.start &&
                                            b.range.start &&
                                            DateHelper.isBefore(a.range.start, b.range.start)
                                                ? -1
                                                : 1
                                        );

                                        const selectedShiftId = !!watch('schedules_shift_id');

                                        return (
                                            <FieldWrapper
                                                type="custom"
                                                fullWidth={true}
                                                isDisplayed={selectedShiftId && !!isShiftLoaded}
                                                key={fullName}
                                            >
                                                <StyledFieldWrapper>
                                                    <ShiftAsTimelineView
                                                        abbr={
                                                            data
                                                                ? {
                                                                      text: data.abbreviation,
                                                                      color: data.color,
                                                                      background: data.background
                                                                  }
                                                                : undefined
                                                        }
                                                        breaks={[
                                                            ...([
                                                                ...(watch('breaks.mandatoryBreaks') ?? []),
                                                                ...(watch('breaks.optionalBreaks') ?? [])
                                                            ].sort((a, b) =>
                                                                DateHelper.isBefore(a.start, b.start) ? -1 : 1
                                                            ) ?? []),
                                                            ...(
                                                                assignedShift?.schedule_plan_day_shift_system_breaks ??
                                                                []
                                                            ).map((item) => ({
                                                                id: item.id,
                                                                break_id: item.id,
                                                                breakDuration: item.duration,
                                                                breakBackground: data?.color ?? '#fff',
                                                                valueStart: DateHelper.fromDateTimeString(item.start)
                                                            }))
                                                        ]}
                                                        shiftEnd={assignedShiftStartEnd.end}
                                                        shiftStart={assignedShiftStartEnd.start}
                                                        timeZone={workplace?.time_zone ?? 'UTC'}
                                                    />
                                                    <SkillsOnShift
                                                        data={skills.map((skill) => ({
                                                            ...skill,
                                                            range: {
                                                                ...skill.range,
                                                                start: skill.range.start ? skill.range.start : null
                                                            }
                                                        }))}
                                                        timeZone={workplace?.time_zone ?? 'UTC'}
                                                    />
                                                </StyledFieldWrapper>
                                            </FieldWrapper>
                                        );
                                    },
                                    validation: {
                                        deps: [
                                            'breaks.mandatoryBreaks',
                                            'breaks.optionalBreaks',
                                            'skills.skillRows',
                                            'shiftStartEnd'
                                        ]
                                    }
                                }
                            },
                            {
                                type: 'dateTimeRange',
                                display: () => isUserAdmin && (!!data || !!assignedShift),
                                props: {
                                    name: 'shiftStartEnd',
                                    label: {
                                        start: t('label.from', 'From'),
                                        end: t('label.end', 'End')
                                    },
                                    minDateTime: assignedShift
                                        ? DateHelper.setTimeZone(
                                              DateHelper.fromDateTimeString(assignedShift.shift_start),
                                              timeZone
                                          )
                                        : timeShiftStart,
                                    maxDateTime: assignedShift
                                        ? assignedShift?.shift_end === null
                                            ? DateHelper.now()
                                            : DateHelper.setTimeZone(
                                                  DateHelper.fromDateTimeString(assignedShift.shift_end),
                                                  timeZone
                                              )
                                        : timeShiftEnd,
                                    minutesStep: 15,
                                    timeZone,
                                    value: {
                                        start: assignedShift
                                            ? DateHelper.setTimeZone(
                                                  DateHelper.fromDateTimeString(assignedShift.shift_start),
                                                  timeZone
                                              )
                                            : timeShiftStart,
                                        end: assignedShift
                                            ? assignedShift.shift_end === null
                                                ? DateHelper.now()
                                                : DateHelper.setTimeZone(
                                                      DateHelper.fromDateTimeString(assignedShift.shift_end),
                                                      timeZone
                                                  )
                                            : timeShiftEnd
                                    }
                                }
                            },
                            {
                                type: 'html',
                                display: ({ schedules_shift_id }) => !!schedules_shift_id,
                                props: {
                                    name: 'dateTime',
                                    render: () => (
                                        <div>
                                            <Typography variant="subtitle1">
                                                {t('subTitle.dateAndTime', 'Date & Time')}
                                            </Typography>
                                            <Divider />
                                            <Weekdays days={data?.days ?? 0} holidays={data?.holiday ?? false} />
                                        </div>
                                    )
                                }
                            },
                            {
                                type: 'tabs',
                                display: ({ schedules_shift_id }) => !!schedules_shift_id,
                                deps: 'schedules_shift_id',
                                props: {
                                    name: 'tabs',
                                    value: 'shiftPlanDayTabs',
                                    tabsContainer: Paper,
                                    tabs: [
                                        {
                                            name: 'skills',
                                            label: t('subTitle.skills', 'Skills'),
                                            inputs: [
                                                {
                                                    type: 'multiRowInputs',
                                                    props: {
                                                        disableButtons: isUnassigned && !isUserAdmin,
                                                        name: 'skillRows',
                                                        value:
                                                            assignedShift?.schedule_plan_day_shift_skills.map(
                                                                (item) => {
                                                                    const start = DateHelper.fromDateTimeString(
                                                                        item.start
                                                                    );

                                                                    return {
                                                                        item_id: item.id,
                                                                        skill_id: `${item.skill_id}`,
                                                                        range: {
                                                                            isRange: true,
                                                                            start,
                                                                            duration: DateHelper.getDifferenceInHours(
                                                                                start,
                                                                                DateHelper.fromDateTimeString(item.end)
                                                                            )
                                                                        }
                                                                    };
                                                                }
                                                            ) ?? [],
                                                        inputs: [
                                                            {
                                                                type: 'select',
                                                                props: {
                                                                    required: true,
                                                                    name: 'skill_id',
                                                                    label: t('label.skillName', 'Skill Name'),
                                                                    disabled: isUnassigned && !isUserAdmin,
                                                                    options: isUnassigned
                                                                        ? skillsData?.map((item) => ({
                                                                              id: `${item.id}`,
                                                                              label: item.name
                                                                          })) ?? []
                                                                        : userData?.user_to_skills?.map((item) => ({
                                                                              id: `${item.skill_id}`,
                                                                              label: item.skill.name
                                                                          })) ?? [],
                                                                    width: 3,
                                                                    validation: {
                                                                        deps: 'skills.skillRows'
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                type: 'timeRangeOrTimeAndLength',
                                                                props: {
                                                                    required: true,
                                                                    name: 'range',
                                                                    disabled: isUnassigned && !isUserAdmin,
                                                                    timeZone,
                                                                    zeroIsNextDay: true,
                                                                    label: {
                                                                        start: t('label.start', 'Start'),
                                                                        end: t('label.end', 'End'),
                                                                        length: t('label.length', 'Length'),
                                                                        range: t('label.range', 'Range')
                                                                    },
                                                                    value: ({ skills, shiftStartEnd }, rowIndex) => {
                                                                        if (rowIndex === null) {
                                                                            return {
                                                                                start: null,
                                                                                duration: 0.25,
                                                                                isRange: false
                                                                            } as ITimeRangeOrTimeAndLengthValueType;
                                                                        }

                                                                        const rowData = (
                                                                            skills as {
                                                                                skillRows: {
                                                                                    range: ITimeRangeOrTimeAndLengthValueType;
                                                                                }[];
                                                                            }
                                                                        ).skillRows[rowIndex];
                                                                        const shiftStart = (
                                                                                shiftStartEnd as IDateTimeRangePickerProps['value']
                                                                            )?.start,
                                                                            shiftEnd = (
                                                                                shiftStartEnd as IDateTimeRangePickerProps['value']
                                                                            )?.end,
                                                                            oldStart =
                                                                                rowData.range.start &&
                                                                                (!rowData.range.start?.isUTC() || !id)
                                                                                    ? DateHelper.moveDateTimeToTimeZone(
                                                                                          rowData.range.start,
                                                                                          timeZone,
                                                                                          false
                                                                                      )
                                                                                    : rowData.range.start,
                                                                            oldEnd = DateHelper.addHours(
                                                                                oldStart,
                                                                                rowData.range.duration
                                                                            );
                                                                        const newStart =
                                                                                shiftStart &&
                                                                                !DateHelper.isBefore(
                                                                                    shiftStart,
                                                                                    oldStart ?? undefined
                                                                                )
                                                                                    ? shiftStart
                                                                                    : oldStart,
                                                                            newEnd =
                                                                                shiftEnd &&
                                                                                DateHelper.isBefore(
                                                                                    shiftEnd,
                                                                                    oldEnd ?? undefined
                                                                                )
                                                                                    ? shiftEnd
                                                                                    : oldEnd;

                                                                        return {
                                                                            ...rowData.range,
                                                                            duration: DateHelper.getDifferenceInHours(
                                                                                newStart ?? null,
                                                                                newEnd
                                                                            ),
                                                                            start: newStart
                                                                        };
                                                                    },
                                                                    minStart: ({ shiftStartEnd }) =>
                                                                        shiftStartEnd &&
                                                                        (
                                                                            shiftStartEnd as {
                                                                                start: DateTimeType;
                                                                            }
                                                                        ).start
                                                                            ? DateHelper.setTimeZone(
                                                                                  DateHelper.fromDateTimeString(
                                                                                      `${DateHelper.formatDate(
                                                                                          movedDay
                                                                                      )} ${DateHelper.formatTime(
                                                                                          (
                                                                                              shiftStartEnd as {
                                                                                                  start: DateTimeType;
                                                                                              }
                                                                                          ).start
                                                                                      )}`
                                                                                  ),
                                                                                  timeZone
                                                                              )
                                                                            : timeShiftStart,
                                                                    minDuration: 0.25,
                                                                    maxDuration: ({ shiftStartEnd }) =>
                                                                        shiftStartEnd
                                                                            ? DateHelper.getDifferenceAsMinutes(
                                                                                  (
                                                                                      shiftStartEnd as {
                                                                                          start: DateTimeType;
                                                                                      }
                                                                                  ).start,
                                                                                  (
                                                                                      shiftStartEnd as {
                                                                                          end: DateTimeType;
                                                                                      }
                                                                                  ).end
                                                                              )
                                                                            : DateHelper.getDifferenceAsMinutes(
                                                                                  timeShiftStart ?? null,
                                                                                  timeShiftEnd ?? null
                                                                              ),
                                                                    minutesStep: 15,
                                                                    width: 7,
                                                                    validation: {
                                                                        deps: ['skills.skillRows', 'shiftStartEnd']
                                                                    }
                                                                }
                                                            } as ITimeRangeOrTimeAndLengthItem
                                                        ],
                                                        validation: {
                                                            deps: ['schedules_shift_id', 'user_id', 'shiftStartEnd']
                                                        }
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            name: 'breaks',
                                            label: t('subTitle.breaks', 'Breaks'),
                                            display: ({ breaks }) =>
                                                !isEmptyArray(
                                                    (breaks as { mandatoryBreaks?: any[] }).mandatoryBreaks
                                                ) ||
                                                !isEmptyArray((breaks as { optionalBreaks?: any[] }).optionalBreaks) ||
                                                (assignedShift?.schedule_plan_day_shift_system_breaks.length ?? 0) > 0,
                                            inputs: [
                                                {
                                                    type: 'html',
                                                    display: ({ breaks }) =>
                                                        (
                                                            (breaks as { mandatoryBreaks?: any[] })
                                                                .mandatoryBreaks as any[]
                                                        ).length > 0,
                                                    props: {
                                                        validation: {
                                                            deps: ['schedules_shift_id', 'tabs']
                                                        },
                                                        name: 'mandatoryBreaksTitle',
                                                        render: () => (
                                                            <>
                                                                <Typography variant="subtitle1">
                                                                    {t('subTitle.mandatoryBreaks', 'Mandatory Breaks')}
                                                                </Typography>
                                                                <Divider />
                                                            </>
                                                        )
                                                    }
                                                },
                                                {
                                                    type: 'multiRowInputs',
                                                    display: ({ breaks }) =>
                                                        (
                                                            (breaks as { mandatoryBreaks?: any[] })
                                                                .mandatoryBreaks as any[]
                                                        ).length > 0,
                                                    props: {
                                                        disableButtons: isUnassigned && !isUserAdmin,
                                                        validation: {
                                                            deps: ['schedules_shift_id', 'tabs', 'shiftStartEnd']
                                                        },
                                                        name: 'mandatoryBreaks',
                                                        fixedSize: true,
                                                        value: allBreaks.filter(({ required }) => required),
                                                        inputs: [
                                                            {
                                                                type: 'custom',
                                                                props: {
                                                                    name: 'break',
                                                                    values: {},
                                                                    render: ({
                                                                        setValue,
                                                                        watch,
                                                                        fullName,
                                                                        parentName,
                                                                        readOnly
                                                                    }) => {
                                                                        const currentName = parentName ?? fullName;
                                                                        const shiftStartEnd = watch('shiftStartEnd');
                                                                        const values = watch(
                                                                            currentName
                                                                        ) as ArrayElement<typeof allBreaks>;

                                                                        if (!values) {
                                                                            return null;
                                                                        }

                                                                        return (
                                                                            <BreakItem
                                                                                key={values.id}
                                                                                breakDuration={values.breakDuration}
                                                                                breakName={values.breakName}
                                                                                day={DateHelper.toDate(day)}
                                                                                disabled={values.disabled}
                                                                                readOnly={readOnly}
                                                                                required={true}
                                                                                shiftEnd={shiftStartEnd.end ?? null}
                                                                                shiftItemDuration={
                                                                                    values.shiftItemDuration
                                                                                }
                                                                                shiftItemStart={values.shiftItemStart}
                                                                                shiftStart={shiftStartEnd.start ?? null}
                                                                                timeZone={timeZone}
                                                                                value={{
                                                                                    start: values.valueStart,
                                                                                    used: values.valueUsed
                                                                                }}
                                                                                onError={handleError}
                                                                                onChangeOutOfShift={(newValue) =>
                                                                                    setValue(currentName, {
                                                                                        ...values,
                                                                                        outOfShift: newValue
                                                                                    })
                                                                                }
                                                                                onChangeStart={(newStart) =>
                                                                                    setValue(currentName, {
                                                                                        ...values,
                                                                                        valueStart: newStart
                                                                                    })
                                                                                }
                                                                                onChangeUsed={(newUsed) =>
                                                                                    setValue(currentName, {
                                                                                        ...values,
                                                                                        valueUsed: newUsed
                                                                                    })
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    type: 'html',
                                                    display: ({ breaks }) =>
                                                        ((breaks as { optionalBreaks?: any[] }).optionalBreaks as any[])
                                                            .length > 0,
                                                    props: {
                                                        name: 'optionalBreaksTitle',
                                                        render: () => (
                                                            <>
                                                                <Typography variant="subtitle1">
                                                                    {t('subTitle.optionalBreaks', 'Optional Breaks')}
                                                                </Typography>
                                                                <Divider />
                                                            </>
                                                        )
                                                    }
                                                },
                                                {
                                                    type: 'multiRowInputs',
                                                    display: ({ breaks }) =>
                                                        ((breaks as { optionalBreaks?: any[] }).optionalBreaks as any[])
                                                            .length > 0,
                                                    props: {
                                                        name: 'optionalBreaks',
                                                        fixedSize: true,
                                                        value: allBreaks.filter(({ required }) => !required),
                                                        inputs: [
                                                            {
                                                                type: 'custom',
                                                                props: {
                                                                    name: 'optionalBreak',
                                                                    values: {},
                                                                    render: ({
                                                                        setValue,
                                                                        watch,
                                                                        fullName,
                                                                        parentName,
                                                                        readOnly
                                                                    }) => {
                                                                        const shiftStartEnd = watch('shiftStartEnd');
                                                                        const currentName = parentName ?? fullName;
                                                                        const values = watch(
                                                                            currentName
                                                                        ) as ArrayElement<typeof allBreaks>;

                                                                        return (
                                                                            <BreakItem
                                                                                key={values.id}
                                                                                breakDuration={values.breakDuration}
                                                                                breakName={values.breakName}
                                                                                day={DateHelper.toDate(day)}
                                                                                disabled={values.disabled}
                                                                                readOnly={readOnly}
                                                                                required={false}
                                                                                shiftEnd={shiftStartEnd.end ?? null}
                                                                                shiftItemDuration={
                                                                                    values.shiftItemDuration
                                                                                }
                                                                                shiftItemStart={values.shiftItemStart}
                                                                                shiftStart={shiftStartEnd.start ?? null}
                                                                                timeZone={timeZone}
                                                                                value={{
                                                                                    start: values.valueStart,
                                                                                    used: values.valueUsed
                                                                                }}
                                                                                onError={handleError}
                                                                                onChangeOutOfShift={(newValue) =>
                                                                                    setValue(currentName, {
                                                                                        ...values,
                                                                                        outOfShift: newValue
                                                                                    })
                                                                                }
                                                                                onChangeStart={(newStart) =>
                                                                                    setValue(currentName, {
                                                                                        ...values,
                                                                                        valueStart: newStart
                                                                                    })
                                                                                }
                                                                                onChangeUsed={(newUsed) =>
                                                                                    setValue(currentName, {
                                                                                        ...values,
                                                                                        valueUsed: newUsed
                                                                                    })
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    type: 'html',
                                                    display: () =>
                                                        (assignedShift?.schedule_plan_day_shift_system_breaks.length ??
                                                            0) > 0,
                                                    props: {
                                                        name: 'systemBreaks',
                                                        render: () => (
                                                            <>
                                                                <Typography variant="subtitle1">
                                                                    {t('subTitle.systemBreaks', 'System Breaks')}
                                                                </Typography>
                                                                <Divider />
                                                                <Table
                                                                    name="systemBreaks"
                                                                    columns={[
                                                                        {
                                                                            id: 'start',
                                                                            label: t('header.start', 'Start'),
                                                                            access: (row) =>
                                                                                dateTimeFormatter.format(
                                                                                    DateHelper.toDate(
                                                                                        DateHelper.fromDateTimeString(
                                                                                            row.start
                                                                                        )
                                                                                    )
                                                                                )
                                                                        },
                                                                        {
                                                                            id: 'duration',
                                                                            label: t('header.duration', 'Duration'),
                                                                            access: (row) =>
                                                                                DateHelper.getMinutesInHumanFormat(
                                                                                    row.duration
                                                                                )
                                                                        }
                                                                    ]}
                                                                    rows={
                                                                        assignedShift?.schedule_plan_day_shift_system_breaks ??
                                                                        []
                                                                    }
                                                                />
                                                            </>
                                                        )
                                                    }
                                                }
                                            ] as (ISimpleFieldTypes | IMultiRowInputsItem)[]
                                        },
                                        ...((isUserAdmin || isUserOwner
                                            ? [
                                                  {
                                                      name: 'requests',
                                                      label: t('subTitle.request', 'Request'),
                                                      display: ({ user_id }) => typeof id === 'number' && !!user_id,
                                                      inputs: [
                                                          {
                                                              type: 'custom',
                                                              props: {
                                                                  name: 'requests',
                                                                  values: {
                                                                      date: assignedShift
                                                                          ? DateHelper.setTimeZone(
                                                                                DateHelper.fromDateTimeString(
                                                                                    assignedShift?.shift_start
                                                                                ),
                                                                                workplace?.time_zone ?? ''
                                                                            )
                                                                          : '',
                                                                      workplace_id: `${workplace?.id ?? ''}`,
                                                                      dateTimeRange: {
                                                                          start: assignedShift?.shift_start
                                                                              ? DateHelper.setTimeZone(
                                                                                    DateHelper.fromDateTimeString(
                                                                                        assignedShift?.shift_start
                                                                                    ),
                                                                                    workplace?.time_zone ?? ''
                                                                                )
                                                                              : '',
                                                                          end: assignedShift?.shift_end
                                                                              ? DateHelper.setTimeZone(
                                                                                    DateHelper.fromDateTimeString(
                                                                                        assignedShift?.shift_end
                                                                                    ),
                                                                                    workplace?.time_zone ?? ''
                                                                                )
                                                                              : ''
                                                                      }
                                                                  },
                                                                  render: (args) => {
                                                                      const activeTab = args.watch('tabs');

                                                                      return (
                                                                          userData && (
                                                                              <UserToRequestForm
                                                                                  {...args}
                                                                                  fullWidth
                                                                                  onlyFields
                                                                                  isFromSchedulePlanDayShiftForm
                                                                                  user={userData}
                                                                                  noRequired={activeTab !== 'request'}
                                                                                  workplaceId={workplace?.id}
                                                                              />
                                                                          )
                                                                      );
                                                                  }
                                                              }
                                                          }
                                                      ]
                                                  },
                                                  {
                                                      name: 'trade',
                                                      label: t('subTitle.trade', 'Trade'),
                                                      display: ({ user_id }) => typeof id === 'number' && !!user_id,
                                                      inputs: [
                                                          {
                                                              type: 'custom',
                                                              props: {
                                                                  name: 'trade',
                                                                  values: {},
                                                                  render: ({
                                                                      register,
                                                                      initialValues,
                                                                      readOnly,
                                                                      setValue,
                                                                      watch
                                                                  }) => {
                                                                      const { name: fieldName } = register(
                                                                          'selectedTradeOrOffer',
                                                                          {
                                                                              value: initialValues.selectedTradeOrOffer
                                                                          }
                                                                      );
                                                                      const { name: isTradeFieldName } = register(
                                                                          'selectedTradeOrOffer_isTrade',
                                                                          {
                                                                              value: initialValues.true
                                                                          }
                                                                      );

                                                                      return (
                                                                          id && (
                                                                              <TabTrade
                                                                                  timeZone={workplace?.time_zone}
                                                                                  onNewTradeClick={handleNewTradeClick}
                                                                                  schedulePlanDayShiftId={id}
                                                                                  schedulePlanId={schedulePlanId}
                                                                                  disableNewTrade={
                                                                                      Array.isArray(trades) &&
                                                                                      trades.length > 0
                                                                                  }
                                                                                  readOnly={readOnly}
                                                                                  selectedId={
                                                                                      watch(fieldName) ??
                                                                                      initialValues.selectedTradeOrOffer ??
                                                                                      null
                                                                                  }
                                                                                  onSelectTrade={(selectedId) => {
                                                                                      setValue(fieldName, selectedId);
                                                                                      setValue(isTradeFieldName, true);
                                                                                  }}
                                                                                  onSelectOffer={(selectedId) => {
                                                                                      setValue(fieldName, selectedId);
                                                                                      setValue(isTradeFieldName, false);
                                                                                  }}
                                                                              />
                                                                          )
                                                                      );
                                                                  }
                                                              }
                                                          }
                                                      ]
                                                  }
                                              ]
                                            : []) as ITabType[]),
                                        {
                                            name: 'note',
                                            label: t('subTitle.note', 'Note'),
                                            display: () => typeof id === 'number',
                                            inputs: [
                                                {
                                                    type: 'textArea',
                                                    props: {
                                                        disabled: isUnassigned && !isUserAdmin,
                                                        name: 'note',
                                                        label: t('subTitle.note', 'Note'),
                                                        value: assignedShift?.description ?? '',
                                                        validation: {
                                                            pattern: {
                                                                value: regex.text,
                                                                message: message.text
                                                            }
                                                        }
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            }
                        ] as ISupportedFieldType[]
                    }
                    actions={
                        [
                            {
                                type: 'button',
                                props: {
                                    type: 'button',
                                    name: 'cancel',
                                    disabled: cancelDisableStatus,
                                    variant: 'text',
                                    readOnlyNoEffect: true,
                                    onClick: handleCancel,
                                    children: t('label.cancel', 'Cancel'),
                                    sx: {
                                        padding: 0
                                    }
                                }
                            },
                            {
                                type: 'button',
                                display: () => !isUserAdmin && isUnassigned && !isSchedulePlanClosed,
                                props: {
                                    type: 'button',
                                    name: 'assign',
                                    disabled: !enableAssign || (isUserOwner ? submitStatus : isAssignedButtonDisabled),
                                    title: !enableAssign
                                        ? t(
                                              'message.info.theShiftAssignmentIsLockedForNow',
                                              'The Empty Shift Assignment is available from {{date}}',
                                              {
                                                  date: schedulePlan?.empty_shifts_from
                                                      ? DateHelper.fromDateString(schedulePlan.empty_shifts_from)
                                                            .toDate()
                                                            .toLocaleDateString()
                                                      : ''
                                              }
                                          )
                                        : isAssignedButtonDisabled
                                        ? t(
                                              'message.info.userDoesNotSupportTheSkillsTheShifts',
                                              '{{user}} Does Not Support The Skills In The Shifts',
                                              {
                                                  user: serializeUser(signedUserData ?? undefined)
                                              }
                                          )
                                        : t('message.info.assignToUser', 'Assign To {{user}}', {
                                              user: serializeUser(signedUserData ?? undefined)
                                          }),
                                    readOnlyNoEffect: true,
                                    variant: 'contained',
                                    color: 'success',
                                    onClick: handleAssignedShiftToUser,
                                    children: t('label.assign', 'Assign'),
                                    sx: {
                                        padding: 0
                                    }
                                }
                            },
                            {
                                type: 'loadingButton',
                                display: () =>
                                    userCanTakeTradeWithoutOffer &&
                                    isEdit &&
                                    !isSchedulePlanClosed &&
                                    !isUserOwner &&
                                    trades &&
                                    trades.length > 0 &&
                                    isUserAllowed(
                                        {
                                            id: PermissionsEnum.ShiftTrades,
                                            mode: Mode.UPDATE
                                        },
                                        permissionsList
                                    ),
                                props: {
                                    name: 'forceTrade',
                                    color: 'secondary',
                                    disabled: submitStatus,
                                    loading: submitStatus,
                                    readOnlyNoEffect: true,
                                    type: 'button',
                                    variant: 'outlined',
                                    children: t('label.takeThisShiftFromTrade', 'Take Shift'),
                                    onClick: handleTakeShift
                                }
                            },
                            {
                                type: 'loadingButton',
                                display: ({ tabs }) => {
                                    return (
                                        isEdit &&
                                        !isSchedulePlanClosed &&
                                        tabs !== 'requests' &&
                                        (isUserAllowed(
                                            {
                                                id: PermissionsEnum.SchedulePlans,
                                                mode: Mode.DELETE
                                            },
                                            permissionsList
                                        ) ||
                                            (isUserOwner &&
                                                trades &&
                                                trades.length > 0 &&
                                                isUserAllowed(
                                                    {
                                                        id: PermissionsEnum.ShiftTrades,
                                                        mode: Mode.DELETE
                                                    },
                                                    permissionsList
                                                )))
                                    );
                                },
                                props: {
                                    type: 'button',
                                    name: 'remove',
                                    disabled: submitStatus,
                                    loading: removingStatus,
                                    onClick: handleRemove,
                                    variant: 'outlined',
                                    color: 'error',
                                    children: trades?.length
                                        ? t('label.removeFromTrade', 'Remove from Trade')
                                        : t('label.remove', 'Remove')
                                }
                            },
                            {
                                type: 'loadingButton',
                                display: ({ tabs }) => {
                                    switch (tabs) {
                                        case 'skills':
                                        case 'note':
                                        case 'breaks':
                                            return (
                                                (isUserAdmin || isUserOwner) &&
                                                !isSchedulePlanClosed &&
                                                isUserAllowed(
                                                    {
                                                        id: PermissionsEnum.SchedulePlans,
                                                        mode: [Mode.UPDATE, Mode.CREATE],
                                                        operator: 'OR'
                                                    },
                                                    permissionsList
                                                )
                                            );
                                        case 'requests':
                                            return (
                                                (isUserAdmin || isUserOwner) &&
                                                !isSchedulePlanClosed &&
                                                isUserAllowed(
                                                    {
                                                        id: PermissionsEnum.ShiftRequest,
                                                        mode: [Mode.UPDATE, Mode.CREATE],
                                                        operator: 'OR'
                                                    },
                                                    permissionsList
                                                )
                                            );
                                        case 'trade':
                                            return (
                                                (isUserAdmin || isUserOwner) &&
                                                !isSchedulePlanClosed &&
                                                isUserAllowed(
                                                    {
                                                        id: PermissionsEnum.ShiftTrades,
                                                        mode: [Mode.UPDATE, Mode.CREATE],
                                                        operator: 'OR'
                                                    },
                                                    permissionsList
                                                )
                                            );
                                        default:
                                            return false;
                                    }
                                },
                                props: {
                                    type: 'submit',
                                    name: 'default',
                                    color: isEdit ? 'success' : 'primary',
                                    disabled: ({ selectedTradeOrOffer, tabs }) =>
                                        (tabs === 'trade' && !selectedTradeOrOffer) ||
                                        removingStatus ||
                                        isSchedulePlanClosed,
                                    loading: submitStatus,
                                    variant: 'contained',
                                    children: isEdit ? t('label.save', 'Save') : t('label.add', 'Add')
                                }
                            }
                        ] as ISupportedFieldType[]
                    }
                    onClose={handleClose}
                    onOpen={handleOpen}
                    onSubmit={handleSubmit}
                />
            </UserPermission>
        </ErrorBoundary>
    );
};

export default ShiftPlanDayForm;
