import { useCallback } from 'react';
import CrudDatatable from '@/components/CrudDatatable';
import { breakEntities } from '@/data/Breaks/BreakSlice';
import { useAppSelector } from '@/data/hooks';
import { shiftEntities } from '@/data/Shifts/ShiftSlice';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import { IUserToShiftFormDataType } from '@/data/UserToShifts/UserToShiftsModels';
import UserToShiftForm from '@/forms/UserToShiftForm';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeTimeFormatter from '@/hooks/useLocalizeTimeFormatter';
import ChipStack from '@/wrappers/ChipStack';

type IShifts = {
    data: IUserToShiftFormDataType[];
    handleData: (value: IUserToShiftFormDataType[]) => void;
};

const ShiftsTab = ({ data, handleData }: IShifts) => {
    const { t } = useAppTranslation();
    const shiftsData = useAppSelector(shiftEntities);
    const breaks = useAppSelector(breakEntities) ?? [];
    const isSystemUser = useAppSelector(isSignedUserUser);
    const timeFormatter = useLocalizeTimeFormatter();

    const handleCreateData = useCallback(
        (values: IUserToShiftFormDataType) => {
            handleData([...data, values]);
        },
        [data, handleData]
    );
    const handleUpdateData = useCallback(
        (oldData: IUserToShiftFormDataType) => (newData: IUserToShiftFormDataType) => {
            handleData([...data.filter((item) => item.shift_id !== oldData.shift_id), newData]);
        },
        [data, handleData]
    );
    const handleDeleteData = useCallback(
        (id: number | string) => {
            handleData(data.filter((item) => item.shift_id !== id));

            return Promise.resolve();
        },
        [data, handleData]
    );

    return (
        <CrudDatatable<IUserToShiftFormDataType, number | string>
            data={data}
            formRender={(id, _justIcon, _openButtonValue, rowData, renderButton) => (
                <UserToShiftForm
                    data={rowData}
                    id={id}
                    usersShiftsData={data}
                    openButtonRender={renderButton}
                    onSubmit={id && rowData ? handleUpdateData(rowData) : handleCreateData}
                />
            )}
            getRowId={(row) => row.shift_id}
            hasSelection={false}
            hasSearch={false}
            header={[
                {
                    headerName: t('header.shift', 'Shift'),
                    field: 'shift_id',
                    sortComparator: (v1, v2) => shiftsData[v1]?.name.localeCompare(shiftsData[v2]?.name ?? '') ?? 1,
                    valueFormatter: (value) => shiftsData[value]?.name ?? value
                },
                {
                    headerName: t('header.breaks', 'Breaks'),
                    field: 'shift_items',
                    renderCell: ({ row }) => (
                        <ChipStack
                            name="breaks"
                            direction="row"
                            values={row.user_to_shift_items
                                .filter((item) => typeof item.used === 'undefined' || item.used)
                                .map((item) => ({
                                    id: item.id.toString(),
                                    label: breaks[item.shift_item.break_id]?.name ?? '',
                                    title: timeFormatter.format(DateHelper.fromTimeString(item.start, 'utc').toDate()),
                                    color: 'primary'
                                }))}
                        />
                    )
                }
            ]}
            //TODO noRecordMessage={t('message.info.everyShiftsAreAllowedForUser', 'Every shifts are allowed for user')}
            name="userToShifts"
            nameOfEntity={(item) => item.id.toString()}
            paging={{
                sort: 'shift_id',
                direction: 'asc'
            }}
            sortingMode="client"
            onRemove={isSystemUser ? undefined : handleDeleteData}
        />
    );
};

export default ShiftsTab;
