import Box from '@mui/material/Box';
import { GridRow, GridRowId, GridRowModel, GridRowProps, GridValidRowModel } from '@mui/x-data-grid';
import { ReactElement, useEffect, useRef, useState } from 'react';

type IProps<RowType extends GridValidRowModel> = GridRowProps & {
    detailPanel?: (data: GridRowModel<RowType>['row']) => ReactElement;
    openedDetailPanelIds: GridRowId[];
};

function RowWithPanel<RowType extends GridValidRowModel>({
    openedDetailPanelIds,
    detailPanel,
    ...rest
}: IProps<RowType>) {
    const currentOpenValue = openedDetailPanelIds.includes(rest.rowId);
    const [openDetail, setOpenDetail] = useState(currentOpenValue);
    const prevOpenedValue = useRef<boolean>(currentOpenValue);

    useEffect(() => {
        if (prevOpenedValue.current !== currentOpenValue) {
            setOpenDetail(currentOpenValue);
        }
    }, [currentOpenValue]);

    if (detailPanel && openDetail) {
        return (
            <>
                <GridRow {...rest} />
                <Box data-role="detailpanel" data-rowindex={rest.index}>
                    {detailPanel(rest.row as GridRowModel<RowType>['row'])}
                </Box>
            </>
        );
    }

    return <GridRow {...rest} />;
}

export default RowWithPanel;
