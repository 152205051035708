import { IBreakModel } from '@/data/Breaks/BreakModels';
import { IFilters } from '@/data/Filters';
import { IPeriodModel } from '@/data/Periods/PeriodModels';
import { IRoleModel } from '@/data/Roles/RoleModels';
import { ISchedulePlanDayBeModel } from '@/data/SchedulePlanDays/SchedulePlanDayModels';
import {
    ISchedulePlanDayShiftFromBEModel,
    ISchedulePlanDayShiftWithoutDefinitionFromBEModel
} from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { SchedulePlanStateEnum } from '@/data/SchedulePlans/SchedulePlanEnums';
import { ISchedulePlanValidationModel } from '@/data/SchedulePlanValidations/SchedulePlanValidationModels';
import { IShiftModel } from '@/data/Shifts/ShiftModels';
import { IShiftToWorkplaceModel } from '@/data/ShiftToWorkplaces/ShiftToWorkplaceModels';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { IUserFromBeModel } from '@/data/Users/UserModels';
import { IUserToRequestCUModel } from '@/data/UserToRequests/UserToRequestModels';
import { IUserToWorkplaceModel } from '@/data/UserToWorkplaces/UserToWorkplaceModels';
import { IWorkplaceFromBeModel } from '@/data/Workplaces/WorkplaceModels';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import { ICellDataType } from '@/helpers/schedule';
import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { fetchDelete, fetchDownloadFile, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import {
    ISchedulePlanBaseFromBeModel,
    ISchedulePlanBreaksSummaries,
    ISchedulePlanCUModel,
    ISchedulePlanDaySkillsCsvFileTempleData,
    ISchedulePlanFromBeModel,
    ISchedulePlanModel,
    ISchedulePlanUpdateRequirementsModel
} from './SchedulePlanModels';

/**
 * Please use this type only in *Api.ts files.
 */
export type ISchedulePlanDetail = ISchedulePlanModel & {
    body: ICellDataType<string, { user_id: number | null }>[];
    breaks: IBreakModel[];
    next_schedule_plans: ISchedulePlanModel[];
    previous_schedule_plans: ISchedulePlanModel[];
    roles: IRoleModel[];
    period: IPeriodModel;
    schedule_plan_days: ISchedulePlanDayBeModel[];
    schedule_plan_day_shifts: Omit<ISchedulePlanDayShiftFromBEModel, 'user'>[];
    schedule_plan_day_shifts_without_definition: ISchedulePlanDayShiftWithoutDefinitionFromBEModel[];
    schedule_plan_validations: ISchedulePlanValidationModel[];
    shifts: IShiftModel[];
    skills: ISkillModel[];
    skills_from_shift: ISkillModel[];
    table_body: {
        id: number; // skill_id
        count_of_rows: number;
        data: {
            id: number | '~'; // shift_id
            count_of_rows: number;
            data: Pick<ICellDataType<string, { user_id: number | null }>, 'from' | 'shift_joins'>[];
        }[];
    }[];
    user_ids_with_shift: number[];
    users: (Omit<IUserFromBeModel, 'subordinate'> & { subordinate: number[] })[];
    workplace: Omit<IWorkplaceFromBeModel, 'queue_to_workplaces' | 'user_to_workplaces' | 'shift_to_workplaces'> & {
        shift_to_workplaces: IShiftToWorkplaceModel[];
        user_to_workplaces: IUserToWorkplaceModel[];
    };
};

export function fetchList(params?: Record<string, IFilters | boolean | string | number | string[] | number[]>) {
    const url = urlBuilderWithParams('/api/schedule-plans', params ?? {});

    return fetchGet<IListResponseWithPaging<ISchedulePlanBaseFromBeModel>>(url);
}

export function fetchOnGoingSchedulePlansList() {
    return fetchGet<IListResponse<Omit<ISchedulePlanFromBeModel, 'schedule_plan_days' | 'schedule_plan_day_shifts'>>>(
        '/api/schedule-plans/ongoingSchedulePlans'
    );
}

export function fetchSchedulePlansListForSelect(search: string, fields: string[] = []) {
    const url = urlBuilderWithParams('/api/schedule-plans/select', { search, fields });

    return fetchGet<IListResponse<ISchedulePlanModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<ISchedulePlanDetail>(`/api/schedule-plans/${id}`);
}

export function fetchBreaksSummaries(data: { period: number; workplace: number; schedulePlan: number }) {
    const queryPath = urlBuilderWithParams('/api/schedule-plans/breaksSummaries', {
        period_id: data.period ?? 0,
        workplace_id: data.workplace ?? 0,
        schedule_plan_id: data.schedulePlan ?? 0
    });

    return fetchGet<ISchedulePlanBreaksSummaries>(queryPath);
}

export function create(data: ISchedulePlanCUModel) {
    return fetchPost<Omit<ISchedulePlanDetail, 'next_schedule_plans' | 'previous_schedule_plans'>>(
        '/api/schedule-plans/',
        data
    );
}

export function calculatePlan(id: number) {
    return fetchPost(`/api/schedule-plans/${id}/calculate`);
}

export function update(id: number, data: ISchedulePlanCUModel) {
    return fetchPatch<ISchedulePlanFromBeModel>(`/api/schedule-plans/${id}`, {
        ...data,
        id
    });
}

export function logInToPlan(id: number | string) {
    return fetchPost<{
        attendance_plans: { id: number; exists: null | boolean; name: string }[];
        schedule_plan_day_shift: ISchedulePlanDayShiftFromBEModel;
    }>(`/api/schedule-plan-attendances/${id}/log-in`);
}

export function logOutFromPlan(id: number | string) {
    return fetchPost<{
        attendance_plans: { id: number; exists: null | boolean; name: string }[];
        schedule_plan_day_shift: ISchedulePlanDayShiftFromBEModel;
    }>(`/api/schedule-plan-attendances/${id}/log-out`);
}

export function remove(id: number) {
    return fetchDelete(`/api/schedule-plans/${id}`);
}

type IUpdateRequirementsModel = Omit<ISchedulePlanUpdateRequirementsModel, 'from' | 'to'> & {
    from: string;
    to: string;
};

export function updateRequirements(data: IUpdateRequirementsModel) {
    return fetchPost('/api/schedule-plans/updateRequirements', data);
}
export function modifyNeeds(
    id: number,
    data: { from: string; to: string; change_to: number; is_percentage: boolean; skill_ids: number[] }
) {
    return fetchPost(`/api/schedule-plans/${id}/modify-needs`, data);
}

export function publishSchedulePlan(id: number) {
    return fetchPost(`/api/schedule-plans/${id}/publish`);
}

export function setSchedulePlanStateToOpen(schedulePlanId: number) {
    return fetchPost<ISchedulePlanModel>(`/api/schedule-plans/${schedulePlanId}/open`, {}, false);
}

export function setSchedulePlanStateToClose(schedulePlanId: number) {
    return fetchPost<{ state: keyof typeof SchedulePlanStateEnum }>(
        `/api/schedule-plans/${schedulePlanId}/close`,
        {},
        false
    );
}

export function validateSchedulePlan(id: number) {
    return fetchPost(`/api/schedule-plans/${id}/validate`);
}
export function recountFundsSchedulePlan(id: number) {
    return fetchPost(`/api/schedule-plans/${id}/recount-funds`);
}
export function downloadFunds(props: {
    workplaceIdsList: number[];
    userSourceType: string | null;
    monthsList: number[];
    year: number;
    closeExportedPlans: boolean;
}) {
    const url = urlBuilderWithParams('/api/reports/download/funds-file', props ?? {});

    return fetchDownloadFile(url);
}

export function downloadNeeds(props: { schedulePlanId: number }) {
    const url = urlBuilderWithParams('/api/reports/download/needs-file', props ?? {});

    return fetchDownloadFile(url);
}

export function find(periodId: number, workplaceId: number) {
    const queryPath = urlBuilderWithParams('/api/schedule-plans/find', {
        period_id: periodId,
        workplace_id: workplaceId
    });

    return fetchGet<IListResponse<ISchedulePlanFromBeModel>>(queryPath);
}

export function createOrUpdateRequest(
    schedulePlanId: number,
    schedulePlanDayShiftId: number,
    data: IUserToRequestCUModel
) {
    return fetchPost<ArrayElement<Required<ISchedulePlanDayShiftFromBEModel>['schedule_plan_day_shift_requests']>>(
        `/api/shift-requests/${schedulePlanId}/day-users/${schedulePlanDayShiftId}/request`,
        { ...data, schedule_plan_day_shift_id: schedulePlanDayShiftId }
    );
}

export function clear(id: number) {
    return fetchPost<ISchedulePlanFromBeModel>(`/api/schedule-plans/${id}/clear`, []);
}

export function recalculate(id: number) {
    return fetchPost(`/api/schedule-plans/${id}/recalculate`, []);
}

export function recalculateWithShifts(id: number) {
    return fetchPost(`/api/schedule-plans/${id}/recalculateWithShifts`, []);
}
export function schedulePlanDaySkillsCsvTempleData() {
    return fetchGet<ISchedulePlanDaySkillsCsvFileTempleData>(
        '/api/schedule-plans/get-schedule-plan-day-skills-csv-temple-data'
    );
}
export function schedulePlanDaySkillsCsvTempleFile() {
    return fetchDownloadFile('/api/schedule-plans/get-schedule-plan-day-skills-csv-temple-file');
}

export function clearShifts(id: number) {
    return fetchPost(`/api/schedule-plans/${id}/clear-shifts`, []);
}
