import { useCallback, useMemo, useState } from 'react';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { ISupportedValueType } from '@/base/FormGenerator';
import CuForm, { ICuProps } from '@/components/CuForm';
import { IBreakModel } from '@/data/Breaks/BreakModels';
import { breakEntities } from '@/data/Breaks/BreakSlice';
import { useAppSelector } from '@/data/hooks';
import { IShiftItemModel, IShiftModel } from '@/data/Shifts/ShiftModels';
import { shiftEntities } from '@/data/Shifts/ShiftSlice';
import { IUserToShiftFormDataType } from '@/data/UserToShifts/UserToShiftsModels';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';

type IProps = Pick<ICuProps<IUserToShiftFormDataType, number | string>, 'openButtonRender'> & {
    id?: string | number;
    data?: IUserToShiftFormDataType;
    usersShiftsData?: IUserToShiftFormDataType[];
    onSubmit: (newData: IUserToShiftFormDataType) => void;
};

export type IShiftItemFormValues = Omit<IShiftItemModel, 'id' | 'start'> & {
    id: string | number;
    used?: boolean;
    start: DateTimeType | null;
    shift_items_start: DateTimeType;
};

const UserToShiftForm = ({ id, data, usersShiftsData, onSubmit }: IProps) => {
    const { t } = useAppTranslation();
    const shifts = useAppSelector(shiftEntities) ?? [];
    const breaks = useAppSelector(breakEntities) ?? [];
    const [shiftId, setShiftId] = useState<number | undefined>(data?.shift_id);

    const getShiftsItems = useMemo(
        (): IShiftItemFormValues[] | undefined =>
            shiftId
                ? shifts[shiftId]?.shift_items?.map((entity) => {
                      const dataItem = data?.user_to_shift_items?.find(
                          (dataEntity) => dataEntity.shift_item_id === entity.id
                      );
                      const breakData = breaks[entity.break_id];

                      return {
                          ...entity,
                          used: !breakData?.required ? dataItem?.used ?? false : undefined,
                          shift_items_start: DateHelper.fromTimeString(entity.start, 'utc'),
                          start: DateHelper.fromOptionalTime(dataItem?.start ?? entity.start, 'utc')
                      };
                  })
                : undefined,
        [id, data, shifts, shiftId, breaks]
    );

    const handleCreate = useCallback(
        (values: ISupportedValueType) => {
            if (values.shift_id) {
                const userToShiftId = generateUniqueID();

                onSubmit({
                    id: userToShiftId,
                    _type: 'new',
                    shift_id: parseInt(values.shift_id.toString()),
                    user_to_shift_items: values.set_breaks
                        ? (values.shift_items as IShiftItemFormValues[])?.map((item) => ({
                              shift_item: {
                                  break_id: item.break_id
                              },
                              id: generateUniqueID(),
                              shift_item_id: typeof item.id === 'string' ? parseInt(item.id) : item.id,
                              start: DateHelper.formatTime(item.start as DateTimeType),
                              used: item.used,
                              user_to_shift_id: userToShiftId
                          }))
                        : []
                });
            }
        },
        [id, onSubmit, shiftId, breaks]
    );

    const handleUpdate = useCallback(
        (values: ISupportedValueType) => {
            if (values.shift_id) {
                const userToShiftId = data?.id ?? generateUniqueID();

                onSubmit({
                    id: id as number | string,
                    _type: 'changed',
                    shift_id: parseInt(values.shift_id.toString()),
                    user_to_shift_items: values.set_breaks
                        ? (values.shift_items as IShiftItemFormValues[])?.map((item) => ({
                              id: item.id ?? generateUniqueID(),
                              start: DateHelper.formatTime(item.start as DateTimeType),
                              user_to_shift_id: userToShiftId,
                              shift_item: {
                                  break_id: item.break_id
                              },
                              shift_item_id: typeof item.id === 'string' ? parseInt(item.id) : item.id,
                              used: item.used
                          }))
                        : []
                });
            }
        },
        [id, onSubmit, data, shiftId, breaks]
    );

    return (
        <CuForm<IUserToShiftFormDataType, IUserToShiftFormDataType, number | string, false>
            id={id}
            displayAsSidebar={true}
            onOpen={() => setShiftId(data?.shift_id)}
            items={[
                {
                    type: 'select',
                    props: {
                        name: 'shift_id',
                        required: true,
                        readOnly: !!id,
                        disabled: !!id,
                        value: data?.shift_id.toString(),
                        onChange: (newValue) => setShiftId(newValue ? parseInt(newValue) : undefined),
                        label: t('label.shift', 'Shift'),
                        validation: {
                            deps: ['shift_id']
                        },
                        options: (Object.values(shifts) as IShiftModel[])
                            .filter((item) => {
                                const result = usersShiftsData?.some((entity) => entity.shift_id === item.id);

                                return id ? result : !result;
                            })
                            .map((item) => ({
                                id: item.id.toString(),
                                label: item.name
                            }))
                    }
                },
                {
                    type: 'switch',
                    display: ({ shift_id }: { shift_id?: number }) =>
                        (shift_id ? shifts[shift_id]?.shift_items.length ?? 0 : 0) > 0,
                    props: {
                        name: 'set_breaks',
                        label: t('label.setBreaks', 'Set Breaks'),
                        value: (data?.user_to_shift_items?.length ?? 0) > 0,
                        validation: {
                            deps: ['shift_items']
                        }
                    }
                },
                {
                    type: 'multiRowInputs',
                    display: ({ set_breaks }) => !!set_breaks,
                    props: {
                        name: 'shift_items',
                        validation: {
                            deps: ['set_breaks', 'shift_id']
                        },
                        fixedSize: true,
                        value: getShiftsItems,
                        inputs: [
                            {
                                type: 'switch',
                                display: (values) => {
                                    const rowData = values as IShiftItemFormValues | undefined;
                                    const breakData = rowData?.break_id ? breaks[rowData?.break_id] : undefined;

                                    return !breakData?.required ?? false;
                                },
                                props: {
                                    name: 'used',
                                    label: t('label.used', 'Used'),
                                    width: 3,
                                    labelPlacement: 'start',
                                    validation: {
                                        deps: ['used']
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    required: ({ set_breaks }) => !!set_breaks,
                                    readOnly: true,
                                    name: 'break.name',
                                    label: t('label.break', 'Break'),
                                    type: 'text',
                                    width: 3
                                }
                            },
                            {
                                type: 'time',
                                props: {
                                    label: t('label.start', 'Start'),
                                    name: 'start',
                                    disabled: (values) => {
                                        const rowData = values as IShiftItemFormValues | undefined;
                                        const breakData = rowData?.break_id ? breaks[rowData?.break_id] : undefined;

                                        if (breakData?.required) {
                                            return false;
                                        }

                                        return !rowData?.used;
                                    },
                                    value: (rowData) => rowData.start as DateTimeType | null,
                                    required: ({ set_breaks }) => !!set_breaks,
                                    minutesStep: 15,
                                    width: 3,
                                    minTime: (rowData) => {
                                        const shiftItemData = rowData as IShiftItemFormValues;
                                        const shiftItem = getShiftsItems?.find((item) => item.id === shiftItemData.id);

                                        return DateHelper.fromOptionalTime(shiftItem?.shift_items_start) ?? undefined;
                                    },
                                    maxTime: (rowData) => {
                                        const shiftItemData = rowData as IShiftItemFormValues;
                                        const shiftItem = getShiftsItems?.find((item) => item.id === shiftItemData.id);
                                        const breakData = shiftItem?.break_id ? breaks[shiftItem?.break_id] : undefined;
                                        const start =
                                            DateHelper.fromOptionalTime(shiftItem?.shift_items_start, 'utc') ??
                                            undefined;

                                        if (start && shiftItem) {
                                            return DateHelper.addMinutes(
                                                DateHelper.addHours(start, shiftItem.duration),
                                                -(breakData?.duration ?? 0)
                                            );
                                        }

                                        return undefined;
                                    },
                                    timezone: 'UTC',
                                    validation: {
                                        deps: ['start', 'used', 'set_breaks']
                                    }
                                }
                            },
                            {
                                type: 'time',
                                props: {
                                    label: t('label.end', 'end'),
                                    name: 'end',
                                    disabled: true,
                                    value: (rowData) =>
                                        DateHelper.addMinutes(
                                            DateHelper.fromOptionalTime(rowData.start as DateTimeType | string),
                                            (rowData.break as IBreakModel)?.duration ?? 0
                                        ),
                                    validation: {
                                        deps: ['start']
                                    },
                                    readOnly: true,
                                    width: 3
                                }
                            }
                        ]
                    }
                }
            ]}
            maxWidth="xl"
            justIcon={Boolean(id)}
            name="userToShift"
            onClose={() => setShiftId(undefined)}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
        />
    );
};

export default UserToShiftForm;
