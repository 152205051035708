import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OpenButton } from '@/base/FormGenerator/FormGenerator';
import CrudDatatable from '@/components/CrudDatatable';
import UserPermission, { Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchSettingsItems } from '@/data/SettingsItems/SettingsItemActions';
import { isSignedUserAdmin, isSignedUserUser } from '@/data/System/SystemReducer';
import { fetchUsers, removeUser, updateUserActive } from '@/data/Users/UserActions';
import { IUserModel } from '@/data/Users/UserModels';
import { selectUsers, userPaging, userUpdatingStatus } from '@/data/Users/UserSlice';
import ResetPasswordToUserForm from '@/forms/ResetPasswordToUserForm/ResetPasswordToUserForm';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { mainUsersClone, mainUsersCreate, mainUsersDetail } from '@/utils/routes';
import { serializeUser } from '@/utils/UserHelper';
import Button from '@/wrappers/Button';
import ChipStack from '@/wrappers/ChipStack';
import ConfirmDialog from '@/wrappers/ConfirmDialog';
import Tooltip from '@/wrappers/Tooltip';
import UserAttendanceReportDialog from '@/wrappers/UserAttendanceReportDialog';
import UserDownloadDialog from '@/wrappers/UserDownloadDialog';

export default function UsersListPage() {
    const { t } = useAppTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const entities = useAppSelector(selectUsers);
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const data = Object.values(entities);
    const isCurrentUserUser = useAppSelector(isSignedUserUser);
    const [openUserAttendanceReportDialog, setOpenUserAttendanceReportDialog] = useState(false);
    const [openUserDownloadDialog, setOpenUserDownloadDialog] = useState(false);

    useEffect(() => {
        dispatch(fetchSettingsItems({}));
    }, []);

    const handleToggleActive = useCallback((item: IUserModel, onSuccess: () => void) => {
        dispatch(
            updateUserActive({
                id: item.id,
                data: {
                    active: !item.active
                },
                onSuccess
            })
        );
    }, []);

    return (
        <LayoutOfPage title={t('title.employeesList', 'Employees List')}>
            <CrudDatatable<ArrayElement<typeof data>>
                actions={(id, rowData) => (
                    <>
                        {isCurrentUserUser ? (
                            <></>
                        ) : (
                            <IconButton
                                title={t('title.cloneUser', 'Clone User')}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    navigate(rowData.id ? mainUsersClone({ id: rowData.id }) : mainUsersCreate());
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        )}
                        {isCurrentUserUser ? (
                            <></>
                        ) : (
                            <ConfirmDialog
                                justIcon
                                title={t(
                                    rowData.active
                                        ? 'message.question.doYouWantToDeactivateEmployee'
                                        : 'message.question.doYouWantToActivateEmployee',
                                    `Do you want to ${rowData.active ? 'deactivate' : 'activate'} employee?`
                                )}
                                openButton={(onClick: () => void) => (
                                    <Button
                                        name="status"
                                        justIcon
                                        title={
                                            rowData.active
                                                ? t('label.deactivateEmployee', 'Deactivate Employee')
                                                : t('label.activateEmployee', 'Activate Employee')
                                        }
                                        onClick={onClick}
                                    >
                                        {rowData.active ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </Button>
                                )}
                                onAgree={(onSuccess) => handleToggleActive(rowData, onSuccess)}
                            />
                        )}
                        {isCurrentUserUser ? (
                            <></>
                        ) : (
                            <UserPermission id={PermissionsEnum.Users} mode={Mode.UPDATE}>
                                <ResetPasswordToUserForm id={id!} userData={rowData} />
                            </UserPermission>
                        )}
                    </>
                )}
                autoHeight={false}
                countOfCustomRowActions={3}
                data={data}
                formRender={(id, justIcon, openButtonValue, _, renderButton) =>
                    id ? (
                        <UserPermission id={PermissionsEnum.Users} mode={Mode.UPDATE}>
                            <OpenButton
                                nameWithId={`user${id}`}
                                openButtonValue={openButtonValue || t('label.edit', 'Edit')}
                                justIcon={!!justIcon}
                                isEdit={true}
                                render={renderButton}
                                onClick={() => navigate(mainUsersDetail({ id }))}
                            />
                        </UserPermission>
                    ) : (
                        <>
                            {isUserAdmin ? (
                                <Tooltip title={t('label.userDownload', 'Users Download')}>
                                    <IconButton onClick={() => setOpenUserDownloadDialog(true)}>
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                            <Tooltip title={t('label.attendanceReport', 'Attendance Report')}>
                                <IconButton onClick={() => setOpenUserAttendanceReportDialog(true)}>
                                    <FunctionsOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <UserPermission id={PermissionsEnum.Users} mode={Mode.CREATE}>
                                <OpenButton
                                    nameWithId="user"
                                    openButtonValue={openButtonValue || t('label.add', 'Add')}
                                    justIcon={!!justIcon}
                                    isEdit={false}
                                    render={renderButton}
                                    onClick={() => navigate(mainUsersCreate())}
                                />
                            </UserPermission>
                        </>
                    )
                }
                header={[
                    { headerName: t('header.id', 'ID'), field: 'id' },
                    {
                        headerName: t('header.user', 'User'),
                        field: 'first_name',
                        renderCell: ({ row }) => <Typography>{serializeUser(row)}</Typography>
                    },
                    {
                        headerName: t('header.email', 'E-mail'),
                        field: 'email'
                    },
                    {
                        headerName: t('header.role', 'Role'),
                        field: 'role',
                        renderCell: ({ row }) => (
                            <ChipStack
                                name="roles"
                                direction="row"
                                values={
                                    row.user_to_roles?.map(({ role }) => ({
                                        id: role.id.toString(),
                                        name: role.name,
                                        label: t(`roles.${role.name.toLowerCase()}`, role.name),
                                        color: role.color
                                    })) ?? []
                                }
                            />
                        )
                    },
                    {
                        headerName: t('header.workplace', 'Workplace'),
                        field: 'workplace',
                        renderCell: ({ row }) => (
                            <ChipStack
                                name="workplaces"
                                direction="row"
                                values={row.user_to_workplaces.map(({ workplace }) => ({
                                    id: workplace.id.toString(),
                                    name: workplace.name,
                                    label: workplace.name
                                }))}
                            />
                        )
                    },
                    {
                        headerName: t('header.status', 'Status'),
                        field: 'active',
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Chip
                                color={row.active ? 'success' : 'error'}
                                label={`${row.active ? t('label.active', 'Active') : t('label.inactive', 'Inactive')}`}
                                variant="outlined"
                            />
                        ),
                        valueFormatter: (value) =>
                            value ? t('label.active', 'Active') : t('label.inactive', 'Inactive')
                    }
                ]}
                hiddenFields={['id']}
                nameOfEntity={(item) => `${item.first_name} ${item.last_name}`}
                name="employee"
                resource={PermissionsEnum.Users}
                disableRemove={isCurrentUserUser}
                paging={useAppSelector(userPaging)}
                updatingStatus={useAppSelector(userUpdatingStatus)}
                onFetchList={fetchUsers}
                onRemove={(id) =>
                    dispatch(removeUser(id))
                        .unwrap()
                        .then(() => id)
                }
            />
            <UserAttendanceReportDialog
                open={openUserAttendanceReportDialog}
                onDialogClose={() => setOpenUserAttendanceReportDialog(false)}
            />
            <UserDownloadDialog open={openUserDownloadDialog} onDialogClose={() => setOpenUserDownloadDialog(false)} />
        </LayoutOfPage>
    );
}
