import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchTimeoffs, removeTimeoff, updateTimeoff } from '@/data/Timeoffs/TimeoffActions';
import { ITimeoffModel } from '@/data/Timeoffs/TimeoffModels';
import { timeoffList, timeoffPaging } from '@/data/Timeoffs/TimeoffReducer';
import TimeoffForm from '@/forms/TimeoffForm';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import PageHeading from '@/wrappers/PageHeading';
import Switch from '@/wrappers/Switch';

export default function TimeoffsListPage() {
    const dispatch = useAppDispatch();
    const data = useAppSelector(timeoffList);

    return (
        <>
            <PageHeading>Time offs</PageHeading>
            <CrudDatatable<ITimeoffModel>
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue) => (
                    <TimeoffForm displayAsSidebar justIcon={justIcon} id={id} openButtonValue={openButtonValue} />
                )}
                header={[
                    { headerName: 'Time off name', field: 'name' },
                    {
                        headerName: 'Paid',
                        field: 'paid',
                        renderCell: ({ row }) => (
                            <Switch
                                name={row.name}
                                value={row.paid}
                                onChange={(value) => {
                                    dispatch(
                                        updateTimeoff({
                                            id: row.id,
                                            data: {
                                                paid: value
                                            }
                                        })
                                    );
                                }}
                            />
                        )
                    },
                    {
                        headerName: 'Active',
                        field: 'active',
                        renderCell: ({ row }) => (
                            <Switch
                                name={row.name}
                                value={row.active}
                                onChange={(value) => {
                                    dispatch(
                                        updateTimeoff({
                                            id: row.id,
                                            data: {
                                                active: value
                                            }
                                        })
                                    );
                                }}
                            />
                        )
                    }
                ]}
                name="timeoff"
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(timeoffPaging)}
                resource={PermissionsEnum.Timeoffs}
                onFetchList={fetchTimeoffs}
                onRemove={(id) =>
                    dispatch(removeTimeoff(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </>
    );
}
