import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, fetchSelectList, remove, update } from './RequestTypeApi';
import { IRequestTypeCUModel } from './RequestTypeModels';
import { requestPaging, requestTypeSelectValuesStatus, requestTypeAll } from './RequestTypeSlice';

export const fetchRequestTypes = createAsyncThunk('requestTypes/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = requestPaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchRequestTypesForSelect = createAsyncThunk(
    'requestTypes/selectList',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSelectList(args.search, args.fields || []);
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (requestTypeSelectValuesStatus(state) !== 'idle' || requestTypeAll(state)?.length) {
                return false;
            }
        }
    }
);

export const fetchRequestTypeById = createAsyncThunk('requestTypes/byId', async (args: number) => {
    return await fetchById(args);
});

export const createRequestType = createAsyncThunk('requestTypes/create', async (args: IRequestTypeCUModel) => {
    return await create(args);
});

export const updateTypeRequest = createAsyncThunk(
    'requestTypes/update',
    async (args: { id: number; data: Partial<IRequestTypeCUModel> }) => {
        return await update(args.id, args.data);
    }
);

export const removeTypeRequest = createAsyncThunk('requestTypes/remove', async (id: number) => remove(id));
