import Divider from '@mui/material/Divider';
import { useCallback, useState } from 'react';
import { IValuesOfSimpleFields } from '@/base/FormGenerator';
import { ICustomItemRenderData } from '@/base/FormGenerator/FieldTypes';
import { IOutputValueType } from '@/base/FormGenerator/FormGenerator';
import CuForm, { ICuProps } from '@/components/CuForm';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { useAppSelector } from '@/data/hooks';
import { createSetting, updateSetting } from '@/data/SettingsItems/SettingsItemActions';
import {
    IAzureConfig,
    IEvolutionAlgorithmBlocksConfig,
    IGoogleConfig,
    IMicrosoftConfig,
    INeznamConfig,
    IMegalaxExportMapConfig,
    IPinyaConfig,
    ISendGridConfig,
    ISettingItems,
    ISettingsItemModel
} from '@/data/SettingsItems/SettingsItemModels';
import {
    getCreatingOfSettingsItemStatus,
    getSettingsItemsByApplicationKey,
    getUpdatingOfSettingsItemStatus,
    settingsItemList
} from '@/data/SettingsItems/SettingsItemSlice';
import { getPermissionsList } from '@/data/System/SystemReducer';
import Azure from '@/forms/IntegratedApplicationForm/Azure';
import EvolutionAlgorithmBlocksParameters from '@/forms/IntegratedApplicationForm/EvolutionAlgorithmBlocksParameters';
import Google from '@/forms/IntegratedApplicationForm/Google';
import MegalaxExportMap from '@/forms/IntegratedApplicationForm/MegalaxExportMap';
import Microsoft from '@/forms/IntegratedApplicationForm/Microsoft';
import Neznam from '@/forms/IntegratedApplicationForm/Neznam';
import Pinya from '@/forms/IntegratedApplicationForm/Pinya';
import SendGrid from '@/forms/IntegratedApplicationForm/SendGrids';
import { getSettingsItemValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import IntegrationsSettingsMicrosoftItemEnum from '@/utils/enums/IntegrationsSettings/IntegrationsSettingsMicrosoftItemEnum';
import IntegrationsSettingsMicrosoftItemKeyMapEnum from '@/utils/enums/IntegrationsSettings/IntegrationsSettingsMicrosoftItemKeyMapEnum';
import IntegrationsSettingsMegalaxExportMapItemEnum from '@/utils/enums/MegalaxSettings/IntegrationsSettingsMegalaxExportMapItemEnum';
import PermissionsEnum, { PermissionsEnumType } from '@/utils/enums/PermissionsEnum';
import SettingsTypesEnum from '@/wrappers/Dashboard/Content/BreaksSummary/types/SettingsTypesEnum';
import { ISelectOption } from '@/wrappers/Select';

type IProps = Omit<ICuProps<ISettingsItemModel, SettingsTypesEnum>, 'resource'>;

const IntegratedApplication = ({ id, ...rest }: IProps) => {
    const { t } = useAppTranslation();
    const alreadyCreatedSettingsItems = useAppSelector(settingsItemList);
    const creatingStatus = useAppSelector(getCreatingOfSettingsItemStatus);
    const updatingStatus = useAppSelector(getUpdatingOfSettingsItemStatus);
    const permissionsList = useAppSelector(getPermissionsList);
    const [applicationKey, setApplicationKey] = useState<SettingsTypesEnum | null>(id ?? null);
    const applicationSettingsItems = useAppSelector((state) =>
        applicationKey ? getSettingsItemsByApplicationKey(state, applicationKey) : []
    );

    const handleData = useCallback(
        (source: IOutputValueType): ISettingItems[] => {
            let keys: string[] = [];
            let unsetKeysList: string[] = [];
            let validApplicationKey: SettingsTypesEnum;

            if (!applicationKey) {
                return [];
            }

            const sourceData = (source[applicationKey] ?? {}) as IOutputValueType;

            if (source.type === SettingsTypesEnum.EvolutionAlgorithmBlocksParameters) {
                validApplicationKey = SettingsTypesEnum.EvolutionAlgorithmBlocksParameters;
                keys = [
                    'thread_count',
                    'max_generations',
                    'population_size',
                    'limit_generations_without_improvement',
                    'simulated_annealing_period',
                    'reversed_generating'
                ];
            } else if (source.type === SettingsTypesEnum.Google) {
                validApplicationKey = SettingsTypesEnum.Google;
                keys = ['client_id', 'client_email', 'signing_key', 'subject'];
            } else if (source.type === SettingsTypesEnum.Pinya) {
                validApplicationKey = SettingsTypesEnum.Pinya;
                keys = [
                    'access_token',
                    'base_url',
                    'endpoint_employees',
                    'endpoint_vacations',
                    'include_working_times',
                    'offset_date_from',
                    'offset_date_to',
                    'tenant'
                ];
            } else if (source.type === SettingsTypesEnum.SendGrid) {
                validApplicationKey = SettingsTypesEnum.SendGrid;
                keys = ['response_email', 'response_name', 'api_key'];
            } else if (source.type === SettingsTypesEnum.MicrosoftActiveDirectoryLogin) {
                validApplicationKey = SettingsTypesEnum.MicrosoftActiveDirectoryLogin;

                const keyMapsKeys: string[] = [];

                Object.values(IntegrationsSettingsMicrosoftItemKeyMapEnum).forEach((item) => {
                    keyMapsKeys.push(item);
                    if (
                        ![
                            IntegrationsSettingsMicrosoftItemKeyMapEnum.name,
                            IntegrationsSettingsMicrosoftItemKeyMapEnum.surname,
                            IntegrationsSettingsMicrosoftItemKeyMapEnum.login,
                            IntegrationsSettingsMicrosoftItemKeyMapEnum.email,
                            IntegrationsSettingsMicrosoftItemKeyMapEnum.userExternalID
                        ].some((enumItem) => enumItem === item)
                    ) {
                        keyMapsKeys.push(item + '_index_start');
                        keyMapsKeys.push(item + '_index_end');
                    }
                });

                keys = [
                    'host',
                    'authenticate_domain',
                    'set_download_settings',
                    'username',
                    'password',
                    'base',
                    'filter',
                    'download_domain',
                    'tls',
                    'port',
                    ...keyMapsKeys
                ];

                if (!sourceData.set_download_settings) {
                    unsetKeysList = [
                        'username',
                        'password',
                        'base',
                        'filter',
                        'download_domain',
                        'tls',
                        'port',
                        ...keyMapsKeys
                    ];
                } else {
                    (
                        source.optionalKeyMap as {
                            key: string;
                            value: string;
                            indexStart?: string | number;
                            indexEnd?: string | number;
                        }[]
                    ).forEach((item) => {
                        sourceData[item.key] = item.value;
                        if (item.indexStart === 0 || !!item.indexStart) {
                            sourceData[item.key + '_index_start'] =
                                typeof item.indexStart == 'string' ? parseInt(item.indexStart) : item.indexStart;
                        }

                        if (item.indexEnd === 0 || !!item.indexEnd) {
                            sourceData[item.key + '_index_end'] =
                                typeof item.indexEnd == 'string' ? parseInt(item.indexEnd) : item.indexEnd;
                        }
                    });
                }

                sourceData.port = typeof sourceData.port === 'string' ? parseInt(sourceData.port) : sourceData.port;
            } else if (source.type === SettingsTypesEnum.AzureActiveDirectoryLogin) {
                validApplicationKey = SettingsTypesEnum.AzureActiveDirectoryLogin;
                keys = ['client_id', 'tenant_id', 'client_secret'];
            } else if (source.type === SettingsTypesEnum.Neznam) {
                validApplicationKey = SettingsTypesEnum.Neznam;
                keys = ['auth_url', 'base_url', 'client_id', 'client_secret', 'employees_uri'];
            } else if (source.type === SettingsTypesEnum.MegalaxExportMap) {
                validApplicationKey = SettingsTypesEnum.MegalaxExportMap;

                Object.values(IntegrationsSettingsMegalaxExportMapItemEnum).forEach((item) => {
                    keys.push(item);

                    sourceData[item] =
                        typeof sourceData[item] === 'string' ? parseInt(`${sourceData[item]}`) : sourceData[item];
                });
            }

            return keys.map((key) => ({
                application_key: validApplicationKey,
                key,
                value: unsetKeysList.some((unsetKey) => unsetKey === key)
                    ? null
                    : (sourceData[key] as boolean | number | string | null)
            })) as ISettingItems[];
        },
        [applicationKey]
    );

    const handleCreate = useCallback(
        (values: IOutputValueType) =>
            createSetting({ application_key: values.type, value: handleData(values) } as ISettingsItemModel),
        [applicationKey]
    );

    const handleOpen = useCallback(() => id && setApplicationKey(id), [id]);

    const handleUpdate = useCallback(
        (values: IOutputValueType) => {
            return updateSetting({
                id: values.type as ISettingsItemModel['application_key'],
                data: {
                    application_key: values.type,
                    value: handleData(values)
                } as ISettingsItemModel
            });
        },
        [applicationKey]
    );
    const typeOptions = [
        {
            id: SettingsTypesEnum.Google,
            label: t('label.google', 'Google')
        },
        {
            id: SettingsTypesEnum.EvolutionAlgorithmBlocksParameters,
            label: t('label.evolutionAlgorithmBlocksParameters', 'Evolution Algorithm Blocks Parameters')
        },
        {
            id: SettingsTypesEnum.SendGrid,
            label: t('label.sendGrid', 'Send Grid')
        },
        {
            id: SettingsTypesEnum.Pinya,
            label: t('label.pinya', 'Pinya')
        },
        {
            id: SettingsTypesEnum.MicrosoftActiveDirectoryLogin,
            label: t('label.microsoftActiveDirectory', 'Microsoft Active Directory')
        },
        {
            id: SettingsTypesEnum.AzureActiveDirectoryLogin,
            label: t('label.azureActiveDirectory', 'Azure Active Directory')
        },
        {
            id: SettingsTypesEnum.Neznam,
            label: t('label.neznam', 'Neznam')
        },
        {
            id: SettingsTypesEnum.MegalaxExportMap,
            label: t('label.accountingExportMap', 'Accounting Export Map')
        }
    ];
    let typeOptionsSelect = [] as ISelectOption[];

    if (id) {
        const event = typeOptions.find((item) => item.id === applicationKey);

        if (event) {
            typeOptionsSelect.push(event);
        }
    } else {
        typeOptionsSelect = typeOptions.filter(
            (item) =>
                !alreadyCreatedSettingsItems.some((entity) => entity.application_key === item.id) &&
                isUserAllowed(
                    {
                        id: `${PermissionsEnum.SettingsItems}-${item.id}`,
                        mode: [Mode.CREATE, Mode.UPDATE]
                    },
                    permissionsList
                )
        );
    }

    if (typeOptionsSelect.length === 0) {
        return null;
    }

    const indexOfEnum = Object.values(PermissionsEnum).indexOf(
        `${PermissionsEnum.SettingsItems}-${id}` as PermissionsEnumType
    );
    const permissionById = id ? Object.values(PermissionsEnum)[indexOfEnum] : undefined;

    const getFormValues = (): IValuesOfSimpleFields => {
        switch (applicationKey) {
            case SettingsTypesEnum.EvolutionAlgorithmBlocksParameters:
                return {
                    [applicationKey]: {
                        max_generations: getSettingsItemValueByKey(applicationSettingsItems, 'max_generations'),
                        population_size: getSettingsItemValueByKey(applicationSettingsItems, 'population_size'),
                        thread_count: getSettingsItemValueByKey(applicationSettingsItems, 'thread_count'),
                        limit_generations_without_improvement: getSettingsItemValueByKey(
                            applicationSettingsItems,
                            'limit_generations_without_improvement'
                        ),
                        simulated_annealing_period: getSettingsItemValueByKey(
                            applicationSettingsItems,
                            'simulated_annealing_period'
                        ),
                        reversed_generating: getSettingsItemValueByKey(applicationSettingsItems, 'reversed_generating')
                    } as IEvolutionAlgorithmBlocksConfig
                };

            case SettingsTypesEnum.Google:
                return {
                    [applicationKey]: {
                        client_id: getSettingsItemValueByKey(applicationSettingsItems, 'client_id'),
                        client_email: getSettingsItemValueByKey(applicationSettingsItems, 'client_email'),
                        signing_key: getSettingsItemValueByKey(applicationSettingsItems, 'signing_key'),
                        subject: getSettingsItemValueByKey(applicationSettingsItems, 'subject')
                    } as IGoogleConfig
                };

            case SettingsTypesEnum.Pinya:
                return {
                    [applicationKey]: {
                        access_token: getSettingsItemValueByKey(applicationSettingsItems, 'access_token'),
                        base_url: getSettingsItemValueByKey(applicationSettingsItems, 'base_url'),
                        endpoint_vacations: getSettingsItemValueByKey(applicationSettingsItems, 'endpoint_vacations'),
                        endpoint_employees: getSettingsItemValueByKey(applicationSettingsItems, 'endpoint_employees'),
                        include_working_times: getSettingsItemValueByKey(
                            applicationSettingsItems,
                            'include_working_times'
                        ),
                        offset_date_from: getSettingsItemValueByKey(applicationSettingsItems, 'offset_date_from'),
                        offset_date_to: getSettingsItemValueByKey(applicationSettingsItems, 'offset_date_to'),
                        tenant: getSettingsItemValueByKey(applicationSettingsItems, 'tenant')
                    } as IPinyaConfig
                };

            case SettingsTypesEnum.SendGrid:
                return {
                    [applicationKey]: {
                        response_email: getSettingsItemValueByKey(applicationSettingsItems, 'response_email'),
                        response_name: getSettingsItemValueByKey(applicationSettingsItems, 'response_name'),
                        api_key: getSettingsItemValueByKey(applicationSettingsItems, 'api_key')
                    } as ISendGridConfig
                };

            case SettingsTypesEnum.MicrosoftActiveDirectoryLogin: {
                const obj: { [key: string]: { [key: string]: string | number | boolean | null | undefined } } = {};
                const requiredKeyMap = [
                    IntegrationsSettingsMicrosoftItemKeyMapEnum.name,
                    IntegrationsSettingsMicrosoftItemKeyMapEnum.surname,
                    IntegrationsSettingsMicrosoftItemKeyMapEnum.login,
                    IntegrationsSettingsMicrosoftItemKeyMapEnum.email,
                    IntegrationsSettingsMicrosoftItemKeyMapEnum.userExternalID
                ];

                Object.assign(obj, { [applicationKey]: {} });

                Object.values(IntegrationsSettingsMicrosoftItemEnum).forEach((item) => {
                    Object.assign(obj[applicationKey], {
                        [item]: getSettingsItemValueByKey(applicationSettingsItems, item)
                    });
                });

                Object.values(IntegrationsSettingsMicrosoftItemKeyMapEnum)
                    .filter((item) => requiredKeyMap.some((required) => required == item))
                    .forEach((item) => {
                        Object.assign(obj[applicationKey], {
                            [item]: getSettingsItemValueByKey(applicationSettingsItems, item)
                        });
                    });

                const objectOptionalKeyMap: {
                    key: string;
                    value: string | number | boolean | null | undefined;
                    setStartAndEndIndex?: boolean;
                    indexStart?: number;
                    indexEnd?: number;
                }[] = [];

                Object.values(IntegrationsSettingsMicrosoftItemKeyMapEnum)
                    .filter((item) => !requiredKeyMap.some((required) => required == item))
                    .forEach((item) => {
                        const indexStart = getSettingsItemValueByKey(
                            applicationSettingsItems,
                            item + '_index_start'
                        ) as number | null | undefined;

                        const indexEnd = getSettingsItemValueByKey(applicationSettingsItems, item + '_index_end') as
                            | number
                            | null
                            | undefined;

                        if (getSettingsItemValueByKey(applicationSettingsItems, item)) {
                            objectOptionalKeyMap.push({
                                key: item,
                                value: getSettingsItemValueByKey(applicationSettingsItems, item),
                                setStartAndEndIndex: !!indexStart || !!indexEnd,
                                indexStart: indexStart ?? undefined,
                                indexEnd: indexEnd ?? undefined
                            });
                        }
                    });

                Object.assign(obj, { optionalKeyMap: objectOptionalKeyMap });

                return obj as IMicrosoftConfig;
            }
            case SettingsTypesEnum.AzureActiveDirectoryLogin:
                return {
                    [applicationKey]: {
                        client_id: getSettingsItemValueByKey(applicationSettingsItems, 'client_id'),
                        client_secret: getSettingsItemValueByKey(applicationSettingsItems, 'client_secret'),
                        tenant_id: getSettingsItemValueByKey(applicationSettingsItems, 'tenant_id')
                    } as IAzureConfig
                };
            case SettingsTypesEnum.Neznam:
                return {
                    [applicationKey]: {
                        auth_url: getSettingsItemValueByKey(applicationSettingsItems, 'auth_url'),
                        base_url: getSettingsItemValueByKey(applicationSettingsItems, 'base_url'),
                        client_id: getSettingsItemValueByKey(applicationSettingsItems, 'client_id'),
                        client_secret: getSettingsItemValueByKey(applicationSettingsItems, 'client_secret'),
                        employees_uri: getSettingsItemValueByKey(applicationSettingsItems, 'employees_uri')
                    } as INeznamConfig
                };
            case SettingsTypesEnum.MegalaxExportMap: {
                const obj: { [key: string]: string | number | boolean | null | undefined } = {};

                Object.values(IntegrationsSettingsMegalaxExportMapItemEnum).forEach((item) => {
                    Object.assign(obj, { [item]: getSettingsItemValueByKey(applicationSettingsItems, item) });
                });

                return { [applicationKey]: obj as IMegalaxExportMapConfig };
            }
            default:
                return {};
        }
    };

    const getForm = (args: ICustomItemRenderData) => {
        switch (applicationKey) {
            case SettingsTypesEnum.EvolutionAlgorithmBlocksParameters:
                return <EvolutionAlgorithmBlocksParameters {...args} fullWidth />;
            case SettingsTypesEnum.Google:
                return <Google {...args} fullWidth />;
            case SettingsTypesEnum.Pinya:
                return <Pinya {...args} fullWidth />;
            case SettingsTypesEnum.SendGrid:
                return <SendGrid {...args} fullWidth />;
            case SettingsTypesEnum.MicrosoftActiveDirectoryLogin:
                return <Microsoft {...args} fullWidth />;
            case SettingsTypesEnum.AzureActiveDirectoryLogin:
                return <Azure {...args} fullWidth />;
            case SettingsTypesEnum.Neznam:
                return <Neznam {...args} fullWidth />;
            case SettingsTypesEnum.MegalaxExportMap:
                return <MegalaxExportMap {...args} fullWidth />;
        }
    };

    return (
        <CuForm
            {...rest}
            id={id}
            name="integratedApplication"
            maxWidth="xl"
            resource={permissionById ?? PermissionsEnum.SettingsItems}
            editResource={{ mode: [Mode.READ, Mode.UPDATE], operator: 'OR' }}
            displayAsSidebar
            items={[
                {
                    type: 'select',
                    props: {
                        name: 'type',
                        disabled: !!id,
                        label: t('label.type', 'Type'),
                        onChange: (newValue) => setApplicationKey(newValue ? (newValue as SettingsTypesEnum) : null),
                        value: applicationKey ?? undefined,
                        options: typeOptionsSelect
                    }
                },
                {
                    type: 'html',
                    display: ({ type }) => !!type,
                    props: {
                        name: 'divider',
                        render: () => <Divider />
                    }
                },
                {
                    type: 'custom',
                    display: ({ type }) =>
                        [
                            SettingsTypesEnum.EvolutionAlgorithmBlocksParameters,
                            SettingsTypesEnum.Google,
                            SettingsTypesEnum.Pinya,
                            SettingsTypesEnum.SendGrid,
                            SettingsTypesEnum.MicrosoftActiveDirectoryLogin,
                            SettingsTypesEnum.AzureActiveDirectoryLogin,
                            SettingsTypesEnum.Neznam,
                            SettingsTypesEnum.MegalaxExportMap
                        ].some((typeItem) => typeItem === type),
                    props: {
                        name: 'form_item',
                        render: (args) => getForm(args),
                        values: getFormValues()
                    }
                }
            ]}
            creatingStatus={creatingStatus}
            updatingStatus={updatingStatus}
            onClose={() => setApplicationKey(id ?? null)}
            onOpen={handleOpen}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
        />
    );
};

export default IntegratedApplication;
