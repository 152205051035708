import { useMemo } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { ICell } from '@/helpers/schedule';
import { maxLengthOfColumnOnDayView } from '@/modules/Scheduler/scheduleConstants';

export const generateColumns = (
    isDayMode: boolean,
    fromMoment: DateTimeType,
    toMoment: DateTimeType
): ICell<DateTimeType>[] => {
    const result: ICell<DateTimeType>[] = [];

    if (isDayMode) {
        let currentDateTime = DateHelper.clone(fromMoment);

        const to = DateHelper.getFirstMomentOfDay(DateHelper.addDays(fromMoment, 1));
        const countOfColumns = DateHelper.getDifferenceAsMinutes(fromMoment, to) / maxLengthOfColumnOnDayView;

        for (let startColumn = 0; startColumn < countOfColumns; startColumn++) {
            const end = DateHelper.addMinutes(currentDateTime, 15);

            result.push({
                id: result.length,
                from: currentDateTime,
                startColumn: startColumn,
                to: end,
                width: 1
            });
            currentDateTime = end;
        }
    } else {
        let currentDateTime = DateHelper.clone(fromMoment);
        let startColumn = 0,
            shouldEnds = false;
        const to = DateHelper.addDays(toMoment, 1);

        while (DateHelper.isBefore(currentDateTime, to) && !shouldEnds) {
            const nextDay = DateHelper.getFirstMomentOfDay(DateHelper.addDays(currentDateTime, 1));
            const minutesToNextDay = DateHelper.getDifferenceAsMinutes(currentDateTime, nextDay);
            const end = DateHelper.isBefore(nextDay, to) ? nextDay : to;

            shouldEnds = minutesToNextDay === 0 || !DateHelper.isBefore(nextDay, to);

            result.push({
                id: result.length,
                from: currentDateTime,
                startColumn,
                to: end,
                width: minutesToNextDay / 60
            });

            currentDateTime = end;
            startColumn += result[result.length - 1].width;
        }
    }

    return result;
};

const useSchedulerColumnTitles = (isDayMode: boolean, from: DateTimeType | null, to: DateTimeType | null) => {
    return useMemo(
        () => (from && to ? generateColumns(isDayMode, from, to) : []),
        [isDayMode, from?.toISOString(), to?.toISOString()]
    );
};

export default useSchedulerColumnTitles;
