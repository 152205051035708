import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Fragment, memo, ReactNode, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '@/data/hooks';
import { selectSchedulePlanDayShiftsBySchedulePlanById } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import {
    availableUsersInPlan,
    getPreparedBodyOfPlan,
    schedulePlanById,
    selectUsersWithShiftByPlan
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { scheduleGridSettings } from '@/data/Settings/SettingSlice';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { userHoursListByPeriodAndWorkplace } from '@/data/Summaries/SummariesSlice';
import { isSignedUserAdmin, signedUser } from '@/data/System/SystemReducer';
import { IUserModel } from '@/data/Users/UserModels';
import AssignEmployeesToPlanForm from '@/forms/AssignEmployeesToPlanForm';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeMonthFormatter from '@/hooks/useLocalizeMonthFormatter';
import SchedulerCalendarDataRow, {
    IMergedColumn,
    ISimpleColumn
} from '@/modules/Scheduler/components/SchedulerCalendarDataRow/SchedulerCalendarDataRow';
import { ISchedulerCalendarDataRowCellProps } from '@/modules/Scheduler/components/SchedulerCalendarDataRow/SchedulerCalendarDataRowCell';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import SkillIcon from '@/modules/Scheduler/components/SkillIcon';
import { minLengthOfColumnOnDayView, minLengthOfColumnOnWeekView } from '@/modules/Scheduler/scheduleConstants';
import {
    compactHeightOfRow,
    GridRootData,
    GridUnassignedData,
    StyledFooterSideBar,
    StyledUnassignedSideBar,
    StyledUserSideBar
} from '@/modules/Scheduler/StyledParts';
import config from '@/utils/config';
import { serializeUser } from '@/utils/UserHelper';
import Tooltip from '@/wrappers/Tooltip';
import { AvailableScheduleTypes } from '../../Scheduler';
import { StyledBodyUnassignedIcon, StyledBodyUserIcon } from '../../StyledTableCell';

type ISchedulerTableBodyProps = Pick<ISchedulerCalendarHeaderProps, 'from' | 'isDayMode' | 'to'> &
    Pick<ISchedulerCalendarDataRowCellProps, 'onClick'> & {
        assignedUsers: number[] | null;
        displayEmptyShifts: boolean;
        isCollapsed: boolean;
        isSchedulePlanClosed?: boolean;
        selectedRequirements: number[] | null;
        schedulePlanId: number;
        skills: ISkillModel[] | null;
        type: AvailableScheduleTypes;
        timeZone: string;
        onLoadingFinished: () => void;
        onLoadingStart: () => void;
    };

const StyledSummary = styled(
    (props: { children?: ReactNode; summaryHours?: number; contractHours?: number; isTooltip: boolean }) => (
        <Typography component="span" {...props} />
    ),
    {
        shouldForwardProp: (propName) => !['summaryHours', 'contractHours', 'isTooltip'].includes(propName as string)
    }
)<{ summaryHours?: number; contractHours?: number; isTooltip: boolean }>(({
    summaryHours = 0,
    contractHours = 0,
    isTooltip,
    theme
}) => {
    const diffHours = summaryHours - contractHours;

    const tenPercent = contractHours * 0.1;

    return {
        color:
            diffHours > 0
                ? theme.palette.error.dark
                : diffHours < -tenPercent
                ? theme.palette.warning.dark
                : theme.palette.success.dark,
        ...(isTooltip ? {} : { fontSize: '0.75rem' })
    };
});

const SchedulerCalendarBody = ({
    assignedUsers,
    displayEmptyShifts,
    from,
    isCollapsed,
    isSchedulePlanClosed = false,
    isDayMode,
    selectedRequirements,
    schedulePlanId,
    skills,
    timeZone,
    to,
    type,
    onClick,
    onLoadingFinished,
    onLoadingStart
}: ISchedulerTableBodyProps) => {
    const { t } = useAppTranslation();
    const setting = useAppSelector(scheduleGridSettings);
    const monthFormatter = useLocalizeMonthFormatter();
    const preparedBody = useAppSelector((state) => getPreparedBodyOfPlan(state, schedulePlanId));
    const schedulePlan = useAppSelector((state) => schedulePlanById(state, schedulePlanId));
    const schedulePlanDayShiftsByPlan = useAppSelector((state) =>
        selectSchedulePlanDayShiftsBySchedulePlanById(state, schedulePlanId)
    );
    const usersInWorkplace = useAppSelector((state) => availableUsersInPlan(state, schedulePlanId));
    const usersWithShift = useAppSelector((state) => selectUsersWithShiftByPlan(state, schedulePlanId));
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const workerInstance = useMemo(() => new Worker(new URL('./worker.ts', import.meta.url), { type: 'module' }), []);
    const [users, setUsers] = useState<(IUserModel & { columns: (ISimpleColumn | IMergedColumn)[] })[]>([]);
    const [unAssigned, setUnAssigned] = useState<(ISimpleColumn | IMergedColumn)[] | null>(null);

    const summary = useAppSelector((state) =>
        userHoursListByPeriodAndWorkplace(state, schedulePlan?.period_id ?? null, schedulePlan?.workplace_id ?? null)
    );

    useEffect(() => {
        onLoadingStart();

        return () => {
            workerInstance.terminate();
        };
    }, []);
    useEffect(() => {
        if (window.Worker) {
            workerInstance.onmessage = (
                e: MessageEvent<{
                    users: (IUserModel & { columns: (ISimpleColumn | IMergedColumn)[] })[];
                    unAssigned: (ISimpleColumn | IMergedColumn)[];
                }>
            ) => {
                setUsers(e.data.users);
                setUnAssigned(e.data.unAssigned);
                setTimeout(onLoadingFinished, 500);
            };
        }
    }, [workerInstance]);
    useEffect(() => {
        onLoadingStart();
        if (window.Worker) {
            workerInstance.postMessage({
                from: from.toISOString(),
                to: (isDayMode ? DateHelper.getFirstMomentOfDay(to) : DateHelper.addDays(to, 1)).toISOString(),
                now: DateHelper.formatISO(DateHelper.setTimeZone(DateHelper.now(), timeZone)),
                isDayMode,
                usersWithShift: [
                    ...(usersWithShift ?? []),
                    ...(
                        usersInWorkplace?.filter(
                            (user) => !usersWithShift?.some((userInWorkplace) => user.user_id === userInWorkplace.id)
                        ) ?? []
                    ).map(({ user }) => user)
                ],
                assignedUsers,
                schedulePlanDayShiftsByPlan,
                preparedBody,
                timeZone
            });
        }
    }, [from, to, isDayMode, usersWithShift, assignedUsers, schedulePlanDayShiftsByPlan, preparedBody]);

    const flooredNowDate = DateHelper.getUTCStartOfTheDay(
        DateHelper.subtractMinutesByTimezone(DateHelper.now(), timeZone),
        true
    ).toDate();
    const notAssignedUsers =
        usersInWorkplace
            ?.map(({ user }) => user)
            ?.filter((user) => !schedulePlanDayShiftsByPlan?.some((item) => item.user_id === user.id)) ?? [];
    const signedUserData = useAppSelector(signedUser);
    const noRole = !selectedRequirements?.some((id) => id < 0) ?? false;
    const noSkill = !selectedRequirements?.some((id) => id >= 0) ?? false;
    const countOfColumns =
        DateHelper.getDifferenceAsMinutes(from, isDayMode ? to : DateHelper.addDays(to, 1)) /
        (isDayMode ? minLengthOfColumnOnDayView : minLengthOfColumnOnWeekView);

    return (
        <>
            {users
                .filter((user) => {
                    const hasShift = usersWithShift?.some((userWithShift) => user.id === userWithShift.id) ?? false;
                    const isAddedIntoPlan = assignedUsers?.includes(user.id) ?? false;

                    if (!hasShift && !isAddedIntoPlan) {
                        return false;
                    }

                    if (selectedRequirements === null || selectedRequirements?.length === 0) {
                        return true;
                    } else {
                        const hasSelectedSkill =
                            (noSkill ||
                                selectedRequirements?.some(
                                    (reqId) => user.user_to_skills?.some((join) => join.skill_id === reqId)
                                )) ??
                            false;
                        const hasSelectedRole =
                            (noRole ||
                                selectedRequirements?.some(
                                    (reqId) => user.user_to_roles?.some((join) => join.role_id === -reqId)
                                )) ??
                            false;

                        return hasSelectedSkill && hasSelectedRole;
                    }
                })
                .slice()
                .sort((a, b) => a.last_name.localeCompare(b.last_name))
                .map((user, rowIndex, usersToDisplay) => {
                    const userName = serializeUser(user);
                    const userSummary = summary?.find((summaryItem) => summaryItem.user_id === user.id);

                    const hoursSummary = userSummary?.funds.reduce(
                        (hours, fund) => hours + fund?.vacation_hours ?? 0,
                        userSummary?.summary?.shift_hours ?? 0
                    );

                    return (
                        <Fragment key={`row-${user.id}`}>
                            <StyledUserSideBar
                                isCurrentUser={signedUserData?.id === user.id}
                                rowIndex={rowIndex}
                                isLast={usersToDisplay.length - 1 === rowIndex}
                            >
                                <Tooltip
                                    disableHoverListener={!userSummary || setting.displayUsedHours}
                                    withBoxWrapper={false}
                                    title={
                                        <Box key="tooltipBox">
                                            <p>
                                                <b>{t('header.shiftHours', 'Shift Hours')}</b>:{' '}
                                                {userSummary?.summary.shift_hours}
                                            </p>
                                            {userSummary?.funds.map((fund, index) => {
                                                const monthIndex = DateHelper.fromDateTimeString(fund.start).month();
                                                const monthName = monthFormatter.format(monthIndex + 1);

                                                return (
                                                    <p key={`user_summary_fund_${index}`}>
                                                        <b>
                                                            {userSummary?.funds.length < 1
                                                                ? t('header.vacationHours', 'Vacation Hours')
                                                                : t(
                                                                      'header.vacationHoursForMonth',
                                                                      'Vacation Hours for {{month}}',
                                                                      {
                                                                          month: monthName
                                                                      }
                                                                  )}
                                                        </b>
                                                        : {fund?.vacation_hours ?? 0}
                                                    </p>
                                                );
                                            })}
                                            <p>
                                                <b>{t('header.userId', 'Id')}</b>: {userSummary?.user_id}
                                            </p>
                                            <p>
                                                <b>{t('header.contractHours', 'Contract Hours')}</b>:{' '}
                                                {userSummary?.summary.contract_hours}
                                            </p>
                                            <p>
                                                <b>{t('header.summary', 'Summary')}</b>:
                                                <StyledSummary
                                                    isTooltip={true}
                                                    summaryHours={hoursSummary}
                                                    contractHours={userSummary?.summary.contract_hours ?? 0}
                                                >
                                                    {hoursSummary}
                                                </StyledSummary>
                                                /{userSummary?.summary.contract_hours}
                                            </p>
                                        </Box>
                                    }
                                >
                                    <Box>
                                        {isCollapsed ? (
                                            <Avatar
                                                alt={userName}
                                                sx={{
                                                    width: '30px',
                                                    height: '30px'
                                                }}
                                            >
                                                {[user.first_name, user.middle_name, user.last_name]
                                                    .filter((part) => !!part)
                                                    .map((part) => part?.substring(0, 1))
                                                    .join('')}
                                            </Avatar>
                                        ) : (
                                            <>
                                                <StyledBodyUserIcon />
                                                <Box
                                                    sx={
                                                        setting.displayUsedHours
                                                            ? { width: '100%' }
                                                            : { display: 'contents' }
                                                    }
                                                >
                                                    <Box
                                                        sx={{
                                                            display: setting.displayUsedHours ? 'flex' : 'contents'
                                                        }}
                                                    >
                                                        {setting.displayUsedHours ? <b>{userName}</b> : userName}
                                                        <div style={{ flex: 1 }} />
                                                        {user.user_to_skills?.map(({ skill }) => (
                                                            <SkillIcon
                                                                key={skill.id}
                                                                {...skill}
                                                                fontSize={compactHeightOfRow / 2}
                                                            />
                                                        ))}
                                                    </Box>
                                                    {setting.displayUsedHours ? (
                                                        <Box sx={{ fontSize: '0.75rem' }}>
                                                            <ul
                                                                style={{
                                                                    margin: 0,
                                                                    paddingLeft: '1em'
                                                                }}
                                                            >
                                                                <li>
                                                                    <b>{t('header.shiftHours', 'Shift Hours')}: </b>
                                                                    {userSummary?.summary.shift_hours}
                                                                </li>
                                                                {userSummary?.funds.map((fund) => {
                                                                    const monthIndex = DateHelper.fromDateTimeString(
                                                                        fund.start
                                                                    ).month();
                                                                    const monthName = monthFormatter.format(
                                                                        monthIndex + 1
                                                                    );

                                                                    return (
                                                                        <li key={`monthVacation_${monthIndex}`}>
                                                                            <b>
                                                                                {userSummary?.funds.length > 1
                                                                                    ? t(
                                                                                          'header.vacationHoursForMonth',
                                                                                          'Vacation Hours for {{month}}',
                                                                                          {
                                                                                              month: monthName
                                                                                          }
                                                                                      )
                                                                                    : t(
                                                                                          'header.vacationHours',
                                                                                          'Vacation Hours'
                                                                                      )}
                                                                            </b>
                                                                            : {fund.vacation_hours}
                                                                        </li>
                                                                    );
                                                                })}
                                                                <li key={'summary'}>
                                                                    <b>{t('header.summary', 'Summary')}</b>:{' '}
                                                                    <StyledSummary
                                                                        isTooltip={false}
                                                                        summaryHours={hoursSummary}
                                                                        contractHours={
                                                                            userSummary?.summary.contract_hours ?? 0
                                                                        }
                                                                    >
                                                                        {hoursSummary}
                                                                    </StyledSummary>
                                                                    /{userSummary?.summary.contract_hours}
                                                                </li>
                                                            </ul>
                                                        </Box>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </Tooltip>
                            </StyledUserSideBar>
                            <GridRootData
                                countOfColumns={countOfColumns}
                                isLast={usersToDisplay.length - 1 === rowIndex}
                                rowIndex={rowIndex}
                            >
                                {schedulePlanId ? (
                                    <SchedulerCalendarDataRow
                                        key={user.id}
                                        from={from}
                                        isDayMode={isDayMode}
                                        flooredNowDate={flooredNowDate}
                                        isCurrentUser={signedUserData?.id === user.id}
                                        isUserAdmin={isUserAdmin}
                                        isSchedulePlanClosed={isSchedulePlanClosed}
                                        columns={user.columns}
                                        rowIndex={rowIndex + 1}
                                        schedulePlanId={schedulePlanId}
                                        timeZone={timeZone}
                                        to={isDayMode ? to : DateHelper.addDays(to, 1)}
                                        type={type}
                                        userId={user.id}
                                        userName={userName}
                                        onClick={onClick}
                                    />
                                ) : (
                                    <Box key={`${user.id}_${rowIndex}`} className="placeholderCell" />
                                )}
                            </GridRootData>
                        </Fragment>
                    );
                })}
            {displayEmptyShifts ? (
                <>
                    <StyledUnassignedSideBar>
                        <Box>
                            <StyledBodyUnassignedIcon />
                            {t('label.emptyShifts', 'Empty Shifts')}
                        </Box>
                    </StyledUnassignedSideBar>
                    {schedulePlanId && unAssigned !== null ? (
                        <GridUnassignedData
                            sx={{
                                gridTemplateColumns: `repeat(${countOfColumns}, 1fr)`
                            }}
                        >
                            <SchedulerCalendarDataRow
                                key={`empty_shift_${DateHelper.formatDateTime(from)}`}
                                from={from}
                                columns={unAssigned}
                                flooredNowDate={flooredNowDate}
                                isCurrentUser={false}
                                isUserAdmin={isUserAdmin}
                                isSchedulePlanClosed={isSchedulePlanClosed}
                                timeZone={timeZone}
                                to={isDayMode ? to : DateHelper.addDays(to, 1)}
                                rowIndex={1}
                                isDayMode={isDayMode}
                                type={type}
                                schedulePlanId={schedulePlanId}
                                userId={null}
                                onClick={onClick}
                            />
                        </GridUnassignedData>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
            {schedulePlanId && !isSchedulePlanClosed && (
                <>
                    <StyledFooterSideBar data-testid="schedulerAssignUserOnSidebar">
                        <AssignEmployeesToPlanForm
                            justIcon={isCollapsed}
                            schedulePlanId={schedulePlanId}
                            alreadyAssigned={assignedUsers}
                            availableUsers={notAssignedUsers}
                            selectedRequirements={selectedRequirements}
                            skillsData={skills}
                        />
                    </StyledFooterSideBar>
                    <Box sx={{ gridArea: 'footerData' }} />
                </>
            )}
        </>
    );
};

export default memo(SchedulerCalendarBody, (prevProps, nextProps) => {
    const {
        assignedUsers,
        from,
        isCollapsed,
        isSchedulePlanClosed,
        isDayMode,
        schedulePlanId,
        selectedRequirements,
        skills,
        timeZone,
        to,
        type,
        onLoadingFinished,
        onLoadingStart,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onClick,
        ...rest
    } = prevProps;

    if (config.isDevelop && Object.keys(rest).length) {
        console.warn('Unknown attributes body', rest);
    }

    return (
        isDayMode === nextProps.isDayMode &&
        schedulePlanId === nextProps.schedulePlanId &&
        timeZone === nextProps.timeZone &&
        type === nextProps.type &&
        isCollapsed === nextProps.isCollapsed &&
        isSchedulePlanClosed === nextProps.isSchedulePlanClosed &&
        JSON.stringify(assignedUsers) === JSON.stringify(nextProps.assignedUsers) &&
        JSON.stringify(skills) === JSON.stringify(nextProps.skills) &&
        JSON.stringify(selectedRequirements) === JSON.stringify(nextProps.selectedRequirements) &&
        DateHelper.isEqual(from, nextProps.from) &&
        DateHelper.isEqual(to, nextProps.to) &&
        onLoadingFinished.toString() === nextProps.onLoadingFinished.toString() &&
        onLoadingStart.toString() === nextProps.onLoadingStart.toString()
    );
});
