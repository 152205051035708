import CrudDatatable from '@/components/CrudDatatable';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { fetchBreaks, removeBreak, updateBreak } from '@/data/Breaks/BreakActions';
import { IBreakModel } from '@/data/Breaks/BreakModels';
import { breakList, breakPaging } from '@/data/Breaks/BreakSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { getPermissionsList } from '@/data/System/SystemReducer';
import BreakForm from '@/forms/BreakForm';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import Switch from '@/wrappers/Switch';

export default function BreaksListPage() {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(breakList);
    const permissionsList = useAppSelector(getPermissionsList);
    const isUserToAllowedUpdateOrCreate = isUserAllowed(
        {
            id: PermissionsEnum.Breaks,
            mode: [Mode.CREATE, Mode.UPDATE],
            operator: 'OR'
        },
        permissionsList
    );

    return (
        <LayoutOfPage title={t('title.breaksList', 'Breaks List')}>
            <CrudDatatable<IBreakModel>
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <BreakForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    {
                        headerName: t('header.id', 'ID'),
                        field: 'id'
                    },
                    {
                        headerName: t('header.breakName', 'Break Name'),
                        field: 'name'
                    },
                    {
                        headerName: t('header.description', 'Description'),
                        field: 'description'
                    },
                    {
                        headerName: t('header.duration', 'Duration'),
                        field: 'duration',
                        type: 'number',
                        align: 'left',
                        headerAlign: 'left',
                        valueFormatter: (rowData: IBreakModel) => DateHelper.getMinutesInHumanFormat(rowData.duration),
                        renderCell: ({ row }) => DateHelper.getMinutesInHumanFormat(row.duration)
                    },
                    {
                        headerName: t('header.abbreviation', 'Abbreviation'),
                        field: 'abbreviation'
                    },
                    {
                        headerName: t('header.color', 'Color'),
                        field: 'color',
                        type: 'color'
                    },
                    {
                        headerName: t('header.background', 'Background'),
                        field: 'background',
                        type: 'color'
                    },
                    {
                        headerName: t('header.active', 'Active'),
                        field: 'active',
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch
                                name={row.name}
                                value={row.active}
                                title={
                                    isUserToAllowedUpdateOrCreate
                                        ? row.active
                                            ? t('label.deactivate', 'Deactivate')
                                            : t('label.activate', 'Activate')
                                        : undefined
                                }
                                onChange={(value) =>
                                    isUserToAllowedUpdateOrCreate &&
                                    dispatch(
                                        updateBreak({
                                            id: row.id,
                                            data: {
                                                active: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    },
                    {
                        headerName: t('header.paid', 'Paid'),
                        field: 'paid',
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch
                                name={row.name}
                                value={row.paid}
                                title={
                                    isUserToAllowedUpdateOrCreate
                                        ? row.paid
                                            ? t('label.setUnpaid', 'Set Unpaid')
                                            : t('label.setPaid', 'Set Paid')
                                        : undefined
                                }
                                onChange={(value) =>
                                    isUserToAllowedUpdateOrCreate &&
                                    dispatch(
                                        updateBreak({
                                            id: row.id,
                                            data: {
                                                paid: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    },
                    {
                        headerName: t('header.mandatory', 'Mandatory'),
                        field: 'required',
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch
                                name={row.name}
                                value={row.required}
                                title={
                                    isUserToAllowedUpdateOrCreate
                                        ? row.paid
                                            ? t('label.setOptional', 'Set Optional')
                                            : t('label.setMandatory', 'Set Mandatory')
                                        : undefined
                                }
                                onChange={(value) =>
                                    isUserToAllowedUpdateOrCreate &&
                                    dispatch(
                                        updateBreak({
                                            id: row.id,
                                            data: {
                                                required: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    },
                    {
                        headerName: t('header.allowOverlapping', 'Allow Overlapping'),
                        field: 'allow_overlapping',
                        type: 'boolean',
                        renderCell: ({ row }) => (
                            <Switch
                                name={row.name}
                                value={row.allow_overlapping}
                                onChange={(value) =>
                                    isUserToAllowedUpdateOrCreate &&
                                    dispatch(
                                        updateBreak({
                                            id: row.id,
                                            data: {
                                                allow_overlapping: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    },
                    {
                        headerName: t('header.createdAt', 'Created At'),
                        type: 'dateTime',
                        field: 'created'
                    },
                    {
                        headerName: t('header.updatedAt', 'Updated At'),
                        type: 'dateTime',
                        field: 'modified'
                    }
                ]}
                hiddenFields={[
                    'id',
                    'description',
                    'abbreviation',
                    'color',
                    'background',
                    'allow_overlapping',
                    'created',
                    'modified'
                ]}
                name="break"
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(breakPaging)}
                resource={PermissionsEnum.Breaks}
                onFetchList={fetchBreaks}
                onRemove={(id) =>
                    dispatch(removeBreak(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </LayoutOfPage>
    );
}
