import { useMemo } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import config from '@/utils/config';

type Props = {
    timeStyle: 'full' | 'long' | 'medium' | 'short' | undefined;
};

const useLocalizeTimeFormatter = (timeStyle?: Props['timeStyle'], timeZone?: string | null) => {
    const {
        i18n: { language, resolvedLanguage }
    } = useAppTranslation();

    return useMemo(() => {
        const lng = language ?? config.defaultLocale;

        return new Intl.DateTimeFormat(lng, {
            timeZone: timeZone ?? 'UTC',
            timeStyle: timeStyle ?? 'short'
        });
    }, [resolvedLanguage, language, timeZone]);
};

export default useLocalizeTimeFormatter;
