import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useRef } from 'react';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import Chip from '@/wrappers/Chip';
import Switch from '@/wrappers/Switch';
import TimePicker from '@/wrappers/TimePicker';
import Tooltip from '@/wrappers/Tooltip';

const StyledBreaks = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'disabled'
})<{ disabled: boolean }>(
    ({ theme, disabled }) => `
        margin-top: ${theme.spacing(2)};
        display: flex;
        justify-content: space-between;
        ${disabled ? 'text-decoration: line-through' : ''};
        gap: ${theme.spacing(2)};
    `
);
const StyledChip = styled(Box)(
    ({ theme }) => `
        margin-top: ${theme.spacing(0.5)};
        margin-right: ${theme.spacing(2)};
        display: flex;
        justify-content: flex-start;
        align-self: flex-start;
    `
);
const StyledPickers = styled(Box)(
    ({ theme }) => `
        display: flex;
        flex: 2;
        gap: ${theme.spacing(2)};
    `
);

export type IBreakItemProps = {
    breakDuration: number;
    breakName: string;
    day: Date;
    disabled: boolean;
    readOnly?: boolean;
    required: boolean;
    shiftEnd: DateTimeType | null;
    /** Break has to start between 'start' and ('start' + 'duration'). */
    shiftItemDuration: number;
    shiftItemStart: DateTimeType;
    shiftStart: DateTimeType | null;
    timeZone: string;
    value: {
        start: DateTimeType;
        used: boolean;
    };
    onError: (reason: string) => void;
    onChangeOutOfShift: (value: boolean) => void;
    onChangeStart: (value: DateTimeType | null) => void;
    onChangeUsed: (value: boolean) => void;
};

const BreakItem = ({
    breakDuration,
    breakName,
    day,
    disabled,
    shiftItemDuration,
    readOnly,
    required,
    shiftEnd,
    shiftStart,
    timeZone,
    value,
    shiftItemStart,
    onError,
    onChangeOutOfShift,
    onChangeStart,
    onChangeUsed
}: IBreakItemProps) => {
    const breakStartByShiftStart = shiftStart
            ? DateHelper.ceilToNearestQuarterMinutes(DateHelper.addMinutes(shiftStart, 5))
            : null,
        validStartBreakTimeByShiftItem = shiftItemStart,
        validEndBreakTimeByShiftItem = DateHelper.addMinutes(
            DateHelper.addHours(validStartBreakTimeByShiftItem, shiftItemDuration),
            -breakDuration
        ),
        validEndBreakTimeByShift = DateHelper.addMinutes(shiftEnd, -breakDuration),
        minDate = DateHelper.isBefore(breakStartByShiftStart ?? undefined, validStartBreakTimeByShiftItem)
            ? validStartBreakTimeByShiftItem
            : breakStartByShiftStart ?? undefined,
        maxDate = DateHelper.isBefore(validEndBreakTimeByShiftItem, validEndBreakTimeByShift ?? undefined)
            ? validEndBreakTimeByShiftItem
            : validEndBreakTimeByShift ?? undefined;
    const isMinDateValid = Boolean(
        minDate && DateHelper.isBetween(minDate, validStartBreakTimeByShiftItem, validEndBreakTimeByShiftItem, '[)')
    );
    const overMidnight = maxDate && minDate && DateHelper.getDay(minDate) !== DateHelper.getDay(maxDate);

    const prevMinMax = useRef([minDate, maxDate]);
    const prevIsMinDateValid = useRef(isMinDateValid);

    const handleOnChange = useCallback(
        (newValue: DateTimeType | null) => {
            if (overMidnight && newValue) {
                let normalizedValue = newValue;
                const midnight = DateHelper.addDays(DateHelper.fromTimeStringAndLocalDate('00:00', day), 1),
                    valueInUTC = DateHelper.utc(newValue, false);

                if (DateHelper.getDay(valueInUTC) < DateHelper.getDay(minDate)) {
                    normalizedValue = DateHelper.addDays(normalizedValue, 1);
                }

                if (DateHelper.getHour(midnight) < DateHelper.getHour(normalizedValue)) {
                    const condition = DateHelper.getHour(minDate) <= DateHelper.getHour(normalizedValue);

                    if (condition) {
                        normalizedValue = DateHelper.setDay(
                            normalizedValue,
                            DateHelper.getDay(DateHelper.getInstanceOf(day))
                        );
                    }
                } else {
                    normalizedValue = DateHelper.setDay(
                        normalizedValue,
                        DateHelper.getDay(DateHelper.addDays(DateHelper.getInstanceOf(day), 1))
                    );
                }

                onChangeStart(normalizedValue);
            } else {
                onChangeStart(newValue);
            }
        },
        [overMidnight, minDate, maxDate, onChangeStart]
    );
    const handleOnChangeUsed = useCallback(() => (newUsed: boolean) => onChangeUsed(newUsed), [onChangeUsed]);

    useEffect(() => {
        if (
            !minDate !== !prevMinMax.current[0] ||
            !maxDate !== !prevMinMax.current[1] ||
            (minDate && prevMinMax.current[0] && !DateHelper.isEqual(minDate, prevMinMax.current[0])) ||
            (maxDate && prevMinMax.current[1] && !DateHelper.isEqual(maxDate, prevMinMax.current[1]))
        ) {
            prevMinMax.current = [minDate, maxDate];
            if (minDate && maxDate && !DateHelper.isBetween(value.start, minDate, maxDate, '[)') && isMinDateValid) {
                handleOnChange(minDate);
            }
        }
    }, [minDate, maxDate]);
    useEffect(() => {
        if (prevIsMinDateValid.current !== isMinDateValid) {
            prevIsMinDateValid.current = isMinDateValid;
            if (required) {
                onChangeOutOfShift(isMinDateValid);
            }
        }
    }, [isMinDateValid]);

    return (
        <StyledBreaks disabled={!isMinDateValid}>
            {!required && (
                <Switch name="disable" disabled={!isMinDateValid} value={value.used} onChange={handleOnChangeUsed()} />
            )}
            <Tooltip arrow title={DateHelper.getMinutesInHumanFormat(breakDuration)}>
                <StyledChip>
                    <Chip name={breakName} label={breakName} disabled={!isMinDateValid} />
                </StyledChip>
            </Tooltip>
            <StyledPickers>
                <TimePicker
                    name="breakStart"
                    label="Start"
                    disabled={disabled || !isMinDateValid}
                    required={required || !isMinDateValid}
                    readOnly={readOnly}
                    minTime={
                        overMidnight
                            ? undefined
                            : (isMinDateValid ? minDate : validStartBreakTimeByShiftItem) ?? undefined
                    }
                    maxTime={
                        overMidnight
                            ? undefined
                            : (isMinDateValid ? maxDate : validEndBreakTimeByShiftItem) ?? undefined
                    }
                    minutesStep={15}
                    timezone={timeZone}
                    value={value?.start ?? minDate}
                    onError={onError}
                    onChange={handleOnChange}
                />
                <TimePicker
                    disabled
                    name="breakEnd"
                    label="End"
                    timezone={timeZone}
                    value={value ? DateHelper.addMinutes(DateHelper.clone(value.start), breakDuration) : null}
                />
            </StyledPickers>
        </StyledBreaks>
    );
};

export default BreakItem;
