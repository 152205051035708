import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Fragment, useEffect, useRef } from 'react';
import { fetchApplicationsSettingsItems } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchRequestTypes } from '@/data/RequestTypes/RequestTypeActions';
import { selectUsersInPlanWithRequests } from '@/data/SchedulePlans/SchedulePlanSlice';
import { signedUser } from '@/data/System/SystemReducer';
import { requestForSchedulerList } from '@/data/UserToRequests/UserToRequestSlice';
import DateHelper from '@/helpers/date/DateHelper';
import useSchedulerTitles from '@/hooks/scheduler/useSchedulerTitles';
import useAppTranslation from '@/hooks/useAppTranslation';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import { ICallableRef } from '@/modules/Scheduler/components/SchedulerRequestBody/DataCellRow/SchedulerRequestBodyDataRow';
import { GridRootData, StyledUserSideBar } from '@/modules/Scheduler/StyledParts';
import { StyledBodyUserIcon } from '@/modules/Scheduler/StyledTableCell';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { serializeUser } from '@/utils/UserHelper';
import Tooltip from '@/wrappers/Tooltip';
import DataCellRow from './DataCellRow';

type ISchedulerCalendarRequestBodyProps = Pick<
    ISchedulerCalendarHeaderProps,
    'from' | 'to' | 'isDayMode' | 'schedulePlanId'
> & {
    isCollapsed: boolean;
    timeZone: string;
    onLoadingStart?: () => void;
    onLoadingFinished?: () => void;
};

const SchedulerCalendarRequestBody = ({
    from,
    isCollapsed,
    isDayMode,
    schedulePlanId,
    to,
    timeZone,
    onLoadingStart,
    onLoadingFinished
}: ISchedulerCalendarRequestBodyProps) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const topRow = useSchedulerTitles(isDayMode, from, to);
    const usersList = useAppSelector((state) => selectUsersInPlanWithRequests(state, schedulePlanId ?? null)) ?? [];
    const requestDataList = useAppSelector(requestForSchedulerList);
    const multiply = 4 * (isDayMode ? 1 : 24);
    const signedUserData = useAppSelector(signedUser);

    useEffect(() => {
        dispatch(fetchRequestTypes({ noPaging: true }));
        dispatch(fetchApplicationsSettingsItems());
    }, []);

    const ref = useRef<Record<number, ICallableRef | null>>([]);

    return (
        <>
            {usersList
                .slice()
                .sort((a, b) => a.last_name.localeCompare(b.last_name))
                .map((user, rowIndex) => {
                    const userName = serializeUser(user);
                    const hasSomeUnresolvedRequests =
                        user.user_to_requests.filter((item) => item.state === UserToRequestsStateEnum.CREATED).length >
                        0;

                    return (
                        <Fragment key={`row-${rowIndex}`}>
                            <StyledUserSideBar
                                rowIndex={rowIndex}
                                isCurrentUser={signedUserData?.id === user.id}
                                isLast={usersList.length - 1 === rowIndex}
                            >
                                <Box
                                    sx={{
                                        padding: '0 1em',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    {isCollapsed ? (
                                        <Avatar
                                            alt={userName}
                                            sx={{
                                                width: '30px',
                                                height: '30px'
                                            }}
                                        >
                                            {[user.first_name, user.middle_name, user.last_name]
                                                .filter((part) => !!part)
                                                .map((part) => part?.substring(0, 1))
                                                .join('')}
                                        </Avatar>
                                    ) : (
                                        <Box>
                                            <StyledBodyUserIcon />
                                            {userName}
                                        </Box>
                                    )}
                                    <Tooltip
                                        color="white"
                                        title={
                                            hasSomeUnresolvedRequests
                                                ? t('title.scrollToNextRequest', 'Scroll To Next Request')
                                                : t('title.allRequestAreResolved', 'All Request Are Resolved')
                                        }
                                    >
                                        <IconButton
                                            name={`${user.id}_nextRequest`}
                                            onClick={() => ref.current[user.id]?.nextRequest()}
                                            disabled={!hasSomeUnresolvedRequests}
                                        >
                                            <SkipNextOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </StyledUserSideBar>
                            <GridRootData
                                countOfColumns={topRow.length * multiply}
                                isLast={usersList.length - 1 === rowIndex}
                                rowIndex={rowIndex}
                            >
                                {schedulePlanId ? (
                                    <DataCellRow
                                        innerRef={(el) => (ref.current[user.id] = el)}
                                        key={user.id}
                                        from={from}
                                        isDayMode={isDayMode}
                                        onLoadingStart={onLoadingStart}
                                        onLoadingFinished={onLoadingFinished}
                                        schedulePlanId={schedulePlanId}
                                        timeZone={timeZone}
                                        to={isDayMode ? to : DateHelper.addHours(to, 24)}
                                        userId={user.id}
                                        usersRequestsList={requestDataList
                                            .filter((item) => item.request_type.type !== RequestTypeEnum.available)
                                            .map((item) => ({
                                                ...item,
                                                datetime_from: DateHelper.ceilToNearestQuarterMinutes(
                                                    DateHelper.fromDateTimeString(item.datetime_from)
                                                ),
                                                datetime_to: DateHelper.ceilToNearestQuarterMinutes(
                                                    DateHelper.fromDateTimeString(item.datetime_to)
                                                ),
                                                state_changed_by_user_at: item.state_changed_by_user_at
                                                    ? DateHelper.ceilToNearestQuarterMinutes(
                                                          DateHelper.fromDateTimeString(item.state_changed_by_user_at)
                                                      )
                                                    : undefined
                                            }))}
                                    />
                                ) : (
                                    <></>
                                )}
                            </GridRootData>
                        </Fragment>
                    );
                })}
        </>
    );
};

export default SchedulerCalendarRequestBody;
