import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { normalize } from '@/helpers/color';
import useDebounce from '@/hooks/useDebounce';
import ColorPicker from '@/wrappers/ColorPicker';
import TextField from '@/wrappers/TextField';

export type IAbbreviationValue = {
    text: string;
    color: string;
    background: string;
};

export type IAbbreviationProps = {
    name: string;
    label: IAbbreviationValue;
    value?: IAbbreviationValue;
    required?: boolean;
    onlyPreview?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    error?: { text?: string; color?: string; background?: string };
    helperText?: string;
    onChange?: (value: IAbbreviationValue) => void;
};

const Abbreviation = ({
    disabled,
    error,
    label,
    name,
    onlyPreview,
    readOnly,
    required,
    value = { text: '', color: '', background: '' },
    onChange
}: IAbbreviationProps) => {
    const [innerValue, setInnerValue] = useState<IAbbreviationValue>({
        text: value?.text ?? '',
        color: value?.color,
        background: value?.background
    });

    const color = normalize(value?.color);
    const background = normalize(value?.background, 'ffffff');

    const debouncedValue = useDebounce(innerValue, 150);

    useEffect(() => {
        if (onChange) {
            onChange(debouncedValue);
        }
    }, [debouncedValue]);

    return (
        <Box sx={{ display: 'flex' }}>
            <TextField
                label={label.text}
                name={`${name}.text`}
                required={required}
                readOnly={readOnly}
                disabled={disabled}
                error={!!error?.text}
                helperText={`${error?.text ?? ''}`}
                value={innerValue.text}
                sx={{ flexGrow: '1' }}
                InputLabelProps={{
                    style: {
                        background: 'white',
                        borderRadius: '6px'
                    }
                }}
                InputProps={{
                    style: {
                        color: color,
                        backgroundColor: background,
                        transition: 'color 0.2s linear, background 0.2s linear'
                    }
                }}
                onChange={(e, newValue) =>
                    setInnerValue({
                        ...innerValue,
                        text: newValue
                    })
                }
            />
            {!onlyPreview && (
                <>
                    <Box sx={{ mx: 1 }} />
                    <ColorPicker
                        disabled={disabled}
                        helperText={error?.color}
                        label={label.color}
                        name={`${name}.color`}
                        readOnly={readOnly}
                        required={required}
                        sx={{ flexGrow: '1' }}
                        value={normalize(innerValue.color)}
                        onChange={(newValue) => {
                            console.log(newValue);
                            setInnerValue({
                                ...innerValue,
                                color: newValue ?? ''
                            });
                        }}
                    />
                    <Box sx={{ mx: 1 }} />
                    <ColorPicker
                        disabled={disabled}
                        helperText={error?.background}
                        label={label.background}
                        name={`${name}.background`}
                        readOnly={readOnly}
                        required={required}
                        value={normalize(innerValue.background, 'ffffff')}
                        sx={{ flexGrow: '1' }}
                        onChange={(newValue) =>
                            setInnerValue({
                                ...innerValue,
                                background: newValue ?? ''
                            })
                        }
                    />
                </>
            )}
        </Box>
    );
};

export default Abbreviation;
