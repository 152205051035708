import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect } from 'react';
import { getSxValue, IValueType, Wrapper } from '@/base/FormGenerator';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchPeriodsForSelect } from '@/data/Periods/PeriodActions';
import { periodsForSelect } from '@/data/Periods/PeriodSlice';
import { schedulePlanById } from '@/data/SchedulePlans/SchedulePlanSlice';
import { fetchWorkplacesForSelect } from '@/data/Workplaces/WorkplaceActions';
import { workplacesForSelect } from '@/data/Workplaces/WorkplaceSlice';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { filterChanged, filterGoLeftOrRight } from '@/helpers/schedule';
import useAppTranslation from '@/hooks/useAppTranslation';
import PrevNextPlanButton from '@/modules/Scheduler/components/PrevNextPlanButton';
import { AvailableScheduleTypes, ISchedulerProps } from '@/modules/Scheduler/Scheduler';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import Button from '@/wrappers/Button';
import DatePicker, { IDatePickerProps } from '@/wrappers/DatePicker';
import DateRangePicker, { IValueType as IDateRangeValueType } from '@/wrappers/DateRangePicker';
import Select from '@/wrappers/Select';

type IProps = {
    dashboardView?: boolean;
    displayMode: SchedulePlanWidthTypeEnum;
    from: DateTimeType | null;
    isPlanReady: boolean;
    periodId: number | null;
    schedulePlanId: number | null;
    timeZone: string | null;
    to: DateTimeType | null;
    type: AvailableScheduleTypes;
    workplaceId: number | undefined;
    onFilterChanged: ISchedulerProps['onFilterChanged'];
    onGoToPlan: (id: number, workplaceId: number, periodId: number) => void;
};

const StyledButtonGroup = styled(ButtonGroup)(
    ({ theme }) => `
        margin-top: ${theme.spacing(1)};
    `
);

const SchedulerFilterPanel = ({
    dashboardView = false,
    displayMode,
    from,
    isPlanReady,
    periodId,
    schedulePlanId,
    timeZone,
    to,
    type,
    workplaceId,
    onFilterChanged,
    onGoToPlan
}: IProps) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const workplaceList = useAppSelector(workplacesForSelect);
    const periodList = useAppSelector(periodsForSelect);
    const schedulePlanData = useAppSelector((state) => schedulePlanById(state, schedulePlanId));
    const selectedPeriodData = periodList.find(({ id }) => `${id}` === `${periodId}`);
    const fromPeriod = DateHelper.fromOptionalDateString(selectedPeriodData?.period_start ?? null, timeZone);
    const toPeriod = DateHelper.fromOptionalDateString(selectedPeriodData?.period_end ?? null, timeZone);
    const isDayMode = displayMode === SchedulePlanWidthTypeEnum.Day;

    useEffect(() => {
        dispatch(fetchWorkplacesForSelect({ search: '' }));
        dispatch(fetchPeriodsForSelect({ search: '', fields: ['period_start', 'period_end'] }));
    }, []);

    const handleGoLeftOrRight = useCallback(
        (isRight?: boolean) => () => {
            if (from !== null && to !== null)
                onFilterChanged(
                    filterGoLeftOrRight(
                        isDayMode,
                        isRight === true,
                        {
                            from: from,
                            to: to
                        },
                        {
                            from: fromPeriod,
                            to: isDayMode ? DateHelper.addDays(toPeriod, 1) : toPeriod
                        }
                    )
                );
        },
        [isDayMode, from, to, fromPeriod, toPeriod, onFilterChanged]
    );
    const handleGoToNextPlan = useCallback(
        (planId: string) => {
            const nextPlan = schedulePlanData?.next_schedule_plans?.find((item) => `${item.id}` === planId);

            if (nextPlan) {
                onGoToPlan(nextPlan.id, nextPlan.workplace_id, nextPlan.period_id);
            }
        },
        [schedulePlanData, onGoToPlan]
    );

    const handleGoToPrevPlan = useCallback(
        (planId: string) => {
            const nextPlan = schedulePlanData?.previous_schedule_plans?.find((item) => `${item.id}` === planId);

            if (nextPlan) {
                onGoToPlan(nextPlan.id, nextPlan.workplace_id, nextPlan.period_id);
            }
        },
        [schedulePlanData, onGoToPlan]
    );
    const handleChangeFilter = useCallback(
        (fieldId: string, value: IValueType) => {
            const changedValues = filterChanged(displayMode, fieldId, value, periodList);

            if (changedValues) {
                return onFilterChanged(changedValues);
            }
        },
        [displayMode, onFilterChanged, periodList]
    );
    const handleChangeDateRange = useCallback(
        (e: IDateRangeValueType<IDatePickerProps['value']>) => handleChangeFilter('date_range', e),
        [handleChangeFilter]
    );

    const isStartOfPeriod = !isPlanReady || !fromPeriod || DateHelper.isEqual(from, fromPeriod);
    const isEndOfPeriod =
        !isPlanReady || !toPeriod || DateHelper.isEqual(to, isDayMode ? DateHelper.addDays(toPeriod, 1) : toPeriod);

    return (
        <Wrapper data-testid="form-schedulerFilter">
            {!dashboardView && (
                <>
                    <Box sx={{ minWidth: '180px', ...getSxValue(1) }} data-testid="ssss">
                        <Select
                            required
                            name="workplaceId"
                            label={t('label.workplace', 'Workplace')}
                            value={workplaceId?.toString()}
                            onChange={(e) => handleChangeFilter('workplaceId', e)}
                            options={workplaceList.map((workplace) => ({
                                id: workplace.id.toString(),
                                label: workplace.name
                            }))}
                        />
                    </Box>
                    <Box sx={{ minWidth: '180px', ...getSxValue(1) }}>
                        <Select
                            required
                            name="periodId"
                            label={t('label.period', 'Period')}
                            value={periodId?.toString()}
                            onChange={(e) => handleChangeFilter('periodId', e)}
                            options={periodList.map((item) => ({
                                id: item.id.toString(),
                                label: item.name
                            }))}
                        />
                    </Box>
                </>
            )}
            {!timeZone ? (
                <></>
            ) : isDayMode ? (
                <Box sx={{ minWidth: '180px', ...getSxValue(1) }}>
                    <DatePicker
                        name="date"
                        value={from}
                        disabled={!isPlanReady}
                        minDate={fromPeriod ?? undefined}
                        maxDate={toPeriod ?? undefined}
                        onChange={(e) => handleChangeFilter('date', e)}
                    />
                </Box>
            ) : (
                <Box sx={{ minWidth: '350px', ...getSxValue(2) }}>
                    <DateRangePicker
                        name="date_range"
                        size="small"
                        value={{
                            start: from,
                            end: to
                        }}
                        disabled={!isPlanReady}
                        minDate={fromPeriod ?? undefined}
                        maxDate={toPeriod ?? undefined}
                        onChange={handleChangeDateRange}
                    />
                </Box>
            )}
            <Box>
                <StyledButtonGroup variant="outlined" size="small">
                    <PrevNextPlanButton
                        enabled={isStartOfPeriod}
                        isPrev={true}
                        plans={schedulePlanData?.previous_schedule_plans ?? []}
                        onSelect={handleGoToPrevPlan}
                    />
                    <Button name="prev" variant="outlined" disabled={isStartOfPeriod} onClick={handleGoLeftOrRight()}>
                        <ChevronLeftIcon />
                    </Button>
                    <Button name="next" variant="outlined" disabled={isEndOfPeriod} onClick={handleGoLeftOrRight(true)}>
                        <ChevronRightIcon />
                    </Button>
                    <PrevNextPlanButton
                        enabled={isEndOfPeriod}
                        isPrev={false}
                        plans={schedulePlanData?.next_schedule_plans ?? []}
                        onSelect={handleGoToNextPlan}
                    />
                </StyledButtonGroup>
            </Box>
            <Box sx={{ minWidth: '180px', ...getSxValue(1) }}>
                <Select
                    required
                    name="displayMode"
                    label={t('label.width', 'Width')}
                    disabled={!isPlanReady}
                    value={displayMode}
                    onChange={(e) => handleChangeFilter('displayMode', e)}
                    options={[
                        ...(type !== 'table'
                            ? [{ id: SchedulePlanWidthTypeEnum.Day, label: t('label.day', 'Day') }]
                            : []),
                        { id: SchedulePlanWidthTypeEnum.Week, label: t('label.week', 'Week') },
                        { id: SchedulePlanWidthTypeEnum.Period, label: t('label.period', 'Period') }
                    ]}
                />
            </Box>
        </Wrapper>
    );
};

export default SchedulerFilterPanel;
