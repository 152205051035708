import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { forwardRef, memo } from 'react';

export type IButtonProps = Omit<MuiButtonProps, 'ref' | 'name'> & {
    tooltip?: string;
    name: string;
    justIcon?: boolean;
};

const Button = forwardRef<HTMLButtonElement, IButtonProps>(function ButtonInner({ hidden, justIcon, ...props }, ref) {
    return justIcon ? (
        <IconButton {...props} ref={ref} style={{ display: hidden ? 'none' : '' }} />
    ) : (
        <MuiButton {...props} ref={ref} style={{ display: hidden ? 'none' : '' }} />
    );
});

const ButtonWithTooltip = forwardRef<HTMLButtonElement, IButtonProps>(function ButtonWithTooltipInner(
    { tooltip, ...rest },
    ref
) {
    if (tooltip) {
        return (
            <Tooltip title={tooltip}>
                <Button ref={ref} {...rest} />
            </Tooltip>
        );
    }

    return <Button ref={ref} {...rest} />;
});

export default memo(ButtonWithTooltip);
