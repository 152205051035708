import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import { createNeed, fetchNeedById, fetchNeeds, removeNeed, updateNeed } from './NeedActions';
import { INeedModel } from './NeedModels';

type INeedReducerState = {
    paging: IPaging;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: INeedReducerState = {
    paging: defaultPaging('created'),
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<INeedModel>({
    selectId: (entity) => entity.id
});
const needSlice = createSlice({
    name: 'needs',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNeeds.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchNeeds.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.setAll(state, action.payload.data);
                if (!action.meta.arg.noPaging) {
                    state.paging = action.payload.collection;
                }
            })
            .addCase(fetchNeeds.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchNeedById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchNeedById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchNeedById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createNeed.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createNeed.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
            })
            .addCase(createNeed.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeNeed.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeNeed.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg);
            })
            .addCase(removeNeed.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateNeed.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateNeed.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
            })
            .addCase(updateNeed.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[needSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default needSlice;
export const isNeedsListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const needPaging = (state: IRootState) => getState(state).paging;
export const isNeedsByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const needList = adapterSelectors.selectAll;
export const needById = (state: IRootState, id?: number) => (id ? adapterSelectors.selectById(state, id) : undefined);
export const needCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const needUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const needRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = needSlice.actions;
