import { useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { useDrag } from 'react-dnd';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { IBreaksCUModel } from '@/data/Breaks/BreakModels';
import { useAppSelector } from '@/data/hooks';
import { selectSchedulePlanDayShiftDelaysBySchedulePlanId } from '@/data/SchedulePlanDayShiftDelays/SchedulePlanDayShiftDelaySlice';
import { workingFromHomeBySchedulePlanDayShift } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { selectErrorValidationsBySchedulePlanId } from '@/data/SchedulePlanValidations/SchedulePlanValidationSlice';
import { scheduleGridSettings } from '@/data/Settings/SettingSlice';
import { isAnyShiftTradeOfferActive } from '@/data/ShiftTradeOffers/ShiftTradeOfferSlice';
import { isAssignedShiftInTradeCenter } from '@/data/ShiftTrades/ShiftTradeSlice';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { getPermissionsList, isSignedUserAdmin } from '@/data/System/SystemReducer';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { isCorrectValidationErrorOnShift } from '@/helpers/schedule';
import { getBestPlaceForShiftLabel } from '@/helpers/scheduleBestPositionForLabel';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import useLocalizeTimeFormatter from '@/hooks/useLocalizeTimeFormatter';
import useTranslatedDays from '@/hooks/useTranslatedDays';
import SchedulerBodyTooltip, { IScheduleBodyTooltipProps } from '@/modules/Scheduler/components/SchedulerBodyTooltip';
import { maxLengthOfColumnOnDayView, minLengthOfColumnOnDayView } from '@/modules/Scheduler/scheduleConstants';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import ShiftAsText from '../../ShiftAsText';

export type ISchedulerCellContentProps = Omit<BoxProps, 'id' | 'sx'> &
    Pick<IScheduleBodyTooltipProps, 'columnFrom' | 'userName' | 'wrapperStyles'> & {
        abbreviation: string;
        breaks: (Pick<IBreaksCUModel, 'abbreviation' | 'background' | 'color'> & {
            id: number;
            columnStart: number;
            width: number;
        })[];
        id: number;
        backgroundColor: string;
        color: string;
        displayData?: boolean;
        isTableView?: boolean;
        innerRef?: (ref: HTMLDivElement) => void;
        isDayMode: boolean;
        schedulePlanId: number;
        shiftId: number | null;
        shiftName: string | null;
        shiftStart: DateTimeType;
        shiftEnd: DateTimeType;
        skills: Pick<ISkillModel, 'id' | 'color' | 'icon'>[];
        timeZone: string;
        userId: number | null;
        width: number;
        withRightBorder?: boolean;
    };

const SchedulerCellContent = ({
    abbreviation,
    breaks,
    backgroundColor,
    color,
    columnFrom,
    displayData = true,
    id,
    innerRef,
    isTableView = false,
    isDayMode,
    schedulePlanId,
    shiftId,
    shiftEnd,
    shiftName,
    shiftStart,
    skills,
    timeZone,
    userId,
    userName,
    width,
    withRightBorder = true,
    wrapperStyles,
    onClick,
    ...rest
}: ISchedulerCellContentProps) => {
    const theme = useTheme();
    const { displayShiftName, displayShiftCode, displayDateTime, displaySkills } = useAppSelector(scheduleGridSettings);
    const { t } = useAppTranslation();
    const dateFormatter = useLocalizeDateFormatter({ timeZone });
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });
    const timeFormatter = useLocalizeTimeFormatter(undefined, timeZone);
    const translatedDays = useTranslatedDays('long');
    const signedUserIsAdmin = useAppSelector(isSignedUserAdmin);
    const permissionsList = useAppSelector(getPermissionsList);
    const hasTrade = useAppSelector((state) => isAssignedShiftInTradeCenter(state, id));
    const workFromHome = useAppSelector((state) => workingFromHomeBySchedulePlanDayShift(state, id));
    const isOffered = useAppSelector((state) => isAnyShiftTradeOfferActive(state, id));
    const errorDelays =
        useAppSelector((state) => selectSchedulePlanDayShiftDelaysBySchedulePlanId(state, schedulePlanId)) ?? [];
    const errorValidations =
        useAppSelector((state) => selectErrorValidationsBySchedulePlanId(state, schedulePlanId)) ?? [];
    const filteredErrorValidations = errorValidations.filter((error) =>
        userId ? isCorrectValidationErrorOnShift(error, id, userId, shiftStart, shiftEnd) : false
    );

    const [, setNodeRef] = useDrag(
        () => ({
            type: 'shift',
            canDrag: isUserAllowed(
                {
                    id: PermissionsEnum.SchedulePlans,
                    mode: Mode.UPDATE
                },
                permissionsList
            ),
            item: { id },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1
            })
        }),
        [id]
    );

    const validDelays = errorDelays.filter((errorDelay) => errorDelay.assigned_shift.id === id);
    const hasValidationError = filteredErrorValidations.length !== 0;
    const hasDelayError = validDelays.length !== 0;
    const existsError = hasValidationError || hasDelayError;
    const hasShiftError = errorDelays.some((item) => item.schedule_plan_day_shift_break_id === null);
    const existsBreakWithError = errorDelays.some((item) => item.schedule_plan_day_shift_break_id !== null);
    const validColor = existsError ? 'black' : color;
    const stylesForText = getBestPlaceForShiftLabel(isDayMode, width, breaks);

    return (
        <SchedulerBodyTooltip
            innerRef={signedUserIsAdmin ? setNodeRef : undefined}
            isEmpty={false}
            hasError={existsError}
            errorMessages={[
                ...validDelays.map((errorDelay) =>
                    t(errorDelay.message.key, errorDelay.message.default, {
                        ...errorDelay.message.params,
                        ...(errorDelay.message.params.from
                            ? {
                                  from: timeFormatter.format(
                                      DateHelper.fromDateTimeString(errorDelay.message.params.from).toDate()
                                  )
                              }
                            : undefined),
                        ...(errorDelay.message.params.to
                            ? {
                                  to: timeFormatter.format(
                                      DateHelper.fromDateTimeString(errorDelay.message.params.to).toDate()
                                  )
                              }
                            : undefined),
                        delay: DateHelper.getMinutesInHumanFormat(errorDelay.delay_in_minutes)
                    })
                ),
                ...filteredErrorValidations.map((errorValidation) =>
                    t(errorValidation.message.key, errorValidation.message.default, {
                        ...errorValidation.message.params,
                        day: errorValidation.message.params.day
                            ? translatedDays[errorValidation.message.params.day as number]?.name
                            : '-',
                        days: errorValidation.message.params.days
                            ? errorValidation.message.params.days
                                  .map((item, index) => (item ? translatedDays[index]?.name : ''))
                                  .filter(Boolean)
                                  .join(', ')
                            : '-',
                        from:
                            errorValidation.message.params.from !== ''
                                ? dateFormatter.format(
                                      DateHelper.fromDateTimeString(errorValidation.message.params.from).toDate()
                                  )
                                : '-',
                        to:
                            errorValidation.message.params.to !== ''
                                ? dateFormatter.format(
                                      DateHelper.fromDateTimeString(errorValidation.message.params.to).toDate()
                                  )
                                : '-',
                        fromDateTime:
                            errorValidation.message.params.fromDateTime !== ''
                                ? dateTimeFormatter.format(
                                      DateHelper.fromDateTimeString(
                                          errorValidation.message.params.fromDateTime
                                      ).toDate()
                                  )
                                : '-',
                        toDateTime:
                            errorValidation.message.params.toDateTime !== ''
                                ? dateTimeFormatter.format(
                                      DateHelper.fromDateTimeString(errorValidation.message.params.toDateTime).toDate()
                                  )
                                : '-'
                    })
                )
            ]}
            columnFrom={columnFrom}
            isDayMode={isDayMode}
            timeZone={timeZone}
            userName={userName}
            wrapperStyles={wrapperStyles}
            data-testid={`schedulerTableBodyCell_${userId === null ? 'emptyShift' : userId}_${DateHelper.formatISO(
                shiftStart
            )}_${id}`}
        >
            <Box
                {...rest}
                ref={(current: HTMLDivElement) => {
                    setNodeRef(current);
                    if (innerRef) {
                        innerRef(current);
                    }
                }}
                sx={{
                    alignItems: 'center',
                    color: validColor,
                    display: 'grid',
                    gridTemplateColumns: `repeat(${width}, 1fr)`,
                    position: 'relative',
                    width: '100%',
                    backgroundColor: existsError ? 'white' : backgroundColor,
                    height: '100%',
                    ...(withRightBorder ? { borderRight: '1px black solid' } : {}),
                    ...(hasTrade || isOffered || existsError
                        ? {
                              borderStyle: existsError ? 'solid' : 'dashed',
                              borderLeftWidth: '1px',
                              borderRightWidth: '1px',
                              borderTopWidth: '1px',
                              borderBottomWidth: '1px'
                          }
                        : {
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              borderTopWidth: 0,
                              borderBottomWidth: 0
                          }),
                    ...(hasShiftError || existsError
                        ? {
                              borderColor: theme.palette.error.main
                          }
                        : hasTrade || isOffered
                        ? {
                              borderColor: 'white'
                          }
                        : {})
                }}
                onClick={onClick}
                data-testid={`schedulerTableBodyCell_${userId === null ? 'emptyShift' : userId}_${DateHelper.formatISO(
                    shiftStart
                )}`}
            >
                <Box
                    sx={
                        isDayMode
                            ? {
                                  gridRow: 1,
                                  gridColumnStart: stylesForText.start,
                                  gridColumnEnd: `span ${stylesForText.width}`
                              }
                            : { gridColumnStart: 1, gridColumnEnd: `span ${width}` }
                    }
                >
                    <ShiftAsText
                        abbr={{
                            text: isTableView ? userName ?? '' : displayData && displayShiftCode ? abbreviation : '',
                            color: validColor
                        }}
                        inStore={hasTrade || isOffered}
                        isDayMode={isDayMode}
                        skills={displayData && displaySkills ? skills : []}
                        shiftName={displayData && displayShiftName ? shiftName : null}
                        shiftStart={displayData && displayDateTime ? shiftStart : null}
                        shiftEnd={displayData && displayDateTime ? shiftEnd : null}
                        simpleView={!isDayMode}
                        timeZone={timeZone}
                        workFromHome={workFromHome}
                        data-testid={`shiftId_${shiftId ?? 'withoutDescription'}`}
                    />
                </Box>
                {breaks.map((item) => {
                    const isBreakWithError =
                        existsBreakWithError &&
                        errorDelays.some((delayDataItem) => delayDataItem.schedule_plan_day_shift_break_id === item.id);

                    return (
                        <Box
                            key={item.columnStart}
                            sx={{
                                display: 'inline-flex',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gridColumnStart: item.columnStart,
                                gridColumnEnd: `span ${item.width}`,
                                color: isBreakWithError ? 'black' : item.color,
                                backgroundColor: isBreakWithError
                                    ? theme.palette.error.main
                                    : existsError
                                    ? theme.palette.grey.A400
                                    : item.background
                            }}
                        >
                            {item.width > maxLengthOfColumnOnDayView / minLengthOfColumnOnDayView ? (
                                <Box>{item.abbreviation}</Box>
                            ) : (
                                <></>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </SchedulerBodyTooltip>
    );
};

export default SchedulerCellContent;
