import { useCallback } from 'react';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { createPeriod, updatePeriod } from '@/data/Periods/PeriodActions';
import { IPeriodModel } from '@/data/Periods/PeriodModels';
import {
    periodById,
    periodCreatingStatus,
    periodPaging,
    periodUpdatingStatus,
    updatePaging
} from '@/data/Periods/PeriodSlice';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';
import { IDateRangePickerProps } from '@/wrappers/DateRangePicker';

type IProps = Omit<ICuProps<IPeriodModel>, 'resource'>;

const PeriodForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const loadedData = useAppSelector((state) => periodById(state, id ?? null));
    const paging = useAppSelector(periodPaging);
    const handleData = (values: IOutputValueType) => ({
        name: values.name as string,
        description: values.description as string,
        period_start: DateHelper.formatDate((values.period as IDateRangePickerProps['value'])?.start),
        period_end: DateHelper.formatDate((values.period as IDateRangePickerProps['value'])?.end),
        active: values.active as boolean
    });
    const handleCreate = useCallback((values: IOutputValueType) => createPeriod(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => updatePeriod({ id: id!, data: handleData(values) }),
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    return (
        <CuForm
            {...props}
            id={id}
            name="period"
            resource={PermissionsEnum.Periods}
            creatingStatus={useAppSelector(periodCreatingStatus)}
            updatingStatus={useAppSelector(periodUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            items={[
                {
                    type: 'switch',
                    props: {
                        name: 'active',
                        label: t('label.active', 'Active'),
                        value: loadedData?.active ?? true
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: t('label.periodName', 'Period Name'),
                        value: loadedData?.name,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'textArea',
                    props: {
                        name: 'description',
                        label: t('label.description', 'Description'),
                        value: loadedData?.description,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'dateRange',
                    props: {
                        required: true,
                        name: 'period',
                        label: {
                            start: t('label.periodStart', 'Period Start'),
                            end: t('label.periodEnd', 'Period End')
                        },
                        timeZone: 'UTC',
                        value: {
                            start: DateHelper.fromOptionalDateString(loadedData?.period_start ?? null, 'utc'),
                            end: DateHelper.fromOptionalDateString(loadedData?.period_end ?? null, 'utc')
                        }
                    }
                }
            ]}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default PeriodForm;
