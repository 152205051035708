import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { fetchById } from '@/data/SchedulePlans/SchedulePlanApi';
import { isPlanClosed, schedulePlanById } from '@/data/SchedulePlans/SchedulePlanSlice';
import { shiftTradesBySchedulePlanDayShiftId } from '@/data/ShiftTrades/ShiftTradeSlice';
import { IRootState } from '@/data/store';
import { canUserEditClosedSchedulePlan } from '@/data/System/SystemReducer';
import { workplaceById } from '@/data/Workplaces/WorkplaceSlice';
import {
    agreeWithOffers,
    deleteSelectOfferedShifts,
    fetchOffersBySchedulePlanDayShiftId,
    selectOfferedShiftById
} from './ShiftTradeOfferApi';
import { selectShiftTradeOffers } from './ShiftTradeOfferSlice';

export const fetchShiftTradeOffersByAssignedShift = createAsyncThunk(
    'shiftTradeOffers/offersByDayUserId',
    async (args: number) => fetchOffersBySchedulePlanDayShiftId(args),
    {
        condition: (schedulePlanDayShiftId: number, { getState }) => {
            const state = getState() as IRootState;

            return shiftTradesBySchedulePlanDayShiftId(state, schedulePlanDayShiftId)?.length !== 0;
        }
    }
);

export const selectOfferedShiftTrade = createAsyncThunk(
    'shiftTadeOffers/selectOffer',
    async (
        args: { schedulePlanId: number; schedulePlanDayShiftId: number; tradeId: number },
        { getState, dispatch }
    ) => {
        const state = getState() as IRootState;
        const isClosed = isPlanClosed(state, args.schedulePlanId);
        const isUserToAbleToEditClosedSchedulePlan = canUserEditClosedSchedulePlan(state);

        if (isClosed && !isUserToAbleToEditClosedSchedulePlan) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            return Promise.reject();
        }

        return await selectOfferedShiftById(args.tradeId, args.schedulePlanDayShiftId).then((ret) => {
            dispatch(
                addNotification({
                    context: 'message.success.shiftSwapRequestWasSuccessfullySubmittedForApproval',
                    defaultMessage: 'Shift Swap Request was successfully submitted for Approval',
                    variant: 'success'
                })
            );

            return ret;
        });
    }
);

export const agreeWithOfferedShift = createAsyncThunk(
    'shiftTadeOffers/agreeWithOffer',
    async (args: { schedulePlanId: number; shiftTradeOfferId: number }, { getState, dispatch }) => {
        const state = getState() as IRootState;
        const offer = selectShiftTradeOffers(state)[args.shiftTradeOfferId];
        const isClosed = isPlanClosed(state, args.schedulePlanId);
        const plan = schedulePlanById(state, args.schedulePlanId);
        const workplace = workplaceById(state, plan?.workplace_id ?? null);
        const isUserToAbleToEditClosedSchedulePlan = canUserEditClosedSchedulePlan(state);

        if (isClosed && !isUserToAbleToEditClosedSchedulePlan) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            return Promise.reject();
        }

        if (offer) {
            return await agreeWithOffers(offer.schedule_plan_day_shift_trade_id, offer.id).then(async (ret) => {
                dispatch(
                    addNotification({
                        context: 'message.success.shiftSwapRequestWasSuccessfullySubmittedForApproval',
                        defaultMessage: 'Shift Swap Request was successfully submitted for Approval',
                        variant: 'success'
                    })
                );

                if (workplace?.automatic_approve_trade) {
                    const newPlan = await fetchById(args.schedulePlanId);

                    return {
                        plan: newPlan,
                        shift: {
                            schedule_plan_day_shift_id: offer.schedule_plan_day_shift_id,
                            user_id: offer.schedule_plan_day_shift.user_id
                        },
                        shiftTrade: {
                            schedule_plan_day_shift_id: offer.shift_trade.schedule_plan_day_shift_id,
                            user_id: offer.shift_trade.schedule_plan_day_shift.user_id
                        },
                        offer: ret
                    };
                } else {
                    return {
                        plan: null,
                        offer: ret
                    };
                }
            });
        }
    }
);

export const removeOfferedShiftTrade = createAsyncThunk(
    'shiftTadeOffers/removeOffer',
    async (args: { offerId: number; tradeId: number }, { dispatch }) => {
        return await deleteSelectOfferedShifts(args.tradeId, args.offerId).then((ret) => {
            dispatch(
                addNotification({
                    context: 'message.success.theShiftTradeOfferWasSuccessfullyRemoved',
                    defaultMessage: 'The Shift Trade Offer was successfully removed',
                    variant: 'success'
                })
            );

            return ret;
        });
    }
);
