import ClearIcon from '@mui/icons-material/Clear';
import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';
import { IRequestState } from '@/data/ApiRequest';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { ISchedulePlanDayShiftExtendedModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import {
    fetchShiftTradeOffersByAssignedShift,
    removeOfferedShiftTrade
} from '@/data/ShiftTradeOffers/ShiftTradeOfferActions';
import {
    offeredShiftTradeOffersStatus,
    selectActiveShiftTradeOffersByAssignedShift,
    selectOfferedShiftTradeOffers,
    selectSelectedShiftTradeOffersByAssignedShift,
    shiftTradeOffersSelectingStatus
} from '@/data/ShiftTradeOffers/ShiftTradeOfferSlice';
import { createShiftTrade, fetchShiftTradesByAssignedShift } from '@/data/ShiftTrades/ShiftTradeActions';
import {
    isAssignedShiftInTradeCenter,
    selectOfferedShiftTrades,
    shiftTradesByAssignedShiftStatus
} from '@/data/ShiftTrades/ShiftTradeSlice';
import { isSignedUserAdmin } from '@/data/System/SystemReducer';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import { serializeUser } from '@/utils/UserHelper';
import Button from '@/wrappers/Button';
import Datatable, { IDatatableProps } from '@/wrappers/Datatable';
import Radio from '@/wrappers/Radio';
import Table, { IProps as ITableProps } from '@/wrappers/Table';
import ShiftAsLabel from '../ShiftAsLabel';

type IProps = {
    disableNewTrade: boolean;
    readOnly?: boolean;
    selectedId: number | null;
    schedulePlanDayShiftId: number;
    schedulePlanId: number;
    timeZone?: string;
    onNewTradeClick: () => void;
    onSelectOffer: (selectedId: number) => void;
    onSelectTrade: (selectedId: number) => void;
};

const isOfferedTableInProgress = createSelector(
    offeredShiftTradeOffersStatus,
    shiftTradeOffersSelectingStatus,
    shiftTradesByAssignedShiftStatus,
    (...values) => values.some((value: IRequestState) => value === 'loading')
);

function TabTrade({
    disableNewTrade,
    readOnly,
    selectedId,
    timeZone,
    onNewTradeClick = () => {},
    schedulePlanDayShiftId,
    onSelectTrade,
    onSelectOffer,
    schedulePlanId
}: IProps) {
    const dispatch = useAppDispatch();
    const dateFormatter = useLocalizeDateFormatter({ timeZone });
    const { t } = useAppTranslation();
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const inProgress = useAppSelector(isOfferedTableInProgress);
    const hasCreatedTrade = useAppSelector((state) => isAssignedShiftInTradeCenter(state, schedulePlanDayShiftId));
    const allOfferedOffers = useAppSelector(selectOfferedShiftTradeOffers);
    const allOfferedTades = useAppSelector(selectOfferedShiftTrades);
    const trades = allOfferedTades[schedulePlanDayShiftId] ?? [];
    const offers = allOfferedOffers[schedulePlanDayShiftId] ?? [];
    const allOffersByAssignedShift = useAppSelector(selectActiveShiftTradeOffersByAssignedShift);
    const selectedTrade = allOffersByAssignedShift[schedulePlanDayShiftId] ?? null;
    const selectedOffers = useAppSelector(selectSelectedShiftTradeOffersByAssignedShift);
    const selectedOffer = selectedOffers[schedulePlanDayShiftId] ?? null;

    useEffect(() => {
        dispatch(fetchShiftTradesByAssignedShift(schedulePlanDayShiftId));
        dispatch(fetchShiftTradeOffersByAssignedShift(schedulePlanDayShiftId));
    }, [schedulePlanDayShiftId]);

    const handleNewTradeClick = useCallback(() => {
        dispatch(
            createShiftTrade({ schedulePlanDayShiftId: schedulePlanDayShiftId, schedulePlanId: schedulePlanId })
        ).then(onNewTradeClick);
    }, [onNewTradeClick, schedulePlanId, schedulePlanDayShiftId]);
    const handleRemoveOffer = useCallback(
        (tradeId: number, offerId: number) => {
            dispatch(removeOfferedShiftTrade({ tradeId, offerId }));
        },
        [schedulePlanDayShiftId]
    );

    const tableColumns = <ROW extends { schedule_plan_day_shift: ISchedulePlanDayShiftExtendedModel }>(
        onClickRemove: (rowData: ROW) => void
    ): ITableProps<ROW>['columns'] => [
        {
            id: 'employee',
            label: t('header.employee', 'Employee'),
            access: (rowData: ROW) => serializeUser(rowData.schedule_plan_day_shift.user)
        },
        {
            id: 'shift',
            label: t('header.shiftType', 'Shift Type'),
            access: (rowData: ROW) => (
                <ShiftAsLabel
                    color={rowData.schedule_plan_day_shift.shift.color}
                    background={rowData.schedule_plan_day_shift.shift.background}
                    text={rowData.schedule_plan_day_shift.shift.abbreviation}
                    from={rowData.schedule_plan_day_shift.shift_start}
                    to={rowData.schedule_plan_day_shift.shift_end}
                    timeZone={timeZone}
                />
            )
        },
        {
            id: 'date',
            label: t('header.date', 'Date'),
            access: (rowData: ROW) =>
                dateFormatter.format(
                    DateHelper.fromDateTimeString(rowData.schedule_plan_day_shift.shift_start).toDate()
                )
        },
        ...(isUserAdmin && !readOnly
            ? [
                  {
                      id: 'actions',
                      label: t('header.actions', 'Actions'),
                      access: (rowData: ROW) => (
                          <Button justIcon name="remove" color="error" onClick={() => onClickRemove(rowData)}>
                              <ClearIcon />
                          </Button>
                      )
                  }
              ]
            : [])
    ];

    if (selectedTrade) {
        return (
            <Table
                name="selectedTrade"
                rows={[selectedTrade.shift_trade]}
                columns={tableColumns((rowData: typeof selectedTrade.shift_trade) =>
                    handleRemoveOffer(rowData.id, selectedTrade.id)
                )}
            />
        );
    }

    if (selectedOffer) {
        return (
            <Table
                name="selectedOffer"
                rows={[selectedOffer]}
                columns={tableColumns((rowData: typeof selectedOffer) =>
                    handleRemoveOffer(rowData.schedule_plan_day_shift_trade_id, rowData.id)
                )}
            />
        );
    }

    if (hasCreatedTrade) {
        return (
            <Datatable
                simplePaging
                loading={inProgress}
                hasSelection={false}
                hasTitle={false}
                noRecordMessage={t('message.info.noShiftTradeOffersToDisplay', 'No Shift Trade Offers to display')}
                header={
                    [
                        ...(readOnly
                            ? []
                            : ([
                                  {
                                      field: 'id',
                                      headerName: t('header.select', 'Select'),
                                      sortable: false,
                                      renderCell: ({ row }) => (
                                          <Radio
                                              checked={selectedId === row.id}
                                              value={row.id}
                                              data-testid={`offer-${row.id}`}
                                              name={`offer-${row.id}`}
                                              onClick={() => onSelectOffer(row.id)}
                                          />
                                      )
                                  }
                              ] as IDatatableProps<ArrayElement<typeof offers>>['header'])),
                        ...tableColumns((rowData: ArrayElement<typeof offers>) =>
                            handleRemoveOffer(rowData.schedule_plan_day_shift_trade_id, rowData.id)
                        )
                    ] as IDatatableProps<ArrayElement<typeof offers>>['header']
                }
                data={offers}
            />
        );
    }

    return (
        <Datatable
            simplePaging
            loading={inProgress}
            name="TabTrade"
            customActionsInToolbar={
                disableNewTrade || readOnly ? undefined : (
                    <Button variant="contained" name="newTrade" data-testid="newTrade" onClick={handleNewTradeClick}>
                        {t('label.newTrade', 'New Trade')}
                    </Button>
                )
            }
            hasSearch={false}
            hasSelection={false}
            hasTitle={!disableNewTrade && !readOnly}
            noRecordMessage={t('message.info.noShiftTradesToDisplay', 'No Shift Trades to display')}
            header={[
                ...(disableNewTrade || readOnly
                    ? []
                    : ([
                          {
                              field: 'id',
                              headerName: t('header.select', 'Select'),
                              sortable: false,
                              renderCell: ({ row }) => (
                                  <Radio
                                      checked={selectedId === row.id}
                                      value={row.id}
                                      data-testid={`offer-${row.id}`}
                                      name={`offer-${row.id}`}
                                      onClick={() => onSelectTrade(row.id)}
                                  />
                              )
                          }
                      ] as IDatatableProps<ArrayElement<typeof trades>>['header'])),
                {
                    field: 'employee',
                    headerName: t('header.employee', 'Employee'),
                    renderCell: ({ row }) => serializeUser(row.schedule_plan_day_shift.user)
                },
                {
                    field: 'shift',
                    headerName: t('header.shiftType', 'Shift Type'),
                    renderCell: ({ row }) => (
                        <ShiftAsLabel
                            color={row.schedule_plan_day_shift.shift.color}
                            background={row.schedule_plan_day_shift.shift.background}
                            text={row.schedule_plan_day_shift.shift.abbreviation}
                            from={row.schedule_plan_day_shift.shift_start}
                            to={row.schedule_plan_day_shift.shift_end}
                            timeZone={timeZone}
                        />
                    )
                },
                {
                    field: 'date',
                    headerName: t('header.date', 'Date'),
                    renderCell: ({ row }) =>
                        dateFormatter.format(
                            DateHelper.fromDateTimeString(row.schedule_plan_day_shift.shift_start).toDate()
                        )
                }
            ]}
            data={trades}
        />
    );
}

export default TabTrade;
