import { FileUpload, ICallable } from '@connect-soft/react-file-upload';
import { lighten, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { addNotification } from '@/data/Notification/NotificationSlice';
import {
    fetchschedulePlanDaySkillsCsvTempleData,
    fetchschedulePlanDaySkillsCsvTempleFile
} from '@/data/SchedulePlans/SchedulePlanActions';
import {
    invalidateSchedulePlan,
    isSchedulePlanDaySkillCsvFileTempleDataInProgress,
    isSchedulePlanDaySkillCsvFileTempleFileInProgress,
    schedulePlanDaySkillCsvFileTempleData
} from '@/data/SchedulePlans/SchedulePlanSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button/Button';
import Datatable from '@/wrappers/Datatable';
import Dialog from '@/wrappers/Dialog';
import LoadingButton from '@/wrappers/LoadingButton';

type IProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    schedulePlanId: number;
    isPlanLocked: boolean;
    scheduleName: string | null;
    onClose?: () => void;
};

const StyledInputField = styled(TextField)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: '5px',
    ['input::file-selector-button']: {
        border: 'none',
        background: theme.palette.primary.main,
        marginRight: '20px',
        marginTop: '-10px',
        borderRadius: '10px',
        color: lighten(theme.palette.primary.main, 0.75),
        padding: '10px 20px',
        cursor: 'pointer'
    }
}));

const UploadSchedulePlanDaySkillsCsvDialog = ({
    open,
    setOpen,
    isPlanLocked,
    schedulePlanId,
    scheduleName,
    ...props
}: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const fileRef = useRef<ICallable>(null);
    const token = localStorage.getItem('token');
    const [progress, setProgress] = useState(false);
    const data = useAppSelector(schedulePlanDaySkillCsvFileTempleData);
    const isCsvFileTempleInProgress = useAppSelector(isSchedulePlanDaySkillCsvFileTempleFileInProgress);

    useEffect(() => {
        if (open) {
            dispatch(fetchschedulePlanDaySkillsCsvTempleData());
        }
    }, [open]);

    const handleSubmit = useCallback(() => {
        setProgress(true);
        fileRef?.current?.sendFiles();
    }, [fileRef]);

    const handleClose = useCallback(() => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }, [setOpen, props.onClose]);

    const handleOnUploaded = useCallback(
        (status: 'success' | 'error') => {
            if (status === 'success') {
                dispatch(
                    addNotification({
                        variant: 'success',
                        context: 'message.success.csvFileToSchedulePlanDays',
                        defaultMessage: 'The CSV file was successfully uploaded to schedule plan {{schedulePlanName}}',
                        values: { schedulePlanName: scheduleName ?? '' }
                    })
                );

                dispatch(invalidateSchedulePlan(schedulePlanId));
                handleClose();
            } else {
                dispatch(
                    addNotification({
                        variant: 'error',
                        context: 'message.error.somethingWentWrong',
                        defaultMessage: 'Something went wrong'
                    })
                );
            }

            setProgress(false);
        },
        [schedulePlanId]
    );
    const handleGetCsvFileTemple = useCallback(() => {
        if (open) {
            dispatch(fetchschedulePlanDaySkillsCsvTempleFile());
        }
    }, [open]);

    return (
        <Dialog
            name="uploadSchedulePlanDaySkillsCsvDialog"
            open={open}
            onClose={handleClose}
            openButton={undefined}
            maxWidth="lg"
            openButtonValue={undefined}
            actions={(onClose) => (
                <>
                    <Button name="no" variant="text" onClick={onClose}>
                        {t('label.cancel', 'Cancel')}
                    </Button>
                    <LoadingButton
                        loading={isCsvFileTempleInProgress}
                        name="submit"
                        variant="outlined"
                        color="primary"
                        disabled={progress}
                        onClick={handleGetCsvFileTemple}
                    >
                        {t('label.downloadCsvTempleFile', 'Download Csv Temple File')}
                    </LoadingButton>
                    <LoadingButton
                        name="add"
                        variant="contained"
                        loading={progress}
                        onClick={handleSubmit}
                        data-testid="send"
                    >
                        {t('label.send', 'Send')}
                    </LoadingButton>
                </>
            )}
        >
            <FileUpload
                apiPath={`/api/schedule-plans/upload-schedule-plan-day-skills-csv/${schedulePlanId}`}
                slots={{
                    input: (
                        <StyledInputField
                            type="file"
                            inputProps={{
                                multiple: true
                            }}
                        />
                    )
                }}
                requestHeaders={{
                    JWT: `Bearer ${token}`
                }}
                withButton={false}
                innerRef={fileRef}
                onFinished={handleOnUploaded}
            />
            <Typography variant="subtitle2" sx={{ paddingTop: 2 }}>
                {t(
                    'message.info.schedulePlanDaySkillsCsvUpload',
                    'Temple of csv file to upload. If the file does not contain full header than the order of columns must be followed'
                )}
            </Typography>
            <Datatable
                header={data?.header?.map((item) => ({ title: item, field: item })) ?? []}
                data={data?.templeData ?? []}
                loading={useAppSelector(isSchedulePlanDaySkillCsvFileTempleDataInProgress)}
                hasTitle={false}
                hasSearch={false}
                hasSelection={false}
                hasSorting={false}
                hasPaginating={false}
            />
        </Dialog>
    );
};

export default UploadSchedulePlanDaySkillsCsvDialog;
