const appLocation = `${window.RUNTIME_CONFIG.PUBLIC_URL || ''}`;
const nodeEnv = `${window.RUNTIME_CONFIG.ENVIRONMENT || 'production'}`;
const defaultLocale = `${window.RUNTIME_CONFIG.DEFAULT_LOCALE || 'en-gb'}`;
const defaultScheduleCompactMode = `${window.RUNTIME_CONFIG.DEFAULT_SCHEDULE_COMPACT_MODE || 'off'}`;
const featureFlagScheduleCompactMode = `${window.RUNTIME_CONFIG.FEATURE_FLAG_SCHEDULE_COMPACT_MODE || 'off'}`;

export const enableOptions = ['true', 'on', '1'];

const loadConfig = () => ({
    appLocation,
    defaultLocale,
    defaultScheduleCompactMode: enableOptions.includes(defaultScheduleCompactMode.toLowerCase()),
    nodeEnv,
    features: {
        scheduleCompactMode: enableOptions.includes(featureFlagScheduleCompactMode.toLowerCase())
    }
});

export default loadConfig;
