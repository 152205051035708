import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { IRootState } from '@/data/store';
import { createUser, fetchUserById, removeUser, updateUser } from '@/data/Users/UserActions';
import { patchStateToRequest } from '@/data/UserToRequests/UserToRequestActions';
import { IUserToVacationModel } from '@/data/UserToVacations/UserToVacationModels';

const adapter = createEntityAdapter<IUserToVacationModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => (a.from_date.localeCompare(b.from_date) ? 1 : -1)
});

const userToVacationSlice = createSlice({
    name: 'userToVacation',
    initialState: adapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                const userIds = action.payload.workplace.user_to_workplaces.map(({ user_id }) => user_id);

                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item && userIds.includes(item.user_id)
                        ) as IUserToVacationModel[]
                    ).map(({ id }) => id)
                );
                adapter.upsertMany(
                    state,
                    action.payload.users.flatMap(({ user_to_vacations }) => user_to_vacations)
                );
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                adapter.addMany(state, action.payload.user_to_vacations);
            })
            .addCase(createUser.fulfilled, (state, action) => {
                adapter.addMany(state, action.payload.user_to_vacations);
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (Object.values(state.entities) as IUserToVacationModel[])
                        .filter(({ user_id }) => user_id === action.meta.arg.id)
                        .map(({ id }) => id)
                );
                adapter.addMany(state, action.payload.user_to_vacations);
            })
            .addCase(patchStateToRequest.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (Object.values(state.entities) as IUserToVacationModel[])
                        .filter(({ request_id }) => request_id === action.meta.arg.id)
                        .map(({ id }) => id)
                );

                if (action.payload.user_to_vacations) {
                    adapter.upsertOne(state, action.payload.user_to_vacations);
                }
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.user_id === action.meta.arg
                        ) as IUserToVacationModel[]
                    ).map(({ id }) => id)
                );
            });
    }
});

const getState = (state: IRootState) => state[userToVacationSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default userToVacationSlice;
export const userToVacationAll = adapterSelectors.selectAll;
