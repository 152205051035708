import { useCallback } from 'react';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { createRequestType, updateTypeRequest } from '@/data/RequestTypes/RequestTypeActions';
import { IRequestTypeModel } from '@/data/RequestTypes/RequestTypeModels';
import {
    requestTypeById,
    requestTypeCreatingStatus,
    requestTypeUpdatingStatus,
    requestPaging,
    updatePaging
} from '@/data/RequestTypes/RequestTypeSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import { message, regex } from '@/utils/validations';
import { IAbbreviationValue } from '@/wrappers/Abbreviation';

type IProps = Omit<ICuProps<IRequestTypeModel>, 'resource'>;

const RequestTypeForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const loadedData = useAppSelector((state) => requestTypeById(state, id));
    const paging = useAppSelector(requestPaging);
    const handleData = (values: IOutputValueType) => ({
        name: values.name as string,
        approve: values.approve as boolean,
        active: values.active as boolean,
        type: values.type as string,
        description: values.description as string,
        abbreviation: (values.abbreviation as IAbbreviationValue).text as string,
        color: (values.abbreviation as IAbbreviationValue).color as string,
        background: (values.abbreviation as IAbbreviationValue).background as string
    });
    const handleCreate = useCallback((values: IOutputValueType) => createRequestType(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => updateTypeRequest({ id: id!, data: handleData(values) }),
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);

    return (
        <CuForm
            {...props}
            id={id}
            name="requestType"
            resource={PermissionsEnum.RequestTypes}
            creatingStatus={useAppSelector(requestTypeCreatingStatus)}
            updatingStatus={useAppSelector(requestTypeUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            items={[
                {
                    type: 'switch',
                    props: {
                        name: 'active',
                        label: t('label.active', 'Active'),
                        value: loadedData?.active ?? true,
                        width: 6
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'approve',
                        label: t('label.approve', 'Approve'),
                        value: loadedData?.approve ?? true,
                        width: 6
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: t('label.name', 'Name'),
                        value: loadedData?.name,
                        width: 6,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'type',
                        label: t('label.type', 'Type'),
                        value: loadedData?.type,
                        options: [
                            { id: RequestTypeEnum.shifts, label: t('label.shift', 'Shifts') },
                            { id: RequestTypeEnum.times_off, label: t('label.timeOff', 'Time off') },
                            { id: RequestTypeEnum.available, label: t('label.available', 'Available') }
                        ],
                        width: 6
                    }
                },
                {
                    type: 'textArea',
                    props: {
                        required: false,
                        name: 'description',
                        label: t('label.description', 'Description'),
                        value: loadedData?.description,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'abbreviation',
                    props: {
                        required: true,
                        name: 'abbreviation',
                        label: {
                            text: t('label.abbreviation', 'Abbreviation'),
                            color: t('label.color', 'Color'),
                            background: t('label.background', 'Background')
                        },
                        value: loadedData
                            ? {
                                  text: loadedData.abbreviation,
                                  color: loadedData.color,
                                  background: loadedData.background
                              }
                            : undefined,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                }
            ]}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default RequestTypeForm;
