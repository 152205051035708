import { IUserCUVacations } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import Datatable from '@/wrappers/Datatable';

type IProps = {
    data: IUserCUVacations[];
};

const VacationsTab = ({ data }: IProps) => {
    const { t } = useAppTranslation();
    const dateTimeFormatter = useLocalizeDateTimeFormatter();

    return (
        <Datatable
            sortingMode="client"
            header={[
                {
                    field: 'from_date',
                    headerName: t('header.fromDate', 'From date'),
                    type: 'dateTime'
                },
                {
                    field: 'to_date',
                    headerName: t('header.toDate', 'To date'),
                    type: 'dateTime'
                }
            ]}
            paging={{
                sort: 'from_date',
                direction: 'desc'
            }}
            formatters={{
                dateTime: dateTimeFormatter.format
            }}
            data={data}
            hasTitle={false}
            hasSearch={false}
            hasSelection={false}
            hasPaginating={false}
            showQuickFilter={false}
        />
    );
};

export default VacationsTab;
