import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';
import { GridRowId } from '@mui/x-data-grid';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { MouseEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import UserPermission, { isUserAllowed, Mode } from '@/components/UserPermision';
import { IRequestState } from '@/data/ApiRequest';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { IPaging } from '@/data/Paging';
import { getPermissionsList } from '@/data/System/SystemReducer';
import formatSnakeToPascal from '@/helpers/format/formatSnakeToPascal';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import { PermissionsEnumType } from '@/utils/enums/PermissionsEnum';
import Datatable, { IDatatableProps } from '@/wrappers/Datatable';
import { IDatatableActions } from '@/wrappers/Datatable/Datatable';
import RemoveDialog from '@/wrappers/RemoveDialog';

export type ICrudDatatableProps<RowType extends object, IDType extends GridRowId = number> = Pick<
    IDatatableProps<RowType>,
    | 'autoHeight'
    | 'customActionsInToolbar'
    | 'data'
    | 'detailPanel'
    | 'editMode'
    | 'getRowId'
    | 'hasSearch'
    | 'hasSelection'
    | 'hasPaginating'
    | 'hasSorting'
    | 'hasTitle'
    | 'header'
    | 'hiddenFields'
    | 'noRecordMessage'
    | 'paging'
    | 'sortingMode'
    | 'searchPlaceHolder'
> & {
    actions?: (id: IDType, rowData: RowType) => ReactElement;
    actionsMinWidth?: number;
    countOfCustomRowActions?: number;
    dialogs?: (id?: IDType, rowData?: RowType) => ReactElement;
    // isLoading?: boolean;
    disableRemove?: boolean;
    disabled?: boolean;
    editByRowClick?: boolean;
    formRender: (
        id?: IDType,
        justIcon?: boolean,
        openButtonValue?: string,
        rowData?: RowType,
        renderButton?: (onClick: (event: MouseEvent) => void) => ReactElement
    ) => ReactElement;
    // hasSelection?: boolean;
    updatingStatus?: IRequestState;
    // removingStatus?: IRequestState;
    // titleOfActionHeader?: string;
    // searchPlaceHolder?: string;
    // // toolbarActions?: IToolbarActions<RowType, IDType>[];
    truncateCells?: string[];
    editResourceMode?: IResourceModeProp;
    name: string;
    nameOfEntity: (row: RowType) => string;
    resource?: PermissionsEnumType | PermissionsEnumType[];
    rowResource?: (row: RowType) => PermissionsEnumType | PermissionsEnumType[] | undefined;
    onRemove?: (id: IDType) => Promise<any>;
    onFetchList?: (paging: Partial<IPaging>) => AsyncThunkAction<unknown, Partial<IPaging>, {}>;
};

type IResourceModeProp = {
    mode: Mode | Mode[];
    operator?: 'AND' | 'OR';
};

const StyledButtonGroup = styled(ButtonGroup)`
    display: flex;
    justify-content: flex-end;
`;

export default function CrudDatatable<RowType extends object, IDType extends GridRowId = number>({
    // updatingStatus,
    // removingStatus,
    actions,
    actionsMinWidth,
    countOfCustomRowActions = 1,
    customActionsInToolbar,
    // titleOfActionHeader,
    dialogs,
    disabled = false,
    disableRemove = false,
    editByRowClick = true,
    editResourceMode = {
        mode: Mode.UPDATE
    },
    formRender,
    header,
    name,
    // hasSelection = false,
    nameOfEntity,
    resource,
    rowResource,
    onFetchList,
    onRemove,
    ...rest
}: ICrudDatatableProps<RowType, IDType>) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const datatableActionsRef = useRef<IDatatableActions<RowType> | null>(null);
    const dateFormater = useLocalizeDateFormatter();
    const [loadingList, setLoadingList] = useState(false);
    const [removingInProgress, setRemovingInProgress] = useState(false);
    const permissionsList = useAppSelector(getPermissionsList);
    // const [removingSubmitted, setRemovingSubmitted] = useState(false);

    const onPagingChanged = useCallback(
        (page: number, perPage: number) => {
            if (onFetchList) {
                setLoadingList(true);
                dispatch(onFetchList({ page: page + 1, limit: perPage }))
                    .unwrap()
                    .finally(() => setLoadingList(false));
            }
        },
        [onFetchList]
    );
    const onSortingChanged = useCallback(
        (column: string, direction: 'asc' | 'desc') => {
            if (onFetchList) {
                setLoadingList(true);
                dispatch(onFetchList({ page: 1, sort: column, direction }))
                    .unwrap()
                    .finally(() => setLoadingList(false));
            }
        },
        [onFetchList]
    );
    const onSearchChanged = useCallback(
        (searchText: string) => {
            if (onFetchList) {
                setLoadingList(true);
                dispatch(onFetchList({ search: searchText, page: 1 })).finally(() => setLoadingList(false));
            }
        },
        [onFetchList]
    );
    const handleRemove = useCallback(
        (id: IDType, onSuccess: () => void) => {
            if (onRemove) {
                setRemovingInProgress(true);

                onRemove(id)
                    .then(() => {
                        onSuccess();
                        datatableActionsRef.current?.removeRow(id as string | number);
                        if (onFetchList) {
                            onPagingChanged(0, rest.paging?.current ?? 10);
                        }
                    })
                    .finally(() => setRemovingInProgress(false));
            }
        },
        [datatableActionsRef, onRemove, onFetchList]
    );

    // const reload = () => {
    //     if (props.paging) {
    //         const page = props.data.length > 0 ? props.paging.page : props.paging.page - 1;
    //
    //         if (props.onFetchList) {
    //             dispatch(props.onFetchList({ ...props.paging, page }));
    //         }
    //     }
    // };
    useEffect(() => {
        if (onFetchList) {
            setLoadingList(true);
            dispatch(onFetchList({ page: 1 })).finally(() => setLoadingList(false));
        }
    }, []);

    // useEffect(() => {
    //     if (removingSubmitted && removingStatus === 'idle') {
    //         reload();
    //     }
    // }, [removingSubmitted, removingStatus]);
    //
    // useEffect(() => {
    //     if (updatingStatus === 'idle') {
    //         reload();
    //     }
    // }, [updatingStatus]);
    //

    return (
        <Datatable<RowType>
            {...rest}
            name={formatSnakeToPascal(name)}
            customActionsInToolbar={
                !customActionsInToolbar && disabled ? undefined : (
                    <>
                        {customActionsInToolbar ? <>{customActionsInToolbar}</> : <></>}
                        {disabled ? (
                            <></>
                        ) : !resource ||
                          isUserAllowed(
                              {
                                  id: resource,
                                  mode: Mode.CREATE
                              },
                              permissionsList
                          ) ? (
                            formRender(
                                undefined,
                                undefined,
                                t(`label.add${formatSnakeToPascal(name)}`, `Add ${formatSnakeToPascal(name)}`)
                            )
                        ) : (
                            <></>
                        )}
                    </>
                )
            }
            // truncateCells={props.truncateCells}
            formatters={{
                date: dateFormater.format
            }}
            innerRef={datatableActionsRef}
            header={[
                ...header,
                {
                    ...(dialogs || actions ? { minWidth: 100 + countOfCustomRowActions * 50 } : { maxWidth: 100 }),
                    align: 'right',
                    disableExport: true,
                    field: 'actions',
                    headerAlign: 'center',
                    headerName: t('header.actions', 'Actions'),
                    sortable: false,
                    cellClassName: 'dataGrid-cell-actions',
                    renderCell: ({ row, id: rowId }) => (
                        <>
                            <StyledButtonGroup
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            >
                                <UserPermission
                                    id={rowResource ? rowResource(row) ?? resource ?? null : resource ?? null}
                                    mode={editResourceMode?.mode}
                                    operator={editResourceMode?.operator}
                                >
                                    {actions ? actions(rowId as IDType, row) : ''}
                                    {formRender(rowId as IDType, true, undefined, row)}
                                </UserPermission>
                                <UserPermission
                                    id={rowResource ? rowResource(row) ?? resource ?? null : resource ?? null}
                                    mode={Mode.DELETE}
                                >
                                    {!disableRemove && onRemove ? (
                                        <RemoveDialog<IDType>
                                            justIcon
                                            onAgree={(onSuccess) => handleRemove(rowId as IDType, onSuccess)}
                                            nameOfItem={nameOfEntity(row)}
                                            idOfItem={rowId as IDType}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </UserPermission>
                            </StyledButtonGroup>
                            {dialogs ? dialogs(rowId as IDType, row) : ''}
                        </>
                    )
                }
            ]}
            loading={loadingList || removingInProgress}
            // noRecordMessage={props.noRecordMessage}
            onPagingChanged={onPagingChanged}
            onSortingChanged={onSortingChanged}
            onSearchChanged={onSearchChanged}
        />
    );
}
