import dayjsInstance, { Dayjs, ManipulateType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import durationPlugin from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/cs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { dateAddDays, dateAddHours, dateAddMinutes } from '@/helpers/date/dateAdd';
import {
    getDifferenceOfDateAsDays,
    getDifferenceOfDateAsMinutes,
    getDifferenceOfDateInHours
} from '@/helpers/date/getDifferenceOfDates';
import config from '@/utils/configStatic';

dayjsInstance.extend(customParseFormat);
dayjsInstance.extend(isBetween);
dayjsInstance.extend(relativeTime, {
    thresholds: [
        { l: 's', r: 1 },
        { l: 'm', r: 1 },
        { l: 'mm', r: 59, d: 'minute' },
        { l: 'h', r: 1 },
        { l: 'hh', r: 23, d: 'hour' },
        { l: 'd', r: 1 },
        { l: 'dd', r: 29, d: 'day' },
        { l: 'M', r: 1 },
        { l: 'MM', r: 11, d: 'month' },
        { l: 'y', r: 1 },
        { l: 'yy', d: 'year' }
    ]
});
dayjsInstance.extend(durationPlugin);
dayjsInstance.extend(utc);
dayjsInstance.extend(timezone);
dayjsInstance.extend(weekday);
dayjsInstance.extend(localizedFormat);
// dayjsInstance.extend(updateLocale);
// dayjsInstance.updateLocale('en', {});

export type DateTimeType = Dayjs;

interface DateAndDayjs {
    <T extends Dayjs | null>(source: T, keepInLocal?: boolean): T;

    <T extends Date>(source: T, keepInLocal?: boolean): Dayjs;
}
interface DateOrNull {
    <T extends Date | null>(source: T): T;

    (source: Dayjs | Date): Date;
}

interface DateAndDayjsOnly {
    <T extends Dayjs | null>(date: T): T;

    <T extends Date>(date: T): Dayjs;
}

interface DateOrDayjsOnly {
    <T extends Dayjs | null>(date: T): T;

    <T extends Date | null>(date: T): T;
}

class DateHelper {
    public static fromTimeStringAndLocalDate(time: string, date: Dayjs | Date, timeZone: null | string = null) {
        const formatted = `${this.formatDate(date)}T${time}.000Z`;

        return this.fromDateTimeString(formatted, timeZone ?? undefined);
    }

    public static fromTimeString(time: string, isUtc: boolean | string = true) {
        const patter = config.time.inputFormat.length === time.length ? config.time.inputFormat : config.time.dbFormat;

        if (typeof isUtc === 'string') {
            return dayjsInstance.tz(time, patter, isUtc);
        }

        return this.utc(dayjsInstance(time, patter, true), isUtc);
    }

    //TODO: při opravovani toto opravit pro timepicker ( kvuli .utc(..) nejde nastavit  {@link DateHelper::fromTimeString}
    public static fromTimeStringToTimePickerHack(time: string) {
        const patter = config.time.inputFormat.length === time.length ? config.time.inputFormat : config.time.dbFormat;
        const parsed = dayjsInstance(time, patter, true);

        return this.addMinutesByTimezone(parsed, Intl.DateTimeFormat().resolvedOptions().timeZone);
    }

    public static fromTimeStringAndDuration(time: string, duration: number, unit: ManipulateType = 'h', isUtc = true) {
        return this.fromTimeString(time, isUtc)?.add(duration, unit);
    }

    public static fromDateTimeString(source: string, timeZone?: string, keepInLocal = false) {
        return timeZone ? dayjsInstance.tz(source, timeZone) : this.utc(dayjsInstance(source), keepInLocal);
    }

    public static fromOptionalYear(source?: number) {
        if (!source) {
            return null;
        }

        return dayjsInstance().set('year', source);
    }

    public static fromOptionalMonth(source?: number) {
        if (!source) {
            return null;
        }

        return dayjsInstance().set('month', source);
    }

    public static format(source: Dayjs, format: string) {
        return source.format(format);
    }

    public static addMinutesByTimezone(source: Dayjs, timeZone: string | null) {
        const offset = DateHelper.now()
            .tz(timeZone ?? undefined)
            .utcOffset();

        return DateHelper.addMinutes(source, offset);
    }

    public static subtractMinutesByTimezone(source: Dayjs, timeZone: string | null) {
        const offset =
            DateHelper.now().utcOffset() -
            DateHelper.now()
                .tz(timeZone ?? undefined)
                .utcOffset();

        return DateHelper.addMinutes(source, -offset);
    }

    public static getUTCStartOfTheDay(source: Dayjs, keepInLocal?: boolean) {
        return this.utc(source.startOf('d'), keepInLocal);
    }

    public static getUTCStartOfTheMonth(monthIndex?: number, keepInLocal?: boolean) {
        let source = this.now();

        if (monthIndex) {
            source = source.set('month', monthIndex);
        }

        return this.utc(source.startOf('month'), keepInLocal);
    }

    public static getMinute(source: Dayjs) {
        return source.minute();
    }

    public static getSeconds(source: Dayjs) {
        return source.second();
    }

    public static getHour(source: Dayjs | Date) {
        return source instanceof Date ? source.getHours() : source.hour();
    }

    public static getMonth(source: Dayjs) {
        return source.month();
    }

    public static getYear(source: Dayjs) {
        return source.year();
    }

    public static getDay(source: Dayjs) {
        return source.date();
    }

    public static getDayOfWeek(source: Dayjs) {
        return source.weekday();
    }

    public static setDay(source: Dayjs, newDay: number) {
        return source.date(newDay);
    }

    public static setTimeZone(source: Dayjs | Date, timeZone: string) {
        return this.getInstanceOf(source).tz(timeZone);
    }

    /**
     * Create DateTime object in current TZ but represents midnight in TimeZone of Source.
     *
     * @param source Source date
     * @param timeZone TimeZone of Source Date
     */
    public static fromDateString(source: string, timeZone?: string | null | 'local') {
        return typeof timeZone === 'string' && timeZone !== 'local'
            ? dayjsInstance.tz(source, config.date.dbFormat, timeZone)
            : timeZone === 'local'
            ? dayjsInstance(source, config.date.dbFormat, true)
            : this.utc(dayjsInstance(source, config.date.dbFormat, true));
    }

    /**
     * Create DateTime object in current TZ but represents midnight in TimeZone of Source or null.
     *
     * @param source Source date
     * @param timeZone TimeZone of Source Date
     */
    public static fromOptionalDateString(source: string | null, timeZone?: string | null) {
        return source === null ? null : this.fromDateString(source, timeZone);
    }

    public static moveDateTimeToTimeZone(source: DateTimeType, timeZone: string, reverse = false) {
        const offset = DateHelper.clone(source).utcOffset() - DateHelper.clone(source).tz(timeZone).utcOffset();

        if (offset === 0) {
            return this.setTimeZone(this.clone(source), timeZone);
        }

        return this.utc(DateHelper.addMinutes(source, offset * (reverse ? -1 : 1)));
    }

    public static fromOptionalTime(source?: Dayjs | Date | string | null, isUtc: boolean | string = true) {
        if (!source) {
            return null;
        } else if (source instanceof Date) {
            return dayjsInstance(source);
        } else if (typeof source === 'string') {
            return this.fromTimeString(source, isUtc);
        }

        return source;
    }

    public static formatISO(input: Dayjs | Date) {
        return input.toISOString();
    }

    public static formatTime(source?: Dayjs | Date | null, format: string = config.time.dbFormat) {
        if (!source) {
            return '';
        }

        const parsed = this.getInstanceOf(source);

        return parsed.format(format);
    }

    public static formatDate(source?: Dayjs | Date | null, format: string = config.date.dbFormat) {
        if (!source) {
            return '';
        }

        const parsed = this.getInstanceOf(source);

        return parsed.format(format);
    }

    public static formatDateTime(source?: Dayjs | Date | null, format: string = config.dateTime.format) {
        if (!source) {
            return '';
        }

        const parsed = this.getInstanceOf(source);

        return parsed.format(format);
    }

    public static formatDateTimeToISO(source?: Dayjs | Date | string | null) {
        if (!source) {
            return '';
        } else if (typeof source === 'string') {
            const parsed = this.fromDateTimeString(source);

            return this.formatISO(parsed);
        }

        return this.formatISO(this.getInstanceOf(source));
    }

    public static formatTimeFromDateTime(source?: Dayjs | Date | string | null) {
        if (!source) {
            return '';
        } else if (typeof source === 'string') {
            const parsed = this.fromDateTimeString(source);

            return this.isValid(parsed) ? this.formatTime(parsed) : '';
        }

        return this.formatTime(this.getInstanceOf(source).toDate());
    }

    public static getMinutesInHumanFormat(countOfMinutes: number) {
        const onlyMinutes = countOfMinutes % 60;
        const onlyHours = (countOfMinutes / 60) % 24;
        const onlyDays = Math.floor(countOfMinutes / 60 / 24);

        const minutesObj = dayjsInstance.duration(onlyMinutes, 'minutes');
        const hoursObj = dayjsInstance.duration(onlyHours, 'hours');
        const daysObj = dayjsInstance.duration(onlyDays, 'days');

        return `${onlyDays > 0 ? daysObj.humanize() : ''}${
            onlyDays > 0 && (onlyMinutes > 0 || countOfMinutes > 60) ? ', ' : ''
        }${onlyHours > 0 ? hoursObj.humanize() : ''}${onlyMinutes > 0 && countOfMinutes > 60 ? ', ' : ''}${
            onlyMinutes > 0 && countOfMinutes > 60 ? minutesObj.humanize() : ''
        }`;
    }

    public static getFirstMomentOfDay: DateAndDayjsOnly = <T extends Dayjs | Date | null>(date: T): T => {
        if (!date) {
            return null as T;
        }

        const source = this.getInstanceOf(date);

        return source.startOf('day') as T;
    };

    public static getFirstFomentOfYear: DateAndDayjsOnly = <T extends Dayjs | Date | null>(date: T): T => {
        if (!date) {
            return null as T;
        }

        const source = this.getInstanceOf(date);

        return source.startOf('year') as T;
    };

    public static getStartOfLastYear = (): DateTimeType => {
        const lastYear = this.now().subtract(1, 'year');

        return DateHelper.getFirstFomentOfYear(lastYear);
    };

    public static getPastMonths = (numberOfMonths = 1): DateTimeType => {
        return this.now().subtract(numberOfMonths, 'months');
    };

    public static getLastMomentOfDay: DateAndDayjsOnly = <T extends Dayjs | Date | null>(date: T): T => {
        if (!date) {
            return null as T;
        }

        const source = this.getInstanceOf(date);

        return source.endOf('day') as T;
    };

    public static addMinutes<T extends Dayjs | Date | null>(date?: T, minutes: number = 0): T {
        if (!date) {
            return null as T;
        } else if (date instanceof Date) {
            return dateAddMinutes(date, minutes) as T;
        }

        return date.clone().add(minutes, 'minutes') as T;
    }

    public static addYears<T extends Dayjs | null>(date?: T, years: number = 0): T {
        if (!date) {
            return null as T;
        }

        return date.clone().add(years, 'years') as T;
    }

    public static subtractMinutes<T extends Dayjs | Date | null>(date?: T, minutes: number = 0): T {
        if (!date) {
            return null as T;
        } else if (date instanceof Date) {
            return dateAddMinutes(date, -minutes) as T;
        }

        return date.clone().subtract(minutes, 'minutes') as T;
    }

    public static addSeconds<T extends Dayjs | null>(date?: T, seconds: number = 0): T {
        if (!date) {
            return null as T;
        }

        return date.clone().add(seconds, 'seconds') as T;
    }

    public static addHours<T extends Dayjs | Date | null>(date?: T, hours: number | null = 0): T {
        if (!date || typeof hours !== 'number') {
            return null as T;
        } else if (date instanceof Date) {
            return dateAddHours(date, hours) as T;
        }

        return date.clone().add(hours, 'h') as T;
    }

    public static addDays<T extends Dayjs | Date | null>(date?: T, days: number = 0): T {
        if (!date || typeof days !== 'number') {
            return null as T;
        } else if (date instanceof Date) {
            return dateAddDays(date, days) as T;
        }

        return date.clone().add(days, 'days') as T;
    }

    public static utcOffset(date: DateTimeType): number {
        return date.utcOffset();
    }

    public static addMonths<T extends Dayjs | Date | null>(date?: T, months: number = 0): T {
        if (!date) {
            return null as T;
        }

        return DateHelper.getInstanceOf(date).add(months, 'months') as T;
    }

    public static getDifferenceInHours<T extends Dayjs | Date | null>(from: T, to: T): number {
        if (!from || !to) {
            return 0;
        } else if (from instanceof Date && to instanceof Date) {
            return getDifferenceOfDateInHours(from, to);
        } else if (from instanceof Date || to instanceof Date) {
            console.warn('Compare different types');

            return this.getInstanceOf(to).diff(this.getInstanceOf(from), 'h', true);
        } else if (typeof from === 'object' && typeof to === 'object') {
            return (to as Dayjs).diff(from as Dayjs, 'h', true);
        }

        return 0;
    }

    public static getDifferenceAsMinutes<T extends Dayjs | Date | null>(
        from: T,
        to: T,
        dependsOnTimeZone = false
    ): number {
        if (!from || !to) {
            return 0;
        } else if (from instanceof Date && to instanceof Date) {
            return (
                getDifferenceOfDateAsMinutes(from, to) +
                (dependsOnTimeZone ? from.getTimezoneOffset() - to.getTimezoneOffset() : 0)
            );
        } else if (from instanceof Date || to instanceof Date) {
            console.warn('Compare different types');

            return this.getInstanceOf(to).diff(this.getInstanceOf(from), 'minutes');
        } else if (typeof from === 'object' && typeof to === 'object') {
            return (
                (to as Dayjs).diff(from as Dayjs, 'minutes') +
                (dependsOnTimeZone ? (from as Dayjs).utcOffset() - (to as Dayjs).utcOffset() : 0)
            );
        }

        return 0;
    }

    public static getDifferenceAsDays<T extends Dayjs | Date | null>(from: T, to: T): number {
        if (!from || !to) {
            return 0;
        } else if (from instanceof Date && to instanceof Date) {
            return getDifferenceOfDateAsDays(from, to);
        } else if (from instanceof Date || to instanceof Date) {
            console.warn('Compare different types');

            return this.getInstanceOf(to).diff(this.getInstanceOf(from), 'days');
        } else if (typeof from === 'object' && typeof to === 'object') {
            return (to as Dayjs).diff(from as Dayjs, 'days');
        }

        return 0;
    }

    public static isEqual<T extends Dayjs | Date>(first: T | null, second: T | null) {
        if (first === null && second === null) {
            return true;
        } else if (first instanceof Date && second instanceof Date) {
            return first.getTime() === second.getTime();
        } else if (typeof first === 'object' && typeof second === 'object' && first !== null) {
            return (first as Dayjs).isSame(second);
        }

        return false;
    }

    public static isBefore<T extends Dayjs | Date>(before?: T, after?: T) {
        if (!before) {
            return false;
        } else if (!after) {
            return true;
        }

        if (before instanceof Date && after instanceof Date) {
            return before < after;
        }

        return (before as Dayjs).isBefore(after);
    }

    public static isAfter<T extends Dayjs | Date>(after: T, before: T) {
        if (after instanceof Date && before instanceof Date) {
            return before.getTime() < after.getTime();
        }

        return (after as Dayjs).isAfter(before);
    }

    public static isBetween<T extends Dayjs | Date>(
        source: T,
        before: T,
        after: T,
        inclusivity?: '[]' | '[)' | '()' | '(]'
    ) {
        if (source instanceof Date && before instanceof Date && after instanceof Date) {
            const sourceTime = source.getTime(),
                beforeTime = before.getTime(),
                afterTime = after.getTime();

            const sourceIsBetween = sourceTime < afterTime && sourceTime > beforeTime;

            return (
                sourceIsBetween ||
                (inclusivity?.startsWith('[') && beforeTime === sourceTime) ||
                (inclusivity?.endsWith(']') && afterTime === sourceTime)
            );
        }

        return (source as Dayjs).isBetween(before, after, null, inclusivity);
    }

    public static now() {
        return dayjsInstance();
    }

    public static floorMinutes(date: Dayjs) {
        const roundedDate = this.clone(date);

        return roundedDate.startOf('h');
    }

    public static floorSeconds(date: Dayjs) {
        const roundedDate = this.clone(date);

        return roundedDate.startOf('m');
    }

    public static floorToNearestQuarterMinutes(date: Dayjs) {
        const roundedDate = this.clone(date);

        return roundedDate.set('minutes', (Math.floor(roundedDate.minute() / 15) * 15) % 60).set('second', 0);
    }

    public static ceilToNearestQuarterMinutes(date: Dayjs | Date) {
        const roundedDate = this.clone(this.getInstanceOf(date));
        const result = Math.ceil(roundedDate.minute() / 15) * 15;

        return roundedDate
            .add((result - (result % 60)) / 60, 'hours')
            .set('minutes', result % 60)
            .set('second', 0);
    }

    public static roundToNearestMinutes(date: Dayjs, minutes: number) {
        const roundedDate = this.clone(date);
        const result = Math.round(roundedDate.minute() / minutes) * minutes;

        return roundedDate
            .add(Math.floor(result / 60), 'hours')
            .set('minutes', result % 60)
            .set('second', 0);
    }

    public static nowStartOfTheDay(keepInLocal?: boolean) {
        return this.utc(dayjsInstance().startOf('d'), keepInLocal);
    }

    public static firstWeekOfCurrentMonth() {
        return this.firstDayOfCurrentMonth().startOf('week');
    }

    public static getFirstDayOfWeekday() {
        return this.getFirstMomentOfDay(this.now().weekday(0));
    }

    public static firstDayOfCurrentMonth() {
        return this.now().startOf('month');
    }

    public static lastWeekOfCurrentMonth() {
        return this.lastDayOfCurrentMonth().endOf('week');
    }

    public static lastDayOfCurrentMonth() {
        return this.now().endOf('month');
    }

    public static clone: DateOrDayjsOnly = <T extends Date | Dayjs | null>(source: T): T => {
        return (source === null ? null : source instanceof Date ? new Date(source.getTime()) : source.clone()) as T;
    };

    public static utc: DateAndDayjs = <T extends Date | Dayjs | null>(source: T, keepInLocal?: boolean): T => {
        if (source instanceof Date) {
            return this.utc(this.getInstanceOf(source), keepInLocal) as T;
        }

        return (source ? source.utc(keepInLocal) : null) as T;
    };

    public static toDate: DateOrNull = <T extends Date | Dayjs | null>(source: T): T => {
        if (source instanceof Date) {
            return source as T;
        }

        return (source ? source.toDate() : null) as T;
    };

    public static weekDay(source: Dayjs | Date) {
        const date = this.getInstanceOf(source);

        return (date.day() + 6) % 7;
    }

    public static isValid(source?: Dayjs | Date | string | null) {
        return source && dayjsInstance(source).isValid();
    }

    public static isStrictValid(source: Dayjs | Date | string, format?: string) {
        return dayjsInstance(source, format, true).isValid();
    }

    public static getInstanceOf(source: Date | Dayjs): Dayjs {
        if (source instanceof Date) {
            return dayjsInstance(source);
        } else {
            return source;
        }
    }
}

export { dayjsInstance };
export default DateHelper;
