import { createTheme, PaletteOptions, alpha } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
    interface Theme {
        custom?: {
            [key: string]: {
                [key: string]: string;
            };
        };
        layout: {
            headerHeight: number;
            footerHeight: number;
            contentHeight: (theme: Theme) => string;
            contentHeightWithTitle: (theme: Theme) => string;
            pageTitleHeight: (theme: Theme) => number;
        };
        boxShadow: {
            hover: {
                createdRequest: string;
                notCreatedRequest: string;
            };
        };
    }

    interface ThemeOptions {
        custom?: {
            [key: string]: {
                [key: string]: string;
            };
        };
        boxShadow: {
            hover: {
                createdRequest: string;
                notCreatedRequest: string;
            };
        };
        layout: {
            headerHeight: number;
            footerHeight: number;
            contentHeight: (theme: Theme) => string;
            contentHeightWithTitle: (theme: Theme) => string;
            pageTitleHeight: (theme: Theme) => number;
        };
    }
}

export type IColors = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
export type IPredefinedColors = string | undefined | IColors;
export const predefinedColors: IPredefinedColors[] = [
    'default',
    'primary',
    'secondary',
    'error',
    'info',
    'success',
    'warning'
];

const palette: PaletteOptions = {
    mode: 'light',
    primary: {
        main: '#5D1049'
    },
    secondary: {
        main: '#9c27b0'
    },
    background: {
        paper: '#ffffff',
        default: '#fbf8f4'
    },
    text: {
        primary: '#423258',
        secondary: '#6A6370'
    },
    error: {
        main: '#ff1800',
        dark: '#B21000'
    },
    warning: {
        main: '#e0b002'
    },
    success: {
        main: '#279500'
    },
    info: {
        main: '#0471a6'
    }
};

const theme = createTheme({
    mixins: {
        MuiDataGrid: {
            containerBackground: alpha((palette.primary as SimplePaletteColorOptions).main ?? '', 0.04)
        }
    },
    palette,
    shape: {
        borderRadius: 6
    },
    typography: {
        fontFamily: '"Inter", sans-serif',
        subtitle2: {
            fontWeight: 400
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    minHeight: ownerState.size === 'large' ? '56px' : '40px'
                })
            }
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: () => ({
                    '& > .Mui-selected': {
                        border: '1px solid #423258'
                    }
                })
            }
        }
    },
    custom: {
        primary: {
            gradient:
                'linear-gradient(270deg, rgb(255 83 73 / 66%) 0%, rgb(159 43 73 / 86.2%) 0.01%, rgb(135 33 73 / 91.3%) 32.81%, #5d1049 92.71%)'
        }
    },
    boxShadow: {
        hover: {
            createdRequest: '#FFFFFF67',
            notCreatedRequest: '#00000067'
        }
    },
    layout: {
        headerHeight: 64,
        footerHeight: 56,
        contentHeight: (currentTheme) =>
            `calc(100vh - ${currentTheme.layout.headerHeight}px - ${
                currentTheme.layout.footerHeight
            }px - ${currentTheme.spacing(3)})`,
        contentHeightWithTitle: (currentTheme) =>
            `calc(${currentTheme.layout.contentHeight(currentTheme)} - ${currentTheme.layout.pageTitleHeight(
                currentTheme
            )}px - ${currentTheme.spacing(3)})`,
        pageTitleHeight: () => 56
    }
});

export default theme;
