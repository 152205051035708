import { PropsWithChildren } from 'react';
import { useAppSelector } from '@/data/hooks';
import { getPermissionsList } from '@/data/System/SystemReducer';
import { ILoginPermissionsResponseModel } from '@/data/System/UserModel';
import { PermissionsEnumType } from '@/utils/enums/PermissionsEnum';

export enum Mode {
    CREATE = 'create',
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete'
}

export const PERMISSION_NONE = 0;
export const PERMISSION_DELETE = 1;
export const PERMISSION_UPDATE = 2;
export const PERMISSION_READ = 4;
export const PERMISSION_CREATE = 8;

type IProps = {
    id: string | string[] | PermissionsEnumType | PermissionsEnumType[] | null;
    mode?: Mode | Mode[];
    operator?: 'AND' | 'OR';
    /** For overriding of permission */
    setAllowed?: boolean;
};

function transformModeToCode(mode: Mode | Mode[]): number {
    if (Array.isArray(mode)) {
        return mode.reduce((previousValue, item) => previousValue + transformModeToCode(item), 0);
    }

    switch (mode) {
        case Mode.CREATE:
            return PERMISSION_CREATE;
        case Mode.READ:
            return PERMISSION_READ;
        case Mode.UPDATE:
            return PERMISSION_UPDATE;
        case Mode.DELETE:
            return PERMISSION_DELETE;
        default:
            return PERMISSION_NONE;
    }
}

function getIsAllowed(
    mode: Mode | Mode[],
    operator: 'AND' | 'OR',
    permission?: ILoginPermissionsResponseModel
): boolean {
    const result = (permission?.permission || PERMISSION_NONE) & transformModeToCode(mode);

    if (Array.isArray(mode) && operator == 'AND') {
        return result === transformModeToCode(mode);
    } else {
        return result > PERMISSION_NONE;
    }
}

export const isUserAllowed = (
    { id, mode = Mode.READ, operator = 'AND' }: IProps,
    permissionsList: ILoginPermissionsResponseModel[]
): boolean => {
    if (id === null) {
        return true;
    }

    const listOfIds = Array.isArray(id) ? id : [id];

    return (
        id === null ||
        permissionsList
            .filter((element) => listOfIds.includes(element.id))
            .some((item) => getIsAllowed(mode, operator, item))
    );
};

export default function UserPermission({
    children,
    id,
    mode = Mode.READ,
    operator = 'AND',
    setAllowed
}: PropsWithChildren<IProps>) {
    const permissionsList = useAppSelector(getPermissionsList);
    const isAllowed = id === null || isUserAllowed({ id, mode, operator }, permissionsList);

    return (typeof setAllowed === 'boolean' ? setAllowed : isAllowed) ? <>{children}</> : <></>;
}
