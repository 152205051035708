import BoyOutlinedIcon from '@mui/icons-material/BoyOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useEffect, useState } from 'react';
import FormGenerator, { IOutputValueType } from '@/base/FormGenerator';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchUsersCategoryTypeOptions } from '@/data/Reports/ReportActions';
import { isUsersCategoryTypeOptionsStatus, usersCategoryTypeOptions } from '@/data/Reports/ReportSlice';
import {
    fetchSchedulePlansForSelect,
    getSchedulePlanDownloadNeeds,
    getSchedulePlanFundsFile
} from '@/data/SchedulePlans/SchedulePlanActions';
import {
    isSchedulePlanFundsDownloading,
    isSchedulePlanNeedDownloading,
    schedulePlanList
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { IRootState } from '@/data/store';
import { canUserCloseSchedulePlanSelector } from '@/data/System/SystemReducer';
import { fetchWorkplacesForSelect } from '@/data/Workplaces/WorkplaceActions';
import { workplacesForSelect } from '@/data/Workplaces/WorkplaceSlice';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import getMonthNames from '@/helpers/date/namesOfMonths';
import useAppTranslation from '@/hooks/useAppTranslation';
import config from '@/utils/config';
import SchedulePlanDownloadTypeEnum from '@/utils/enums/SchedulePlanDownloadTypeEnum';
import Dialog from '@/wrappers/Dialog';

const StyledTitle = styled(Box)`
    text-align: center;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
    margin-top: 1em;
    margin-bottom: 1em;
`;

type IProps = {
    open: boolean;
    onDialogClose: () => void;
};

const SchedulePlanDownloadDialog = ({ open, onDialogClose }: IProps) => {
    const { i18n, t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const workplacesData = useAppSelector(workplacesForSelect);
    const usersCategoryTypeOptionsData = useAppSelector(usersCategoryTypeOptions);
    const schedulePlanDataList = useAppSelector(schedulePlanList);
    const canUserClose = useAppSelector(canUserCloseSchedulePlanSelector);
    const [type, setType] = useState<SchedulePlanDownloadTypeEnum | null>(null);

    const handleClose = useCallback(() => {
        setType(null);
        onDialogClose();
    }, [onDialogClose]);

    const handleSubmit = useCallback(
        (values: IOutputValueType) => {
            if (open) {
                switch (type) {
                    case SchedulePlanDownloadTypeEnum.megalax: {
                        const workplaceIds = (values.workplaceIds ?? []) as string[];
                        const months = (values.months ?? []) as string[];
                        const date = values.year as DateTimeType;
                        const userSourceType = values.userSourceType as string | undefined | null;

                        dispatch(
                            getSchedulePlanFundsFile({
                                workplaceIdsList: workplaceIds.map((id) => parseInt(id)) as number[],
                                userSourceType: userSourceType ?? null,
                                monthsList: months.map((monthId) => parseInt(monthId)) as number[],
                                year: date.year(),
                                closeExportedPlans: (values?.close_exported_plans ?? false) as boolean
                            })
                        );
                        break;
                    }
                    case SchedulePlanDownloadTypeEnum.needs:
                        dispatch(getSchedulePlanDownloadNeeds({ schedulePlanId: values.schedulePlanId as number }));

                        break;
                }
            }
        },
        [open, type]
    );

    useEffect(() => {
        if (open) {
            dispatch(fetchWorkplacesForSelect({ search: '' }));
            dispatch(fetchSchedulePlansForSelect({ search: '' }));
            dispatch(fetchUsersCategoryTypeOptions());
        }
    }, [open]);

    const isLoadingSelector = createSelector(
        (state: IRootState) => isSchedulePlanFundsDownloading(state),
        (state: IRootState) => isSchedulePlanNeedDownloading(state),
        (state: IRootState) => isUsersCategoryTypeOptionsStatus(state),
        (fundDownloading, needDownloading, usersCategoryTypeLoading) => {
            return fundDownloading || needDownloading || usersCategoryTypeLoading;
        }
    );

    const isLoading = useAppSelector(isLoadingSelector);

    return (
        <Dialog
            name="schedulePlanDownloadDialog"
            openButtonValue={undefined}
            openButton={undefined}
            title={<StyledTitle>{t('label.downloadSchedulePlan', 'Download Schedule Plan')}</StyledTitle>}
            maxWidth="md"
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <StyledBottomNavigation
                showLabels
                value={type}
                onChange={(event, value) => {
                    setType(value);
                }}
                data-testid="summarizationDialogTypeNavigation"
            >
                <BottomNavigationAction
                    label={t('label.accountingExport', 'Accounting Export')}
                    value={SchedulePlanDownloadTypeEnum.megalax}
                    icon={<FileDownloadOutlinedIcon />}
                />
                <BottomNavigationAction
                    label={t('label.needs', 'Needs')}
                    value={SchedulePlanDownloadTypeEnum.needs}
                    icon={<BoyOutlinedIcon />}
                />
            </StyledBottomNavigation>
            <FormGenerator
                name="userDownloadForm"
                displayAsModal={false}
                displayAsSidebar={false}
                onSubmit={handleSubmit}
                fields={[
                    {
                        type: 'select',
                        display: () => type === SchedulePlanDownloadTypeEnum.megalax,
                        props: {
                            name: 'userSourceType',
                            label: t('label.userCategoryType', 'User Category Type'),
                            required: (values) => {
                                const workplaceIdsList = values.workplaceIds as string[];

                                return type === SchedulePlanDownloadTypeEnum.megalax && workplaceIdsList.length === 0;
                            },
                            disabled: (values) => {
                                const workplaceIdsList = values.workplaceIds as string[] | undefined;

                                return (workplaceIdsList?.length ?? 0) > 0;
                            },
                            options: usersCategoryTypeOptionsData.map((item) => ({
                                id: `${item}`,
                                label: item
                            })),
                            validation: {
                                deps: ['workplaceIds', 'userSourceType']
                            }
                        }
                    },
                    {
                        type: 'multiSelect',
                        display: () => type === SchedulePlanDownloadTypeEnum.megalax,
                        props: {
                            name: 'workplaceIds',
                            required: (values) => {
                                const userSourceType = values.userSourceType as string | null;

                                return (
                                    type === SchedulePlanDownloadTypeEnum.megalax && (userSourceType?.length ?? 0) === 0
                                );
                            },
                            label: t('label.workplace', 'Workplace'),
                            disabled: (values) => {
                                const userSourceType = values.userSourceType as string | null;

                                return (userSourceType?.length ?? 0) > 0;
                            },
                            options: () =>
                                workplacesData
                                    ?.slice()
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((item) => ({
                                        id: `${item.id}`,
                                        label: item.name
                                    })),
                            validation: {
                                deps: ['workplaceIds', 'userSourceType']
                            }
                        }
                    },
                    {
                        type: 'select',
                        display: () => type === SchedulePlanDownloadTypeEnum.needs,
                        props: {
                            name: 'schedulePlanId',
                            label: t('label.schedulePlan', 'Schedule Plan'),
                            required: () => type === SchedulePlanDownloadTypeEnum.needs,
                            options: schedulePlanDataList.map((item) => ({
                                id: `${item.id}`,
                                label: item.name
                            })),
                            validation: {
                                deps: ['schedulePlanId']
                            }
                        }
                    },
                    {
                        type: 'multiSelect',
                        display: () => type === SchedulePlanDownloadTypeEnum.megalax,
                        props: {
                            name: 'months',
                            required: () => type === SchedulePlanDownloadTypeEnum.megalax,
                            label: t('label.month', 'Month'),
                            options: getMonthNames(i18n.language ?? config.defaultLocale).map((month, index) => {
                                const monthId = index + 1;

                                return {
                                    id: `${monthId}`,
                                    label: month
                                };
                            }),
                            validation: {
                                deps: ['months']
                            },
                            width: 6
                        }
                    },
                    {
                        type: 'date',
                        display: () => type === SchedulePlanDownloadTypeEnum.megalax,
                        props: {
                            name: 'year',
                            label: t('label.year', 'Year'),
                            required: () => type === SchedulePlanDownloadTypeEnum.megalax,
                            value: DateHelper.now(),
                            openTo: 'year',
                            views: ['year'],
                            minDate: DateHelper.now().subtract(5, 'year'),
                            maxDate: DateHelper.now(),
                            width: 6
                        }
                    },
                    {
                        type: 'switch',
                        display: () => type === SchedulePlanDownloadTypeEnum.megalax && canUserClose,
                        props: {
                            name: 'close_exported_plans',
                            label: t('label.closeExportedSchedulePlans', 'Close Exported Schedule Plans'),
                            value: false
                        }
                    }
                ]}
                actions={[
                    {
                        type: 'button',
                        props: {
                            type: 'button',
                            name: 'cancel',
                            variant: 'text',
                            onClick: handleClose,
                            children: t('label.cancel', 'Cancel'),
                            width: 6
                        }
                    },
                    {
                        type: 'loadingButton',
                        props: {
                            type: 'submit',
                            name: 'download',
                            variant: 'contained',
                            children: t('label.download', 'Download'),
                            width: 6,
                            loading: isLoading,
                            validation: {
                                deps: ['months', 'workplaceIds', 'userSourceType', 'schedulePlanId']
                            },
                            disabled: ({ months, workplaceIds, userSourceType, schedulePlanId }) => {
                                switch (type) {
                                    case SchedulePlanDownloadTypeEnum.megalax:
                                        return (
                                            ((months ?? []) as string[]).length === 0 ||
                                            (((workplaceIds ?? []) as string[]).length === 0 && !userSourceType)
                                        );
                                    case SchedulePlanDownloadTypeEnum.needs:
                                        return !schedulePlanId;
                                    default:
                                        return true;
                                }
                            }
                        }
                    }
                ]}
            />
        </Dialog>
    );
};

export default SchedulePlanDownloadDialog;
