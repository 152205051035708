import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '@/data/hooks';
import { schedulePlanDaysBySchedulePlanId } from '@/data/SchedulePlanDays/SchedulePlanDaySlice';
import { ISchedulePlanOptModel } from '@/data/SchedulePlans/SchedulePlanModels';
import {
    getPreparedTableBodyOfPlan,
    isPlanLoaded,
    selectSkillsUsedByPlanId,
    selectTimeZoneOfPlan
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { shiftEntities } from '@/data/Shifts/ShiftSlice';
import { skillEntities } from '@/data/Skills/SkillSlice';
import { isSignedUserAdmin } from '@/data/System/SystemReducer';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import useSchedulerColumnTitles from '@/hooks/scheduler/useSchedulerColumnTitles';
import useSchedulerRequirements from '@/hooks/scheduler/useSchedulerRequirements';
import HeaderDateContent from '@/modules/Scheduler/components/HeaderDateContent';
import LeftTopCorner from '@/modules/Scheduler/components/LeftTopCorner';
import SchedulerBodyTooltip from '@/modules/Scheduler/components/SchedulerBodyTooltip';
import { IChildSchedulerTypes } from '@/modules/Scheduler/Scheduler';
import { SchedulerContext } from '@/modules/Scheduler/SchedulerContext';
import SchedulerTableBodyCellDayForm from '@/modules/Scheduler/SchedulerTableBodyCellDayForm';
import {
    EmptyCell,
    EmptyCellWrapper,
    StyledTopDataWrapper,
    StyledWrapper,
    TableEmptyCell,
    TableFooterWrapper
} from '@/modules/Scheduler/StyledParts';
import { StyledBodyAddIcon } from '@/modules/Scheduler/StyledTableCell';
import SchedulerTableBodyCell from '@/modules/Scheduler/Table/SchedulerTableBodyCell';
import { serializeUser } from '@/utils/UserHelper';
import VisibleOnlyRenderer, { VisibilityOnlyRenderContextProvider } from '@/wrappers/VisibleOnlyRenderer';
import SchedulerTableSideBarShift from './SchedulerTableSideBarShift';
import SchedulerTableSideBarSkill from './SchedulerTableSideBarSkill';

export type IProps = IChildSchedulerTypes & {
    isSchedulePlanClosed?: boolean;
};

export const GridRootSideBar = styled(Box)`
    grid-area: scheduleSideBar0;
    display: grid;
    grid-auto-rows: 50px;
    position: sticky;
    left: 0;
    min-width: max-content;
    z-index: 1;
    background-color: white;
    border-right: 2px black solid;

    > * {
        padding: 5px;
    }
`;

const GridRootData = styled(Box)`
    grid-area: data0;
    display: grid;
    grid-auto-rows: 50px;

    > * {
        border-right: 1px solid black;
    }
`;
const StyledTopDataWrapperTable = styled(StyledTopDataWrapper)`
    > * {
        display: block !important;
    }
`;

export const StyledSkillItem = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'withBorder'
})<{ withBorder: boolean }>(({ withBorder }) => ({
    display: 'grid',
    gridColumnStart: 1,
    paddingRight: '5px',
    alignItems: 'normal',
    ...(withBorder ? { borderTop: '1px black solid' } : {})
}));
export const StyledSkillContentItem = styled(Box)``;
export const StyledUserItem = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'withBorder'
})<{ withBorder: boolean }>(({ withBorder }) => ({
    display: 'grid',
    gridColumnStart: 2,
    alignItems: 'center',
    ...(withBorder ? { borderTop: '1px black solid' } : {})
}));

const SchedulerTable = ({ from, schedulePlanId, to, isSchedulePlanClosed = false }: IProps) => {
    const theme = useTheme();
    const { compactMode } = useContext(SchedulerContext);
    const [, setIsRefInitialized] = useState(false);
    const [dataToForm, setDataToForm] = useState<{
        date: Date | DateTimeType;
        from: Date | null;
        id: number | null;
        to: Date | null;
        userId: number | null;
    } | null>(null);
    const isUserAdmin = useAppSelector(isSignedUserAdmin);
    const isLoaded = useAppSelector((state) => isPlanLoaded(state, schedulePlanId));
    const timeZone = useAppSelector((state) => selectTimeZoneOfPlan(state, schedulePlanId));
    const schedulePlanDays = useAppSelector((state) => schedulePlanDaysBySchedulePlanId(state, schedulePlanId));
    const scrollRef = useRef<HTMLDivElement>(null);
    const schedulerRef = useRef<HTMLDivElement>(null);
    const skills = useAppSelector((state) => selectSkillsUsedByPlanId(state, schedulePlanId));
    const skillEntitiesData = useAppSelector(skillEntities);
    const shifts = useAppSelector(shiftEntities);
    const body = useAppSelector((state) => getPreparedTableBodyOfPlan(state, schedulePlanId));
    const headerRows = useSchedulerRequirements(false, from, to, schedulePlanDays ?? [], skills ?? [], []);
    const columnTitles = useSchedulerColumnTitles(false, from, to);

    const flooredNowDate = DateHelper.getUTCStartOfTheDay(
        DateHelper.subtractMinutesByTimezone(DateHelper.now(), timeZone)
    );

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: 'instant',
                block: 'start',
                inline: 'center'
            });
        } else if (schedulerRef.current) {
            schedulerRef.current.scrollIntoView({
                behavior: 'instant',
                block: 'start',
                inline: 'center'
            });
        }
    }, [scrollRef.current, schedulerRef.current, isLoaded]);

    let rowStartCounter = 1;

    const sortedBody = useMemo(
        () =>
            (body ?? [])
                .map((skillItem) => ({
                    ...skillItem,
                    skill: skillEntitiesData[skillItem.id] ?? null
                }))
                .sort((a, b) => (a.skill?.name ?? '~').localeCompare(b.skill?.name ?? '~'))
                .map((skillItem) => {
                    const currentRowStart = rowStartCounter;

                    let skillRowStart = currentRowStart;

                    rowStartCounter += skillItem.count_of_rows;

                    return {
                        ...skillItem,
                        row_start: currentRowStart,
                        data:
                            from && to
                                ? (
                                      (typeof skillItem.data === 'object'
                                          ? Object.values(skillItem.data)
                                          : Array.from(skillItem.data)) as ArrayElement<
                                          ISchedulePlanOptModel['table_body']
                                      >['data']
                                  )
                                      .map((shiftItem) => ({ ...shiftItem, shift: shifts[shiftItem.id] ?? null }))
                                      .sort((a, b) =>
                                          (a.shift?.abbreviation ?? '~').localeCompare(b.shift?.abbreviation ?? '~')
                                      )
                                      .map((shiftItem) => {
                                          const currentShiftStart = skillRowStart;

                                          skillRowStart += shiftItem.count_of_rows;

                                          return {
                                              ...shiftItem,
                                              row_start: currentShiftStart,
                                              data: shiftItem.data.map((column) => ({
                                                  ...column,
                                                  from: new Date(column.from),
                                                  ff: 't',
                                                  shift_joins: column.shift_joins
                                                      .map((shiftJoin) => ({
                                                          ...shiftJoin,
                                                          userName: serializeUser(shiftJoin)
                                                      }))
                                                      .sort((a, b) => a.userName.localeCompare(b.userName))
                                              }))
                                              //.filter((column) => DateHelper.isBetween(column.from, from, to, '[)'))
                                          };
                                      })
                                : []
                    };
                }),
        [from, to, body]
    );

    useEffect(() => {
        setIsRefInitialized(schedulerRef.current !== null);
    }, [schedulerRef.current]);
    useEffect(() => {
        setIsRefInitialized(schedulerRef.current !== null);
    }, []);

    const validFrom = from ? DateHelper.toDate(from) : null,
        validTo = to ? DateHelper.addDays(DateHelper.toDate(to), 1) : null;

    return (
        <StyledWrapper
            countOfRows={1}
            countOfSkills={0}
            hasUnassignedRow={false}
            isClosed={isSchedulePlanClosed}
            isCompact={compactMode}
            isTableMode={true}
            sx={{
                gridTemplateRows: '50px max-content 50px'
            }}
            data-testid="scheduler-table"
            ref={schedulerRef}
        >
            <VisibilityOnlyRenderContextProvider container={schedulerRef.current} offsetTop={1}>
                {isLoaded && (
                    <>
                        <LeftTopCorner isCollapsed={false} withCollapse={false} onClick={undefined} />
                        <StyledTopDataWrapperTable countOfColumns={columnTitles.length} isCompact={compactMode}>
                            {columnTitles.map((column, index) => (
                                <HeaderDateContent
                                    key={column.id}
                                    ref={DateHelper.isEqual(flooredNowDate, column.from) ? scrollRef : null}
                                    from={column.from}
                                    isDayMode={false}
                                    isCompact={compactMode}
                                    sx={{
                                        ...(index > 0 ? { borderLeft: '1px black solid' } : {}),
                                        ...(DateHelper.isEqual(flooredNowDate, column.from)
                                            ? {
                                                  borderLeft: '2px black solid',
                                                  fontWeight: 'bold'
                                              }
                                            : DateHelper.isAfter(flooredNowDate, column.from)
                                            ? { backgroundColor: theme.palette.grey.A200 }
                                            : {})
                                    }}
                                    timeZone={timeZone ?? 'UTC'}
                                    data-testid={`date_${index}`}
                                />
                            ))}
                        </StyledTopDataWrapperTable>
                        {sortedBody.length ? (
                            <GridRootSideBar data-testid="headerSideBarSkill">
                                {sortedBody?.map((skillData, skillIndex) => (
                                    <Fragment key={skillData.id}>
                                        <StyledSkillItem
                                            withBorder={skillIndex !== 0}
                                            sx={{
                                                gridRow: `${skillData.row_start}/${
                                                    skillData.row_start + skillData.count_of_rows
                                                }`
                                            }}
                                        >
                                            <SchedulerTableSideBarSkill id={skillData.id} />
                                        </StyledSkillItem>
                                        {skillData.data.map((shiftData) => (
                                            <SchedulerTableSideBarShift
                                                key={shiftData.id}
                                                id={shiftData.id === '~' ? null : shiftData.id}
                                                isFirst={shiftData.row_start === 1}
                                                rowEnd={shiftData.row_start + shiftData.count_of_rows}
                                                rowStart={shiftData.row_start}
                                            />
                                        ))}
                                    </Fragment>
                                ))}
                            </GridRootSideBar>
                        ) : null}
                        <GridRootData sx={{ gridTemplateColumns: `repeat(${columnTitles.length}, 1fr)` }}>
                            {schedulePlanId && validFrom && validTo ? (
                                sortedBody.map(({ row_start: skillRowStart, ...skillData }, skillIndex) =>
                                    skillData.data.map(({ row_start: rowStart, ...shiftData }) => {
                                        return shiftData.data
                                            .filter((column) =>
                                                DateHelper.isBetween(column.from, validFrom, validTo, '[)')
                                            )
                                            .map((column, gridColumnStart) => {
                                                return (
                                                    <Fragment key={gridColumnStart}>
                                                        {column.shift_joins.map((assignedShift, gridRowStart) => (
                                                            <VisibleOnlyRenderer
                                                                key={assignedShift.id}
                                                                columnEnd={1}
                                                                columnStart={gridColumnStart}
                                                                partialVisibility={true}
                                                                placeholder={<>{assignedShift.userName}</>}
                                                                rowStart={rowStart + gridRowStart}
                                                            >
                                                                <SchedulerTableBodyCell
                                                                    assignedShiftId={assignedShift.id}
                                                                    bodyFrom={DateHelper.getInstanceOf(validFrom)}
                                                                    columnDateFrom={DateHelper.toDate(
                                                                        columnTitles[gridColumnStart].from
                                                                    )}
                                                                    columnDateTo={DateHelper.toDate(
                                                                        DateHelper.addDays(
                                                                            columnTitles[gridColumnStart].from,
                                                                            1
                                                                        )
                                                                    )}
                                                                    currentDate={DateHelper.toDate(flooredNowDate)}
                                                                    gridColumnStart={gridColumnStart}
                                                                    gridRowStart={gridRowStart}
                                                                    rowStart={rowStart}
                                                                    skillRowStart={skillRowStart}
                                                                    schedulePlanId={schedulePlanId}
                                                                    onClick={() =>
                                                                        setDataToForm({
                                                                            date: DateHelper.toDate(
                                                                                columnTitles[gridColumnStart].from
                                                                            ),
                                                                            id: assignedShift.id,
                                                                            from: DateHelper.toDate(
                                                                                columnTitles[gridColumnStart].from
                                                                            ),
                                                                            to: DateHelper.toDate(
                                                                                DateHelper.addDays(
                                                                                    columnTitles[gridColumnStart].from,
                                                                                    1
                                                                                )
                                                                            ),
                                                                            userId: assignedShift.user_id
                                                                        })
                                                                    }
                                                                />
                                                            </VisibleOnlyRenderer>
                                                        ))}
                                                        {column.shift_joins.length <
                                                        rowStart - shiftData.count_of_rows - rowStart ? (
                                                            new Array(
                                                                rowStart -
                                                                    shiftData.count_of_rows -
                                                                    rowStart -
                                                                    column.shift_joins.length
                                                            )
                                                                .fill('')
                                                                .map((_, index) => (
                                                                    <EmptyCell
                                                                        key={`empty_${skillRowStart}_${rowStart}_${index}`}
                                                                        bodyFrom={validFrom}
                                                                        columnStart={gridColumnStart}
                                                                        columnWidth={1}
                                                                        columnFrom={DateHelper.toDate(
                                                                            columnTitles[gridColumnStart].from
                                                                        )}
                                                                        columnTo={DateHelper.toDate(
                                                                            DateHelper.addDays(
                                                                                columnTitles[gridColumnStart].from,
                                                                                1
                                                                            )
                                                                        )}
                                                                        currentDate={DateHelper.toDate(flooredNowDate)}
                                                                        isCurrentUser={false}
                                                                        isDayMode={true}
                                                                        rowStart={
                                                                            rowStart + index + column.shift_joins.length
                                                                        }
                                                                        sx={{
                                                                            ...(skillIndex !== 0 &&
                                                                            rowStart +
                                                                                index +
                                                                                column.shift_joins.length ===
                                                                                skillRowStart
                                                                                ? {
                                                                                      borderTop: '1px solid black'
                                                                                  }
                                                                                : {}),
                                                                            ...(gridColumnStart !== 0
                                                                                ? { borderLeft: '1px solid black' }
                                                                                : {}),
                                                                            borderRight: 'none'
                                                                        }}
                                                                    />
                                                                ))
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Fragment>
                                                );
                                            });
                                    })
                                )
                            ) : (
                                <></>
                            )}
                        </GridRootData>
                        {isUserAdmin && !isSchedulePlanClosed && from && to ? (
                            <>
                                <Box sx={{ gridArea: 'footerSideBar' }}></Box>
                                <TableFooterWrapper
                                    sx={{
                                        gridTemplateColumns: `repeat(${columnTitles.length}, 1fr)`
                                    }}
                                >
                                    {columnTitles.map((column, index) => (
                                        <TableEmptyCell
                                            key={DateHelper.formatISO(column.from)}
                                            data-testid={`schedulerTableBodyCell_${DateHelper.formatDateTime(
                                                column.from
                                            ).replace(' ', '_')}_empty`}
                                            bodyFrom={DateHelper.toDate(from)}
                                            isCurrentUser={false}
                                            currentDate={DateHelper.toDate(flooredNowDate)}
                                            isDayMode={false}
                                            columnStart={index}
                                            columnFrom={DateHelper.toDate(column.from)}
                                            columnTo={DateHelper.toDate(DateHelper.addHours(column.from, 24))}
                                            columnWidth={1}
                                        >
                                            <SchedulerBodyTooltip
                                                isEmpty
                                                columnFrom={DateHelper.toDate(column.from)}
                                                isDayMode={false}
                                                timeZone={timeZone}
                                                wrapperStyles={{ height: '100%', width: '100%' }}
                                            >
                                                <EmptyCellWrapper
                                                    sx={{ minHeight: '50px' }}
                                                    onClick={
                                                        isUserAdmin
                                                            ? () =>
                                                                  setDataToForm({
                                                                      id: null,
                                                                      from: null,
                                                                      to: null,
                                                                      userId: null,
                                                                      date: column.from
                                                                  })
                                                            : undefined
                                                    }
                                                >
                                                    <StyledBodyAddIcon
                                                        noHover={true}
                                                        className="icon"
                                                        data-testid="noShift"
                                                        sx={{ borderRadius: '50px' }}
                                                    />
                                                </EmptyCellWrapper>
                                            </SchedulerBodyTooltip>
                                        </TableEmptyCell>
                                    ))}
                                </TableFooterWrapper>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </VisibilityOnlyRenderContextProvider>
            {dataToForm !== null && from !== null && to !== null ? (
                <SchedulerTableBodyCellDayForm
                    id={dataToForm.id}
                    isDayMode={false}
                    date={DateHelper.getInstanceOf(dataToForm.date)}
                    recommendedSkills={
                        headerRows?.map((headerRow) => ({
                            skillId: headerRow.id,
                            columns: headerRow.columns.filter((headerRowColumn) =>
                                DateHelper.isBetween(headerRowColumn.date, from, to, '[)')
                            )
                        })) ?? null
                    }
                    schedulePlanId={schedulePlanId}
                    shiftJoin={
                        dataToForm.id && dataToForm.from && dataToForm.to
                            ? {
                                  id: dataToForm.id,
                                  from: dataToForm.from,
                                  to: dataToForm.to
                              }
                            : null
                    }
                    type="table"
                    userId={dataToForm.userId}
                    onClose={() => setDataToForm(null)}
                />
            ) : (
                <></>
            )}
        </StyledWrapper>
    );
};

export default SchedulerTable;
