/**
 * Worker throws error when we try access to Window object
 */
import languages from '@/utils/languages';
import version from '@/utils/version';

const configStatic = {
    version: version ?? 'v0.0-develop',
    apiServer: () => window.location.protocol + '//' + window.location.host,
    date: {
        inputFormat: 'DD/MM/YYYY',
        momentFormat: 'DD/MM/YYYY',
        dbFormat: 'YYYY-MM-DD',
        calendar: {
            headerFormat: 'ddd DD/MM'
        }
    },
    time: {
        inputFormat: 'HH:mm',
        dbFormat: 'HH:mm:ss',
        calendar: {
            eventFormat: 'HH:mm'
        }
    },
    dateTime: {
        format: 'YYYY-MM-DD HH:mm',
        inputFormat: 'DD/MM/YYYY HH:mm',
        formatWithSeconds: 'YYYY-MM-DD HH:mm:ss'
    },
    languages
};

export default configStatic;
