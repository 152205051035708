import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, remove, update } from './TimeoffApi';
import { ITimeoffCUModel } from './TimeoffModels';
import { timeoffPaging } from './TimeoffReducer';

export const fetchTimeoffs = createAsyncThunk('timeoffs/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = timeoffPaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchTimeoffById = createAsyncThunk('timeoffs/byId', async (args: number) => {
    return await fetchById(args);
});

export const createTimeoff = createAsyncThunk('timeoffs/create', async (args: ITimeoffCUModel) => {
    return await create(args);
});

export const updateTimeoff = createAsyncThunk(
    'timeoffs/update',
    async (args: { id: number; data: Partial<ITimeoffCUModel> }) => {
        return await update(args.id, args.data);
    }
);

export const removeTimeoff = createAsyncThunk('timeoffs/remove', async (id: number) => remove(id));
