import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchNeeds, removeNeed } from '@/data/Needs/NeedActions';
import { INeedModel } from '@/data/Needs/NeedModels';
import { needList, needPaging } from '@/data/Needs/NeedSlice';
import NeedsForm from '@/forms/NeedsForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import NeedTypesOfDaysEnum from '@/utils/enums/NeedTypesOfDaysEnum';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import Chip from '@/wrappers/Chip';
import DateRange from '@/wrappers/Datatable/CellViews/DateRange';
import Icon from '@/wrappers/Datatable/CellViews/Icon';
import Weekdays from '@/wrappers/Datatable/CellViews/Weekdays';
import Switch from '@/wrappers/Switch';

export default function NeedsListPage() {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(needList);

    return (
        <LayoutOfPage title={t('title.needsList', 'Needs List')}>
            <CrudDatatable<INeedModel>
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <NeedsForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    {
                        headerName: t('header.id', 'ID'),
                        field: 'id'
                    },
                    {
                        headerName: t('header.skillName', 'Skill Name'),
                        field: 'Skills.name',
                        flex: 0.5,
                        renderCell: ({ row }) => (row.skill?.icon ? <Icon {...row.skill} /> : undefined)
                    },
                    {
                        headerName: t('header.daysOrNumberOfDays', 'Days/Number Of Days'),
                        field: 'daysOrNumberOfDays',
                        sortable: false,
                        renderCell: ({ row }) => {
                            if (
                                [NeedTypesOfDaysEnum.DaysPicker, NeedTypesOfDaysEnum.ContractFilling].some(
                                    (item) => row.days_type === item
                                )
                            ) {
                                return <Weekdays days={row.days!} holidays={row.holiday} />;
                            }

                            if (row.days_type === NeedTypesOfDaysEnum.NumberOfDaysPerWeek) {
                                return (
                                    <>
                                        {row.number_of_days} {t('label.days/Week', 'Days/Week')}
                                    </>
                                );
                            }

                            return <></>;
                        }
                    },
                    {
                        headerName: t('header.workplace', 'Workplace'),
                        field: 'workplace',
                        flex: 0.5,
                        sortable: false,
                        renderCell: ({ row }) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                name={`workplace-${row.workplace_id}`}
                                label={row.workplace.name}
                            />
                        )
                    },
                    {
                        headerName: t('header.valid', 'Valid'),
                        field: 'period_start',
                        flex: 0.5,
                        renderCell: ({ row }) =>
                            row.period && <DateRange start={row.period.period_start} end={row.period.period_end} />
                    },
                    {
                        headerName: t('header.holiday', 'Holiday'),
                        field: 'holiday',
                        type: 'boolean',
                        renderCell: ({ row }) => <Switch name="holiday" value={row.holiday} readOnly={true} />
                    },
                    {
                        headerName: t('header.weekends', 'Weekends'),
                        field: 'weekends',
                        type: 'boolean',
                        renderCell: ({ row }) => <Switch name="weekend" value={row.weekends} readOnly={true} />
                    },
                    {
                        headerName: t('header.description', 'Description'),
                        field: 'description'
                    },
                    {
                        headerName: t('header.createdAt', 'Created At'),
                        type: 'dateTime',
                        field: 'created'
                    },
                    {
                        headerName: t('header.updatedAt', 'Updated At'),
                        type: 'dateTime',
                        field: 'modified'
                    }
                ]}
                hiddenFields={['id', 'description', 'holiday', 'weekends', 'created', 'modified']}
                name="need"
                nameOfEntity={(item) => `Need with id ${item.id}`}
                paging={useAppSelector(needPaging)}
                resource={PermissionsEnum.Needs}
                onFetchList={fetchNeeds}
                onRemove={(id) =>
                    dispatch(removeNeed(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </LayoutOfPage>
    );
}
