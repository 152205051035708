import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchPeriods, removePeriod } from '@/data/Periods/PeriodActions';
import { IPeriodModel } from '@/data/Periods/PeriodModels';
import { periodPaging, selectFilteredPeriods } from '@/data/Periods/PeriodSlice';
import PeriodForm from '@/forms/PeriodForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import DateRange from '@/wrappers/Datatable/CellViews/DateRange';

export default function PeriodsListPage() {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectFilteredPeriods);

    return (
        <LayoutOfPage title={t('title.periodsList', 'Periods List')}>
            <CrudDatatable<IPeriodModel>
                autoHeight={false}
                data={data}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <PeriodForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    {
                        headerName: t('header.id', 'ID'),
                        field: 'id'
                    },
                    { headerName: t('header.periodName', 'Period Name'), field: 'name' },
                    { headerName: t('header.description', 'Description'), field: 'description' },
                    {
                        headerName: t('header.period', 'Period'),
                        field: 'period_start',
                        renderCell: ({ row }) => <DateRange start={row.period_start} end={row.period_end} />
                    },
                    {
                        headerName: t('header.active', 'Active'),
                        field: 'active',
                        type: 'boolean'
                    },
                    {
                        headerName: t('header.createdAt', 'Created At'),
                        type: 'dateTime',
                        field: 'created'
                    },
                    {
                        headerName: t('header.updatedAt', 'Updated At'),
                        type: 'dateTime',
                        field: 'modified'
                    }
                ]}
                name="period"
                hiddenFields={['id', 'active', 'created', 'modified']}
                nameOfEntity={(item) => item.name}
                paging={useAppSelector(periodPaging)}
                resource={PermissionsEnum.Periods}
                truncateCells={['description']}
                onFetchList={fetchPeriods}
                onRemove={(id) =>
                    dispatch(removePeriod(id))
                        .unwrap()
                        .then(() => id)
                }
            />
        </LayoutOfPage>
    );
}
