import Autocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { forwardRef, SyntheticEvent, useState } from 'react';
import TextField, { ITextFieldProps } from '@/wrappers/TextField';

export type ISelectOption = {
    id: string;
    label: string;
    custom?: { [key: string]: unknown };
};
export type ISelectProps = Omit<
    MuiAutocompleteProps<ISelectOption, false, boolean | undefined, false>,
    | 'multiple'
    | 'name'
    | 'getOptionLabel'
    | 'getOptionId'
    | 'renderInput'
    | 'renderOption'
    | 'onChange'
    | 'defaultValue'
    | 'value'
> &
    Pick<ITextFieldProps, 'name' | 'label' | 'disabled' | 'readOnly' | 'required'> & {
        value?: string | number;
        error?: boolean;
        helperText?: string;
        disabledInput?: boolean;
        onChange?: (selected: string | null, event?: SyntheticEvent) => void;
    };

const Select = forwardRef<HTMLDivElement, ISelectProps>(function Inner(
    { name, label, options = [], value, disabled, required, readOnly, error, helperText, onChange, ...rest },
    ref
) {
    const [optionMenuOpen, setOptionMenuOpen] = useState(false);
    const parsedValue = value ? `${value}` : undefined;
    const { disabledInput, ...props } = rest;
    const [highlightedOption, setHighlightedOption] = useState<ISelectOption | null>(null);

    return (
        <Autocomplete
            {...props}
            open={optionMenuOpen}
            onOpen={() => setOptionMenuOpen(true)}
            onClose={() => setOptionMenuOpen(false)}
            autoHighlight
            options={options}
            value={options.find((option) => option.id === `${parsedValue}`) ?? null}
            getOptionLabel={(option) => option.label}
            renderOption={(renderProps, option) => (
                <Box component="li" data-testid="select-item" {...renderProps}>
                    {option.label}
                </Box>
            )}
            onHighlightChange={(event, option) => {
                setHighlightedOption(option);
            }}
            disabled={disabled}
            readOnly={readOnly}
            isOptionEqualToValue={(option, selected) => option.id === selected.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    ref={ref}
                    name={name}
                    data-testid={`select_${name}`}
                    placeholder={props.placeholder}
                    label={label}
                    disabled={!!disabledInput || disabled}
                    required={required}
                    error={error}
                    helperText={helperText}
                    readOnly={readOnly}
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: typeof props.placeholder === 'string' ? !!props.placeholder : undefined
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            event.stopPropagation();

                            if (onChange && highlightedOption) {
                                onChange(highlightedOption.id, event);
                            }

                            setOptionMenuOpen(false);
                        }
                    }}
                />
            )}
            onChange={(event, selected) => onChange && onChange(selected?.id ?? null, event)}
        />
    );
});

export default Select;
