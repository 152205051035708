import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, remove, update } from './AgentGroupApi';
import { IAgentGroupCUModel } from './AgentGroupModels';
import { agentGroupPaging } from './AgentGroupReducer';

export const fetchAgentGroups = createAsyncThunk('agentGroups/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = agentGroupPaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchAgentGroupById = createAsyncThunk('agentGroups/byId', async (args: number) => {
    return await fetchById(args);
});

export const createAgentGroup = createAsyncThunk('agentGroups/create', async (args: IAgentGroupCUModel) => {
    return await create(args);
});

export const updateAgentGroup = createAsyncThunk(
    'agentGroups/update',
    async (args: { id: number; data: IAgentGroupCUModel }) => {
        return await update(args.id, args.data);
    }
);

export const removeAgentGroup = createAsyncThunk('agentGroups/remove', async (id: number) => remove(id));
