import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { WithRequiredProp } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { ICallableRef, IOutputValueType, ISupportedFieldType, ISupportedValueType } from '@/base/FormGenerator';
import { ISupportedValueSimpleType } from '@/base/FormGenerator/utils';
import CuForm, { ICuProps } from '@/components/CuForm';
import { IContractsDayData } from '@/data/Contracts/ContractModels';
import { contractList } from '@/data/Contracts/ContractSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchShiftsForSelect } from '@/data/Shifts/ShiftActions';
import { shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import { IUserContractsDayData, IUserCUContract } from '@/data/Users/UserModels';
import { dayTest } from '@/helpers/bit';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useTranslatedDays from '@/hooks/useTranslatedDays';
import { IDateRangePickerProps } from '@/wrappers/DateRangePicker';
import { ISelectOption } from '@/wrappers/Select';
import { ITimeRangePickerProps } from '@/wrappers/TimeRangePicker';

export type IUserToContractFormDataType = IUserCUContract & {
    isNew: boolean;
    day_data_0?: IUserContractsDayData[];
    day_data_1?: IUserContractsDayData[];
    day_data_2?: IUserContractsDayData[];
    day_data_3?: IUserContractsDayData[];
    day_data_4?: IUserContractsDayData[];
    day_data_5?: IUserContractsDayData[];
    day_data_6?: IUserContractsDayData[];
};

type IProps = {
    props: Omit<ICuProps<IUserToContractFormDataType, number | string>, 'resource'>;
    data?: IUserToContractFormDataType;
    onDataChanged: (value: WithRequiredProp<IUserToContractFormDataType, 'id'>) => void;
    onRemove: (id: number | string) => void;
};

const UserToContractForm = ({
    data,
    props: { id, justIcon, displayAsModal, displayAsSidebar, ...props },
    onDataChanged,
    onRemove
}: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const translatedDays = useTranslatedDays();
    const contractsListData = useAppSelector(contractList);
    const shifts = useAppSelector(shiftsForSelect);
    const formRef = useRef<ICallableRef | null>(null);

    const handleCreate = useCallback(
        (values: ISupportedValueType) => {
            const period = values.period as IDateRangePickerProps['value'];

            const day0 = values.day_data_0;
            const day1 = values.day_data_1;
            const day2 = values.day_data_2;
            const day3 = values.day_data_3;
            const day4 = values.day_data_4;
            const day5 = values.day_data_5;
            const day6 = values.day_data_6;

            delete values.day_data_0;
            delete values.day_data_1;
            delete values.day_data_2;
            delete values.day_data_3;
            delete values.day_data_4;
            delete values.day_data_5;
            delete values.day_data_6;

            onDataChanged({
                ...values,
                id: generateUniqueID(),
                holidays_allowed: values.holidays_allowed === 'default' ? null : values.holidays_allowed === 'true',
                night_shifts_allowed:
                    values.night_shifts_allowed === 'default' ? null : values.night_shifts_allowed === 'true',
                full_fill: values.full_fill === 'default' ? null : values.full_fill === 'true',
                days: values.use_default_days ? undefined : values.days,
                day_data: values.use_default_days ? [] : [day0, day1, day2, day3, day4, day5, day6],
                contract_id: values.contract_id ? parseInt(values.contract_id.toString()) : undefined,
                start_at: period?.start ? DateHelper.formatDate(period.start) : null,
                end_at: period?.end ? DateHelper.formatDate(period.end) : null,
                isNew: true
            } as WithRequiredProp<IUserToContractFormDataType, 'id'>);
        },
        [id, onDataChanged]
    );

    const handleUpdate = useCallback(
        (values: ISupportedValueType) => {
            const period = values.period as IDateRangePickerProps['value'];

            const day0 = values.day_data_0;
            const day1 = values.day_data_1;
            const day2 = values.day_data_2;
            const day3 = values.day_data_3;
            const day4 = values.day_data_4;
            const day5 = values.day_data_5;
            const day6 = values.day_data_6;

            delete values.day_data_0;
            delete values.day_data_1;
            delete values.day_data_2;
            delete values.day_data_3;
            delete values.day_data_4;
            delete values.day_data_5;
            delete values.day_data_6;

            onDataChanged({
                ...values,
                id,
                holidays_allowed: values.holidays_allowed === 'default' ? null : values.holidays_allowed === 'true',
                night_shifts_allowed:
                    values.night_shifts_allowed === 'default' ? null : values.night_shifts_allowed === 'true',
                full_fill: values.full_fill === 'default' ? null : values.full_fill === 'true',
                days: values.use_default_days ? undefined : values.days,
                day_data: values.use_default_days ? [] : [day0, day1, day2, day3, day4, day5, day6],
                contract_id: values.contract_id ? parseInt(values.contract_id.toString()) : undefined,
                start_at: period?.start ? DateHelper.formatDate(period.start) : null,
                end_at: period?.end ? DateHelper.formatDate(period.end) : null,
                isNew: data?.isNew ?? false
            } as WithRequiredProp<IUserToContractFormDataType, 'id'>);
        },
        [id, onDataChanged]
    );
    const handleRemove = useCallback(() => id && onRemove(id), [id, onRemove]);

    const handleCopyFromContract = useCallback(() => {
        const contractId = formRef.current?.getFieldValue('contract_id');
        const contractData = contractsListData.find((item) => item.id == contractId);

        if (contractData) {
            const dayData: IContractsDayData[][] = [];

            contractData?.contracts_hours?.forEach((contractHourItem) => {
                const start = DateHelper.fromOptionalTime(contractHourItem.start);

                if (typeof dayData[contractHourItem.day] === 'undefined') {
                    dayData[contractHourItem.day] = [];
                }

                dayData[contractHourItem.day].push({
                    id: undefined,
                    time_range: {
                        start: start,
                        duration: DateHelper.getDifferenceInHours(
                            start,
                            DateHelper.fromOptionalTime(contractHourItem.end)
                        )
                    }
                });
            });

            formRef.current?.setFieldValue('days', contractData?.days);
            translatedDays.forEach((day, translatedDayIndex) => {
                if (dayData.some((item, daysIndex) => daysIndex === translatedDayIndex)) {
                    formRef.current?.setFieldValue(`day_data_${translatedDayIndex}`, dayData[translatedDayIndex]);
                } else {
                    formRef.current?.setFieldValue(`day_data_${translatedDayIndex}`, [
                        { add: undefined, time_range: { duration: null, start: null } }
                    ]);
                }
            });

            formRef.current?.setFieldValue('use_default_days', typeof contractData?.days == 'undefined');
            formRef.current?.setFieldValue('holidays_allowed', contractData?.holidays_allowed.toString() ?? 'default');
            formRef.current?.setFieldValue(
                'night_shifts_allowed',
                contractData?.night_shifts_allowed.toString() ?? 'default'
            );
            formRef.current?.setFieldValue('full_fill', contractData?.full_fill.toString() ?? 'default');
            formRef.current?.setFieldValue('week_hours_limit', contractData?.week_hours_limit ?? '');
            formRef.current?.setFieldValue('number_of_hours_per_day', contractData?.number_of_hours_per_day ?? '');
            formRef.current?.setFieldValue(
                'min_continuous_rest_per_week',
                contractData?.min_continuous_rest_per_week ?? ''
            );
            formRef.current?.setFieldValue('min_break_hours_per_day', contractData?.min_break_hours_per_day ?? '');
            formRef.current?.setFieldValue(
                'min_break_hours_per_day_splitted',
                contractData?.min_break_hours_per_day_splitted ?? ''
            );
            formRef.current?.setFieldValue(
                'break_at_least_after_hours',
                contractData?.break_at_least_after_hours ?? ''
            );
            formRef.current?.setFieldValue('day_hours_limit', contractData?.day_hours_limit ?? '');

            formRef.current?.setFieldValue('month_hours_limit', contractData?.month_hours_limit ?? '');
            formRef.current?.setFieldValue(
                'groups',
                (contractData?.contract_groups ?? []).length > 0
                    ? contractData.contract_groups
                    : [{ min: '', max: '', shift_ids: [] }]
            );
        }
    }, [formRef.current, contractsListData]);

    useEffect(() => {
        dispatch(fetchShiftsForSelect({ search: '' }));
    }, []);

    const booleanOptions: ISelectOption[] = useMemo(() => {
        return [
            {
                id: 'default',
                label: t('label.default', 'Default')
            },
            {
                id: 'true',
                label: t('label.active', 'Active')
            },
            {
                id: 'false',
                label: t('label.inactive', 'Inactive')
            }
        ];
    }, []);

    return (
        <CuForm<
            WithRequiredProp<IUserToContractFormDataType, 'id'>,
            IUserToContractFormDataType,
            number | string,
            false
        >
            {...props}
            id={id}
            maxWidth="xl"
            isAsync={false}
            innerRef={formRef}
            displayAsSidebar={true}
            displayAsModal={false}
            items={[
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'contract_id',
                        label: t('header.contractName', 'Contract Name'),
                        value: data?.contract_id?.toString(),
                        options: contractsListData.map((contract) => ({
                            id: contract.id.toString(),
                            label: contract.name
                        })),
                        width: 10,
                        onChange: (_newContractIdString) => {
                            formRef.current?.setFieldValue('days', undefined);
                            formRef.current?.setFieldValue('use_default_days', true);
                            formRef.current?.setFieldValue('holidays_allowed', 'default');
                            formRef.current?.setFieldValue('night_shifts_allowed', 'default');
                            formRef.current?.setFieldValue('full_fill', 'default');
                            formRef.current?.setFieldValue('week_hours_limit', '');
                            formRef.current?.setFieldValue('number_of_hours_per_day', '');
                            formRef.current?.setFieldValue('min_continuous_rest_per_week', '');
                            formRef.current?.setFieldValue('min_break_hours_per_day', '');
                            formRef.current?.setFieldValue('min_break_hours_per_day_splitted', '');
                            formRef.current?.setFieldValue('break_at_least_after_hours', '');
                            formRef.current?.setFieldValue('day_hours_limit', '');
                            formRef.current?.setFieldValue('month_hours_limit', '');
                            translatedDays.forEach((day, translatedDayIndex) => {
                                formRef.current?.setFieldValue(`day_data_${translatedDayIndex}`, [
                                    { add: undefined, time_range: { duration: null, start: null } }
                                ]);
                            });
                            formRef.current?.setFieldValue('groups', [{ min: '', max: '', shift_ids: [] }]);
                        },
                        validation: {
                            deps: 'contract_id'
                        }
                    }
                },
                {
                    type: 'button',
                    display: ({ contract_id }) => !!contract_id,
                    props: {
                        name: 'copyFromParent',
                        width: 2,
                        children: t('label.copy', 'Copy'),
                        onClick: handleCopyFromContract,
                        validation: {
                            deps: 'contract_id'
                        }
                    }
                },
                {
                    type: 'dateRange',
                    props: {
                        required: true,
                        optionalEnd: true,
                        name: 'period',
                        timezone: 'UTC',
                        label: {
                            start: t('header.contractStart', 'Contract Start'),
                            end: t('header.contractEnd', 'Contract End')
                        },
                        value: {
                            start: DateHelper.fromOptionalDateString(data?.start_at ?? null, 'utc'),
                            end: DateHelper.fromOptionalDateString(data?.end_at ?? null, 'utc')
                        }
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'use_default_days',
                        label: t('label.useDefaultDays', 'Use Default Days'),
                        value: typeof data?.days == 'undefined',
                        width: 12,
                        validation: {
                            deps: ['use_default_days']
                        }
                    }
                },
                {
                    type: 'weekdays',
                    display: ({ use_default_days }) => !use_default_days,
                    props: {
                        name: 'days',
                        value: data?.days,
                        multiple: true,
                        width: 12,
                        sx: { alignItems: 'center' },
                        validation: {
                            deps: ['days_type', 'days', 'use_default_days']
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: 'holidays_allowed',
                        label: t('label.holidaysAllowed', 'Holidays allowed'),
                        options: booleanOptions,
                        value: data?.holidays_allowed?.toString() ?? 'default',
                        width: 4
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: 'night_shifts_allowed',
                        label: t('label.nightShiftsAllowed', 'Night Shifts Allowed'),
                        options: booleanOptions,
                        value: data?.night_shifts_allowed?.toString() ?? 'default',
                        width: 4
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: 'full_fill',
                        label: t('label.fullFill', 'Full Fill'),
                        options: booleanOptions,
                        value: data?.full_fill?.toString() ?? 'default',
                        width: 4
                    }
                },
                {
                    type: 'html',
                    props: {
                        name: 'divider',
                        render: () => (
                            <>
                                <Divider />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        {t(
                                            'message.info.ifNextValuesAreNullThanDefaultValueWillBeTakenFromContract',
                                            'If Next Values Are Null Than Default Value Will Be Taken From Contract'
                                        )}
                                    </Typography>
                                </Box>
                            </>
                        )
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'day_hours_limit',
                        label: t('label.dayHoursLimit', 'Day Hours Limit'),
                        value: data?.day_hours_limit,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'week_hours_limit',
                        label: t('label.weekHoursLimit', 'Week Hours Limit'),
                        value: data?.week_hours_limit,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'number_of_hours_per_day',
                        label: t('label.numberOfHoursPerDay', 'Number Of Hours Per Day'),
                        value: data?.number_of_hours_per_day,
                        disabled: (currentValues) =>
                            currentValues.month_hours_limit !== '' &&
                            currentValues.month_hours_limit !== '0' &&
                            currentValues.month_hours_limit != null,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            deps: ['month_hours_limit', 'number_of_hours_per_day'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'min_continuous_rest_per_week',
                        label: t('label.minContinuousRestPerWeek', 'Min Continuous Rest Per Week'),
                        value: data?.min_continuous_rest_per_week,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'min_break_hours_per_day',
                        label: t('label.minBreakHoursPerDay', 'Min Break Hours Per Day'),
                        value: data?.min_break_hours_per_day,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'min_break_hours_per_day_splitted',
                        label: t('label.minBreakHoursPerDaySplitted', 'Min Break Hours Per Day Splitted'),
                        value: data?.min_break_hours_per_day_splitted,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'break_at_least_after_hours',
                        label: t('label.breakAtLeastAfterHours', 'Break At Least After Hours'),
                        value: data?.break_at_least_after_hours,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'month_hours_limit',
                        label: t('label.monthHoursLimit', 'Month Hours Limit'),
                        value: data?.month_hours_limit,
                        width: 6,
                        type: 'number',
                        disabled: (currentValues) =>
                            currentValues.number_of_hours_per_day !== '' &&
                            currentValues.number_of_hours_per_day !== '0' &&
                            currentValues.number_of_hours_per_day != null,
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            deps: ['number_of_hours_per_day', 'month_hours_limit'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'multiRowInputs',
                    props: {
                        name: 'groups',
                        value: data?.contract_groups,
                        inputs: [
                            {
                                type: 'textField',
                                props: {
                                    required: (currentValues, rowIndex) =>
                                        Array.isArray(currentValues.groups) &&
                                        rowIndex !== null &&
                                        currentValues.groups.length > rowIndex &&
                                        (currentValues.groups[rowIndex].shift_ids ?? []).length !== 0,
                                    name: 'min',
                                    label: t('label.min', 'Min'),
                                    type: 'number',
                                    InputProps: {
                                        inputProps: {
                                            min: 1
                                        }
                                    },
                                    width: 2,
                                    validation: {
                                        deps: ['shift_ids', 'min'],
                                        min: {
                                            value: 1,
                                            message: t('message.info.minimalValueIs1', 'Minimal value is {{value}}.', {
                                                value: 1
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    name: 'max',
                                    label: t('label.max', 'Max'),
                                    type: 'number',
                                    InputProps: {
                                        inputProps: {
                                            min: 1
                                        }
                                    },
                                    width: 2
                                }
                            },
                            {
                                type: 'multiSelect',
                                props: {
                                    name: 'shift_ids',
                                    required: (currentValues, rowIndex) =>
                                        Array.isArray(currentValues.groups) &&
                                        rowIndex !== null &&
                                        currentValues.groups.length > rowIndex &&
                                        currentValues.groups[rowIndex].min !== '' &&
                                        parseInt(`${currentValues.groups[rowIndex].min}`) !== 0,
                                    label: t('label.shifts', 'Shifts'),
                                    options:
                                        shifts?.map((item) => ({
                                            id: `${item.id}`,
                                            label: `${item.name}`
                                        })) ?? [],
                                    width: 6,
                                    validation: {
                                        deps: ['shift_ids', 'min']
                                    }
                                }
                            }
                        ]
                    }
                },
                {
                    type: 'html',
                    display: (values: IOutputValueType) =>
                        !!values.days && typeof values.days == 'number' && values.days > 0,
                    props: {
                        name: 'day_data_default_text',
                        render: () => (
                            <Typography variant="caption">
                                {t(
                                    'message.info.ifNextValuesAreNullThanDefaultValueWillBeWholeDay',
                                    'If Next Values Are Empty Than Default Value Will Be Whole Day'
                                )}
                            </Typography>
                        )
                    }
                },
                ...((translatedDays.length > 0
                    ? translatedDays.flatMap((day, index) => {
                          let dayData: ISupportedValueSimpleType = [];

                          if (typeof data !== 'undefined') {
                              dayData =
                                  (data as { [key: string]: ISupportedValueSimpleType })[`day_data_${index}`] ?? [];
                          }

                          return [
                              {
                                  type: 'html',
                                  display: (values: IOutputValueType) =>
                                      dayTest((values.days as number | null) || 0, day.id),
                                  props: {
                                      name: `day_divider_${index}`,
                                      render: () => (
                                          <>
                                              <Divider textAlign="left">
                                                  <Typography variant="subtitle2">{day.name}</Typography>
                                              </Divider>
                                          </>
                                      ),
                                      validation: {
                                          deps: ['weekdays']
                                      }
                                  }
                              },
                              {
                                  type: 'multiRowInputs',
                                  display: (values: IOutputValueType) =>
                                      dayTest((values.days as number | null) || 0, day.id),
                                  props: {
                                      name: `day_data_${index}`,
                                      value: dayData,
                                      inputs: [
                                          {
                                              type: 'timeRange',
                                              props: {
                                                  name: 'time_range',
                                                  disableEmpty: false,
                                                  label: {
                                                      start: t('label.start', 'Start'),
                                                      end: t('label.end', 'End')
                                                  },
                                                  minTime: (values: ISupportedValueType, rowIndex: number | null) => {
                                                      const time = values.day_data as
                                                          | {
                                                                time_range: ITimeRangePickerProps['value'];
                                                            }[][]
                                                          | undefined;

                                                      let previousTime = null;

                                                      if (rowIndex && rowIndex > 0 && time) {
                                                          previousTime = time[index][rowIndex - 1].time_range;
                                                      }

                                                      return previousTime
                                                          ? DateHelper.addMinutes(
                                                                DateHelper.addHours(
                                                                    previousTime.start,
                                                                    previousTime.duration
                                                                ),
                                                                10
                                                            )
                                                          : null;
                                                  },
                                                  minutesStep: 15,
                                                  width: 11,
                                                  validation: {
                                                      deps: [`day_data_${index}.time_range`]
                                                  }
                                              }
                                          }
                                      ]
                                  }
                              }
                          ];
                      })
                    : []) as ISupportedFieldType[])
            ]}
            justIcon={justIcon || false}
            name="userToContractForm"
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onRemove={handleRemove}
        />
    );
};

export default UserToContractForm;
