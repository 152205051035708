export default class DateTimeMonthFormat extends Intl.DateTimeFormat {
    format(date?: Date | number): string {
        if (typeof date === 'number' && date > 0 && date < 13) {
            const mm = date < 10 ? `0${date}` : date;

            date = new Date(`2017-${mm}-01T00:00:00+00:00`);
        } else if (typeof date === 'number') {
            return '';
        }

        return super.format(date);
    }
}
